const initialState = 0;

const searchResultsTableSelectedRowReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARACH_SEARCH_SEQUENCE_DOCUMENT':
      return initialState;
    case 'SELECT_TABLE_ROW':
      return action.id;
    default:
      break;
  }
  return state;
}

export default searchResultsTableSelectedRowReducer;