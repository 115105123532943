import React, {Component} from 'react';
import { Modal, Button, Table } from 'react-bootstrap'
import axios from "axios";
import configData from "../../../config/config.json";

export default class LegalDetails extends Component {

   state = {
		metesAndBoundsHeaders:[],
      metesAndBoundsRows: [],
		lotsAndBlocksHeaders: [],
      lotsAndBlocksRows: [],
		condoHeaders: [],
		condoRows: []
	  }

   handleCloseClick = () => {
      this.props.hideLegalDetails();
   }

   componentDidMount() {
      axios.post(configData.LANDSHARK_SERVER_URL + "search/legalDescriptionDetails", {id: this.props.docId}, { withCredentials: true })
      .then(
        (response) => {
          const content = response.data.content;
          this.setState( {
            metesAndBoundsHeaders: content.metesAndBoundsHeaders,
            metesAndBoundsRows: content.metesAndBoundsRows? content.metesAndBoundsRows.legalDescriptionTypeDetails: [],
            lotsAndBlocksHeaders: content.lotsAndBlocksHeaders,
            lotsAndBlocksRows: content.lotsAndBlocksRows? content.lotsAndBlocksRows.legalDescriptionTypeDetails : [],
            condoHeaders: content.condoHeaders,
            condoRows: content.condoRows?  content.condoRows.legalDescriptionTypeDetails : []
          });
        },
        (error) => {
         console.log(error);
        }
      );
    }

   render() {
      let metesAndBoundsHeaders = this.state.metesAndBoundsHeaders.map((header) => {
         return <th scope="col">{header}</th>
      })

      let metesAndBoundsDetails = this.state.metesAndBoundsRows.map((row) => {
         return <tr>
            {
               row.map((column) => {
                  return <td>{column}</td>
               })
            }
         </tr>
      })

      let metesAndBoundsTable = <></>;
      if (metesAndBoundsHeaders) {
         metesAndBoundsTable =
            <Table striped hover size="sm" >
               <thead>
                     <tr>
                     {metesAndBoundsHeaders}
                     </tr>
               </thead>
               <tbody>
                   {metesAndBoundsDetails}
               </tbody>
            </Table>
      }

      let lotsAndBlocksHeaders = this.state.lotsAndBlocksHeaders.map((header) => {
         return <th scope="col">{header}</th>
      })

      let lotsAndBlocksDetails = this.state.lotsAndBlocksRows.map((row) => {
         return <tr>
            {
               row.map((column) => {
                  return <td>{column}</td>
               })
            }
         </tr>
      })

      let lotsAndBlocksTable = <></>;
      if (lotsAndBlocksHeaders) {
         lotsAndBlocksTable =
            <Table striped hover size="sm" >
               <thead>
                  <tr>
                     {lotsAndBlocksHeaders}
                  </tr>
               </thead>
               <tbody>
                  {lotsAndBlocksDetails}
               </tbody>
            </Table>
      }

      let condoHeaders = this.state.condoHeaders.map((header) => {
         return <th scope="col">{header}</th>
      })

      let condoDetails = this.state.condoRows.map((row) => {
         return <tr>
            {
               row.map((column) => {
                  return <td>{column}</td>
               })
            }
         </tr>
      })

      let condoTable = <></>;
      if (condoHeaders) {
         condoTable =
            <Table striped hover size="sm" >
               <thead>
                  <tr>
                     {condoHeaders}
                  </tr>
               </thead>
               <tbody>
                 {condoDetails}
               </tbody>
            </Table>
      }

      return (

         <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
       
            <Modal.Header closeButton >
               <Modal.Title style={{color: '#3674AB'}}>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black',  height:'50vh', borderBottom: '2px solid black', overflowY: 'scroll'}}>
               {metesAndBoundsTable}
               {lotsAndBlocksTable}
               {condoTable}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
            </Modal.Footer>
         </Modal>
      
      )
   }
}