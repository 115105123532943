import React, { Component } from "react"
import { connect } from "react-redux";

import FlatFeeAddonWindow from "../search/FlatFeeAddonWindow";
import FlatFeeAddonConfirmationWindow from "../search/FlatFeeAddonConfirmationWindow";
import axios from "axios";
import configData from "../../config/config.json";
import Alert from '../common/modal/Alert'
import AjaxResult from "../../utils/AjaxResult";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"

import { LOADING_STATUES } from "../constants/constants";
import Loading from '../common/modal/Loading';
import { refreshUsageBalance, refreshUsageBalanceFromContent } from "../../redux/actions/otherLandLinkActions";

// Child state actions.
const ALERT_MESSAGE = 'MESSAGE';
const ALERT_WARNING = 'WARNING';

const matchDispatchToProps = (dispatch) => {
    return { 
        refreshUsageBalanceFromContent: (content) => dispatch(refreshUsageBalanceFromContent(content)),
        refreshUsageBalance: (userId) => dispatch(refreshUsageBalance(userId))
      }
}

const mapStateToProps = state => {
    return {
    };
}

// This class deals with adding to a flat fee account.
//
// Props:
// - show: boolean if the window should show
// - imageStateReference: { imageState: state }  If state==null, we will create
//     and destroy this internally.
// - backClicked(): notification that the component had the back button clicked (null==no button)
// - closeClicked(): notification that the component was closed
// - completed(): notification that a purchase was made
class FlatFeeAddon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addonWindow : this.createAddonWindowState(false),
            addonConfirmationWindow: this.createAddonConfirmationWindowState(null, false),

            alertWindow: {
                callback: null,
                message: '',
                show: false,
                type: ''
            },
            loadingStatus: ""
        };
        
        this.imageState = null;   // set if none in props
    }

    addShowLoading = (newState) => {
        newState.loadingStatus = LOADING_STATUES.LOADING;
    }

    showLoading = (func) => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          }, function() { 
            if (func) func();
          })

          return true;
        } else {
            if (func) func();
        } 

        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
    }

    loadOptions = () => {
        let me = this;
        this.showLoading( function() { me.loadOptions2(); });
    }

    loadOptions2 = () => {
        let request = {
        };

        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "flatFee/getAddOnOptions", request, { withCredentials: true })
                    .then((response) => {
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                            this.hideLoading();
                        } else {
                            this.imageState = result.content();
                            this.setState( {
                                addonWindow : this.createAddonWindowState(true),
                                loadingStatus: ''
                            });
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, this.showAlertText, null);
                        this.hideLoading();
                    })
        })();
    }

    componentDidMount = () => {
        if (!this.props.show) return;
        this.loadOptions();
    }

    componentDidUpdate = (prevProps, prevState) => {
        // We get here is the IMAGE button is clicked or an internal state change
        // occurs.
        if (!this.props.show) return;
        
        // Reload state if hide->show
        if (!prevProps.show) {
            if (this.hasImageStateProperty()) {
                this.setState( {
                    addonWindow : this.createAddonWindowState(true)
                } );
            } else {
                this.loadOptions();
            }
        }
    }

    createAddonWindowState = (show) => {
        return {
            show: show
        };
    }

    createAddonConfirmationWindowState = (content, show) => {
        return {
            content: content || {},
            show: show
        };
    }

    addonWindowCallbackBackClicked = () => {
        let newState = {};
        let me = this;

        this.addShowAddonWindow(newState, false);
        this.setState(newState, function() {
            me.cancelImageState( me.props.backClicked );
        });
    }

    addonConfirmationWindowCallbackBackClicked = () => {
        let newState = {};
        
        this.addShowAddonConfirmationWindow(newState, false);
        this.addShowAddonWindow(newState, true);
        this.setState(newState);
    }

    addonWindowCallbackCloseClicked = () => {
        let newState = {};
        let me = this;

        this.addShowAddonWindow(newState, false);
        this.setState(newState, function() {
            me.cancelImageState( me.props.closeClicked );
        });
    }

    addonConfirmationWindowCallbackCloseClicked = () => {
        let newState = {};
        let me = this;

        this.addShowAddonConfirmationWindow(newState, false);
        this.setState(newState, function() {
            me.cancelImageState( me.props.closeClicked );
        });
    }

    cancelImageState = (func) => {
        // We only cancel internally created state.
        if (!this.imageState) {
            if (func) func();
            return;
        }

        let request = {
            imageStateGuid: this.imageState.guid
        };
      
        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "imaging/cancelImageState", request, { withCredentials: true })
                    .then((response) => {
                      
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else {
                            this.imageState = null;
                        }

                        if (func) func();
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, this.showAlertText, null);
                        if (func) func();
                    })
        })();
    }

    hasImageStateProperty = () => {
        return this.props.imageStateReference && this.props.imageStateReference.imageState &&
            this.props.imageStateReference.imageState.purchaseOptions && this.props.imageStateReference.imageState.purchaseOptions.flatFeeOptions;
    }

    getImageState = () => {
        if (this.hasImageStateProperty()) {
            return this.props.imageStateReference.imageState;
        } else {
            return this.imageState;
        }
    }

    addonConfirmationWindowCallbackBuyClicked = () => {
        // Complete the payment.
        let me = this;
        let request = {
            imageStateGuid: this.getImageState().guid
        };
      
        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "flatFee/userAddOn", request, { withCredentials: true })
                    .then((response) => {

                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else if (result.content().ok) {
                            let newState = {};

                            this.addAlert(newState, 
                                me.createAlertWindowParams(true, result.content().message, null, ALERT_MESSAGE));
                            this.addShowAddonConfirmationWindow(newState, false);
                            this.setState(newState, function() {
                                me.cancelImageState(me.props.completed);
                            });
                        } else {
                            // Just stay on the page when an error occurs.
                            this.showAlert(
                                me.createAlertWindowParams(true, result.content().message, null, ALERT_WARNING));
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, this.showAlertText, null);
                    })
        })();
    }

    addonWindowCallbackBuyClicked = (state) => {
        // Complete the payment.
        let me = this;
        let request = {
            option: state.option,
            imageStateReference: { imageStateGuid: this.getImageState().guid }
        };

        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "flatFee/getUserAddOnConfirmation", request, { withCredentials: true })
                    .then((response) => {
                      
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else if (result.content().ok) {
                            // Close the confirm window, put up the image window.
                            let newState = {};

                            me.addShowAddonWindow(newState, false);
                            newState.addonConfirmationWindow = me.createAddonConfirmationWindowState(result.content(), true);
                            me.setState(newState);
                        } else {
                            // Just stay on the page when an error occurs.
                            me.showAlert(
                                me.createAlertWindowParams(true, result.content().errorMessage, null, ALERT_WARNING));
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, me.showAlertText, null);
                    })
        })();
    }

    addShowAddonWindow = (state, show) => {
        state.addonWindow = this.createAddonWindowState(show);
    }

    addShowAddonConfirmationWindow = (state, show) => {
        state.addonConfirmationWindow = this.createAddonConfirmationWindowState(null, show);
    }

    createAlertWindowParams = (show, message, callback, type) => {
        if (show) {
            return {
                callback: callback,
                message: message,
                show: show,
                type: type ? type : ALERT_MESSAGE
            };
        } else {
            return {
                callback: null,
                message: '',
                show: show,
                type: ALERT_MESSAGE
            };
        }
    }

    addAlert = (state, alertWindowParams) => {
		state.alertWindow = alertWindowParams;
    }
	
    showAlert = (alertWindowParams) => {
		this.setState({
		  alertWindow: alertWindowParams
		})
	  }
	
    showAlertText = (msg) => {
        this.showAlert(this.createAlertWindowParams(true, msg, null, ALERT_MESSAGE));
    }

	hideAlert = () => {
        // After the alert is confirmed, close the alert and pay for the order.
        let callback = this.state.alertWindow.callback;
        let params = this.createAlertWindowParams(false);
		this.setState({
			    alertWindow: params
		    },
            () => { if (callback) callback(); }
        );
	}

    render() {
        // console.log("BEGIN flatfeeaddon ###################################");
        // console.log("add on: " + this.state.addonWindow.show)
        // console.log("confirm: " + this.state.addonConfirmationWindow.show)
        // console.log("END flatfeeaddon ###################################");

        let imageState = this.getImageState();
        var title;
        var options;

        if (imageState == null) {
            title = '';
            options = [];
        } else {
            title = imageState.purchaseOptions.flatFeeOptions.title;
            options = imageState.purchaseOptions.flatFeeOptions.options;
        }

        return (
            <>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <FlatFeeAddonWindow
                    show={this.state.addonWindow.show}
                    title={title}
                    options={options}
                    backClicked={this.props.backClicked ? this.addonWindowCallbackBackClicked : null}
                    buyClicked={this.addonWindowCallbackBuyClicked}
                    closeClicked={this.addonWindowCallbackCloseClicked} />
                <FlatFeeAddonConfirmationWindow 
                    show={this.state.addonConfirmationWindow.show}
                    content={this.state.addonConfirmationWindow.content} 
                    backClicked={this.addonConfirmationWindowCallbackBackClicked}
                    buyClicked={this.addonConfirmationWindowCallbackBuyClicked}
                    closeClicked={this.addonConfirmationWindowCallbackCloseClicked} />
                <Alert show={this.state.alertWindow.show} hideAlert={this.hideAlert} message={this.state.alertWindow.message} type={this.state.alertWindow.type} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(FlatFeeAddon);