import axios from "axios";
import configData from "../../config/config.json";
import AjaxError from "../../utils/AjaxError";

export const login = (credential, defaultSearch, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "auth/login", credential, { withCredentials: true })
    .then((response) => {
      // We either have an error or the current user.
      if (response.data.content) {
        dispatch({ type: 'UPDATE_SEARCH_HISTORY', historyResults: response.data.content.searchHistoryList });
        dispatch({ type: 'UPDATE_CURRENT_USER', currentUser: response.data.content });
        dispatch({ type: 'SET_SELECTED_SEARCH_TYPE', selectedSearchType: defaultSearch });
        callback( { error: false } );
        history.push('/home'); // default to the home page after logging in
      } else {
        callback( { error: true, message: response.data.message });
      }
    }, (error) => {
      let result = new AjaxError(error);

      callback( { error: true, message: result.getRawErrorMessage() });
    });
};

export const logout = (history) => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "auth/logout", { withCredentials: true })
    .then((response) => {
      history.push('/login');
      dispatch({ type: 'LOGOUT_USER'  });
    }, (error) => {
       alert("Logout Failed");
    });
};