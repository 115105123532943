
const initialState = 0;

const searchDetailsReducer = (state = initialState, action) => {
   
    if (action.type === 'DISPLAY_DETAILS') {
        return action.id;
    }

    if (action.type === 'UPDATE_SEARCH_RESULTS' || action.type === 'UPDATE_PAGINATED_SEARCH_RESULTS' || action.type === 'APPLY_SEARCH_RESULTS_FILTER') {
        return 0;
    }

    if (action.type === 'RESET_SEARCH_DETAILS') {
        return 0;
    }
    
    return state;
};
export default searchDetailsReducer;