import React, {Component}  from 'react';
import { connect } from "react-redux";
import {NavLink} from 'react-router-dom'

import SideNavigationLabel from "./SideNavigationLabel";

const mapStateToProps = state => {
   return {
       sideNavigationOpen: state.sideNavigationReducer
   };
}

class SideNavigationNavLink extends Component {

    render(){
        let minWidth = 0
        if (this.props.sideNavigationOpen) {
            minWidth = 250
        }
        let className = "side-navlink";
        if (this.props.selected) {
            className += " side-navlink-selected"
        }
        return(
            <NavLink to={this.props.link} id={this.props.id} title={this.props.name} style={{ minWidth:minWidth }} className={className}><SideNavigationLabel {...{ image: this.props.image, name: this.props.name, badge:this.props.badge}} /></NavLink>
        )
     }
}

export default connect(mapStateToProps)(SideNavigationNavLink);