import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, LandSharkOneAuditLogControlPanelKey } from "../../redux/actions/controlPanelActions";

import moment from 'moment';

import configData from "../../config/config.json";
import { LOADING_STATUES } from "../constants/constants";
import Confirmation from '../common/modal/Confirmation';
import Loading from '../common/modal/Loading';
import Alert from '../common/modal/Alert';

const componentName = LandSharkOneAuditLogControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class LandSharkOneAuditLog extends Component {

  state = {
    landSharkOneDbAddress: "",
    landSharkOneDbName: "",
    landSharkOneDbUsername: "",
    landSharkOneDbPassword: "",
    fromDate: "",
    toDate: "",
    dataType: 'All',
    batchSize: 10000,
    message:"",
    showAlert: false,
    alertMessage: "",
    showMigrateConfirmationDialog: false,
    loadingStatus: "",
    errors: {}
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

	showLoading = () => {
		if (this.state.status === "") {
			this.setState({
				status: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleConfirmMigration = () => {

    if (this.showLoading() === true) {
      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/migrateLandSharkOneAuditLog", this.state, { withCredentials: true })
      .then(
        (response) => {
          this.setState({ 
            status: ''
          }, this.showAlert(response.data.message));
        },
        (error) => {
          this.setState({ 
            status: ''
          });
        }
      );
		}
	}

	showMigrateConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showMigrateConfirmationDialog: true
		})
	}

	hideMigrateConfirmation = () => {
		this.setState({
      status: '',
			showMigrateConfirmationDialog: false
		})
	}

  handleDatePickerChangeFromDate = (date) => {
    this.setState({
      fromDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToDate = (date) => {
    this.setState({
      toDate: moment(date).format('L')
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.showMigrateConfirmation(event);
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      message: "",
      errors: {},
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}

  componentDidMount() {
    this.props.setSelectedControlPanelType();
  }

  render() {
  
    const formProps = {
      componentName: componentName,
      pageHeading: "LandShark One Migration - AUDIT LOG",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      message: this.state.message,
      formGroupRows: [
        {
          formGroups: [{ id: 'landSharkOneDbAddress', label: 'LS1 DB Address (ex. localhost:1234)', fieldType: 'text', required: true, primaryCriteria: true, value: this.state.landSharkOneDbAddress }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbName', label: 'LS1 DB Name (ex. LS)', fieldType: 'text', required: true, primaryCriteria: true, value: this.state.landSharkOneDbName }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbUsername', label: 'LS1 DB Username', fieldType: 'text', required: true, primaryCriteria: true, value: this.state.landSharkOneDbUsername }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbPassword', label: 'LS1 DB Password', fieldType: 'password', required: true, primaryCriteria: true, value: this.state.landSharkOneDbPassword }]
        },
        {
          formGroups: [{ id: 'fromDate', label: "From Date", fieldType: 'date', value: this.state.fromDate, handleDatePickerChange: this.handleDatePickerChangeFromDate, error: this.state.errors['fromDate'] }]
        },
        {
          formGroups: [{ id: 'toDate', label: "To Date", fieldType: 'date', value: this.state.toDate, handleDatePickerChange: this.handleDatePickerChangeToDate, error: this.state.errors['toDate'] }]
        },
        {
          formGroups: [{ id: 'dataType', label: "Data Type", fieldType: 'select', options: [{code: "all", description: "All"}, {code: "transactions", description: "Transactions Only"}], value: this.state.dataType }]
        },
        {
          formGroups: [{ id: 'batchSize', label: "Migration Batch Size", fieldType: 'number', numberType:'integer', value: this.state.batchSize  }]
        }
      ]
    }

    let deleteQuestion = 'Clicking Yes will delete all previously migrated LS1 Audit Log data.  Continue?'; 
    if (this.state.fromDate != '' && this.state.toDate != '') {
      deleteQuestion = 'Clicking Yes will delete previously migrated ' + this.state.fromDate + '-' + this.state.toDate + ' LS1 Audit Log data .  Continue?'; 
    } else if (this.state.fromDate != '') {
      deleteQuestion = 'Clicking Yes will delete previously migrated LS1 Audit Log data from and include ' + this.state.fromDate + '.  Continue?'; 
    } else if (this.state.toDate != '') {
      deleteQuestion = 'Clicking Yes will delete previously migrated LS1 Audit Log data up to and include  ' + this.state.toDate + '.  Continue?'; 
    }

    return (
      <>
        <FormTemplate {...formProps} />
        <Confirmation show={this.state.showMigrateConfirmationDialog} cancelConfirmation={this.hideMigrateConfirmation} proceedConfirmation={this.handleConfirmMigration}  question={deleteQuestion} />
        <Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
        <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(LandSharkOneAuditLog);