import React from 'react';

import { Modal, Button } from 'react-bootstrap'

import { checkIcon, warningIcon, infoIcon } from '../../../img/font_awesome/font_awesome'

export default class Alert extends React.Component {

   handleCloseClick = () => {
      this.props.hideAlert();
   }

   render() {
      let title = ""

      if (this.props.title) {
         title = this.props.title;
      }

      let icon = checkIcon;
      if (this.props.type === 'WARNING') {
         icon = warningIcon;
      }

      if (this.props.type === 'INFO') {
         icon = infoIcon;
      }

      return (
         <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
       
            <Modal.Header closeButton >
               <Modal.Title style={{color: '#3674AB'}}>{icon} {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
               {this.props.message}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
            </Modal.Footer>
         </Modal>
      )
   }
}