import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import PlatSelectionList from "../../common/selection/PlatSelectionList";
import { loadDocuments, setSelectedSearchType, SpecialSearchPlatKey, SpecialSearchPlatUrl } from "../../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../constants/constants";

import axios from "axios";
import configData from "../../../config/config.json";

import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

import { handleError } from "../../../redux/actions/commonStuff";

const componentName = SpecialSearchPlatKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByPlat: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SpecialSearchPlatUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    notableDates: state.notableDatesReducer,
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
  
    plat: previousSearchKeyValue.plat ? previousSearchKeyValue.plat : null,
    platNumber: previousSearchKeyValue.plat ? previousSearchKeyValue.plat : null,
    platName: previousSearchKeyValue.platName ? previousSearchKeyValue.platName : null,
    allPlatList: state.platsReducer.codeDescriptionResultList,
    allPlatList: state.platsReducer.codeDescriptionResultList,
    allPlatNameList: state.platNamesReducer.codeDescriptionResultList,
    allPlatNumberList: state.platNumbersReducer.codeDescriptionResultList
  };
}

class SpecialSearchPlat extends Component {

  state = {
    plat: this.props.plat,
    platName: this.props.platName,
    platNumber: this.props.platNumber,

    showPlatNumberDropdown: false,
    platNumberFilteredList: this.props.allPlatNumberList,
    showPlatNameDropdown: false,
    platNameFilteredList: this.props.allPlatNameList,
    showPlatList: false,

    loadingStatus: "",
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {

    let targetValue = event.target.value;    
         if ((event.target.id !== 'platName' && event.target.id !== 'platNumber') || !this.state.plat) {
      this.setState({
        errors: {},
        submitMessage: '',
        [event.target.id]: targetValue
      })
    }
    
    if (event.target.id === 'platNameSelect') {
      let filteredList = this.props.allPlatList.filter(function (plat) {
        return plat.code == targetValue;
      });
      this.setState({
        showPlatNameDropdown: false,
        showPlatNumberDropdown: false,
        platNumber: targetValue,
        platName: filteredList[0].description,
        // platFound: true,
        plat: targetValue
      })
    } else if (event.target.id === 'platName' && !this.state.plat) {
      let filteredList = this.props.allPlatNameList.filter(function (plat) {
        return plat.description.toUpperCase().startsWith(targetValue.toUpperCase())
      });

      if (filteredList.length === 0) {
        let errors = {};
        errors["platName"] = "No matching plat found.";
        this.setState({
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat: '',
          errors: errors
        })
      } else if (filteredList.length === 1) {
        let namefilteredList = this.props.allPlatList.filter(function (plat) {
          return plat.code == filteredList[0].code;
        });
        this.setState({
          platNumber: filteredList[0].code,
          platName: namefilteredList[0].description,
          platNameFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          // platFound: true,
          plat: filteredList[0].code
        })
      } else if (targetValue.length >= 2) {
        filteredList = [{code: "-1", description: "(Click here to select a plat)"}].concat(filteredList);
      
        this.setState({
          platNameFilteredList: filteredList,
          showPlatNameDropdown: true,
          showPlatNumberDropdown: false,
          plat:''
        })
      } else if (targetValue.length < 2) {
        this.setState({
          platNameFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:''
        })
      }
    } else if (event.target.id === 'platNumberSelect') {
      let filteredList = this.props.allPlatList.filter(function (plat) {
        return plat.code == targetValue;
      });
      this.setState({
        showPlatNameDropdown: false,
        showPlatNumberDropdown: false,
        platNumber: targetValue,
        platName: filteredList[0].description,
        // platFound: true,
        plat: targetValue
      })
    } else if (event.target.id === 'platNumber' && !this.state.plat) {
      let filteredList = this.props.allPlatNumberList.filter(function (plat) {
        return plat.code.toUpperCase().startsWith(targetValue.toUpperCase())
      });

      if (filteredList.length === 0) {
        let errors = {};
        errors["platNumber"] = "No matching plat found.";
        this.setState({
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:'',
          errors: errors
        })
      } else if (filteredList.length === 1) {
        let namefilteredList = this.props.allPlatList.filter(function (plat) {
          return plat.code == filteredList[0].code;
        });
        this.setState({
          platNumber: filteredList[0].code,
          platName: namefilteredList[0].description,
          platNumberFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat: filteredList[0].code
        })
      } else if (targetValue.length >= 2) {
        filteredList = [{code: "-1", description: "(Click here to select a plat)"}].concat(filteredList);
      
        this.setState({
          platNumberFilteredList: filteredList,
          showPlatNumberDropdown: true,
          showPlatNameDropdown: false,
          plat:''
        })
      } else if (targetValue.length < 2) {
        this.setState({
          platNumberFilteredList: filteredList,
          showPlatNameDropdown: false,
          showPlatNumberDropdown: false,
          plat:''
        })
      }
    }

  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  // Same code used in all searches - END

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { platNameFound, platNumber, showPlatList, loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByPlat(request, this.props.history, this.hideLoading);
  }

  handleValidation = () => {
    let errors = {};

    if (!this.state.plat) {
      errors["platNumber"] = "A valid plat required.";
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    this.setState({
      plat: '',
      platNumber: '',
      platName: '',
      showPlatNumberDropdown: false,
      platNumberFilteredList: this.props.allPlatNumberList,
      showPlatNameDropdown: false,
      platNameFilteredList: this.props.allPlatNameList,
      showPlatList: false,
      submitMessage: ''
    })
  }

  handlePlatLookup = () => {
    this.setState({
      showPlatList: true
    });
  }

  handlePlatReset = () => {
    this.setState({
      plat: '',
      platName: '',
      platNumber: '',
      showPlatNumberDropdown: false,
      platNumberFilteredList: this.props.allPlatNumberList,
      showPlatNameDropdown: false,
      platNameFilteredList: this.props.allPlatNameList,
      showPlatList: false,
      errors: {}
    });
  }

  handleClosePlatLookup = (selectedId, selectedName) => {
  
    this.setState({
      errors: {},
      showPlatList: false
    })

    if (selectedId) {
      this.setState({
        plat: selectedId,
        platNumber: selectedId,
        platName: selectedName
      })
    }
  }

  render() {

    const formProps = {
      componentName: componentName,
      pageHeading: "Plat Search",
      autoComplete: false,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,
      formGroupRows: [
        {
          formGroups: [
            { id: 'platNumber', label: 'Plat Number', primaryCriteria: true, options: this.state.platNumberFilteredList, style: {minWidth: 100}, fieldType: 'plat', required: true, hideSelect: this.state.showPlatNumberDropdown === false, value: this.state.platNumber, error: this.state.errors['platNumber'] },
            { id: 'platName', label: 'Plat Name', primaryCriteria: true, options: this.state.platNameFilteredList, style: {minWidth: 300}, fieldType: 'plat', required: true, hideSelect: this.state.showPlatNameDropdown === false, value: this.state.platName, error: this.state.errors['platName']  },
            { id: 'platResetButton', label: 'Reset', fieldType: 'reset', onClick: this.handlePlatReset },
            { id: 'platLookupButton', fieldType: 'lookupWithLabel', style: {marginLeft: 8}, label: 'Full List', onClick: this.handlePlatLookup }
          ]
        }
      ],

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return (
      <>
        <FormTemplate {...formProps} />
        <PlatSelectionList showModal={this.state.showPlatList} onHide={(selectedId, selectedName) => this.handleClosePlatLookup(selectedId, selectedName)} />
      </>
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SpecialSearchPlat);
