import React from 'react';
import { connect } from "react-redux";

import configData from "../../../config/config.json"
import axios from "axios";

import { Modal, Container, Col, Row, Button, Form, InputGroup } from 'react-bootstrap'
import Paginator from '../page/Paginator'
import { getPaginatorHelper } from '../page/PaginatorHelper'
import Loading from '../modal/Loading';

import { searchIcon } from "../../../img/font_awesome/font_awesome"
import { LOADING_STATUES } from "../../constants/constants";

export const AccountSelectionListComponentName = "AccountSelectionListComponentName";

const mapStateToProps = state => {
    return {
        paginationPage: state.accountSelectionListPaginationReducer 
    };
}

class AccountSelectionList extends React.Component {
    state = {
        filteredList: [],
        filterText: '',
        loadingStatus: ''
    }

    handleTextFilter = (filterText) => {
		this.setState({ filterText: filterText },  function() { this.handleFilter() } )
	}

    handleFilter = () => {
        if (this.showLoading() !== true) {
            return;
        }

        let filters = { filterText: this.state.filterText };
        axios.post(configData.LANDSHARK_SERVER_URL + "helperdata/accountscodedescription", filters, { withCredentials: true })
            .then((response) => {
                this.setState({ filteredList: response.data.content.codeDescriptionResultList, loadingStatus: '' })
            }, (error) => {
                this.setState({  filteredList:[], loadingStatus: '' });
            });
    }

    handleCloseClick = () => {
        this.props.onHide();
    }

    handleRowClick = (event) => {
        this.props.onHide(event.target.closest("tr").id, event.target.closest("tr").getAttribute("name"));
    }

    handleChange = (event) => {
        event.preventDefault();

        this.setState({
            filterText: event.target.value
        })
    }
    
     showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

    handleSubmit = (event) => {
        event.preventDefault();

        // 13 is Enter. 
        if (event.type === 'keyPress' && event.keyCode !== 13) {
            const entry = String.fromCharCode(event.keyCode);
            this.setState({
                filterText: this.state.filterText + entry
            })

            return;
        }
        this.handleTextFilter(this.state.filterText);
    }

    componentDidMount() {
		this.handleFilter();
	}

    render() {
        let filteredList = this.state.filteredList;
        if (!filteredList) {
            return <></>
        }

        const numberOfVisibleRecords = 10;
        let numberOfPages = Math.ceil(filteredList.length / numberOfVisibleRecords);
        let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);
       
        return (
            <>
            <Modal show={this.props.showModal} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header style={{ borderBottom: '2px solid #3674AB' }} >
                    <Modal.Title id="contained-modal-title-vcenter" style={{color: '#3674AB'}}>
                        Click row to select account:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2 }}>
                            <Row>
                                <Col>
                                    <Form onSubmit={this.handleSubmit}>
                                        <InputGroup>
                                           <Form.Control  onChange={this.handleChange} type='text' value={this.state.filterText} placeholder="Enter a word or name to filter" />
                                            <InputGroup.Append>
                                                <Button style={{ color: "white" }} type="submit" >{searchIcon}</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>

                        <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2, border: '3px solid darkGray', borderRadius: '5px' }}>

                            <table className="table table-striped table-hover">
                                <thead >
                                    <tr>
                                        <th scope="col">Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginationHelper.currentPageRecordList.map((record) => {
                                            return (
                                                <tr style={{ cursor: "pointer" }} id={record.code} name={record.description} onClick={this.handleRowClick} >
                                                    <td>{record.description}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Container>
                        <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2 }}>

                            <Row>
                                <Col><Paginator style={{ justifyContent: 'center' }} componentName={AccountSelectionListComponentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></Paginator></Col>
                            </Row>
                            <Row>
                                <Col>Displaying Results: {paginationHelper.currentPageStartingRecordNumber}-{paginationHelper.currentPageEndingRecordNumber} of {filteredList.length}</Col>
                            </Row>

                        </Container>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseClick}>Close</Button>
                </Modal.Footer>
            </Modal>
             <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
             </>
        )
    }
}

export default connect(mapStateToProps)(AccountSelectionList);
