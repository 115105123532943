const countyConfigCreditCardExtraReducer = (state = [{}], action) => {
  switch (action.type) {
    case 'LANDSHARK_COUNTY_CONFIG_CREDIT_CARD_EXTRA':
      return action.results;
    default:
      break;
  }
  return state;
}

export default countyConfigCreditCardExtraReducer;