import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, LanguagesControlPanelKey } from "../../redux/actions/controlPanelActions";

import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../constants/constants";
import Alert from '../common/modal/Alert';

const componentName = LanguagesControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class Languages extends Component {

  state = {
    languages: false,
    message:"",
    showAlert: false,
    alertMessage: ""
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

  handleSubmit = (event) => {
    event.preventDefault();
  
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING,
        message: ""
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/languages", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      message: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/languages", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          languages: content.languages,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
  
    const formProps = {
      componentName: componentName,
      pageHeading: "Languages",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      message: this.state.message,
      formGroupRows: [
        {
          formGroups: [{ id: 'languages', label: 'Languages', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.languages }]
        }
      ]
    }


    return (
      <>
        <FormTemplate {...formProps} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(Languages);