
const userTypesReducer = (state = [], action) => {
  switch (action.type) {
    case 'LANDSHARK_USER_TYPES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default userTypesReducer;