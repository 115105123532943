import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, LandSharkOneAccountsUsersControlPanelKey } from "../../redux/actions/controlPanelActions";

import configData from "../../config/config.json";
import { LOADING_STATUES } from "../constants/constants";
import Confirmation from '../common/modal/Confirmation';
import Loading from '../common/modal/Loading';
import Alert from '../common/modal/Alert';

const componentName = LandSharkOneAccountsUsersControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class LandSharkOneAccountsUsers extends Component {

  state = {
    landSharkOneDbAddress: "",
    landSharkOneDbName: "",
    landSharkOneDbUsername: "",
    landSharkOneDbPassword: "",
    message:"",
    showAlert: false,
    alertMessage: "",
    showMigrateConfirmationDialog: false,
    loadingStatus: "",
    errors: {}
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

	showLoading = () => {
		if (this.state.status === "") {
			this.setState({
				status: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleConfirmMigration = () => {

    if (this.showLoading() === true) {
      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/migrateLandSharkOneAccountsUsers", this.state, { withCredentials: true })
      .then(
        (response) => {
          this.setState({ 
            status: ''
          }, this.showAlert(response.data.message));
        },
        (error) => {
          this.setState({ 
            status: ''
          });
        }
      );
		}
	}

	showMigrateConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showMigrateConfirmationDialog: true
		})
	}

	hideMigrateConfirmation = () => {
		this.setState({
      status: '',
			showMigrateConfirmationDialog: false
		})
	}

  handleSubmit = (event) => {
    event.preventDefault();
    this.showMigrateConfirmation(event);
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      message: "",
      errors: {},
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}

  componentDidMount() {
    this.props.setSelectedControlPanelType();
  }

  render() {
  
    const formProps = {
      componentName: componentName,
      pageHeading: "LandShark One Migration - ACCOUNTS and USERS",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      message: this.state.message,
      formGroupRows: [
        {
          formGroups: [{ id: 'landSharkOneDbAddress', label: 'LS1 DB Address (ex. localhost:1234)', fieldType: 'text', required: true, value: this.state.landSharkOneDbAddress }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbName', label: 'LS1 DB Name (ex. LS)', fieldType: 'text', required: true, value: this.state.landSharkOneDbName }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbUsername', label: 'LS1 DB Username', fieldType: 'text', required: true, value: this.state.landSharkOneDbUsername }]
        },
        {
          formGroups: [{ id: 'landSharkOneDbPassword', label: 'LS1 DB Password', fieldType: 'password', required: true, value: this.state.landSharkOneDbPassword }]
        }
      ]
    }


    return (
      <>
        <FormTemplate {...formProps} />
        <Confirmation show={this.state.showMigrateConfirmationDialog} cancelConfirmation={this.hideMigrateConfirmation} proceedConfirmation={this.handleConfirmMigration}  question={'Clicking Yes will wipe clean any existing ACCOUNTS and USERS in LS2.  Continue?'} />
        <Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
        <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(LandSharkOneAccountsUsers);