import { ReportStatementKey } from "../actions/reportActions";

const initialState = {     
      selectedReportType : ReportStatementKey
}

const reportTypeReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_REPORT_TYPE':
                  return {
                        selectedReportType: action.selectedReportType  
                  }
            default:
                  return state;
      }
     
};
export default reportTypeReducer;