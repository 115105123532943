import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, ALaCarteRatesControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = ALaCarteRatesControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class ALaCarteRates extends Component {

  state = {
    searchByAddress: "0.0",
    searchByBookAndPage: "0.0",
    searchByDate: "0.0",
    searchByDocumentNumber: "0.0",
    searchByLegalDescription: "0.0",
    searchByName: "0.0",
    searchByParcelId: "0.0",
    searchByTorrensCertificateNumber: "0.0",
    searchByTorrensText: "0.0",
    searchByPlat: "0.0",
    searchByTaxLien: "0.0",
    searchByGrantorGranteeBook: "0.0",
    searchByTractBook: "0.0",
    documentDetails: "0.0",
    taxLienDetails: "0.0",
    imageDocument: "0.0",
    imageFirstPage: "0.0",
    imageAdditionalPages: "0.0",
    status: "",
    message: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }
  
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/aLaCarte", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: "SUCCESS"
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    this.setState({
      status: "",
      [event.target.id]: event.target.value ? event.target.value : 0
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/aLaCarte", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          searchByAddress: content.searchByAddress,
          searchByBookAndPage: content.searchByBookAndPage,
          searchByDate: content.searchByDate,
          searchByDocumentNumber: content.searchByDocumentNumber,
          searchByLegalDescription: content.searchByLegalDescription,
          searchByName: content.searchByName,
          searchByParcelId: content.searchByParcelId,
          searchByTorrensCertificateNumber: content.searchByTorrensCertificateNumber,
          searchByTorrensText: content.searchByTorrensText,
          searchByPlat: content.searchByPlat,
          searchByTaxLien: content.searchByTaxLien,
          searchByGrantorGranteeBook: content.searchByGrantorGranteeBook,
          searchByTractBook: content.searchByTractBook,
          documentDetails: content.documentDetails,
          taxLienDetails: content.taxLienDetails,
          imageDocument: content.imageDocument,
          imageFirstPage: content.imageFirstPage,
          imageAdditionalPages: content.imageAdditionalPages,
          status : ""
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "A La Carte Rates",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          heading: 'Searches',
          formGroups: [
            { id: 'searchByAddress', label: 'Address ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByAddress },
            { id: 'searchByBookAndPage', label: 'Book & Page ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByBookAndPage },
            { id: 'searchByDate', label: 'Date ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByDate },
            { id: 'searchByDocumentNumber', label: 'Document Number ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByDocumentNumber },
            { id: 'searchByLegalDescription', label: 'Legal Descriptions ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByLegalDescription },
            { id: 'searchByName', label: 'Name ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByName },
            { id: 'searchByParcelId', label: 'Parcel ID ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByParcelId },
            { id: 'searchByTorrensCertificateNumber', label: 'Torrens Certificate ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByTorrensCertificateNumber },
            { id: 'searchByTorrensText', label: 'Torrens Text ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByTorrensText },
            { id: 'searchByPlat', label: 'Plat ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByPlat },
            { id: 'searchByTaxLien', label: 'Tax Lien ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByTaxLien },
            { id: 'searchByGrantorGranteeBook', label: 'Grantor/ee Book ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByGrantorGranteeBook },
            { id: 'searchByTractBook', label: 'Tract Book ($)', fieldType: 'number', numberType:'decimal', value: this.state.searchByTractBook }
          ]
        },
        {
          heading: 'Search Details',
          formGroups: [
            { id: 'documentDetails', label: 'Document ($)', fieldType: 'number', numberType:'decimal', value: this.state.documentDetails },
            { id: 'taxLienDetails', label: 'Tax Lien ($)', fieldType: 'number', numberType:'decimal', value: this.state.taxLienDetails }
          ]
        },
        {
          heading: 'Document Image',
          formGroups: [
            { id: 'imageDocument', label: 'Document ($)', fieldType: 'number', numberType:'decimal', value: this.state.imageDocument },
            { id: 'imageFirstPage', label: '1st Page ($)', fieldType: 'number', numberType:'decimal', value: this.state.imageFirstPage },
            { id: 'imageAdditionalPages', label: 'Additional Pages ($)', fieldType: 'number', numberType:'decimal', value: this.state.imageAdditionalPages }
          ]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(ALaCarteRates);