const countyConfigFlatFeeOverrideUISettingsReducer = (state = [{}], action) => {
  switch (action.type) {
    case 'LANDSHARK_COUNTY_CONFIG_FLAT_FEE_OVERRIDE_UI_SETTINGS':
      return action.results;
    default:
      break;
  }
  return state;
}

export default countyConfigFlatFeeOverrideUISettingsReducer;