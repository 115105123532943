export const validateNumberOfImagesCostPair = (errors, unlimited, numberOfImages, numberOfImagesKey, cost, costKey) => {

    if (!unlimited && (!numberOfImages || parseInt(numberOfImages) === 0)) {
        errors[numberOfImagesKey] = "Required.";
        return false;
    }

    if (cost === undefined || cost === null || cost === '') {
        errors[costKey] = "Required. Zero is ok.";
        return false;
    }

    return true;
};