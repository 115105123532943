
const initialState = 0;

const searchDetailsTaxLienReducer = (state = initialState, action) => {
   
    if (action.type === 'DISPLAY_TAX_LIEN_SEARCH_DETAILS') {
        return action.id;
    }

    if (action.type === 'UPDATE_SEARCH_RESULTS') {
        return 0;
    }

    if (action.type === 'RESET_SEARCH_DETAILS') {
        return 0;
    }
    
    return state;
};
export default searchDetailsTaxLienReducer;