import React, {Component} from 'react';
import { Container, Row } from 'react-bootstrap'

import PageBanner from "../common/page/PageBanner";
import CountyNotes from "../common/page/CountyNotes";
import { connect } from "react-redux";
import { setSelectedControlPanelType, PerformanceMonitoringControlPanelKey  } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";

const componentName = PerformanceMonitoringControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

const mapStateToProps = (state) => {
  return {
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class PerformanceMonitoring extends Component {

  handleClick = (event) => {
    event.preventDefault();

    var url = configData.LANDSHARK_SERVER_URL + 'monitoring';
    window.open(url, '_blank');
  }

	componentDidMount() {
		this.props.setSelectedControlPanelType();
  }

  render() {
    let formTemplateMarginLeft = 60
    if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    return (     
      <div style={{ marginLeft: formTemplateMarginLeft, marginRight: 20, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <CountyNotes componentName={this.props.componentName} />
        <PageBanner pageHeading='Technical - Performance Monitoring' />
        <Container fluid="true" style={{ paddingTop: 30, paddingLeft: 50, paddingBottom: 50, marginTop: 10, marginLeft: 4, marginRight: 4, border: '3px solid darkGray', borderRadius: '5px' }}>
          <Row>&nbsp;</Row>
          <Row>
              <button onClick={this.handleClick} className="btn btn-primary">Perform Performance Monitoring</button>
          </Row>
        </Container>

      </div>
    )
  }


}

export default connect(mapStateToProps, matchDispatchToProps)(PerformanceMonitoring);