import React, { Component } from 'react';
import { connect } from "react-redux";

import { Form, Row, Col, Container } from 'react-bootstrap'
import TextField from "../common/form/TextField";
import PrimaryButton from '../common/form/PrimaryButton';
import LoginTop from "../../components/top/LoginTop";
import { login } from "../../redux/actions/loginActions";
import LoginPageLinks from './LoginPageLinks';
import LoginPageSlides from './LoginPageSlides';
import LoginPageLatestNews from './LoginPageLatestNews';
import { NavLink } from "react-router-dom";
import SelectField from '../common/form/SelectField';
import Footer from '../bottom/Footer'

const mapStateToProps = state => {
	return {
		defaultSearch: state.countyConfigSearchReducer.defaultSearch,
		loginRegistration: state.countyConfigLoginRegistrationReducer,
		languages: state.countyConfigLanguagesReducer
	};
}

const matchDispatchToProps = (dispatch) => {
	return {
		login: (credential, defaultSearch, history, callback) => dispatch(login(credential, defaultSearch, history, callback))
	}
}

class Login extends Component {
	state = {
		username: "",
		password: "",
		language: "ENGLISH",
		error: "",
		loading: false
	}

	handleChange = (event) => {
		this.setState({
			error: "",
			[event.target.id]: event.target.value
		})
	}

	handleCallback = (status) => {
		this.setState({
			error: "",
			loading: false
		})
		if (status.error) {
			this.setState({
				error: status.message,
			})
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({
			loading: true
		})
		this.props.login(this.state, this.props.defaultSearch, this.props.history, this.handleCallback);
	}

	render() {
		const languageOptions = [{ code: "BENGALI", description: "Bengali" }, 
								 { code: "CAROLINIAN", description: "Carolinian" },  
							     { code: "CHAMORRO", description: "Chamorro" },
								 { code: "CHINESE", description: "中文" }, 
								 { code: "CHUUKESE", description: "Chuukese" }, 
								 { code: "ENGLISH", description: "English" }, 
								 { code: "JAPANESE", description: "日本語" }, 
								 { code: "KOREAN", description: " 한국어" },
								 { code: "PALAUAN", description: "Palauan" },
								 { code: "POHNPEIAN", description: "Pohnpeian" },
								 { code: "RUSSIAN", description: "Русский" },
								 { code: "TAGALOG", description: "Tagalog" },
								 { code: "THAI", description: "ไทย" },
								 { code: "TURKISH", description: "Türkçe" }];

		return (
			<>
				<LoginTop pathname={this.props.location.pathname} />
				<section >
					<Container fluid="true" className='h-100'>
					
						<Row className='align-items-center' style={{ height: '85%', marginLeft: 10, marginRight:10, paddingLeft: 13}}  >
							<Col lg={8} >
								<LoginPageSlides />
							</Col>
							<Col lg={4} >
							<Row style={{marginBottom: 30}}>
								<LoginPageLatestNews />
							</Row>
							<Form onSubmit={this.handleSubmit} style={{ background: 'gray', color: 'white', border: '3px solid darkGray', marginLeft:10, marginRight: 0, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"}} >
									<Row>
										<Col lg={12}>
											<Form.Group controlId={'username'}>
												<TextField formGroup={{ label: 'Username', primaryCriteria: true, fieldType: 'text', required: true, style: {width: '100%'} }} onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											<Form.Group controlId={'password'}>
												<TextField formGroup={{ label: 'Password', primaryCriteria: true, fieldType: 'password', required: true }} onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>

									{
										this.props.languages.languages === true &&
										<Row>
											<Col className='float-left' lg={12}>
												<SelectField  formGroup={{ id: 'language', label: 'Language', fieldType: 'select', value: 'ENGLISH', options: languageOptions  }} onChange={this.handleChange} />
											</Col>
										</Row>
									}
									<Row>
										&nbsp;
									</Row>
										
									<Row  className='align-items-center'>
										<Col lg={3} className='text-center' >
											<PrimaryButton buttonLabel={'Log In'} />
										</Col>
										{

											this.props.loginRegistration.forgotPasswordLink === true &&
											<Col lg={3} className='text-center' >
												<span><NavLink to='/forgotUsername' style={{ color: 'white' }}>Forgot Username?</NavLink></span>
											</Col>
										}
										{
											this.props.loginRegistration.forgotPasswordLink === true &&
											<Col lg={3} className='text-center' >
												<span><NavLink to='/forgotPassword' style={{ color: 'white' }}>Forgot Password?&nbsp;&nbsp;</NavLink></span>
											</Col>
										}
										{
											this.props.loginRegistration.selfRegistration === true &&
											<Col lg={3} className='text-center' >
												<span><NavLink to='/register' style={{ color: 'white' }}>New User?</NavLink></span>
											</Col>
										}
									</Row>
									{
										this.state.error &&
										<Row className='align-items-center'>
											<Col lg={1} />
											<Col lg={10} className='text-center' style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, marginTop: 10, background: 'lightgray', border: '3px solid red', color: 'red' }} >
												{this.state.error}
											</Col>
											<Col lg={1} />
										</Row>
									}
									{
										this.state.loading &&
										<Row className='align-items-center'>
											<Col lg={1} />
											<Col lg={10} className='text-center' style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, marginTop: 10, background: 'lightgray', border: '3px solid #3674AB', color: '#3674AB' }} >
												Logging in...
											</Col>
											<Col lg={1} />
										</Row>
									}
								</Form>
								
							</Col>
							
						</Row>
						<LoginPageLinks />
					</Container>
				</section>
				<Footer />
			</>

		)
	}
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);