import axios from "axios";

import configData from "../../config/config.json";

export const saveAccount = (account, callback) => async dispatch => {

  await axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/account", account, { withCredentials: true })
    .then((response) => {
      dispatch({ type: 'LANDSHARK_ACCOUNTS', results: response.data.content }); 
      callback(true, false, response.data.message);
    }, (error) => {
      callback(false, true, error.response.data.message);   
    });
};


