import { createStore, applyMiddleware, compose } from "redux";

import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "../reducers/index";
import thunk from 'redux-thunk'

const initialState = {};
const middleware = [thunk]

const persistConfig = {
    key: "root",
    storage
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer);

const ReactReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
let store;
if (window.navigator.userAgent.includes("Chrome") && ReactReduxDevTools) {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), ReactReduxDevTools));
} else {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware)));
}

const persistor = persistStore(store);

export default store;