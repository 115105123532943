import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Form, Container } from 'react-bootstrap'
import FieldRow from "../common/form/FieldRow";
import Loading from '../common/modal/Loading';
import { formatPhone, formatInternationalPhone, formatZip } from "../common/function/formattingUtils.js";
import { validateUser, trimUserFields } from "../common/function/userValidation.js";
import axios from "axios";
import configData from "../../config/config.json";
import usStates from "../../config/us_states.json";
import { LOADING_STATUES, ALERT_TYPES } from "../constants/constants";
import Alert from '../common/modal/Alert';

import { userActiveIcon } from "../../img/font_awesome/font_awesome"

const mapStateToProps = (state) => {
   return {
      currentUser: state.currentUserReducer
   };
}

class CurrentUser extends Component {

   state = {
      firstName: "",
      lastName: "",
      company: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phoneFormatType: '',
		phoneInternational: '',
		phoneStandard: '',
      loadingStatus: '',
      errors: {},
      alertType: ALERT_TYPES.OK
   }

   handleChange = (event) => {
      event.preventDefault();
    
      let value = event.target.value;

      if (event.target.id === 'phoneStandard') {
			value = formatPhone(value, this.state.phoneStandard);
		} else if (event.target.id === 'phoneInternational') {
			value = formatInternationalPhone(value);
		} else if (event.target.id === 'zip') {
			value = formatZip(value);
		}

      this.setState({
         [event.target.id]: value,
         errors: {}
      })
   }

   showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

   validateAndSubmit = () => {
		let errors = validateUser(this.state, false);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({errors});
			return;
		}

		if (this.showLoading() === true) {

         const { loadingStatus, alertType, errors, phoneInternational, phoneStandard,
             ...request } = this.state; // remove UI only fields before submitting

         if (this.state.phoneFormatType === 'INTERNATIONAL') {
				request.phone = this.state.phoneInternational;
			} else {
				request.phone = this.state.phoneStandard;
			}

         axios.post(configData.LANDSHARK_SERVER_URL + "currentUser/profile", request, { withCredentials: true })
            .then((response) => {
               this.setState({ loadingStatus: "" })
               this.props.closeCurrentUser();
            },  (error) => {
               var response = error.response;
               if (response.status === 403) {
                    this.props.history.push('/login');
               } else {
   					this.setState({loadingStatus: "", redirectToLogin: false}, this.showWarning(error.response.data.message));
               } 
				}); 
		}
	}

   handleSubmit = (event) => {
		event.preventDefault();

		const trimmedUserFields = trimUserFields(this.state);
		this.setState({ ...trimmedUserFields }, function () {
			this.validateAndSubmit();
		});
	}
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.setState({  errors: {} })
      this.props.closeCurrentUser();
   }

   handleChangePassword = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.setState({  errors: {} })
      this.props.closeCurrentUser();
      this.props.openCurrentUserChangePassword();
   }

   componentDidMount() {
	
		axios.get(configData.LANDSHARK_SERVER_URL + "currentUser/profile", { withCredentials: true })
		.then(
			(response) => {
				const content = response.data.content;
				this.setState( {
               firstName: content.firstName,
               lastName: content.lastName,
               company: content.company,
               address: content.address,
               city: content.city,
               state: content.state,
               zip: content.zip,
               email: content.email,
               phoneFormatType: content.phoneFormatType,
					phoneStandard: content.phoneFormatType === 'STANDARD' ? content.phone: '',
					phoneInternational: content.phoneFormatType === 'INTERNATIONAL' ? content.phone: ''
				});
			
			},
			(error) => {
			   console.log(error);
			}
		);
	}

   showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }

   showWarning = (alertMessage) => {
		this.setState({
		  alertType: ALERT_TYPES.WARNING,
		  showAlert: true,
		  alertMessage
		})
	}
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
		if (this.state.redirectToLogin) {
			this.props.history.push('/login');
		}
	}

   render() {

      let formGroupRows = [
         {
            formGroups: [{ id: 'firstName', label: 'First Name', fieldType: 'text', value: this.state.firstName, required: true, primaryCriteria: true, error: this.state.errors['firstName'] }, { id: 'lastName', label: 'Last Name', fieldType: 'text', value: this.state.lastName, required: true, primaryCriteria: true, error: this.state.errors['lastName']  }]
         },
         {
            formGroups: [{ id: 'email', label: 'Email', fieldType: 'email', value: this.state.email, required: true, primaryCriteria: true, error: this.state.errors['email']  }]
         },
         {
            formGroups: [{ id: 'company', label: 'Company', fieldType: 'text', value: this.state.company, required: false, error: this.state.errors['company']  }]
         },
         {
            formGroups: [{ id: 'address', label: 'Address', fieldType: 'text', value: this.state.address, required: false, style: {minWidth: 400}, error: this.state.errors['address']  }]
         },
         {
            formGroups: [{ id: 'city', label: 'City', fieldType: 'text', value: this.state.city, required: false, error: this.state.errors['city']  }]
         },
         {
            formGroups: [ { id: 'state', label: 'State', fieldType: 'select', value: this.state.state, required: false, options: usStates }, { id: 'zip', label: 'Zip  (ex. 55111)', fieldType: 'zip', value: this.state.zip, required: false, style: {maxWidth: 80}, error: this.state.errors['zip']  }]
         },
			{
				formGroups: [{ id: 'phoneFormatType', label: 'Phone Format', fieldType: 'select', options: [{ code: "STANDARD", description: "(XXX) XXX-XXXX" }, { code: "INTERNATIONAL", description: "International" }], value: this.state.phoneFormatType, required: false, error: this.state.errors['phoneFormatType'] },
				             { id: 'phoneStandard', label: 'Phone (ex. (612) 555-1234)', fieldType: 'tel', value: this.state.phoneStandard, required: false, hideField: this.state.phoneFormatType !== 'STANDARD', error: this.state.errors['phoneStandard'] },
							    { id: 'phoneInternational', label: 'Phone country code (ex. 8613123456789)', fieldType: 'tel_international', value: this.state.phoneInternational, required: false, hideField: this.state.phoneFormatType !== 'INTERNATIONAL', error: this.state.errors['phoneInternational'] } ]
			}
      ]

      return (
         <>
            <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
               <Form onSubmit={this.handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title style={{color: '#3674AB'}}>{userActiveIcon} {this.props.currentUser.username} </Modal.Title>
               </Modal.Header>
               <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white'}}>
                  <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 50, marginTop: 10, background: 'white', color: 'black', border: '2px solid black',  borderRadius: '5px' }}>
                     <br/>
                        {
                           formGroupRows.map((formGroupRow, index) => {
                              return (
                                 <FieldRow key={"currentUserProfileFieldRow" + index} formGroupRow={formGroupRow} onChange={this.handleChange} />
                              );
                           })
                        }
                  </Container>
               </Modal.Body>
               <Modal.Footer>
                  <Button className='mr-auto' variant="secondary"  onClick={this.handleChangePassword} >Change Password</Button>
                  <Button variant="primary" type="submit" >Update</Button>
                  <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
               </Modal.Footer>
               </Form>
            </Modal>
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType}/>
         </>
      )
   }
}

export default connect(mapStateToProps, null)(CurrentUser);