import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, SearchControlPanelKey } from "../../redux/actions/controlPanelActions";
import { SearchByAddressKey, SearchByBookAndPageKey, SearchByDateKey, SearchByDocumentNumberKey,
  SearchByLegalDescriptionKey, SearchByNameKey, SearchByParcelIdKey, SearchByTorrensCertificateKey, SearchByTorrensTextKey,
  SpecialSearchGrantorGranteeBookKey, SpecialSearchPlatKey, SpecialSearchTaxLienKey, SpecialSearchTractBookIdKey, SpecialSearchTractBookLegalKey, SearchByTractIndexNMIKey } from "../../redux/actions/searchActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";
import Alert from '../common/modal/Alert';
import { ALERT_TYPES } from "../constants/constants";

const componentName = SearchControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class Search extends Component {

  state = {
    defaultSearch: SearchByDocumentNumberKey,
    searchAccess: "same",
    searchByAddress: false,
    searchByAddressCountyNote: "",
    searchByBookAndPage: false,
    searchByBookAndPageCountyNote: "",
    searchByDate: false,
    searchByDateCountyNote: "",
    searchByDocumentNumber: false,
    searchByDocumentNumberCountyNote: "",
    searchByLegalDescription: false,
    searchByLegalDescriptionCountyNote: "",
    searchByName: false,
    searchByNameCountyNote: "",
    searchByParcelId: false,
    searchByParcelIdCountyNote: "",
    searchByTorrensCertificate: false,
    searchByTorrensCertificateCountyNote: "",
    searchByTorrensText: false,
    searchByTorrensTextCountyNote: "",
    searchByTaxLien: false,
    searchByTaxLienCountyNote: "",
    searchByGrantorGranteeBook: false,
    searchByGrantorGranteeBookCountyNote: "",
    searchByPlat: false,
    searchByPlatCountyNote: "",
    searchByTractBookId: false,
    searchByTractBookIdCountyNote: "",
    searchByTractBookLegal: false,
    searchByTractBookLegalCountyNote: "",
    searchByTractIndexNMI: false,
    searchByTractIndexNMICountyNote: "",
    message:"",
    showAlert: false,
    alertMessage: ""
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

  validate = () => {
      if (this.state.defaultSearch && (this.state[this.state.defaultSearch] === false || this.state[this.state.defaultSearch] === 'false')) {
        return "Your default search needs to be enabled first.";
      }
  
    return null;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let message = this.validate();
    if (message) {
      this.showAlert(message, ALERT_TYPES.WARNING);
      return;
    }

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING,
        message: ""
      })
    } else {
      return;
    }

    this.validate();
    
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/search", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS,
          message: "Settings and Users updated"
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleSave = (event) => {
    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING,
        message: ""
      })
    } else {
      return;
    }
    
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/search", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS,
          message: "Settings updated"
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      message: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/search", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          defaultSearch: content.defaultSearch,
          searchAccess: content.searchAccess,
          searchByAddress: content.searchByAddress,
          searchByAddressCountyNote: content.searchByAddressCountyNote,
          searchByBookAndPage: content.searchByBookAndPage,
          searchByBookAndPageCountyNote: content.searchByBookAndPageCountyNote,
          searchByDate: content.searchByDate,
          searchByDateCountyNote: content.searchByDateCountyNote,
          searchByDocumentNumber: content.searchByDocumentNumber,
          searchByDocumentNumberCountyNote: content.searchByDocumentNumberCountyNote,
          searchByLegalDescription: content.searchByLegalDescription,
          searchByLegalDescriptionCountyNote: content.searchByLegalDescriptionCountyNote,
          searchByName: content.searchByName,
          searchByNameCountyNote: content.searchByNameCountyNote,
          searchByParcelId: content.searchByParcelId,
          searchByParcelIdCountyNote: content.searchByParcelIdCountyNote,
          searchByTorrensCertificate: content.searchByTorrensCertificate,
          searchByTorrensCertificateCountyNote: content.searchByTorrensCertificateCountyNote,
          searchByTorrensText: content.searchByTorrensText,
          searchByTorrensTextCountyNote: content.searchByTorrensTextCountyNote,
          searchByTaxLien: content.searchByTaxLien,
          searchByTaxLienCountyNote: content.searchByTaxLienCountyNote,
          searchByGrantorGranteeBook: content.searchByGrantorGranteeBook,
          searchByGrantorGranteeBookCountyNote: content.searchByGrantorGranteeBookCountyNote,
          searchByPlat: content.searchByPlat,
          searchByPlatCountyNote: content.searchByPlatCountyNote,
          searchByTractBookId: content.searchByTractBookId,
          searchByTractBookIdCountyNote: content.searchByTractBookIdCountyNote,
          searchByTractBookLegal: content.searchByTractBookLegal,
          searchByTractBookLegalCountyNote: content.searchByTractBookLegalCountyNote,
          searchByTractIndexNMI: content.searchByTractIndexNMI,
          searchByTractIndexNMICountyNote: content.searchByTractIndexNMICountyNote,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {

    let searchTypes = [
      { code: SearchByAddressKey, description: "Search by Address"},
      { code: SearchByBookAndPageKey, description: "Search by Book & Page"}, 
      { code: SearchByDateKey, description: "Search by Date"},
      { code: SearchByDocumentNumberKey, description: "Search by Document Number"}, 
      { code: SearchByLegalDescriptionKey, description: "Search by Legal Description"},
      { code: SearchByNameKey, description: "Search by Name"}, 
      { code: SearchByParcelIdKey, description: "Search by Parced ID"},
      { code: SearchByTorrensCertificateKey, description: "Search by Torrens Certificate"}, 
      { code: SearchByTorrensTextKey, description: "Search by Torrens Text"}, 
      { code: SearchByTractIndexNMIKey, description: "Search by Tract Index(NMI)"},
      { code: SpecialSearchGrantorGranteeBookKey, description: "Special Search - Grantor Grantee Book"}, 
      { code: SpecialSearchPlatKey, description: "Special Search - Plat"}, 
      { code: SpecialSearchTaxLienKey, description: "Special Search - Tax Lien"},
      { code: SpecialSearchTractBookIdKey, description: "Special Search - Tract Book Id"},
      { code: SpecialSearchTractBookLegalKey, description: "Special Search - Tract Book Legal"}
    ];

  
    const formProps = {
      componentName: componentName,
      pageHeading: "Search 1",
      onChange: this.handleChange,
      onSeconaryClick: this.state.searchAccess === "same" ? null : this.handleSave,
      onSubmit: this.handleSubmit,
      primaryButtonLabel: this.state.searchAccess === "same" ? "Submit" : "Save and Update",
      secondaryButtonLabel: this.state.searchAccess === "same" ? null :"Save Only",
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      message: this.state.message,
      formGroupRows: [
        {
          formGroups: [{ id: 'searchAccess', label: 'Access', fieldType: 'select', value: this.state.searchAccess, options: [{ code: "configurable", description: "Configurable by accounts"}, {code: "same", description: "Same for All Accounts"}]  }]
        },
        {
          formGroups: [{ id: SearchByAddressKey, label: 'Address', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByAddress === true || this.state.searchByAddress === 'true' }, 
                       { id: SearchByAddressKey + 'CountyNote', label: 'County Note', fieldType: 'text', value: this.state.searchByAddressCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByBookAndPageKey, label: 'Book & Page', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByBookAndPage === true || this.state.searchByBookAndPage === 'true' },  
                       { id: SearchByBookAndPageKey + 'CountyNote', label: 'County Note', fieldType: 'text', value: this.state.searchByBookAndPageCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByDateKey, label: 'Date', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByDate === true || this.state.searchByDate === 'true' }, 
                       { id: SearchByDateKey + 'CountyNote', label: 'County Note', fieldType: 'text', value: this.state.searchByDateCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByDocumentNumberKey, label: 'Document Number', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByDocumentNumber === true || this.state.searchByDocumentNumber === 'true' }, 
                       { id: SearchByDocumentNumberKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByDocumentNumberCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByLegalDescriptionKey, label: 'Legal Descriptions', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByLegalDescription === true || this.state.searchByLegalDescription === 'true' }, 
                       { id: SearchByLegalDescriptionKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByLegalDescriptionCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByNameKey, label: 'Name', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByName === true || this.state.searchByName === 'true' }, 
                       { id: SearchByNameKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByNameCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByParcelIdKey, label: 'Parcel Id', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByParcelId === true || this.state.searchByParcelId === 'true' }, 
                       { id: SearchByParcelIdKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByParcelIdCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByTorrensCertificateKey, label: 'Torrens Certificate', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTorrensCertificate === true || this.state.searchByTorrensCertificate === 'true' },  
                       { id: SearchByTorrensCertificateKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTorrensCertificateCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SearchByTorrensTextKey, label: 'Torrens Text', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTorrensText === true || this.state.searchByTorrensText === 'true' }, 
                       { id: SearchByTorrensTextKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTorrensTextCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SpecialSearchPlatKey, label: 'Plat', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByPlat === true || this.state.searchByPlat === 'true' }, 
                       { id: SpecialSearchPlatKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByPlatCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SpecialSearchGrantorGranteeBookKey, label: 'Grantor/ee Book', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByGrantorGranteeBook === true || this.state.searchByGrantorGranteeBook === 'true' }, 
                       { id: SpecialSearchGrantorGranteeBookKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByGrantorGranteeBookCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SpecialSearchTaxLienKey, label: 'Tax Lien', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTaxLien === true || this.state.searchByTaxLien === 'true' }, 
                       { id: SpecialSearchTaxLienKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTaxLienCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SpecialSearchTractBookIdKey, label: 'Tract Book ID', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractBookId === true || this.state.searchByTractBookId === 'true' },
                       { id: SpecialSearchTractBookIdKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTractBookIdCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: SpecialSearchTractBookLegalKey, label: 'Tract Book Legal', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractBookLegal === true || this.state.searchByTractBookLegal === 'true'},
                       { id: SpecialSearchTractBookLegalKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTractBookLegalCountyNote, lgSize: 10 }]
        },
      
        {
          formGroups: [{ id: SearchByTractIndexNMIKey, label: 'Tract Index(NMI)', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractIndexNMI === true || this.state.searchByTractIndexNMI === 'true' },
                       { id: SearchByTractIndexNMIKey + 'CountyNote', label: 'County Note', fieldType: 'text',  value: this.state.searchByTractIndexNMICountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: 'defaultSearch', label: 'Default Search', fieldType: 'select', value: this.state.defaultSearch, options: searchTypes }]
        }
      ]
    }


    return (
      <>
        <FormTemplate {...formProps} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(Search);