class AjaxResult {
    constructor(result) {
        this.result = result || {};

        if (this.result.data) {
            this.data = this.result.data;
        } else {
            this.data = {
                ok: false,
                message: '',
                lockout: null,
                content: null
            }
        }
    }

    isOk = () => {
        return this.data.ok;
    }

    // Handle an error by notifying the user.
    // It's presumed an error has occurred.
    handleError = () => {
        let msg = this.getErrorMessage();

        alert(msg);
        console.log(msg);
    }

    // A message is always returned.
    getErrorMessage = () => {
        let msg = '';

        if (!msg.length && this.data.message) {
            msg = 'ERROR: ' + this.data.message;
        }

        if (!msg.length) {
            msg = 'HTTP status ' + this.result.status;

            if (this.result.statusText) {
                msg += ' (' + this.result.statusText + ')';
            }
        }

        if (!msg.length) {
            msg = 'An error occurred, please note the steps you took and contact TriMin.';
        }

        return msg;
    }

    // An object is always returned, though it may be empty.
    content = () => {
        return this.data.content;
    }
}

export default AjaxResult;
