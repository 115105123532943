import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, HomeSelectionsControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = HomeSelectionsControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class HomeSelections extends Component {

  state = {
    latestNews: false,
    latestNewsLoginPage: false,
    latestNewsMore: false,
    disclaimer: false,
    contactUs: false,
    links: false,
    linksLoginPage: false,
    linksMore: false,
    loginSlides: false,
    notableDates: false,
    transactions: false,
    status: "",
    message: ""
  }

  handleSubmit = (event) => {

    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/homeSelections", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/homeSelections", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          latestNews: content.latestNews,
          latestNewsLoginPage: content.latestNewsLoginPage,
          latestNewsMore: content.latestNewsMore,
          disclaimer: content.disclaimer,
          contactUs: content.contactUs,
          links: content.links,
          linksLoginPage: content.linksLoginPage,
          linksMore: content.linksMore,
          loginSlides: content.loginSlides,
          notableDates: content.notableDates,
          transactions: content.transactions,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Home - Selections",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'contactUs', label: 'Contact Us Card', fieldType: 'select', value: this.state.contactUs, style: {minWidth: 200} }]
        },
        {
          formGroups: [{ id: 'disclaimer', label: 'Disclaimer Card', fieldType: 'select', value: this.state.disclaimer, style: {minWidth: 200} }]
        },
        {
          formGroups: [{ id: 'disclaimerMessage', fieldType: 'label', hideRow: this.state.disclaimer !== true && this.state.disclaimer !== 'true', value: "* Make sure there's a link labeled Disclaimer in Login - Links." }]
        },
        {
          formGroups: [{ id: 'latestNews', label: 'Latest News Card', fieldType: 'select', value: this.state.latestNews, style: { minWidth: 200 } },
                       { id: 'latestNewsLoginPage', label: 'Login Page Latest News', fieldType: 'select', value: this.state.latestNewsLoginPage, style: { minWidth: 200 } },
                       { id: 'latestNewsMore', label: 'More News', fieldType: 'select', value: this.state.latestNewsMore, style: { minWidth: 200 } }]
        },
        {
          formGroups: [{ id: 'links', label: 'Links Card', fieldType: 'select', value: this.state.links, style: { minWidth: 200 } },
                       { id: 'linksLoginPage', label: 'Login Page Links', fieldType: 'select', value: this.state.linksLoginPage, style: { minWidth: 200 } },
                       { id: 'linksMore', label: 'More Links', fieldType: 'select', value: this.state.linksMore, style: { minWidth: 200 } }]
        },
        {
          formGroups: [{ id: 'loginSlides', label: 'Login Slide Cards', fieldType: 'select', value: this.state.loginSlides, style: {minWidth: 200} }]
        },
        {
          formGroups: [{ id: 'notableDates', label: 'Notable Dates Card', fieldType: 'select', value: this.state.notableDates, style: {minWidth: 200} }]
        },
        {
          formGroups: [{ id: 'transactions', label: 'Transactions Card', fieldType: 'select', value: this.state.transactions, style: {minWidth: 200} }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(HomeSelections);