const productPropertiesReducer = (state = [], action) => {
  switch (action.type) {
    case 'LANDSHARK_PRODUCT_PROPERTIES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default productPropertiesReducer;