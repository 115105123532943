export const validateCharacters = (errors, text, fieldName) => {
    if (text.includes("%")) {
        errors[fieldName] = "Invalid characters.";
        return false;
    }
    return true;
}

export const validateLength = (errors, text, fieldName, minLength) => {
    if (text.trim().length < minLength) {
        errors[fieldName] = minLength + " characters minimum.";
        return false;
    }
    return true;
}