import React from 'react';
import { Form } from 'react-bootstrap'

export default class TextField extends React.Component {
    
    render() {
        let placeholder = this.props.formGroup.placeholder;
        if (!placeholder) {
            placeholder = '(Optional)'
        }

        let style = this.props.formGroup.style;
        if (this.props.formGroup.fieldType === 'number') {
            style = {...style, textAlign: 'right'};
        } 
        if (this.props.formGroup.fieldType === 'minute') {
            style = {...style, textAlign: 'right', width: '48px'};
        } 

        let numberType = this.props.formGroup.numberType;
        let step = 1;
        if (numberType === 'decimal') {
            step = 0.01;
        } 

        let maxLength = this.props.formGroup.maxLength;
        if (!maxLength) {
            maxLength = 999; // arbitrary max length
        }

        let minLength = this.props.formGroup.minLength;
        if (!minLength) {
            minLength = 0; 
        }

        let maxValue = this.props.formGroup.maxValue;
        if (!maxLength) {
            maxLength = undefined;
        }

        let minValue = this.props.formGroup.minValue;
        if (!minValue) {
            minValue = 0;
        }

        let formControl =   <Form.Control style={style} type={this.props.formGroup.fieldType} minLength={minLength} maxLength={maxLength} min={minValue} max={maxValue} step={step} precision={2} placeholder={placeholder} disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.props.formGroup.onBlur} value={this.props.formGroup.value}/>
        if (this.props.formGroup.required === true) {
            if (this.props.formGroup.fieldType === 'tel') {
                formControl =  <Form.Control style={style} required type='tel' maxLength='14' pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}" disabled={this.props.disabled} onChange={this.props.onChange} value={this.props.formGroup.value}/>
            } if (this.props.formGroup.fieldType === 'tel_international') {
                formControl =  <Form.Control style={style} required maxLength='15' disabled={this.props.disabled} onChange={this.props.onChange} value={this.props.formGroup.value}/>
            } else if (this.props.formGroup.fieldType === 'zip') {
                formControl =  <Form.Control style={style} required type='text' maxLength='5' disabled={this.props.disabled} onChange={this.props.onChange} value={this.props.formGroup.value}/>
            } else if (this.props.formGroup.fieldType === 'minute') {
                formControl =  <Form.Control style={style} required type={this.props.formGroup.fieldType} minLength='1' maxLength='2' pattern='[0-5]?[0-9]' disabled={this.props.disabled} onChange={this.props.onChange} value={this.props.formGroup.value}/>
            } else {
                formControl =  <Form.Control style={style} required type={this.props.formGroup.fieldType} minLength={minLength} maxLength={maxLength} min={minValue} max={maxValue} step={step} precision={2} disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.props.formGroup.onBlur} value={this.props.formGroup.value}/>
            }
        } else if (this.props.formGroup.disableField == true) {
            formControl =  <Form.Control style={style} disabled type={this.props.formGroup.fieldType} precision={2} onChange={this.props.onChange} value={this.props.formGroup.value}/>
        } else if (this.props.formGroup.fieldType === 'tel') {
            formControl =  <Form.Control style={style} type='tel' maxLength='14' pattern="(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}" disabled={this.props.disabled} placeholder={placeholder} onChange={this.props.onChange} value={this.props.formGroup.value}/>
        } else if (this.props.formGroup.fieldType === 'tel_international') {
            formControl =  <Form.Control style={style} type='text' maxLength='15' disabled={this.props.disabled} placeholder={placeholder} onChange={this.props.onChange} value={this.props.formGroup.value}/>
        } else if (this.props.formGroup.fieldType === 'zip') {
            formControl =  <Form.Control style={style} type='text' maxLength='5' disabled={this.props.disabled} placeholder={placeholder} onChange={this.props.onChange} value={this.props.formGroup.value}/>
        } else if (this.props.formGroup.fieldType === 'minute') {
            formControl =  <Form.Control style={style} required type={this.props.formGroup.fieldType} minLength='1' maxLength={2} pattern='[0-5]?[0-9]'  disabled={this.props.disabled} onChange={this.props.onChange} value={this.props.formGroup.value}/>
        }

        let fontWeight = 'normal';
        if (this.props.formGroup.primaryCriteria) {
            fontWeight = 'bold';
        }

        let label = this.props.formGroup.label ? this.props.formGroup.label + ':' : <>&nbsp;</>;
        let leadingCharacter = this.props.formGroup.fieldType === 'tel_international' ? '+' : '';
        return (
            <div style={{  marginLeft: 4, fontWeight }}>
                 <div>{label}</div>
                 <div style={{display: 'flex', alignItems : 'center'}}><span>{leadingCharacter}</span> {formControl} </div>
            </div>
        )
    }
}