export const AccountsControlPanelKey = "AccountsControlPanelKey";
export const ALaCarteRatesControlPanelKey = "ALaCarteRatesControlPanelKey";
export const ALaCarteRatesSpecialControlPanelKey = "ALaCarteRatesSpecialControlPanelKey";
export const CreditCardAlternateRatesControlPanelKey = "CreditCardAlternateRatesControlPanelKey";
export const CreditCardExtraControlPanelKey = "CreditCardExtraControlPanelKey";
export const EscrowControlPanelKey = "EscrowControlPanelKey";
export const FlatFeeControlPanelKey = "FlatFeeControlPanelKey";
export const FlatFeeOverrideControlPanelKey = "FlatFeeOverrideControlPanelKey";
export const BillingScheduleControlPanelKey = "BillingScheduleControlPanelKey";

export const CommonControlPanelKey = "CommonControlPanelKey";
export const HomeSelectionsControlPanelKey = "HomeSelectionsControlPanelKey";
export const HomeLatestNewsControlPanelKey = "HomeLatestNewsControlPanelKey";
export const HomeExternalLinksControlPanelKey = "HomeExternalLinksControlPanelKey";
export const HomeNotableDatesControlPanelKey = "HomeNotableDatesControlPanelKey";
export const LoginPageLinksControlPanelKey = "LoginPageLinksControlPanelKey";
export const LoginPageLatestNewsControlPanelKey = "LoginPageLatestNewsControlPanelKey";
export const LoginPageSlidesControlPanelKey = "LoginPageSlidesControlPanelKey";
export const LoginPageRegistrationControlPanelKey = "LoginPageRegistrationControlPanelKey";
export const ReportsControlPanelKey = "ReportsControlPanelKey";
export const SearchControlPanelKey = "SearchControlPanelKey";
export const Search2ControlPanelKey = "Search2ControlPanelKey";
export const EfileControlPanelKey = "EfileControlPanelKey";
export const LanguagesControlPanelKey = "LanguagesControlPanelKey";
export const LandSharkOneAccountsUsersControlPanelKey = "LandSharkOneAccountsUsersControlPanelKey";
export const LandSharkOneAuditLogControlPanelKey = "LandSharkOneAuditLogControlPanelKey";

export const UserLockoutControlPanelKey = "UserLockoutControlPanelKey";
export const UserLockout2ControlPanelKey = "UserLockout2ControlPanelKey";
export const UserPurgeNowControlPanelKey = "UserPurgeNowControlPanelKey";
export const UserPurgeScheduleControlPanelKey = "UserPurgeScheduleControlPanelKey";
export const UserAccessControlPanelKey = "UserAccessControlPanelKey";

export const AuditLogPurgeScheduleControlPanelKey = "AuditLogPurgeScheduleControlPanelKey";

export const PerformanceMonitoringControlPanelKey = "PerformanceMonitoringControlPanelKey";
export const ReloadConfigurationControlPanelKey = "ReloadConfigurationControlPanelKey";

export const setSelectedControlPanelType = (selectedControlPanelType) => {
  return {
    type: 'SET_SELECTED_CONTROL_PANEL_TYPE',
    selectedControlPanelType
  }
}
    
export const updateAccountListFilters = (filters) => {
  return {
    type: 'SET_ACCOUNT_LIST_FILTERS',
    filters
  }
}