import React from 'react';

import { Button } from 'react-bootstrap'

export default class ClearButton extends React.Component {
   render() {
      if (this.props.buttonLabel !== undefined) {
         return <Button variant="secondary"  onClick={this.props.onClear}>{this.props.buttonLabel}</Button>  
      }
      return (
         <Button variant="secondary"  onClick={this.props.onClear}>Clear</Button>
      )
   }
}