import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown, faFilter, faMinusCircle, faCaretSquareLeft, faFileDownload, faArrowRight, faRemoveFormat, faExclamationCircle, faInfoCircle, faArrowDown, faArrowUp, faSignInAlt, faThLarge, faSquare, faAtlas, faSpinner, faPiggyBank, faCartPlus, faCreditCard, faUtensils, faMoneyBillAlt, faHome, faColumns, faCalendar, faFileInvoiceDollar, 
    faDoorClosed, faSearch, faCube, faBook, faHashtag, faDollarSign, faCaretUp, faCaretDown, faChevronCircleLeft, faChevronLeft, faChevronRight, faGlobe, faFileImport, faLanguage,
    faDoorOpen, faUserAltSlash, faUserClock, faUserLock, faCog, faDatabase, faStethoscope, faStopwatch, faPaperclip, faRedo, faPaperPlane, faMobileAlt, faComment, faExternalLinkAlt, faList, faExpand, faImage } from '@fortawesome/free-solid-svg-icons'
import '../../landshark.css';

export const minusIcon = <FontAwesomeIcon icon={faMinusCircle}  size="1x" /> 
export const filterIcon = <FontAwesomeIcon icon={faFilter}  size="1x" /> 

export const arrowAltDownIcon = <FontAwesomeIcon icon={faLongArrowAltDown} className="side-navlink-icon" size="1x"  /> 

export const arrowRightIcon = <FontAwesomeIcon icon={faArrowRight} className="side-navlink-icon" size="1x"  /> 
export const arrowRightActiveIcon = <FontAwesomeIcon icon={faArrowRight} className="side-navlink-icon-active" size="1x"  /> 

export const arrowDownIcon = <FontAwesomeIcon icon={faArrowDown} className="side-navlink-icon" size="1x"  /> 
export const arrowUpIcon = <FontAwesomeIcon icon={faArrowUp} className="side-navlink-icon" size="1x"  /> 

export const atlasIcon = <FontAwesomeIcon icon={faAtlas} className="side-navlink-icon" size="1x"  /> 
export const atlasActiveIcon = <FontAwesomeIcon icon={faAtlas} className="side-navlink-icon-active" size="1x"  /> 

export const spinnerIconGray = <FontAwesomeIcon icon={faSpinner} size="2x" style={{ color: '#808080' }} spin /> 

export const spinnerIconWhiteSmall = <FontAwesomeIcon icon={faSpinner}  style={{ color: 'white' }} size="1x" spin  /> 
export const spinnerIconWhiteBig = <FontAwesomeIcon icon={faSpinner}  style={{ color: 'white' }} spin  /> 

export const calendarIcon = <FontAwesomeIcon icon={faCalendar} size="1x"  /> 

export const dateIcon = <FontAwesomeIcon icon={faCalendar} className="side-navlink-icon" size="1x"  /> 
export const dateActiveIcon = <FontAwesomeIcon icon={faCalendar} className="side-navlink-icon-active" size="1x"  />

export const platIcon = <FontAwesomeIcon icon={faSquare} className="side-navlink-icon" size="1x"  /> 
export const platActiveIcon = <FontAwesomeIcon icon={faSquare} className="side-navlink-icon-active" size="1x"  /> 

export const addressIcon = <FontAwesomeIcon icon={faDoorClosed} className="side-navlink-icon" size="1x"  /> 
export const addressActiveIcon = <FontAwesomeIcon icon={faDoorClosed} className="side-navlink-icon-active" size="1x"  /> 

export const bookIcon = <FontAwesomeIcon icon={faBook} className="side-navlink-icon" size="1x"  /> 
export const bookActiveIcon = <FontAwesomeIcon icon={faBook} className="side-navlink-icon-active" size="1x"  /> 

export const documentNumberIcon = <FontAwesomeIcon icon={faHashtag} className="side-navlink-icon" size="1x"  /> 
export const documentNumberActiveIcon = <FontAwesomeIcon icon={faHashtag} className="side-navlink-icon-active" size="1x"  /> 

export const dollarIcon = <FontAwesomeIcon icon={faDollarSign} className="side-navlink-icon" size="1x"  /> 
export const dollarActiveIcon = <FontAwesomeIcon icon={faDollarSign} className="side-navlink-icon-active" size="1x"  /> 
export const dollarIconBlack = <FontAwesomeIcon icon={faDollarSign} className="side-navlink-icon" size="1x" style={{ color: 'black' }} /> 

export const imageIcon = <FontAwesomeIcon icon={faImage}  size="1x" /> 

export const parcelIcon = <FontAwesomeIcon icon={faCube} className="side-navlink-icon" size="1x"  /> 
export const parcelActiveIcon = <FontAwesomeIcon icon={faCube} className="side-navlink-icon-active" size="1x"  /> 

export const legalIcon = <FontAwesomeIcon icon={faThLarge} className="side-navlink-icon" size="1x"  /> 
export const legalActiveIcon = <FontAwesomeIcon icon={faThLarge} className="side-navlink-icon-active" size="1x"  /> 

export const homeIcon = <FontAwesomeIcon icon={faHome}  size="1x" /> 
export const homeActiveIcon = <FontAwesomeIcon icon={faHome} className="side-navlink-icon-active" size="1x" /> 

export const loginIcon = <FontAwesomeIcon icon={faSignInAlt}  size="1x" /> 
export const loginActiveIcon = <FontAwesomeIcon icon={faSignInAlt} className="side-navlink-icon-active" size="1x" /> 

export const searchIcon = <FontAwesomeIcon icon={faSearch}   size="1x" /> 
export const searchActiveIcon = <FontAwesomeIcon icon={faSearch} className="side-navlink-icon-active" size="1x" /> 

export const caretSquareDownIcon = <FontAwesomeIcon icon={faCaretDown} size="1x"  />
export const caretSquareUpIcon = <FontAwesomeIcon icon={faCaretUp} size="1x" />

export const chevronCircleLeftIcon = <FontAwesomeIcon icon={faChevronCircleLeft}  style={{ color: 'darkGray' }} size="3x"/>
export const chevronLeftIcon = <FontAwesomeIcon icon={faChevronLeft} size="1x" className="side-navlink-icon" />
export const chevronRightIcon = <FontAwesomeIcon icon={faChevronRight} size="1x" className="side-navlink-icon" />

export const statementIcon = <FontAwesomeIcon icon={faFileInvoiceDollar} className="side-navlink-icon" size="1x"  /> 
export const statementActiveIcon = <FontAwesomeIcon icon={faFileInvoiceDollar} className="side-navlink-icon-active" size="1x"  /> 

export const accountTypeIcon = <FontAwesomeIcon icon={faColumns} className="side-navlink-icon" size="1x"  /> 
export const accountTypeActiveIcon = <FontAwesomeIcon icon={faColumns} className="side-navlink-icon-active" size="1x"  /> 

export const commonIcon = <FontAwesomeIcon icon={faGlobe} className="side-navlink-icon" size="1x"  /> 
export const commonActiveIcon = <FontAwesomeIcon icon={faGlobe} className="side-navlink-icon-active" size="1x"  /> 

export const flatFeeIcon = <FontAwesomeIcon icon={faMoneyBillAlt} className="side-navlink-icon" size="1x"  /> 
export const flatFeeActiveIcon = <FontAwesomeIcon icon={faMoneyBillAlt} className="side-navlink-icon-active" size="1x"  /> 

export const unitRatesIcon = <FontAwesomeIcon icon={faUtensils} className="side-navlink-icon" size="1x"  /> 
export const unitRatesActiveIcon = <FontAwesomeIcon icon={faUtensils} className="side-navlink-icon-active" size="1x"  /> 

export const creditCardIcon = <FontAwesomeIcon icon={faCreditCard} className="side-navlink-icon" size="1x"  /> 
export const creditCardActiveIcon = <FontAwesomeIcon icon={faCreditCard} className="side-navlink-icon-active" size="1x"  /> 

export const cartPlusIcon = <FontAwesomeIcon icon={faCartPlus} className="side-navlink-icon" size="1x"  /> 
export const cartPlusActiveIcon = <FontAwesomeIcon icon={faCartPlus} className="side-navlink-icon-active" size="1x"  />

export const escrowReloadIcon = <FontAwesomeIcon icon={faPiggyBank} className="side-navlink-icon" size="1x"  /> 
export const escrowReloadActiveIcon = <FontAwesomeIcon icon={faPiggyBank} className="side-navlink-icon-active" size="1x"  /> 

export const userPurgeIcon = <FontAwesomeIcon icon={faUserAltSlash} className="side-navlink-icon" size="1x"  /> 
export const userPurgeActiveIcon = <FontAwesomeIcon icon={faUserAltSlash} className="side-navlink-icon-active" size="1x"  /> 

export const userClockIcon = <FontAwesomeIcon icon={faUserClock} className="side-navlink-icon" size="1x"  /> 
export const userClockActiveIcon = <FontAwesomeIcon icon={faUserClock} className="side-navlink-icon-active" size="1x"  /> 

export const userLockIcon = <FontAwesomeIcon icon={faUserLock} className="side-navlink-icon" size="1x"  /> 
export const userLockActiveIcon = <FontAwesomeIcon icon={faUserLock} className="side-navlink-icon-active" size="1x"  /> 

export const doorOpenIcon = <FontAwesomeIcon icon={faDoorOpen} className="side-navlink-icon" size="1x"  /> 
export const doorOpenActiveIcon = <FontAwesomeIcon icon={faDoorOpen} className="side-navlink-icon-active" size="1x"  /> 

export const databaseIcon = <FontAwesomeIcon icon={faDatabase} className="side-navlink-icon" size="1x"  /> 
export const databaseActiveIcon = <FontAwesomeIcon icon={faDatabase} className="side-navlink-icon-active" size="1x"  /> 

export const stethoscopeIcon = <FontAwesomeIcon icon={faStethoscope} className="side-navlink-icon" size="1x"  /> 
export const stethoscopeActiveIcon = <FontAwesomeIcon icon={faStethoscope} className="side-navlink-icon-active" size="1x"  /> 

export const stopWatchIcon = <FontAwesomeIcon icon={faStopwatch} className="side-navlink-icon" size="1x"  /> 
export const stopWatchActiveIcon = <FontAwesomeIcon icon={faStopwatch} className="side-navlink-icon-active" size="1x"  /> 

export const cogIconBlack = <FontAwesomeIcon icon={faCog} className="side-navlink-icon" size="1x" style={{ color: 'black' }} /> 

export const paperclipIconBlue = <FontAwesomeIcon icon={faPaperclip}  style={{ color: '#408CC7', marginTop: 5 }} size="1x" />

export const redoIcon = <FontAwesomeIcon icon={faRedo} className="side-navlink-icon" size="1x"  /> 

export const warningIcon = <FontAwesomeIcon icon={faExclamationCircle}  size="2x" /> 
export const warningSmallIcon = <FontAwesomeIcon icon={faExclamationCircle}  size="1x" /> 

export const infoIcon = <FontAwesomeIcon icon={faInfoCircle}  size="2x" /> 
export const infoSmallIcon = <FontAwesomeIcon icon={faInfoCircle}  size="1x" /> 

export const backwardSmallIcon = <FontAwesomeIcon icon={faCaretSquareLeft}  size="1x" /> 

export const fileDownloadSmallIcon = <FontAwesomeIcon icon={faFileDownload}  size="1x" /> 

export const removeFormatIcon = <FontAwesomeIcon icon={faRemoveFormat} className="side-navlink-icon" size="1x"  /> 
export const removeFormatActiveIcon = <FontAwesomeIcon icon={faRemoveFormat} className="side-navlink-icon-active" size="1x"  /> 

export const paperPlaneIcon = <FontAwesomeIcon icon={faPaperPlane} size="1x" />

export const mobileIcon = <FontAwesomeIcon icon={faMobileAlt} size="1x" />

export const commentIcon = <FontAwesomeIcon icon={faComment} size="1x" />

export const externalLinkIcon = <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" />

export const expandIcon = <FontAwesomeIcon icon={faExpand} size="1x" />

export const listIcon = <FontAwesomeIcon icon={faList} size="1x" />

export const fileImportIcon = <FontAwesomeIcon icon={faFileImport} size="1x" />
export const fileImportActiveIcon = <FontAwesomeIcon icon={faFileImport} className="side-navlink-icon-active" size="1x"  /> 

export const languageIcon = <FontAwesomeIcon icon={faLanguage} size="1x" />
export const languageActiveIcon = <FontAwesomeIcon icon={faLanguage} className="side-navlink-icon-active" size="1x"  /> 
