import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { Form } from 'react-bootstrap'
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, CreditCardAlternateRatesControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = CreditCardAlternateRatesControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class CreditCardAlternateRates extends Component {

  state = {
    applyAlternateRates: false,
    searchByAddressCreditCard: "0.0",
    searchByBookAndPageCreditCard: "0.0",
    searchByDateCreditCard: "0.0",
    searchByDocumentNumberCreditCard: "0.0",
    searchByLegalDescriptionCreditCard: "0.0",
    searchByNameCreditCard: "0.0",
    searchByParcelIdCreditCard: "0.0",
    searchByTorrensCertificateNumberCreditCard: "0.0",
    searchByTorrensTextCreditCard: "0.0",
    searchByPlatCreditCard: "0.0",
    searchByTaxLienCreditCard: "0.0",
    searchByGrantorGranteeBookCreditCard: "0.0",
    searchByTractBookCreditCard: "0.0",
    documentDetailsCreditCard: "0.0",
    taxLienDetailsCreditCard: "0.0",
    imageDocumentCreditCard: "0.0",
    imageFirstPageCreditCard: "0.0",
    imageAdditionalPagesCreditCard: "0.0",
    platImagePlatCreditCard: "0.0",
    platImageFirstPageCreditCard: "0.0",
    platImageAdditionalPagesCreditCard: "0.0",
    status: "",
    message: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
  
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }
    
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/creditCard", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );

  }

  handleChange = (event) => {
    const targetId = event.target.id;
    if (targetId.includes("documentType")) {
      const index = targetId.replace("documentType", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.documentType = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("instrumentCode")) {
      const index = targetId.replace("instrumentCode", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.instrumentCode = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("documentRate")) {
      const index = targetId.replace("documentRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.documentRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("firstPageRate")) {
      const index = targetId.replace("firstPageRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.firstPageRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("additionalPagesRate")) {
      const index = targetId.replace("additionalPagesRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.additionalPagesRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else {
      let value = event.target.value;
      value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
      this.setState({
        status: "",
        [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
      })
    }
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/creditCard", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          status : "",
          applyAlternateRates: content.applyAlternateRates,
          searchByAddressCreditCard: content.searchByAddressCreditCard,
          searchByBookAndPageCreditCard: content.searchByBookAndPageCreditCard,
          searchByDateCreditCard: content.searchByDateCreditCard,
          searchByDocumentNumberCreditCard: content.searchByDocumentNumberCreditCard,
          searchByLegalDescriptionCreditCard: content.searchByLegalDescriptionCreditCard,
          searchByNameCreditCard: content.searchByNameCreditCard,
          searchByParcelIdCreditCard: content.searchByParcelIdCreditCard,
          searchByTorrensCertificateNumberCreditCard: content.searchByTorrensCertificateNumberCreditCard,
          searchByTorrensTextCreditCard: content.searchByTorrensTextCreditCard,
          searchByPlatCreditCard: content.searchByPlatCreditCard,
          searchByTaxLienCreditCard: content.searchByTaxLienCreditCard,
          searchByGrantorGranteeBookCreditCard: content.searchByGrantorGranteeBookCreditCard,
          searchByTractBookCreditCard: content.searchByTractBookCreditCard,

          documentDetailsCreditCard: content.documentDetailsCreditCard,
          taxLienDetailsCreditCard: content.taxLienDetailsCreditCard,
          
          imageDocumentCreditCard: content.imageDocumentCreditCard,
          imageFirstPageCreditCard: content.imageFirstPageCreditCard,
          imageAdditionalPagesCreditCard: content.imageAdditionalPagesCreditCard,
          rates: content.rates
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {

    let afterRowsContent = <><h5 style={{ textDecoration: 'underline' }}>Special Rates</h5>None</>

    // if (this.state.rates && this.state.rates.length > 0 && this.state.applySpecialRates !== true) {
    //   afterRowsContent = <><h5 style={{ textDecoration: 'underline' }}>Special Rates</h5>Not Applied</>
    // } else 
    
    if (this.state.rates && this.state.rates.length > 0) {
      let rateTable =
        <div>
          {
            this.state.rates.map((rate, index) => (
              (index > 0) &&
              <Form.Row>
                <div style={{ display: 'inline-block', marginLeft: 4 }}>
                  <Form.Control disabled type='text' id={'documentType' + index} value={this.state.rates[index].documentType} style={{ maxWidth: 130 }} />
                </div>
                <div style={{ display: 'inline-block', marginLeft: 4 }}>
                  <Form.Control disabled type='text' id={'instrumentCode' + index} value={this.state.rates[index].instrumentCode} style={{ maxWidth: 130 }} />
                </div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style={{ display: 'inline-block', marginLeft: 4 }}>
                  {
                    this.state.applyAlternateRates !== false &&
                    <Form.Control type='number' step='0.01' id={'documentRate' + index} onChange={this.handleChange} value={this.state.rates[index].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                  {
                    this.state.applyAlternateRates === false &&
                    <Form.Control type='number' step='0.01' id={'documentRate' + index} disabled value={this.state.rates[index].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                </div>
                <div style={{ display: 'inline-block', marginLeft: 4 }}>
                  {
                    this.state.applyAlternateRates !== false &&
                    <Form.Control type='number' step='0.01' id={'firstPageRate' + index} onChange={this.handleChange} value={this.state.rates[index].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                  {
                    this.state.applyAlternateRates === false &&
                    <Form.Control type='number' step='0.01' id={'firstPageRate' + index} disabled value={this.state.rates[index].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                </div>
                <div style={{ display: 'inline-block', marginLeft: 4 }}>
                  {
                    this.state.applyAlternateRates !== false &&
                    <Form.Control type='number' step='0.01' id={'additionalPagesRate' + index} onChange={this.handleChange} value={this.state.rates[index].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                  {
                    this.state.applyAlternateRates === false &&
                    <Form.Control type='number' step='0.01' id={'additionalPagesRate' + index} disabled value={this.state.rates[index].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                  }
                </div>
              </Form.Row>
            ))
          }
        </div>

      afterRowsContent =
        <>
          <h5 style={{ textDecoration: 'underline' }}>Special Rates</h5>
          <Form.Row>
            <div style={{ display: 'inline-block', marginLeft: 4 }}>
              <div>Document Type:</div>
              <Form.Control id='documentType0'  disabled type='text' value={this.state.rates[0].documentType} style={{ maxWidth: 130 }} />
            </div>
            <div style={{ display: 'inline-block', marginLeft: 4 }}>
              <div>Instrument Code:</div>
              <Form.Control id='instrumentCode0'  disabled type='text' value={this.state.rates[0].instrumentCode} style={{ maxWidth: 130 }} />
            </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div style={{ display: 'inline-block', marginLeft: 4 }}>
              <div>Document ($):</div>
              {
                this.state.applyAlternateRates !== false &&
                <Form.Control type='number' step='0.01' id='documentRate0' onChange={this.handleChange} value={this.state.rates[0].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
              {
                this.state.applyAlternateRates === false &&
                <Form.Control type='number' step='0.01' id='documentRate0' disabled value={this.state.rates[0].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
            </div>
            <div style={{ display: 'inline-block', marginLeft: 4 }}>
              <div>1st Page ($):</div>
              {
                this.state.applyAlternateRates !== false &&
                <Form.Control type='number' step='0.01' id='firstPageRate0' onChange={this.handleChange} value={this.state.rates[0].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
              {
                this.state.applyAlternateRates === false &&
                <Form.Control type='number' step='0.01' id='firstPageRate0' disabled value={this.state.rates[0].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
            </div>
            <div style={{ display: 'inline-block', marginLeft: 4 }}>
              <div>Additional Pages ($):</div>
              {
                this.state.applyAlternateRates !== false &&
                <Form.Control type='number' step='0.01' id='additionalPagesRate0' onChange={this.handleChange} value={this.state.rates[0].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
              {
                this.state.applyAlternateRates === false &&
                <Form.Control type='number' step='0.01' id='additionalPagesRate0' disabled value={this.state.rates[0].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
              }
            </div>
          </Form.Row>
          {rateTable}
        </>
    }
    const formProps = {
      componentName: componentName,
      pageHeading: "Credit Card Alternate Rates",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      afterRowsContent: afterRowsContent,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'applyAlternateRates', label: 'Apply Alternate Rates', fieldType: 'select', value: this.state.applyAlternateRates }]
        },
        {
          heading: 'Searches',
          formGroups: [
            { id: 'searchByAddressCreditCard', label: 'Address ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByAddressCreditCard },
            { id: 'searchByBookAndPageCreditCard', label: 'Book & Page ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByBookAndPageCreditCard },
            { id: 'searchByDateCreditCard', label: 'Date ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByDateCreditCard },
            { id: 'searchByDocumentNumberCreditCard', label: 'Document Number ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByDocumentNumberCreditCard },
            { id: 'searchByLegalDescriptionCreditCard', label: 'Legal Descriptions ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByLegalDescriptionCreditCard },
            { id: 'searchByNameCreditCard', label: 'Name ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByNameCreditCard },
            { id: 'searchByParcelIdCreditCard', label: 'Parcel ID ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByParcelIdCreditCard },
            { id: 'searchByTorrensCertificateNumberCreditCard', label: 'Torrens Certificate ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByTorrensCertificateNumberCreditCard },
            { id: 'searchByTorrensTextCreditCard', label: 'Torrens Text ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByTorrensTextCreditCard },
            { id: 'searchByPlatCreditCard', label: 'Plat ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByPlatCreditCard },
            { id: 'searchByTaxLienCreditCard', label: 'Tax Lien ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByTaxLienCreditCard },
            { id: 'searchByGrantorGranteeBookCreditCard', label: 'Grantor/ee Book ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByGrantorGranteeBookCreditCard },
            { id: 'searchByTractBookCreditCard', label: 'Tract Book ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.searchByTractBookCreditCard }
          ]
        },
        {
          heading: 'Search Details',
          formGroups: [{ id: 'documentDetailsCreditCard', label: 'Document ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.documentDetailsCreditCard },
          { id: 'taxLienDetailsCreditCard', label: 'Tax Lien ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.taxLienDetailsCreditCard }]
        },
        {
          heading: 'Document Image',
          formGroups: [
            { id: 'imageDocumentCreditCard', label: 'Document ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.imageDocumentCreditCard },
            { id: 'imageFirstPageCreditCard', label: '1st Page ($)', fieldType: 'number', numberType:'decimal', disableField:  this.state.applyAlternateRates === false, value: this.state.imageFirstPageCreditCard },
            { id: 'imageAdditionalPagesCreditCard', label: 'Additional Pages ($)', fieldType: 'number', numberType:'decimal', disableField: this.state.applyAlternateRates === false, value: this.state.imageAdditionalPagesCreditCard }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(CreditCardAlternateRates);