import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from "axios";
import configData from "../../config/config.json";
import AjaxResult from "../../utils/AjaxResult";

//https://stackoverflow.com/questions/46162278/authenticate-async-with-react-router-v4

class PrivateRoute extends React.Component {
  
   state = {
      loading: true,
      isAuthenticated: false,
   }

   componentDidMount() {
      this.isLoggedIn().then((isAuthenticated) => {
         this.setState({
            loading: false,
            isAuthenticated,
         });
      });
   }

   isLoggedIn() {
      //Check if logged in
      return axios.get(configData.LANDSHARK_SERVER_URL + "currentUser/loginStatus", { withCredentials: true })
         .then((response) => {
            let result = new AjaxResult(response);
   
            if (result.isOk()) {
               return true;
            } 
            return false
         }, (error) => {
            // Nothing we can do with the error.  Just assume they aren't logged in.
            return false;
         });
   }

   render() {
      const { component: Component, ...rest } = this.props;
      if (this.state.loading) {
         return <div>LOADING</div>;
      } else {
         return ( <Route {...rest} render={props =>this.state.isAuthenticated ? (
               <Component {...props} />
            ) : (
               this.state.loading ? (
                 <div>LOADING</div>
               ) : (
                  <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
               )
            )}
         />)
      }
   } 
}

export { PrivateRoute };