// Usage from https://github.com/axios/axios#handling-errors
// Should be invoked from the axios error function.
class AjaxResponseHandler {
    // Gets the HTTP status of an error from an axios error callback.
    // 'error' is the error object, typically the axios object passed to error().
    // 'defaultValue' is the default status if it can't be determined, defaults to 0.
    // Returns the status.
    static getStatus(error, defaultValue) {
        let status = defaultValue ? defaultValue : 0;

        if (error) {
            let obj = error.response;

            if (obj && (obj.status)) {
                status = obj.status;
            }
        }

        return status;
    }

    // Handle an error from an axios error callback.
    // 'error' is the error object, typically the axios object passed to error().
    // 'handler(message)' is the message handler or null for an alert to be displayed.
    // 'history' is the history stack or null.
    static handleError(error, handler, history) {
        // Update the history
        let defaultStatus = '';
        let status = AjaxResponseHandler.getStatus(error, defaultStatus);

        if (history) {
            if (status === 403) {
                history.push('/login');
            } 
        }

        // Generate the message.
        let message = '';

        if (error) {
            let obj = error.response;

            if (obj && obj.data) {
                message = obj.data.message;
            }
        }

        if (!message) {
            if (status === defaultStatus) {
                message = 'An unexpected error has occurred';
            } else {
                message = 'An unexpected error has occurred (' + status + ')';
            }
        }
        
        // Dispatch the error.
        AjaxResponseHandler.dispatchError(message, handler);
    }

    static consoleLogHandler(message) {
        console.log(message);
    }

    static dispatchError(message, handler) {
        if (handler) {
            handler(message);
        } else {
            alert(message);
        }
    }
}

export default AjaxResponseHandler;
