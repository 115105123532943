import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByTractIndexNMIKey, SearchByAddressUrl } from "../../../redux/actions/searchActions";
import { validateDates } from "../../../utils/DateValidation";
import { LOADING_STATUES } from "../../constants/constants";
import translations from "../../../config/translation/search_by_tract_index_nmi_translations.json";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";

const componentName = SearchByTractIndexNMIKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByAddress: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByAddressUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  // If it was coming from a Recent Search link, there should be a searchHistoryNumber.  Use it to look up the search criteria in search history.
  // Otherwise, look up the previous search criteria for SearchByAddress.

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    fromRecordedDate: previousSearchKeyValue.fromRecordedDate ? previousSearchKeyValue.fromRecordedDate : moment('01/01/1900').format('L'),
    toRecordedDate: previousSearchKeyValue.toRecordedDate ? previousSearchKeyValue.toRecordedDate : moment().format('L'),
    island: previousSearchKeyValue.island ? previousSearchKeyValue.island : null,
    village: previousSearchKeyValue.village ? previousSearchKeyValue.village : null,
    parcelId: previousSearchKeyValue.parcelId ? previousSearchKeyValue.parcelId : null,
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'
  };
}

class SearchByTractIndexNMI extends Component {

  state = {
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    island: this.props.island,
    village: this.props.village,
    parcelId: this.props.parcelId,
    loadingStatus: "",
    submitMessage: "",

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: "",
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }
  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByAddress(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.island) {
      errors["island"] = translations[this.props.language].required;
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.village) {
      errors["village"] = translations[this.props.language].required;
      this.setState({ errors: errors });
      return false;
    }

    if (this.state.village.length < 3) {
      errors["village"] = translations[this.props.language].three_characters_minimum;
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();
    this.setState({
      island: '',
      village: '',
      parcelId: '',
      fromRecordedDate: moment('01/01/1900').format('L'),
      toRecordedDate: moment().format('L'),
      submitMessage: '',
      errors: {}
    })
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: translations[this.props.language].search_by_tract_index,
      onChange: this.handleChange,
      onClear: this.handleClear,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      primaryButtonLabel: translations[this.props.language].submit,
      clearButtonLabel: translations[this.props.language].clear,
      formGroupRows: [
        {
          formGroups: [{ id: 'island', label: translations[this.props.language].island, fieldType: 'select', primaryCriteria: true, required: true, value: this.state.island, error: this.state.errors['island'] }]
        },
        {
          formGroups: [{ id: 'village', label: translations[this.props.language].village, primaryCriteria: true, fieldType: 'text', value: this.state.village, error: this.state.errors['village'] }]
        },
        {
          formGroups: [{ id: 'parcelId', label: translations[this.props.language].parcel_id, fieldType: 'text', value: this.state.parcelId, error: this.state.errors['parcelId'] }]
        },
        {
          formGroups: [{ id: 'fromRecordedDate', label: translations[this.props.language].from_date, fieldType: 'date', value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
        },
        {
          formGroups: [{ id: 'toRecordedDate', label: translations[this.props.language].to_date, fieldType: 'date', value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
        }
      ],

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }
    return (
      <FormTemplate {...formProps} />
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByTractIndexNMI);
