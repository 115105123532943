const initialState = { }

const imageContentReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'UPDATE_IMAGE_CONTENT':
                  return action.content;
            default:
                  break;
      }
      return state;
};
export default imageContentReducer;