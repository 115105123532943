import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'

const NO_OPTION_SELECTED = '';
const PREFIX = 'FlatFeeAddOnWindow: ';

// Class notes.
//
// Properties:
// - show: boolean: show or not
// - title: string: window title
// - options: { text: string, value: string }. value cannot be null
// - backClicked(): callback when the back button is clicked (null == no button)
// - buyClicked({ option: value-of-option-selected }): callback
//     when the buy button is clicked
// - closeClicked(): callback when the close button is clicked
//
// The selected option starts with the 1st option.  If the 
// currently selected option is not valid, the 1st option is chosen.
class FlatFeeAddOnWindow extends Component {
   constructor(props) {
      super(props);
      
      console.log(PREFIX + "constructed with props", props);

      this.state = {
         optionSelected: NO_OPTION_SELECTED
      };

      console.log(PREFIX + 'construction complete');
   }

   componentDidMount = () => {
   }

   componentDidUpdate = (prevProps, prevState) => {
   }

   handleBackClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.backClicked();
   }

   handleBuyClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.buyClicked({
         option: this.state.optionSelected
      });
   }
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.closeClicked();
   }

   handleOptionChange = (event) => {
      this.setState( {
         optionSelected: event.target.value
      });
   }

   render() {
      console.log(PREFIX + 'rendering');
      console.log(PREFIX + 'state: ' , this.state);
      console.log(PREFIX + 'props: ' , this.props);
      
      // Ensure selected option is valid.
      // I'm not a fan of calling setState() here as a re-render 
      // is involved, but the other option is a bit clumsy as
      // we would forget the selected value when coming back to
      // the window from the back and forward buttons in the workflow.
      let me = this;
      let validSelectedValue = null;

      this.props.options.map(function(option, idx) {
         if (me.state.optionSelected === option.value) {
            validSelectedValue = true;
         }
      });

      if (!validSelectedValue && (this.props.options.length > 0)) {
         this.setState( {
            optionSelected: this.props.options[0].value
         });
      }

      return (
         <Modal show={this.props.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  Flat Fee Account Add On
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ background: '#408CC7' }}>
               <Container >
                  <Row className="justify-content-md-center">
                  <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                        <Container>
                           <Row>
                              <Col>
                                 <Container>
                                    <Row>
                                       <span style={{ fontWeight: 'bold'}}>{this.props.title}</span>
                                    </Row>
                                       { 
                                             this.props.options.map(function(option, idx) {
                                                return <>
                                                   <Row>
                                                      <Form.Check name='purchase' id={'PURCHASE_'+idx} type='radio' 
                                                         checked={me.state.optionSelected === option.value} onChange={me.handleOptionChange} 
                                                         style={{ display: 'inline-block' }} 
                                                         label={option.text} value={option.value} /> 
                                                   </Row>
                                                </>;
                                             })
                                       }
                                 </Container>
                              </Col>
                         </Row>
                  </Container>
                  </Col>   
                  </Row>
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     {
                        this.props.backClicked && 
                        <Col md='3' className='align-self-end' style={{ textAlign: 'left' }}>
                           <Button variant="secondary" onClick={this.handleBackClick}>Back</Button>
                        </Col>
                     }
                     <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                        <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleBuyClick}>Buy</Button>
                        <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default FlatFeeAddOnWindow;
