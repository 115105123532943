
const platNamesReducer = (state = [{code: "-1", description: "(Select from list)"}], action) => {
  switch (action.type) {
    case 'LANDLINK_PLAT_NAMES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default platNamesReducer;