import React from 'react';
import { connect } from "react-redux";

import landshark_logo from '../../img/trimin_landshark_verysmall.png';

const mapStateToProps = state => {
	return {
		countyName: state.countyConfigCommonReducer.countyName,
		appTitle: state.countyConfigCommonReducer.appTitle
	};
}

class LoginTop extends React.Component {

	handleTriminClick = (event) => {
		event.preventDefault();
		window.open("https://www.trimingov.com/");
	};

	render() {

		// let rightHandSide = '';
		// if (this.props.pathname && this.props.pathname === '/login') {
		// 	rightHandSide = <span>&nbsp;New User?<NavLink to='/register'> <button className="btn btn-primary">Register</button></NavLink></span>
		// }

		return (
			<header className="container-fluid" style={{borderBottom: '3px solid black'}} >
				<table>
					<tbody>
						<tr>
							<td valign="bottom">
								<table align="center" width="100%">
									<tbody>
										<tr>
											<td>
												<span style={{ color: '#3674AB', fontSize: 36, fontVariant: 'small-caps', fontWeight: 'bold' }}>{this.props.countyName} {this.props.appTitle}</span>
											</td>
										</tr>
										<tr>
											<td>
												<span style={{ fontSize: 20, fontWeight: 500, marginTop: 50 }}>&nbsp;powered by&nbsp;</span>
												<img border="0" src={landshark_logo} alt="Trimin Landshark" style={{ paddingBottom: 15, cursor: "pointer" }} onClick={ this.handleTriminClick} />
											</td>
										</tr>
									</tbody>
								</table>
							</td>
							{/* <td valign="bottom" width="1%">
								{rightHandSide}
							</td> */}
						</tr>
					</tbody>
				</table>
			</header>
		)
	}
}

export default connect(mapStateToProps)(LoginTop);