import React, { Component } from "react"
import { Container, Col, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import SearchResultsDetailsNoResults from './SearchResultsDetailsNoResults'
import { LOADING_STATUES } from "../../constants/constants";
import Loading from '../../common/modal/Loading';
import Thumbnails from "../../image/Thumbnails";

const mapStateToProps = state => {
    return {
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsGrantorGranteeBookReducer,
        currentUser: state.currentUserReducer
    };
}

class GrantorGranteeBookSearchResultsDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imagingWindows: this.createImagingWindowsWindowState('', false),
            loadingStatus: ""
        };
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
     }

    // May return null if not found.
    getSelectedSearchResult = () => {
        if (!this.props.searchResults || (this.props.searchResultsDetails === 0)) {
            return null;
        }

        let searchResult = this.props.searchResults.searchResultList.find((searchResult) => {
            return this.props.searchResultsDetails == searchResult.docNumber;
        });

        return searchResult;
    }

    // Can return null.
    getSelectedSearchResultInfo = () => {
        let searchResult = this.getSelectedSearchResult();

        if (!searchResult) {
            return null;
        }

		let info = { 
            documentType: searchResult.docType, 
            documentNumber: searchResult.docNumber,
            documentKey: searchResult.docType + searchResult.docNumber,
            instrumentCode: ''      // there is none for this doc type
        };

        return info;
    }

    handleImageClick = (event) => {
        if (event) {
            event.preventDefault();
         }
           
        let info = this.getSelectedSearchResultInfo();

        if (!info) {
            alert("Select a document.");
            return; 
        }

        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(info, true)
        } );
    }

    handleImageComplete = () => {
        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(null, false)
        } );
    }

    createImagingWindowsWindowState = (searchResult, show) => {
        return {
            docKey: searchResult ? searchResult.documentKey : '',
            instCode: searchResult ? searchResult.instrumentCode : '',
            show: show
        }
    }
    
    render() {
        let selectedSearchResult = this.getSelectedSearchResult();

        if (!selectedSearchResult) {
            return (<SearchResultsDetailsNoResults />)
        }

        let { docNumber, type, fromName, toName, fromDate, toDate } = selectedSearchResult;

        return (
            <Col style={{ paddingLeft: 0, marginRight: 0, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <Container key={this.props.searchResultsDetails} style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 0, paddingBottom: 10, marginRight: 0 }}  fluid="true">
                    <div>
                        <h5 >
                            <strong>{type}</strong>
                            <span style={{ float: 'right' }}>
                                {
                                    this.props.currentUser.imageRetrieval &&
                                    <Button variant="primary" size="sm" onClick={this.handleImageClick} >Image</Button>
                                }
                            </span>
                        </h5>
                    </div>
                    <hr style={{ background: 'black', marginTop: 2, marginBottom: 2 }}></hr>
                    <div style={{ marginBottom: 0, marginTop: 10 }} >
                        <div><strong>Book Reference #: </strong>{docNumber} &nbsp;</div>
                        <div><strong>Name: </strong>{fromName} - {toName}</div>
                        <div><strong>Date: </strong>{fromDate} - {toDate}</div>
                    </div>
                </Container>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <Thumbnails show={this.state.imagingWindows.show} 
                    docKey={this.state.imagingWindows.docKey} 
                    instCode={this.state.imagingWindows.instCode} 
                    complete={this.handleImageComplete} />
            </Col>
        )
    }
}

export default connect(mapStateToProps)(GrantorGranteeBookSearchResultsDetails);