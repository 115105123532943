import React, { Component } from "react"
import { Container, Col } from 'react-bootstrap'
import { chevronCircleLeftIcon } from "../../../img/font_awesome/font_awesome"
import '../../../landshark.css';

export default class SearchResultsDetailsNoResults extends Component {
    render() {
        return (
            <Col style={{paddingLeft: 0, marginLeft: 6, marginRight: 4}} >
                <Container fluid="true">
                    <div id="arrow" className="circle bounce" data-target="#introText">
                        <div  className="bounce" style={{ paddingLeft:10, paddingTop: 65 }}> {chevronCircleLeftIcon} <span style={{position: 'relative', bottom: 10, color: 'black'}}>Click on a record to view details and images</span></div>
                    </div>
                </Container>
            </Col>
        )
    }
}