const initialState = new Set();

const searchResultsVisitedReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'VISITED':
                  const id = action.id;    
                  if (state instanceof Set !== true) {
                         state = new Set();
                  }
                  state.add(id);           
                  return state;
            case 'RESET_READ':
            case 'UPDATE_SEARCH_RESULTS':   
            case 'LOGOUT_USER':
                  return new Set();
            default:
                  break;
      }
      return state;
};
export default searchResultsVisitedReducer;