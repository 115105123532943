const notableDatesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_NOTABLE_DATES':
      return action.result;
    default:
      break;
  }
  return state;
}

export default notableDatesReducer;