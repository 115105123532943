const accountTypesReducer =  (state = [], action) => {
  switch (action.type) {
    case 'LANDSHARK_ACCOUNT_TYPES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default accountTypesReducer;