import React, {Component} from 'react';
import { Card, Table, Button } from 'react-bootstrap'

import { listIcon, expandIcon } from '../../img/font_awesome/font_awesome';
import TransactionReport from './TransactionReport';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class Transactions extends Component {

    state = {
        showModal: false
    }

    handleClick = () => {
        this.setState({
            showModal : true
        })
    }

    handleClose = () => {
        this.setState({
            showModal : false
        })
    }

    render() {
        let transactionTotalsTable = <></>;
        if (this.props.transactionTotals) {
            transactionTotalsTable =
                <Table size='sm'>
                    <tbody>
                    <tr>
                        {
                            this.props.transactionTotals.headings.map((heading) => {
                                return <th className="table-td-xs">{heading}</th>
                            })

                        }
                    </tr>
                    {
                        this.props.transactionTotals.rows.map((row, index) => {
                            return <tr key={index}>
                                {
                                    row.values.map((value, index) => { return <td key={index} className="table-td-xs">{value}</td> })
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
        }

        return (
            <>
            <Card>
                <Card.Header>
                    {listIcon}&nbsp;&nbsp;{translations[this.props.language].transactions}<span onClick={this.handleClick}  style={{ cursor: "pointer", float: 'right' }}>{expandIcon}</span>
                </Card.Header>
                <Card.Body>
                    {transactionTotalsTable}
                    <Button variant="primary" className='btn-sm' onClick={this.handleClick} >{translations[this.props.language].details}</Button> 
                </Card.Body>
            </Card>
            <TransactionReport show={this.state.showModal} handleClose={this.handleClose} />
            </>
        )
    }
}

export default connect(mapStateToProps)(Transactions);
