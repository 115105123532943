import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container, Table } from 'react-bootstrap'

const PREFIX = 'FlatFeeAddOnConfirmationWindow: ';

// Class notes.
//
// Properties:
// - show: boolean: show or not
// - content: {
//     accountMessage: '',
//     beginningBalance: null,  // { description: '', value: '' }
//     endingBalance: null,  // { description: '', value: '' }
//     headers: [],
//     insufficientBalance: false,
//     purchase: { description: '', value: ''}
//   }
// - backClicked(): callback when the back button is clicked
// - buyClicked(): callback
//     when the buy button is clicked
// - closeClicked(): callback when the close button is clicked
//
class FlatFeeAddOnConfirmationWindow extends Component {
   constructor(props) {
      super(props);
      
      console.log(PREFIX + "constructed with props", props);

      this.defaultContent = {
         accountMessage: '',
         beginningBalance: null,
         endingBalance: null,
         headers: [],
         insufficientBalance: false,
         purchase: { description: '', value: ''}
      };
      
      this.state = {
      };

      console.log(PREFIX + 'construction complete');
   }

   componentDidMount = () => {
   }

   componentDidUpdate = (prevProps, prevState) => {
   }

   handleBackClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.backClicked();
   }

   handleBuyClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.buyClicked();
   }
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.closeClicked();
   }

   render() {
      console.log(PREFIX + 'rendering');
      console.log(PREFIX + 'props: ' , this.props);
      console.log(PREFIX + 'state: ' , this.state);
      
      let content = Object.assign({}, this.defaultContent, this.props.content);
      let indent = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

      return (
         <Modal show={this.props.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  Flat Fee Account Add On Confirmation
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ background: '#408CC7' }}>
               <Container >
                  <Row className="justify-content-md-center">
                  <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                        <Container>
                           <Row>
                              <Col>
                                 <Container style={{ textAlign: 'center' }}> 
                                 {
                                    content.headers.map((header, idx) => {
                                       let style = {};
                                       
                                       if (idx === 1) style.fontWeight = 'bold';

                                       return <>
                                             <span style={style}>{header}</span><br/>
                                       </>;
                                    })
                                 }
                                 </Container>
                                 <Row>&nbsp;</Row>
                                 <Container>
                                       <Table borderless size="sm">
                                          <tbody>
                                             <tr>
                                                <td colSpan="2" style={{ fontWeight: 'bold' }}>{content.accountMessage}</td>
                                             </tr>
                                             {
                                                content.beginningBalance && 
                                                <tr>
                                                   <td style={{ fontWeight: 'bold' }}>{content.beginningBalance.description}</td>
                                                   <td style={{ textAlign: 'right' }}>{content.beginningBalance.value}</td>
                                                </tr>
                                             }
                                             <tr>
                                                <td style={{ fontWeight: 'bold' }}>{content.purchaseMessage}</td>
                                                <td>&nbsp;</td>
                                             </tr>
                                             <tr>
                                                <td>{indent}{content.purchase.description}</td>
                                                <td style={{ textAlign: 'right' }}>{content.purchase.value}</td>
                                             </tr>
                                             {
                                                content.endingBalance && content.insufficientBalance &&
                                                <tr>
                                                   <td style={{ fontWeight: 'bold' }}>{content.endingBalance.description}</td>
                                                   <td style={{ fontWeight: 'bold', textDecoration: 'underline', textAlign: 'right' }}>{content.endingBalance.value}</td>
                                                </tr>
                                             }
                                             {
                                                content.endingBalance && !content.insufficientBalance &&
                                                <tr>
                                                   <td style={{ fontWeight: 'bold' }}>{content.endingBalance.description}</td>
                                                   <td style={{ textAlign: 'right' }}>{content.endingBalance.value}</td>
                                                </tr>
                                             }
                                          </tbody>
                                       </Table>
                                    </Container>
                              </Col>
                         </Row>
                  </Container>
                  </Col>   
                  </Row>
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='3' className='align-self-end' style={{ textAlign: 'left' }}>
                        <Button variant="secondary" onClick={this.handleBackClick}>Back</Button>
                     </Col>
                     <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                        {
                           !content.insufficientBalance &&
                           <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleBuyClick}>Buy</Button>
                        }
                        <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default FlatFeeAddOnConfirmationWindow;
