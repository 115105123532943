const sideNavigationReducer = (state = true, action) => {
  if (action.type === "TOGGLE_SIDE_NAVIGATION") {
    if (state === true)
      state = false;
    else
      state = true;
  } else if (action.type === 'LOGOUT_USER') {
    state = true;
  }
  return state;
}

export default sideNavigationReducer;