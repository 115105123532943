const documentTypesWithBlankReducer = (state = [{code: "", description: ""}], action) => {
  switch (action.type) {
    case 'LANDLINK_DOCUMENT_TYPES_WITH_BLANKS':
      return action.results;
    default:
      break;
  }
  return state;
}

export default documentTypesWithBlankReducer;