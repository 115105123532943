import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col, Container, Table } from 'react-bootstrap'

const PREFIX = 'EscrowAddOnWindow: ';

// Class notes.
//
// - props.escrowAddOnWindow.*
// - action ( 'show' or any )
// - event.backClicked
// - event.closeClicked
// - imageState
// - show
// - version
//
// The CONTINUE handler passes back:
//   option: The option value selected
class EscrowAddOnWindow extends Component {
   constructor(props) {
      super(props);

      console.log(PREFIX + "constructed with props", props);

      this.state = {
         deposit: '0'
      };

      this.version = 0;

      this.updateStateFromProps(true, false);
      console.log(PREFIX + 'construction complete');
   }

   handleBackClick = (event) => {
      console.log(PREFIX + 'handleBackClick');

      if (event) {
         event.preventDefault();
      }

      this.state.event.backClicked({});
   }

   handleBuyClick = (event) => {
      console.log(PREFIX + 'handleBuyClick');

      if (event) {
         event.preventDefault();
      }

      this.state.event.buyClicked({
         deposit: this.state.deposit
      });
   }
   
   handleCloseClick = (event) => {
      console.log(PREFIX + 'handleCloseClick');

      if (event) {
         event.preventDefault();
      }

      this.state.event.closeClicked();
   }

   handleDepositChanged = (event) => {
      console.log(PREFIX + 'handleDepositChanged: ', event.target.value);

      // Since the user is typing here, select the "pages" form of entry.
      this.updateState( {
         deposit: event.target.value
      });
   }

   handleDepositBlur = (event) => {
      console.log(PREFIX + 'handleDepositBlur');

      // Update the new value so the server gets it.
      this.updateState( {
         deposit: event.target.value
      });
   }

   updateStateFromProps = (createIfMissing, callSetState) => {
      let source = this.props.escrowAddonWindow;

      if (!source) {
         if (!createIfMissing) return;

         source = {
            action: '',
            event: {},
            imageState: null,
            show: false,
            version: (this.version < 1) ? 1 : this.version + 1
         }
      }

      if (source.version <= this.version) return;

      console.log(PREFIX + 'updating state from props version ' + source.version + ' state version ' + this.version);
      console.log(PREFIX + 'updating state from source', source);

      let newState = callSetState ? {} : this.state;

      newState.show = source.show;
      this.version = source.version;

      if (!source.imageState) {
         source.imageState = {
             purchaseOptions: {
                 escrowOptions: {
                 }
             }
         }
      }

      if (source.action !== 'show') {
         newState.event = source.event;
         newState.imageState = source.imageState;
      }

      if (callSetState) {
         this.updateState(newState);
      }
   }

   updateState = (newState) => {
      console.log(PREFIX + 'setState', newState);
      this.setState(newState);
   }

   render() {
      console.log(PREFIX + 'rendering state version ' + this.version);

      this.updateStateFromProps(false, false);

      console.log(PREFIX + 'state: ', this.state);
      console.log(PREFIX + 'props: ', this.props);

      let eo = this.state.imageState.purchaseOptions.escrowOptions;

      return (
         <Modal show={this.state.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  Escrow Account Add On
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ background: '#408CC7' }}>
               <Container >
                  <Row className="justify-content-md-center">
                     <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                        <Container>
                           <Row>
                              <Col>
                                 <Container>
                                    <Table borderless size="sm">
                                       <tbody>
                                          <tr>
                                             <td colSpan="2" style={{ fontWeight: 'bold' }}>{eo.accountMessage}</td>
                                          </tr>
                                          {
                                             eo.beginningBalance &&
                                             <tr>
                                                <td style={{ fontWeight: 'bold' }}>{eo.beginningBalance.description}</td>
                                                <td style={{ textAlign: 'right' }}>{eo.beginningBalance.value}</td>
                                             </tr>
                                          }
                                          {
                                             eo.lowBalanceMessage &&
                                             <tr>
                                                <td>{eo.lowBalanceMessage}</td>
                                                <td style={{ textAlign: 'right' }}>&nbsp;</td>
                                             </tr>
                                          }
                                          <tr>
                                             <td style={{ fontWeight: 'bold', verticalAlign: 'middle' }}>{eo.depositAmountMessage}</td>
                                             <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                                <Form.Control id='deposit' style={{ width: 200, margin: 5, display: 'inline-block' }} 
                                                   type='text' onChange={this.handleDepositChanged} 
                                                   onBlur={this.handleDepositBlur} value={this.state.deposit} /> 
                                             </td>
                                          </tr>
                                          {
                                             eo.surchargeMessage &&
                                             <tr>
                                                <td>{eo.surchargeMessage}</td>
                                                <td style={{ textAlign: 'right' }}>&nbsp;</td>
                                             </tr>
                                          }
                                          {
                                             eo.endingBalance &&
                                             <tr>
                                                <td style={{ fontWeight: 'bold' }}>{eo.endingBalance.description}</td>
                                                <td style={{ textAlign: 'right' }}>{eo.endingBalance.value}</td>
                                             </tr>
                                          }
                                       </tbody>
                                    </Table>
                                 </Container>
                              </Col>
                           </Row>
                        </Container>
                     </Col>
                  </Row>
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='3' className='align-self-end' style={{ textAlign: 'left' }}>
                        <Button variant="secondary" onClick={this.handleBackClick}>Back</Button>
                     </Col>
                     <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                        <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleBuyClick}>Buy</Button>
                        <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default EscrowAddOnWindow;
