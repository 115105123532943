import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Alert from '../common/modal/Alert';
import Loading from '../common/modal/Loading';
import FormTemplate from "../common/form/FormTemplate";
import LoginTop from "../top/LoginTop";
import axios from "axios";
import configData from "../../config/config.json";
import Footer from '../bottom/Footer'
import { LOADING_STATUES } from "../constants/constants";

class ForgotPassword extends React.Component {
	state = {
		username: "",
		email: "",
		showAlert: false,
		alertMessage: "",
		loadingStatus: "",
		errors: {},
		redirectToLogin: false
	  }

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleChange = (event) => {
		event.preventDefault();
	
		let value = event.target.value;
		this.setState({
		   [event.target.id]: value,
		   errors: {}
		})
	}
	handleSubmit = (event) => {
		event.preventDefault();

		if (this.showLoading() === true) {
			let { loadingStatus, errors, showAlert, alertMessage, redirectToLogin, ...request } = this.state; // remove UI only fields before submitting
			axios.post(configData.LANDSHARK_SERVER_URL + "auth/forgotPassword", request, { withCredentials: true })
				.then((response) => {
					this.setState({loadingStatus: "", redirectToLogin: true}, this.showAlert(response.data.message)); 
				}, (error) => {
					this.setState({loadingStatus: "", redirectToLogin: false}, this.showAlert(error.response.data.message));
				});
		}
	}
	
	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/login');
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
		if (this.state.redirectToLogin) {
			this.props.history.push('/login');
		}
	}

	render() {
		let formGroupRows = [
			{
				formGroups: [{ id: 'username', label: 'Username', fieldType: 'text', value: this.state.username, style: {minWidth: 300},  required: true, primaryCriteria: true,  error: this.state.errors['username'] } ]
		  	},
			{
				formGroups: [ { id: 'email', label: 'Email', fieldType: 'email', value: this.state.email, style: {minWidth: 300},  required: true, primaryCriteria: true,  error: this.state.errors['email']  }]
		  	}
		]

		const formProps = {
			componentName: "ForgotPassword",
			pageHeading: "Forgot Password?  Enter your username and registered email.",
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			primaryButtonLabel: "Send Email",
			formGroupRows: formGroupRows,
			formOnly: true
		}

		return (
			<>
                <LoginTop />
				<section className="cn container-fluid" style={{background: '#3674AB' }}>
				<div style={{background: 'white'}}>
					<Container fluid="true" >
						<Row>
						<Col>
							<FormTemplate {...formProps} />
						</Col>
						</Row>
					</Container>
				</div>
				</section>
				<Footer />
				<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
			</>
        )
    }
}

export default ForgotPassword;