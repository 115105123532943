const countyConfigCommonReducer = (state = [{appTitle: "Land Record Portal", countyName: "", bookLabel: "Book", torrensLabel: "Torrens"}], action) => {
  switch (action.type) {
    case 'LANDSHARK_COUNTY_CONFIG_COMMON':
      return action.results;
    default:
      break;
  }
  return state;
}

export default countyConfigCommonReducer;