import axios from "axios";
import { saveAs } from 'file-saver';
import moment from 'moment';
import configData from "../../config/config.json";
import { handleError, handleCallBack } from "./commonStuff";

export const SearchByAddressKey = "searchByAddress";
export const SearchByBookAndPageKey = "searchByBookAndPage";
export const SearchByDateKey = "searchByDate";
export const SearchByDocumentNumberKey = "searchByDocumentNumber";
export const SearchByLegalDescriptionKey = "searchByLegalDescription";
export const SearchByNameKey = "searchByName";
export const SearchByParcelIdKey = "searchByParcelId";
export const SearchByTorrensCertificateKey = "searchByTorrensCertificate";
export const SearchByTorrensTextKey = "searchByTorrensText";
export const SpecialSearchGrantorGranteeBookKey = "searchByGrantorGranteeBook";
export const SpecialSearchTractBookIdKey = "searchByTractBookId";
export const SpecialSearchTractBookLegalKey = "searchByTractBookLegal";
export const SpecialSearchPlatKey = "searchByPlat";
export const SpecialSearchTaxLienKey = "searchByTaxLien";
export const SearchByTractIndexNMIKey = "searchByTractIndexNMI";

export const SearchByAddressUrl = "search/" + SearchByAddressKey;
export const SearchByBookAndPageUrl = "search/" + SearchByBookAndPageKey;
export const SearchByDateUrl = "search/" + SearchByDateKey;
export const SearchByDocumentNumberUrl = "search/" + SearchByDocumentNumberKey;
export const SearchByLegalDescriptionUrl = "search/" + SearchByLegalDescriptionKey;
export const SearchByNameUrl = "search/" + SearchByNameKey;
export const SearchByParcelIdUrl = "search/" + SearchByParcelIdKey;
export const SearchByTorrensCertificateUrl = "search/" + SearchByTorrensCertificateKey;
export const SearchByTorrensTextUrl = "search/" + SearchByTorrensTextKey;
export const SpecialSearchGrantorGranteeBookUrl = "search/" + SpecialSearchGrantorGranteeBookKey;
export const SpecialSearchTractBookIdUrl = "search/" + SpecialSearchTractBookIdKey;
export const SpecialSearchTractBookLegalUrl = "search/" + SpecialSearchTractBookLegalKey;
export const SpecialSearchPlatUrl = "search/" + SpecialSearchPlatKey;
export const SpecialSearchTaxLienUrl = "search/" + SpecialSearchTaxLienKey;
export const SearchByTractIndexNMIUrl = "search/" + SearchByTractIndexNMIKey;

const LoadByBatchUrls = [SearchByAddressUrl, SearchByBookAndPageUrl, SearchByDateUrl, SearchByDocumentNumberUrl, SearchByLegalDescriptionUrl, SearchByNameUrl, SearchByParcelIdUrl, SpecialSearchPlatUrl, SpecialSearchTaxLienUrl];

export const SEARCH_RESULTS_PAGE_SIZE = 100; // 100 records max per page
export const SEARCH_RESULTS_BATCH_SIZE = 25; // 25 records max per batch.
 
export const displayTractBookDetails = (id, description, callback) => async dispatch => {
  const searchDetails = {
    docId: id,
    description
  }

  dispatch({ type: 'VISITED', id });

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/tractBookDetails", searchDetails, { withCredentials: true })
    .then((response) => {
      handleTractBookDetails(dispatch, id, callback, response.data.content);
    }, (error) => {
      console.log(error);
    });
};

const handleTractBookDetails = (dispatch, id, callback, content) => {
  if (content.paymentNeededAgain === true || content.paymentNeeded === true) {
    dispatch({ type: 'DISPLAY_TRACT_BOOK_SEARCH_DETAILS', id : 0 }, handleCallBack(callback, true, content)); 
  } else {
    dispatch({ type: 'DISPLAY_TRACT_BOOK_SEARCH_DETAILS', id }, handleCallBack(callback, true, content)); 
  }
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

export const displayTorrensCertificateDetails = (id, description, callback) => async dispatch => {
  const searchDetails = {
    docId: id,
    description
  }

  dispatch({ type: 'VISITED', id });

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/torrensCertificateDetails", searchDetails, { withCredentials: true })
    .then((response) => {
      handleTorrensCertificateDetails(dispatch, id, callback, response.data.content);
    }, (error) => {
      console.log(error);
    });
};

const handleTorrensCertificateDetails = (dispatch, id, callback, content) => {
  if (content.paymentNeededAgain === true || content.paymentNeeded === true) {
    dispatch({ type: 'DISPLAY_TORRENS_CERTIFICATE_SEARCH_DETAILS', id : 0 }, handleCallBack(callback, true, content)); 
  } else {
    dispatch({ type: 'DISPLAY_TORRENS_CERTIFICATE_SEARCH_DETAILS', id }, handleCallBack(callback, true, content)); 
  }
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

export const displayGrantorGranteeBookDetails = (id, description, callback) => async dispatch => {
  const searchDetails = {
    docId: id,
    description
  }

  dispatch({ type: 'VISITED', id });

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/grantorGranteeBookDetails", searchDetails, { withCredentials: true })
    .then((response) => {
      handleGrantorGranteeBookDetails(dispatch, id, callback, response.data.content);
    }, (error) => {
      console.log(error);
    });
};

const handleGrantorGranteeBookDetails = (dispatch, id, callback, content) => {
  if (content.paymentNeededAgain === true || content.paymentNeeded === true) {
    dispatch({ type: 'DISPLAY_GRANTOR_GRANTEE_BOOK_SEARCH_DETAILS', id : 0 }, handleCallBack(callback, true, content)); 
  } else {
    dispatch({ type: 'DISPLAY_GRANTOR_GRANTEE_BOOK_SEARCH_DETAILS', id }, handleCallBack(callback, true, content)); 
  }
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

export const displayTaxLienDetails = (id, description, callback) => async dispatch => {
  const searchDetails = {
    docId: id,
    description
  }

  dispatch({ type: 'VISITED', id });

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/taxLienDetails", searchDetails, { withCredentials: true })
    .then((response) => {
      handleTaxLienSearchDetails(dispatch, id, callback, response.data.content);
    }, (error) => {
      console.log(error);
    });
};

const handleTaxLienSearchDetails = (dispatch, id, callback, content) => {
  if (content.paymentNeededAgain === true || content.paymentNeeded === true) {
    dispatch({ type: 'DISPLAY_TAX_LIEN_SEARCH_DETAILS', id : 0 }, handleCallBack(callback, true, content)); 
  } else {
    dispatch({ type: 'DISPLAY_TAX_LIEN_SEARCH_DETAILS', id }, handleCallBack(callback, true, content)); 
  }
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

export const displayDetails = (id, description, callback) => async dispatch => {
  const searchDetails = {
    docId: id,
    description
  }

  dispatch({ type: 'VISITED', id });

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/documentDetails", searchDetails, { withCredentials: true })
    .then((response) => {
      handleSearchDetails(dispatch, id, callback, response.data.content);
    }, (error) => {
      console.log(error);
    });
};

export const selectSearchSequenceRow = (id) => async dispatch => {
    dispatch({ type: 'SELECT_TABLE_ROW', id : id })
};

const handleSearchDetails = (dispatch, id, callback, content) => {
    if (content.paymentNeededAgain === true || content.paymentNeeded === true) {
      dispatch({ type: 'DISPLAY_DETAILS', id : 0 }, handleCallBack(callback, true, content)); 
    } else {
      dispatch({ type: 'DISPLAY_DETAILS', id }, handleCallBack(callback, true, content)); 
    }
    dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

export const setSelectedSearchType = (selectedSearchType) => {
    return {
        type: 'SET_SELECTED_SEARCH_TYPE',
        selectedSearchType
    }
}

const handleLockout = (dispatch, lockout) => {
  dispatch({ type: 'SET_LOCKOUT_IN_PLACE', lockout });    
};

const handleSearchResults = (dispatch, content, resetPage = true, pageNumber = 1, sortKey = 'docNumber', sortDirection = 'ASC') => {
  dispatch({ type: 'UPDATE_SEARCH_RESULTS', searchResults: content.searchResults });    // This is the search results table
  if (resetPage) {
    dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_PAGE', pageNumber });    // New search results, reset the search results table to page 1.
    dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_SORT', sort: { sortKey, sortDirection } });    // New search results, reset the search results table to page 1.
    updateSearchHistory(dispatch, content);
  }
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

const updateSearchHistory = (dispatch, content) => {
  dispatch({ type: 'UPDATE_SEARCH_HISTORY', historyResults: content.historyResults });    // This is the search history in the lower left.
  dispatch({ type: 'UPDATE_SEARCH_CRITERIA', newHistory: content.historyResults.searchHistoryList[0] });   // This is the search criteria that populates the Search forms.
  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};

const handleDocumentSearchResponse = (response, history, callback, pushHistory, dispatch, pageNumber, sortKey, sortDirection) => {
  handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/SearchResults', pageNumber, sortKey, sortDirection);
}

const handleTorrensSearchResponse = (response, history, callback, pushHistory, dispatch, pageNumber, sortKey, sortDirection) => {
  handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/TorrensSearchResults', pageNumber, sortKey, sortDirection);
}

const handleSearchResponse = (response, history, callback, pushHistory, dispatch, pushDestination, pageNumber, sortKey, sortDirection) => {
  handleLockout(dispatch, response.data.lockout);

  let content = response.data.content;
  let foundRecords = false;
  if (content.paymentNeeded !== true && content.paymentNeededAgain !== true) {
    if (content.searchResults.totalRecordCount > 0) {
      if (pushHistory) {
        history.push(pushDestination);
      }    
      handleSearchResults(dispatch, content, pushHistory === true, pageNumber, sortKey, sortDirection);     
      foundRecords = true;
    } else {
      updateSearchHistory(dispatch, content);
    }
  }
  handleCallBack(callback, foundRecords, content);
}

const handleTableBatchResponse = (response, dispatch) => {
  let content = response.data.content;
  if (content.searchResults.totalRecordCount > 0) {
   
    dispatch({ type: 'UPDATE_TABLE_BATCH_RESULTS', searchResults: content.searchResults });    // This is the search results table   
  }
}

export const printResults = (searchCriteria, callback, url) => async dispatch => {
  
  await axios.post(configData.LANDSHARK_SERVER_URL + url + 'Print', searchCriteria, { responseType: 'blob', withCredentials: true })
    .then((response) => {
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'SearchResultsReport.pdf');
      handleCallBack(callback, true);
    }, (error) => {
      
    });

   
};

export const loadDocuments = (searchCriteria, history, callback, url, pushHistory = true) => async dispatch => {
  let totalRecordCount = 0;
  dispatch({ type: 'SET_TABLE_LOADING', tableLoading: false });
  searchCriteria.timestamp = moment().valueOf();
  await axios.post(configData.LANDSHARK_SERVER_URL + url, searchCriteria, { withCredentials: true })
    .then((response) => {
      handleDocumentSearchResponse(response, history, callback, pushHistory, dispatch, searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
      totalRecordCount = response.data.content.searchResults.totalRecordCount;
    }, (error) => {
      handleError(error, history);
    });

    if (totalRecordCount > SEARCH_RESULTS_BATCH_SIZE) {
      dispatch(loadTableBatches(searchCriteria, url));
    }
};

export const loadTableBatches = (searchCriteria, url) => async (dispatch, getState) => {
  const numberOfBatches = SEARCH_RESULTS_PAGE_SIZE / SEARCH_RESULTS_BATCH_SIZE;

  if (LoadByBatchUrls.indexOf(url) >= 0) {
    for (let index = 2; index <= numberOfBatches; index++) { // Batch 1 has already been fetched, so start with batch 2.
      let recordsFetched = 0;
      dispatch({ type: 'SET_TABLE_LOADING', tableLoading: true });
      searchCriteria.batch = index;
      searchCriteria.updateHistory = false;
      await axios.post(configData.LANDSHARK_SERVER_URL + url, searchCriteria, { withCredentials: true })
        .then((response) => {
          handleTableBatchResponse(response, dispatch);
          recordsFetched = response.data.content.searchResults.totalRecordCount;
        }, (error) => {
        });
      dispatch({ type: 'SET_TABLE_LOADING', tableLoading: false });


      const currentSearchTimestamp = getState().searchResultsReducer.timestamp;

      // if fetches less than batch size, it means there's no more to fetch.
      if (recordsFetched < SEARCH_RESULTS_BATCH_SIZE || currentSearchTimestamp !== searchCriteria.timestamp) {
        break;
      }
    }
  }
}

// GRANTOR GRANTEE BOOK
export const searchByGrantorGranteeBook = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByGrantorGranteeBook", searchCriteria, { withCredentials: true })
    .then((response) => {
      handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/GrantorGranteeBookSearchResults', searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
    }, (error) => {
      handleError(error, history);
    });
};

// GRANTOR GRANTEE BOOK Print
export const searchByGrantorGranteeBookPrint = (searchCriteria, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByGrantorGranteeBookPrint", searchCriteria, { withCredentials: true })
    .then((response) => {
      
    }, (error) => {
      
    });
};

// TAX LIEN
export const searchByTaxLien = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {

  let totalRecordCount = 0;
  dispatch({ type: 'SET_TABLE_LOADING', tableLoading: false });
  searchCriteria.timestamp = moment().valueOf();
  await axios.post(configData.LANDSHARK_SERVER_URL + SpecialSearchTaxLienUrl, searchCriteria, { withCredentials: true })
    .then((response) => {
      handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/TaxLienSearchResults', searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
      totalRecordCount = response.data.content.searchResults.totalRecordCount;
    }, (error) => {
      handleError(error, history);
    });
    if (totalRecordCount > SEARCH_RESULTS_BATCH_SIZE) {
      dispatch(loadTableBatches(searchCriteria, SpecialSearchTaxLienUrl));
    }
};

// TAX LIEN Print
export const searchByTaxLienPrint = (searchCriteria, callback) => async dispatch => {

  searchCriteria.pageNumber = -1;

  await axios.post(configData.LANDSHARK_SERVER_URL + SpecialSearchTaxLienUrl, searchCriteria, { withCredentials: true })
    .then((response) => {
      
      
    }, (error) => {
      
    });
};

// TORRENS CERTIFICATE
export const searchByTorrensCertificate = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTorrensCertificate", searchCriteria, { withCredentials: true })
    .then((response) => {
      handleTorrensSearchResponse(response, history, callback, pushHistory, dispatch, searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
    }, (error) => {
      handleError(error, history);
    });
};

// TORRENS CERTIFICATE Print
export const searchByTorrensCertificatePrint = (searchCriteria, callback) => async dispatch => {
  searchCriteria.pageNumber = -1;

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTorrensCertificatePrint", searchCriteria, { withCredentials: true })
    .then((response) => {

    }, (error) => {
     
    });
};

// TORRENS TEXT
export const searchByTorrensText = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTorrensText", searchCriteria, { withCredentials: true })
    .then((response) => {
      handleTorrensSearchResponse(response, history, callback, pushHistory, dispatch, searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
    }, (error) => {
      handleError(error, history);
    });
};

// TORRENS TEXT Print
export const searchByTorrensTextPrint = (searchCriteria, callback) => async dispatch => {
  searchCriteria.pageNumber = -1;

  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTorrensTextPrint", searchCriteria, { withCredentials: true })
    .then((response) => {
 
    }, (error) => {
      
    });
};

// TRACT BOOK Legal
export const searchByTractBookLegal = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTractBookLegal", searchCriteria, { withCredentials: true })
    .then((response) => {
      handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/TractBookSearchResults', searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
    }, (error) => {
      handleError(error, history);
    });
};

// TRACT BOOK Id
export const searchByTractBookId = (searchCriteria, history, callback, pushHistory = true) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "search/searchByTractBookId", searchCriteria, { withCredentials: true })
    .then((response) => {
      handleSearchResponse(response, history, callback, pushHistory, dispatch, '/search/TractBookSearchResults', searchCriteria.pageNumber, searchCriteria.sortKey, searchCriteria.sortDirection);
    }, (error) => {
      handleError(error, history);
    });
};

// SORT
export const searchResultsSort = (sort, currentSearch, history, callback) => async dispatch => {

  let displayCriteria = currentSearch.displayCriteria;

  let searchCriteria = {};
  displayCriteria.forEach((criteria) => {
    searchCriteria[criteria.key] = criteria.value;      
  })

  searchCriteria.sortKey = sort.searchResultsSort;
  searchCriteria.sortDirection = sort.searchResultsSortDirection;
  searchCriteria.pageNumber = sort.pageNumber;

  dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_SORT', sort: {sortKey: searchCriteria.sortKey, sortDirection: searchCriteria.sortDirection }});
  dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_PAGE', pageNumber: sort.pageNumber });   

  switch (currentSearch.type) {
    case SearchByAddressKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByAddressUrl, false));
      break;

    case SearchByBookAndPageKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByBookAndPageUrl, false));
      break;

    case SearchByDateKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByDateUrl, false));
      break;

    case SearchByDocumentNumberKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByDocumentNumberUrl, false));
      break;

    case SpecialSearchGrantorGranteeBookKey:
      dispatch(searchByGrantorGranteeBook(searchCriteria, history, callback, false));
      break;

    case SpecialSearchTractBookIdKey:
      dispatch(searchByTractBookId(searchCriteria, history, callback, false));
      break;

    case SpecialSearchTractBookLegalKey:
      dispatch(searchByTractBookLegal(searchCriteria, history, callback, false));
      break;

    case SearchByLegalDescriptionKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByLegalDescriptionUrl, false));
      break;

    case SearchByNameKey:
        dispatch(loadDocuments(searchCriteria, history, callback, SearchByNameUrl, false));
        break;

    case SearchByParcelIdKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SearchByParcelIdUrl, false));
      break;

    case SpecialSearchPlatKey:
      dispatch(loadDocuments(searchCriteria, history, callback, SpecialSearchPlatUrl, false));
      break;

    case SpecialSearchTaxLienKey:
      dispatch(searchByTaxLien(searchCriteria, history, callback, false));
      break;

    case SearchByTorrensCertificateKey:
      dispatch(searchByTorrensCertificate(searchCriteria, history, callback, false));
      break;
    
    case SearchByTorrensTextKey:
      dispatch(searchByTorrensText(searchCriteria, history, callback, false));
      break;

    default:
      break;
  }
}

export const searchResultsPrint = (sort, currentSearch, history, callback) => async dispatch => {

  let displayCriteria = currentSearch.displayCriteria;

  let searchCriteria = {};
  displayCriteria.forEach((criteria) => {
    searchCriteria[criteria.key] = criteria.value;      
  })

  searchCriteria.sortKey = sort.searchResultsSort;
  searchCriteria.sortDirection = sort.searchResultsSortDirection;
  searchCriteria.pageNumber = sort.pageNumber;

  switch (currentSearch.type) {
    case SearchByAddressKey:
      dispatch(printResults(searchCriteria, callback, SearchByAddressUrl));
      break;

    case SearchByBookAndPageKey:
      dispatch(printResults(searchCriteria, callback, SearchByBookAndPageUrl));
      break;

    case SearchByDateKey:
      dispatch(printResults(searchCriteria, callback, SearchByDateUrl));
      break;

    case SearchByDocumentNumberKey:
      dispatch(printResults(searchCriteria, callback, SearchByDocumentNumberUrl));
      break;

    case SpecialSearchGrantorGranteeBookKey:
      dispatch(searchByGrantorGranteeBookPrint(searchCriteria, callback));
      break;

    case SearchByLegalDescriptionKey:
      dispatch(printResults(searchCriteria, callback, SearchByLegalDescriptionUrl));
      break;

    case SearchByNameKey:
        dispatch(printResults(searchCriteria, callback, SearchByNameUrl));
        break;

    case SearchByParcelIdKey:
      dispatch(printResults(searchCriteria, callback, SearchByParcelIdUrl));
      break;

    case SpecialSearchPlatKey:
      dispatch(printResults(searchCriteria, callback, SpecialSearchPlatUrl));
      break;

    case SpecialSearchTaxLienKey:
      dispatch(searchByTaxLienPrint(searchCriteria, callback));
      break;

    case SearchByTorrensCertificateKey:
      dispatch(searchByTorrensCertificatePrint(searchCriteria, callback));
      break;
    
    case SearchByTorrensTextKey:
      dispatch(searchByTorrensTextPrint(searchCriteria, callback));
      break;

    default:
      break;
  }
}