import moment from 'moment';

export const momentDateFormat = "MM/DD/YYYY";

export const momentDateTimeFormat = "MM/DD/YYYY h:m a";

export const hourOptions = [{ code :'1', description: '1'}, {code: '2', description: '2'}, {code: '3', description: '3'}, {code: '4', description: '4'}, {code: '5', description: '5'}, {code: '6', description: '6'}, {code: '7', description: '7'}, {code: '8', description: '8'}, {code: '9', description: '9'}, {code: '10', description: '10'}, {code: '11', description: '11'}, {code: '12', description: '12'} ];
export const minuteOptions =  [{ code :'0', description: '00'}, {code: '15', description: '15'}, {code: '30', description: '30'}, {code: '45', description: '45'}, {code: '59', description: '59'} ]
export const amPmOptions = [{ code :'am', description: 'AM'}, {code: 'pm', description: 'PM'}];


export const validateDates = (errors, fromDate, fromDateKey, toDate, toDateKey) => {
    let fromMomentDate = moment(fromDate, momentDateFormat, true);
    if (!fromMomentDate.isValid()) {
        errors[fromDateKey] = momentDateFormat;
        return false;
    }

    let toMomentDate = moment(toDate, momentDateFormat, true);
    if (!toMomentDate.isValid()) {
        errors[toDateKey] = momentDateFormat;
        return false;
    }

    let currentDate = moment().startOf('day'); // current start of day
    if (fromMomentDate.diff(currentDate, 'days') > 0) {
        errors[fromDateKey] = "Future date entered.";
        return false;
    }

    if (toMomentDate.diff(currentDate, 'days') > 0) {
        errors[toDateKey] = "Future date entered.";
        return false;
    }

    if (toMomentDate < fromMomentDate) {
        errors[fromDateKey] = "Invalid date range.";
        errors[toDateKey] = "Invalid date range.";
        return false;
    }

    return true;
};


export const validateDateTimes = (errors, fromDate, fromHour, fromMinute, fromAmPm, fromDateKey, toDate, toHour, toMinute, toAmPm, toDateKey) => {
    // check the date values
    let dateCheck = validateDates(errors, fromDate, fromDateKey, toDate, toDateKey);
    if (!dateCheck) {
        return false;
    }

    // check the date+time values
    let fromDateTime = fromDate + ' ' + fromHour + ':' + fromMinute + ' ' + fromAmPm;
    let toDateTime = toDate + ' ' + toHour + ':' + toMinute + ' ' + toAmPm;

    let fromMomentDateTime = moment(fromDateTime, momentDateTimeFormat, true);
    let toMomentDateTime = moment(toDateTime, momentDateTimeFormat, true);

    if (toMomentDateTime < fromMomentDateTime) {
        errors[fromDateKey] = "Invalid date/time range.";
        errors[toDateKey] = "Invalid date/time range.";
        return false;
    }

    return true;
};

export const validateMonthRangeLimit = (errors, fromDate, fromDateKey, toDate, toDateKey) => {

    let fromMomentDate = moment(fromDate, momentDateFormat, true);
    let toMomentDate = moment(toDate, momentDateFormat, true);

    if (toMomentDate.diff(fromMomentDate, 'days') > 31) {
        errors[fromDateKey] = "Date range cannot exceed a month.";
        errors[toDateKey] = "Date range cannot exceed a month.";
        return false;
    }
    return true;
};

export const validateYearRangeLimit = (errors, fromDate, fromDateKey, toDate, toDateKey) => {

    let fromMomentDate = moment(fromDate, momentDateFormat, true);
    let toMomentDate = moment(toDate, momentDateFormat, true);

    if (toMomentDate.diff(fromMomentDate, 'days') > 366) {
        errors[fromDateKey] = "Date range cannot exceed a year.";
        errors[toDateKey] = "Date range cannot exceed a year.";
        return false;
    }
    return true;
};