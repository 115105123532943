import React from 'react';

import { Modal, Button } from 'react-bootstrap'
import { questionIcon } from '../../../img/font_awesome/font_awesome'


export default class Confirmation extends React.Component {
   handleYesClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.cancelConfirmation();
      this.props.proceedConfirmation();
   }

   handleNoClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.cancelConfirmation();
   }

   render() {
      let title = ""

      if (this.props.title) {
         title = this.props.title;
      }

      return (
         <Modal show={this.props.show} onHide={this.handleNoClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton >
               <Modal.Title style={{color: '#3674AB'}}>{questionIcon} {title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
               {this.props.question}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={this.handleYesClick}>Yes</Button>
               <Button variant="secondary" onClick={this.handleNoClick}>No</Button>
            </Modal.Footer>
         </Modal>
      )
   }
}