import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { searchByTaxLien, setSelectedSearchType, SpecialSearchTaxLienKey } from "../../../redux/actions/searchActions";
import { validateDates, validateMonthRangeLimit } from "../../../utils/DateValidation";
import { LOADING_STATUES } from "../../constants/constants";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SpecialSearchTaxLienKey;

const DOCUMENT_NUMBER_SEARCH = 'DOCUMENTNUMBER';
const LIEN_NUMBER_SEARCH = 'LIENNUMBER';
const TAXPAYER_SEARCH = 'TAXPAYER';
const DATE_RANGE_SEARCH = 'DATERANGE'

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByTaxLien: (searchCriteria, history, callback) => dispatch(searchByTaxLien(searchCriteria, history, callback)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  let fromRecordedDate = previousSearchKeyValue.fromRecordedDate;
  if (!fromRecordedDate && state.countyConfigNotableDatesReducer.showNotableDates === true) {
    fromRecordedDate = state.landlinkConfigReducer.abstractIndexBeginDate;
  } 
  
  if (!fromRecordedDate) {
    fromRecordedDate = moment('01/01/1900').format('L');
  }

  let toRecordedDate = previousSearchKeyValue.toRecordedDate;
  if (!toRecordedDate && state.countyConfigNotableDatesReducer.useVerificationDate === true) {
    toRecordedDate = state.landlinkConfigReducer.abstractVerifiedDate;
  }
  
  if (!toRecordedDate) {
    toRecordedDate = moment().format('L');
  }

  return {
    notableDates: state.notableDatesReducer,
    showNotableDates: state.countyConfigNotableDatesReducer.showNotableDates,
    useVerificationDate: state.countyConfigNotableDatesReducer.useVerificationDate,
    abstractIndexBeginDate: state.landlinkConfigReducer.abstractIndexBeginDate,
    abstractVerifiedDate: state.landlinkConfigReducer.abstractVerifiedDate,
    isPropertyCode: state.landlinkConfigReducer.propertyCode,
    allOnlySearch: state.countyConfigSearch2Reducer.allOnlySearch,
    documentTypes: state.documentTypesReducer.codeDescriptionResultList,
    documentType: previousSearchKeyValue.documentType ? previousSearchKeyValue.documentType : 'ALL',
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    currentOrExpired: previousSearchKeyValue.currentOrExpired ? previousSearchKeyValue.currentOrExpired : 'CURRENT',
    searchByTaxLienMethod: previousSearchKeyValue.searchByTaxLienMethod ? previousSearchKeyValue.searchByTaxLienMethod : DATE_RANGE_SEARCH,
    documentNumber: previousSearchKeyValue.documentNumber ? previousSearchKeyValue.documentNumber : null,
    lienNumber: previousSearchKeyValue.lienNumber ? previousSearchKeyValue.lienNumber : null,
    taxpayerName: previousSearchKeyValue.taxpayerName ? previousSearchKeyValue.taxpayerName : null,
    taxpayerFirstName: previousSearchKeyValue.taxpayerFirstName ? previousSearchKeyValue.taxpayerFirstName : null,
    taxpayerMiddleInitial: previousSearchKeyValue.taxpayerMiddleInitial ? previousSearchKeyValue.taxpayerMiddleInitial : null,
    fromRecordedDate,
    toRecordedDate
  };
}

class SpecialSearchTaxLien extends Component {

  state = {
    searchByTaxLienMethod: this.props.searchByTaxLienMethod,
    currentOrExpired: this.props.currentOrExpired,
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    documentType: this.props.documentType,
    documentNumber: this.props.documentNumber,
    lienNumber: this.props.lienNumber,
    taxpayerName: this.props.taxpayerName,
    taxpayerFirstName: this.props.taxpayerFirstName,
    taxpayerMiddleInitial: this.props.taxpayerMiddleInitial,
    loadingStatus: "",
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    if (event.target.type === 'radio') {
      this.setState({
        searchByTaxLienMethod: event.target.id
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
        submitMessage: '',
        errors: {}
      });
    }
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  // Same code used in all searches - END

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      // The tax lien search has multiple search "pages". 
      // Reset the search criteria that are not on the current search "page", as
      // the other "pages" may contain dirty data and shouldn't be in the search.
      if (this.state.searchByTaxLienMethod === DATE_RANGE_SEARCH) {
        this.setState({
          documentType: '',
          documentNumber: '',
          lienNumber: '',
          taxpayerName: '',
          taxpayerFirstName: '',
          taxpayerMiddleInitial: ''
        })
      } else if (this.state.searchByTaxLienMethod === DOCUMENT_NUMBER_SEARCH) {
        this.setState({
          fromRecordedDate: moment('01/01/1900').format('L'),
          toRecordedDate: moment().format('L'),
          lienNumber: '',
          taxpayerName: '',
          taxpayerFirstName: '',
          taxpayerMiddleInitial: ''
        })
      } else if (this.state.searchByTaxLienMethod === LIEN_NUMBER_SEARCH) {
        this.setState({
          documentType: '',
          fromRecordedDate: moment('01/01/1900').format('L'),
          toRecordedDate: moment().format('L'),
          documentNumber: '',
          taxpayerName: '',
          taxpayerFirstName: '',
          taxpayerMiddleInitial: ''
        })
      } else if (this.state.searchByTaxLienMethod === TAXPAYER_SEARCH) {
        this.setState({
          documentType: '',
          fromRecordedDate: moment('01/01/1900').format('L'),
          toRecordedDate: moment().format('L'),
          documentNumber: '',
          lienNumber: ''
        })
      }

      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByTaxLien(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (this.state.searchByTaxLienMethod === DATE_RANGE_SEARCH) {
      let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
      if (!formIsValid) {
        this.setState({ errors: errors });
        return false;
      }

      formIsValid = validateMonthRangeLimit(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
      if (!formIsValid) {
        this.setState({ errors: errors });
        return false;
      }
    }

    if (this.state.searchByTaxLienMethod === DOCUMENT_NUMBER_SEARCH) {
      if (!this.state.documentNumber) {
        errors["documentNumber"] = "Required.";
        this.setState({ errors: errors });
        return false;
      }
    }

    if (this.state.searchByTaxLienMethod === LIEN_NUMBER_SEARCH) {
      if (!this.state.lienNumber) {
        errors["lienNumber"] = "Required.";
        this.setState({ errors: errors });
        return false;
      }
    }
 
    if (this.state.searchByTaxLienMethod === TAXPAYER_SEARCH) {
      if (!this.state.taxpayerName) {
        errors["taxpayerName"] = "Required.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.taxpayerName && this.state.taxpayerMiddleInitial) {
        errors["taxpayerName"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }

      if (!this.state.taxpayerFirstName && this.state.taxpayerMiddleInitial) {
        errors["taxpayerFirstName"] = "Required if MI entered.";
        this.setState({ errors: errors });
        return false;
      }
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();
    if (this.state.searchByTaxLienMethod === DATE_RANGE_SEARCH) {
      let fromRecordedDate = moment('01/01/1900').format('L');
      if (this.props.showNotableDates === true) {
        fromRecordedDate = this.props.abstractIndexBeginDate;
      } 
    
      let toRecordedDate = moment().format('L');
      if (this.props.useVerificationDate === true) {
        toRecordedDate = this.props.abstractVerifiedDate;
      }

      this.setState({
        currentOrExpired: 'CURRENT',
        fromRecordedDate,
        toRecordedDate,
        submitMessage: '',
        errors: {}
      })
    } else if (this.state.searchByTaxLienMethod === DOCUMENT_NUMBER_SEARCH) {
      this.setState({
        currentOrExpired: 'CURRENT',
        documentType: 'ALL',
        documentNumber: '',
        submitMessage: '',
        errors: {}
      })
    } else if (this.state.searchByTaxLienMethod === LIEN_NUMBER_SEARCH) {
      this.setState({
        currentOrExpired: 'CURRENT',
        lienNumber: '',
        submitMessage: '',
        errors: {}
      })
    } else if (this.state.searchByTaxLienMethod === TAXPAYER_SEARCH) {
      this.setState({
        currentOrExpired: 'CURRENT',
        taxpayerName: '',
        taxpayerFirstName: '',
        taxpayerMiddleInitial: '',
        submitMessage: '',
        errors: {}
      })
    }
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {

    let formGroupRows = [
      {
        formGroups: [{ id: 'searchByTaxLienMethod', label: 'Search By', style: { height: 110 }, fieldType: 'radio', primaryCriteria: true, options: [{ code: DATE_RANGE_SEARCH, description: 'Date Range' }, { code: DOCUMENT_NUMBER_SEARCH, description: 'Document Number' }, { code: LIEN_NUMBER_SEARCH, description: 'Lien Number' }, { code: TAXPAYER_SEARCH, description: 'Taxpayer Name' }], value: this.state.searchByTaxLienMethod }]
      },
      {
        formGroups: [{ id: 'fromRecordedDate', label: 'Date', fieldType: 'date', hideRow: this.state.searchByTaxLienMethod !== DATE_RANGE_SEARCH, primaryCriteria: true, value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
      },
      {
        formGroups: [{ id: 'toRecordedDate', label: 'To', fieldType: 'date', hideRow: this.state.searchByTaxLienMethod !== DATE_RANGE_SEARCH, primaryCriteria: true, value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
      }
    ]

    if (this.props.isPropertyCode === true && this.props.allOnlySearch !== true && this.props.allOnlySearch !== 'true') {
      formGroupRows.push({
        formGroups: [{ id: 'documentType', label: 'Document Type', fieldType: 'select', hideRow: this.state.searchByTaxLienMethod !== DOCUMENT_NUMBER_SEARCH, value: this.state.documentType, options: this.props.documentTypes }]
      });
    }

    formGroupRows.push({
      formGroups: [{ id: 'documentNumber', label: 'Document Number', fieldType: 'text', primaryCriteria: true, hideRow: this.state.searchByTaxLienMethod !== DOCUMENT_NUMBER_SEARCH, required: true, value: this.state.documentNumber, error: this.state.errors['documentNumber'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'lienNumber', label: 'Lien Number', fieldType: 'text', primaryCriteria: true, hideRow: this.state.searchByTaxLienMethod !== 'LIENNUMBER', required: true, value: this.state.lienNumber, error: this.state.errors['lienNumber'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'taxpayerName', label: 'Name or Business Name (one word min.)', fieldType: 'text', primaryCriteria: true, hideRow: this.state.searchByTaxLienMethod !== TAXPAYER_SEARCH, required: true, value: this.state.taxpayerName, error: this.state.errors['taxpayerName']  }, { id: 'taxpayerFirstName', label: 'First Name', fieldType: 'text', placeholder: '(Optional)', hideRow: this.state.searchBy !== TAXPAYER_SEARCH, value: this.state.taxpayerFirstName, error: this.state.errors['taxpayerFirstName']  }, { id: 'taxpayerMiddleInitial', label: 'MI', fieldType: 'text', placeholder: '(Optional)', hideRow: this.state.searchBy !== TAXPAYER_SEARCH, value: this.state.taxpayerMiddleInitial, error: this.state.errors['taxpayerMiddleInitial'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'currentOrExpired', label: 'Current/Expired', fieldType: 'select', options: [{ code: 'ALL', description: 'All' }, { code: 'CURRENT', description: 'Current' },  { code: 'EXPIRED', description: 'Expired' }], value: this.state.currentOrExpired }]
    });

    const formProps = {
      componentName: componentName,
      pageHeading: "Tax Lien Search",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return <FormTemplate {...formProps} />
  }

}

export default connect(mapStateToProps, matchDispatchToProps)(SpecialSearchTaxLien);