


const serverProductVersionsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SERVER_PRODUCT_VERSIONS':
      return action.result;
    default:
      break;
  }
  return state;
}

export default serverProductVersionsReducer;