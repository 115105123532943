import React, { Component } from "react";
import { Route, HashRouter, Switch } from "react-router-dom";

import Home from "./components/home/Home";
import Search from "./components/search/Search";
import SearchByParcelIdExternal from "./components/search/searchBy/SearchByParcelIdExternal";
import SearchByDocumentNumberExternal from "./components/search/searchBy/SearchByDocumentNumberExternal";
import Efile from "./components/efile/Efile";
import Reports from "./components/reports/Reports";
import UserList from "./components/userManager/UserList";
import UserEntry from "./components/userManager/UserEntry";
import UserPassword from "./components/userManager/UserPassword";
import ControlPanel from "./components/controlPanel/ControlPanel";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotUsername from "./components/auth/ForgotUsername";
import ForgotPassword from "./components/auth/ForgotPassword";
import PasswordReset from "./components/auth/PasswordReset";
import CompleteRegistration from "./components/auth/CompleteRegistration";
import configData from "./config/config.json";
import {PrivateRoute} from "./components/auth/PrivateRoute.js";

const PREFIX = 'App: ';

export default class App extends Component {
  constructor(props) {
    super(props);

    console.log(PREFIX + "constructed with props", props);
  }

  render() {
      return (
        <HashRouter>
          <div className="App">
          <div style={{maxWidth:2000, margin:'0 auto'}} className="content">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/home" component={Home} />
            <Route path="/register" component={Register} />
            <Route path="/passwordReset/:ticket" component={PasswordReset} />
            <Route path="/CompleteRegistration/:ticket" component={CompleteRegistration} />
            <Route path="/forgotUsername" component={ForgotUsername} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/efile" component={Efile} />
            <PrivateRoute path="/search" component={Search} />
            <PrivateRoute path="/externalsearch/parcelid/:id" component={SearchByParcelIdExternal} />
            <PrivateRoute path="/externalsearch/documentnumber/:id" component={SearchByDocumentNumberExternal} />
            <PrivateRoute path="/reports" component={Reports} />
            <PrivateRoute path="/userManager/UserPassword/:id" component={UserPassword} /> 
            <PrivateRoute path="/userManager/:id" component={UserEntry} />
            <PrivateRoute path="/userManager" component={UserList} />
            <PrivateRoute path="/controlPanel" component={ControlPanel} />
            <Route path='/external' component={(props) => {
                let url =configData.LANDSHARK_SERVER_URL + 
                  props.location.pathname.substring(1);
                console.log('redirecting to: ' + url);
                window.location.href = url;
                return null;
              }} />
          </Switch>
          </div>
        </div>
      </HashRouter >

    );
  }
}

