import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import axios from "axios";
import { setSelectedControlPanelType, HomeLatestNewsControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import PageBanner from "../common/page/PageBanner";
import PrimaryButton from "../common/form/PrimaryButton";
import { checkIconGreen, spinnerIconGray } from "../../img/font_awesome/font_awesome";
import { LOADING_STATUES } from "../constants/constants";

const componentName = HomeLatestNewsControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

const mapStateToProps = state => {

  return {
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class HomeLatestNews extends Component {

  state = {
    paragraphs: [
      {
        heading: '',
        paragraphContent: ''
      }
    ],
    status: "",
    message: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/latestNews", this.state, { withCredentials: true })
      .then(
        (response) => {
          this.setState({
            status: LOADING_STATUES.SUCCESS
          });
        },
        (error) => {
          handleError(error, this.props.history);
        }
      );
  }

  // Add five blank entries
  handleMoreRows = (event) => {
    event.preventDefault();

    const currentLength = this.state.paragraphs.length;
    if (currentLength < 50) { // 50 is the abitrary max length
      let blankArray = [
        {
          heading: '',
          paragraphContent: ''
        },
        {
          heading: '',
          paragraphContent: ''
        },
        {
          heading: '',
          paragraphContent: ''
        },
        {
          heading: '',
          paragraphContent: ''
        },
        {
          heading: '',
          paragraphContent: ''
        }
      ]; // Five blanks
      // If adding the blankArray exceeds 100 in length, shorten the blankArray so that it won't exceed 100.
      if ((currentLength + blankArray.length) > 50) {
        blankArray.length = 50 - currentLength;
      }

      this.setState({
        paragraphs: [...this.state.paragraphs, ...blankArray]
      })
    } else {
      alert("There's a limit of 50 paragraphs.");
    }
  }

  handleChange = (event) => {
    const targetId = event.target.id;
    if (targetId.includes("heading")) {
      const index = targetId.replace("heading", "");
      let paragraphs = [...this.state.paragraphs];
      let rate = { ...paragraphs[index] };
      rate.heading = event.target.value;
      paragraphs[index] = rate;
      this.setState({
        status: "",
        paragraphs
      });
    } else if (targetId.includes("paragraphContent")) {
      const index = targetId.replace("paragraphContent", "");
      let paragraphs = [...this.state.paragraphs];
      let rate = { ...paragraphs[index] };
      rate.paragraphContent = event.target.value;
      paragraphs[index] = rate;
      this.setState({
        status: "",
        paragraphs
      });
    } else {
      this.setState({
        status: "",
        [event.target.id]: event.target.value ? event.target.value : 0
      })
    }
  }

  handleRemoveRow = (event) => {
    let indexToRemove = event.target.id.replace("remove", "");
    indexToRemove = parseInt(indexToRemove);
    let paragraphs = this.state.paragraphs;
    paragraphs.splice(indexToRemove, 1);
    this.setState({
      paragraphs: paragraphs
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/latestNews", { withCredentials: true })
      .then(
        (response) => {
          const content = response.data.content;
          let paragraphs = content.paragraphs;
          if (!paragraphs || paragraphs.length === 0) {
            paragraphs = [ {
              heading: '',
              paragraphContent: ''
            }]
          }

          this.setState({
            status: "",
            paragraphs
          });
        },
        (error) => {
          handleError(error, this.props.history);
        }
      );
  }

  render() {
    let formTemplateMarginLeft = 60
    let formTemplateMarginRight = 20
    if (this.props.formOnly) {
      formTemplateMarginLeft = 0
      formTemplateMarginRight = 0
    } else if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    let pageButtons = [{ label: 'Add', onClickAction: this.handleMoreRows, className: 'btn btn-secondary btn-sm' }]

    let paragraphTable =
      <>
        {
          this.state.paragraphs.map((rate, index) => (
            (index > 0) &&
            <Row>
              <Col xs={3} sm={3} md={3} xl={3} >
                <Form.Control type='text'  id={'heading' + index} disabled={this.state.status === 'LOADING'} onChange={this.handleChange} value={this.state.paragraphs[index].heading} style={{ maxWidth: 400}} />
              </Col>
              <Col xs={6} sm={6} md={7} xl={8} style={{ paddingLeft: 0}}>
                <Form.Control type='text'  id={'paragraphContent' + index} disabled={this.state.status === 'LOADING'} onChange={this.handleChange} value={this.state.paragraphs[index].paragraphContent}  />
              </Col>
             
              <Col xs={3} sm={3} md={2} xl={1} style={{ paddingLeft: 0}}>
             
              <Button id={'remove' + index} variant="danger" size="sm" style={{ margin: 1 }} disabled={this.state.status === 'LOADING'} onClick={this.handleRemoveRow}>Remove</Button>
              </Col>
            </Row>
          ))
        }
      </>

    return (
      <div style={{ marginLeft: formTemplateMarginLeft, marginRight: formTemplateMarginRight, marginTop:4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <PageBanner pageHeading="More News" buttons={pageButtons} />
        <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 50, marginTop: 10, marginLeft:4, marginRight:4, border: '3px solid darkGray', borderRadius: '5px' }}>
          <Row>&nbsp;</Row>
          <Form onSubmit={this.handleSubmit}>
            <Row >
              <Col xs={3} sm={3}  md={3} xl={3} >
                <div>Heading:</div>
                <Form.Control type='text'  id='heading0' disabled={this.state.status === 'LOADING'} onChange={this.handleChange} value={this.state.paragraphs[0].heading} style={{ maxWidth: 400 }} />
              </Col>
              <Col xs={6} sm={6} md={7} xl={8} style={{ paddingLeft: 0}} >
                <div>Paragraph:</div>
                <Form.Control type='text'  id='paragraphContent0' disabled={this.state.status === 'LOADING'} onChange={this.handleChange} value={this.state.paragraphs[0].paragraphContent} />
              </Col>
            </Row>
            {paragraphTable}
            <Row>&nbsp;</Row>
            <PrimaryButton buttonLabel="Submit" disabled={this.state.status === 'LOADING'} />&nbsp;&nbsp;
            {
              this.state.status === 'SUCCESS' &&
              <span style={{ width: 18, verticalAlign: "bottom", paddingBottom: 1, display: "inline-block" }}>{checkIconGreen}</span>
            }
            {
              this.state.status === 'LOADING' &&
              <span style={{ width: 18, verticalAlign: "bottom", marginBottom: 1, display: "inline-block" }}>{spinnerIconGray}</span>
            }
          </Form>
        </Container>

      </div>
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(HomeLatestNews);