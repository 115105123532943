import winston from 'winston';
import configData from "./config/config.json";

const lsUrl = new URL(configData.LANDSHARK_SERVER_URL);
export const backendLogger = winston.createLogger({
  transports: [
    new winston.transports.Http({ host: lsUrl.hostname,
                                  port: lsUrl.port,
                                  path: lsUrl.pathname + 'logReact',
                                  ssl: lsUrl.protocol == "https:" ? true : false})
  ]
});

