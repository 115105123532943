import React, {Component} from 'react';
import { Form } from 'react-bootstrap'

class SelectFilter extends Component {

   handleChange = (event) => {
      event.preventDefault();      
      this.props.selectFilterProps.selectFilter(event.target.value);
   }
   
   render() {
      return (
         <Form.Control as='select' onChange={this.handleChange} >
         {
            this.props.selectFilterProps.options.map((option) => {
               if (this.props.selectFilterProps.selected === option.code)
                  return (<option key={option.code} selected value={option.code}>{option.description}</option>)
               else
                  return (<option key={option.code} value={option.code}>{option.description}</option>)
            })
         }
         </Form.Control>
      )
   }
}

export default SelectFilter;