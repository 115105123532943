
const platNumbersReducer = (state = [{code: "-1", description: "(Select from list)"}], action) => {
  switch (action.type) {
    case 'LANDLINK_PLAT_NUMBERS':
      // return [{code: "-1", description: "(Select from list)"}].concat(action.results);

      return action.results;
    default:
      break;
  }
  return state;
}

export default platNumbersReducer;