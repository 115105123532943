const initialState = {
  lockoutInPlace: false,
	messageLogin: "",
	messageHeadline: ""
};

const countyConfigUserLockoutReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_LOCKOUT_IN_PLACE':
                  return action.lockout;
            default:
                  break;
      }
      return state;
};
export default countyConfigUserLockoutReducer;