import React  from 'react';
import {Link} from 'react-router-dom'
import SideNavigationNumberedLabel from "./SideNavigationNumberedLabel";

export default class SideNavigationNumberedNavLink extends React.Component {
    render(){
       let currentSearchIndicator = '';
       if (this.props.index === 1) {
       		currentSearchIndicator = 'Current';
       }

       let minWidth = 0
       if (this.props.sideNavigationOpen) {
           minWidth = 250
       }

       let className = "side-numbered-navlink";
       if (this.props.index === 5) {
           className += " side-numbered-navlink-last"
       }

       let historyLink = '/search/searchHistory/' + this.props.type + '/' + this.props.index;
       return(
          <Link to={historyLink} style={{ minWidth:minWidth}} className={className}><SideNavigationNumberedLabel {...{ number: this.props.index, name: this.props.description, title:this.props.criteriaDescription, badge:currentSearchIndicator}} /></Link>
       )
     }
}