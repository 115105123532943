import React from 'react';
import { Form } from 'react-bootstrap'
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
    return {
        allPlatList: state.platsReducer.codeDescriptionResultList,
        allPlatNameList: state.platNamesReducer.codeDescriptionResultList,
        allPlatNumberList: state.platNumbersReducer.codeDescriptionResultList
    };
}

class PlatField extends React.Component {
    render() {
        let fontWeight = 'normal';
        if (this.props.formGroup.primaryCriteria) {
            fontWeight = 'bold';
        }

        let placeholder = this.props.formGroup.placeholder;
        if (!placeholder) {
            placeholder = '(Optional)'
        }

        let style = this.props.formGroup.style;
        let textField = <Form.Control id={this.props.formGroup.id} style={style} type={this.props.formGroup.fieldType} placeholder={placeholder} disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.props.formGroup.onBlur} value={this.props.formGroup.value} />
        if (this.props.formGroup.required === true) {
            textField = <Form.Control id={this.props.formGroup.id} style={style} required type={this.props.formGroup.fieldType} disabled={this.props.disabled} onChange={this.props.onChange} onBlur={this.props.formGroup.onBlur} value={this.props.formGroup.value} />
        } else if (this.props.formGroup.disableField == true) {
            textField = <Form.Control id={this.props.formGroup.id} style={style} disabled type={this.props.formGroup.fieldType} onChange={this.props.onChange} value={this.props.formGroup.value} />
        }

        let size = this.props.formGroup.size ? this.props.formGroup.size : "md";
        let options = this.props.formGroup.options;

        let selectField =
            <Form.Control size={size} id={this.props.formGroup.id + 'Select'} style={this.props.formGroup.style} as='select' onChange={this.props.onChange}  >
                {
                    // 'disabled' is assumed false
                    options.map((option) => {
                        return (<option key={option.code} disabled={!!option.disabled} value={option.code}>{option.description}</option>)
                    })
                }
            </Form.Control>

        if (this.props.formGroup.disableField == true || this.props.disabled === true) {
            selectField =
                <Form.Control size={size} id={this.props.formGroup.id + 'Select'} style={this.props.formGroup.style} as='select' disabled onChange={this.props.onChange} >
                    {
                        // 'disabled' is assumed false
                        options.map((option, description) => {
                                return (<option value={option[0]}>{option[1]}</option>)
                        })
                    }
                </Form.Control>
        }

        let label = this.props.formGroup.label ? this.props.formGroup.label + ':' : <>&nbsp;</>;
        return (
            <div style={{ marginLeft: 4, fontWeight }}>

                <div>{label}</div>
                {textField}
                {
                    this.props.formGroup.hideSelect !== true &&
                    selectField
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(PlatField);