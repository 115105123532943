import React, {Component} from 'react';
import { Card } from 'react-bootstrap'
import { commentIcon } from '../../img/font_awesome/font_awesome';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class Disclaimer extends Component {
    render() {
        let disclaimerLink = <></>;
        if (this.props.links && this.props.links.length > 0) {
            const fitleredDisclaimerList = this.props.links.filter( link => link.heading === 'Disclaimer' );
            if (fitleredDisclaimerList && fitleredDisclaimerList.length === 1) {
                disclaimerLink = <ul style={{ listStyle: 'none' }}><li><a target="_blank" rel="noopener noreferrer" href={fitleredDisclaimerList[0].paragraphContent} style={{ color: '#408CC7', fontWeight: 'bold' }}>{translations[this.props.language].click}&nbsp;{translations[this.props.language].here}</a></li></ul> 
            } 
        }

        return (
            <Card>
                <Card.Header>
                    {commentIcon}&nbsp;&nbsp;{translations[this.props.language].disclaimer}
                </Card.Header>
                <Card.Body>
                    {disclaimerLink}
                </Card.Body>
            </Card >
        )
    }
}

export default connect(mapStateToProps)(Disclaimer);
