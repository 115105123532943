const initialState = [];

const searchResultsPageVisitedReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_SEARCH_RESULTS_PAGE':
                  state.push(action.pageNumber);
                  state.sort();
                  return [...new Set(state)];
            case 'UPDATE_SEARCH_HISTORY':
                  return [1];
            case 'SET_SELECTED_SEARCH_RESULTS_SORT':
                  return [];
            case 'LOGOUT_USER':
                  return [];
            default:
                  break;
      }
      return state;
};
export default searchResultsPageVisitedReducer;