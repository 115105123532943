import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'

import Loading from '../common/modal/Loading';
import FormTemplate from "../common/form/FormTemplate";
import LoginTop from "../top/LoginTop";
import axios from "axios";
import configData from "../../config/config.json";
import Footer from '../bottom/Footer'
import { LOADING_STATUES } from "../constants/constants";

class CompleteRegistration extends React.Component {
	state = {
		pageHeading: 'Registration Incomplete',
		usernameLine: '',
		welcomeLine: '',
		completeMessage: '',
		loadingStatus: "",
		errors: {},
		redirectToLogin: false
	  }

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	componentDidMount() {
		var ticket = { ticket: this.props.match.params.ticket };

		axios.post(configData.LANDSHARK_SERVER_URL + "auth/completeRegistration", ticket, { withCredentials: true })
			.then((response) => {
				this.setState({ pageHeading: 'Registration Complete', welcomeLine: 'Welcome, ' +  response.data.content.fullName, usernameLine:  'Username: ' + response.data.content.username,  completeMessage: 'You may continue to log in.' });
			}, (error) => {
				this.setState({status: "", redirectToLogin: false, completeMessage: error.response.data.message});
			});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.props.history.push('/login');
	}

	render() {

		let formGroupRows = [
			{
				formGroups: [{ id: 'label1', fieldType: 'label', value: this.state.welcomeLine }]
			  },
			  {
				formGroups: [{ id: 'label2', fieldType: 'label', value: this.state.usernameLine }]
			  },
			  {
				formGroups: [{ id: 'label3', fieldType: 'label', value: this.state.completeMessage }]
			  }
		]

		const formProps = {
			componentName: "CompleteRegistration",
			pageHeading: this.state.pageHeading,
			onSubmit: this.handleSubmit,
			primaryButtonLabel: "Continue",
			formGroupRows: formGroupRows,
			formOnly: true
		}

		return (
			<>
                <LoginTop />
				<section className="cn container-fluid" style={{background: '#3674AB' }}>
				<div style={{background: 'white', border: 'solid 3px black', borderRadius: 5, paddingBottom: 10, paddingLeft: 10, paddingRight: 5}}>
					<Container fluid="true" >
						<Row>
						<Col>
							<FormTemplate {...formProps} />
						</Col>
						</Row> 
					</Container>
				</div>
				</section>
				<Footer />
				<Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
			</>
        )
    }
}

export default CompleteRegistration;