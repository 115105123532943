import React, { Component } from 'react';
import { Modal, Button, Form, Row, Container, Col } from 'react-bootstrap'
import { connect } from "react-redux";
import axios from "axios";
import configData from "../../config/config.json";
import DateField from '../common/form/DateField';
import SelectField from '../common/form/SelectField';
import { listIcon } from '../../img/font_awesome/font_awesome';
import { validateYearRangeLimit, validateDates } from "../../utils/DateValidation";
import moment from 'moment';
import translations from "../../config/translation/home_translations.json"

const TODAY = 'TODAY';
const MONTH = 'MONTH';
const YTD = 'YTD';
const CUSTOM = 'CUSTOM';

const mapStateToProps = (state) => {
   return {
      currentUser: state.currentUserReducer,
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
   };
}

class TransactionReport extends Component {

   state = {
      fromDate: moment().subtract(1, 'M').format('L'),
      toDate:  moment().format('L'),
      reportType: 'USER',
      reportRange: YTD,
      errors: {}
   }

    handleSelectionMethodChange = (event) => {
        event.preventDefault();
        this.setState({
            reportRange: event.target.id,
            errors: {}
        })
    }

   handleChange = (event) => {
      event.preventDefault();
      this.setState({
         [event.target.id]: event.target.value,
         errors: {}
      })
   }

   handleSubmit = (event) => {
		event.preventDefault();
      if (this.handleValidation()) {
         axios.post(configData.LANDSHARK_SERVER_URL + "home/transactionReport", this.state, { responseType: 'blob', withCredentials: true })
         .then((response) => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
         }, (error) => {

         }); 
       }
	}
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.setState({  errors: {} })
      this.props.handleClose();
   }

   handleDatePickerChangeFromDate = (date) => {
      this.setState({
        fromDate : moment(date).format('L'),
        errors: {}
      })
    }
  
    handleDatePickerChangeToDate = (date) => {
      this.setState({
        toDate : moment(date).format('L'),
        errors: {}
      })
    }

    handleValidation() {
      let errors = {};
      if (!this.state.fromDate) {
        errors['fromDate'] = "Required.";
        this.setState({ errors: errors });
        return false;
      }

      let formIsValid = validateDates(errors, this.state.fromDate, 'fromDate', this.state.toDate, 'toDate');
      if (!formIsValid) {
        this.setState({ errors: errors });
        return false;
      }

       formIsValid = validateYearRangeLimit(errors, this.state.fromDate, 'fromDate', this.state.toDate, 'toDate');
      if (!formIsValid) {
        this.setState({ errors: errors });
        return false;
      }
  
      this.setState({ errors: errors });
      return true;
    }

   render() {
      let fromDateError = '';
      if (this.state.errors && this.state.errors['fromDate']) {
         fromDateError = this.state.errors['fromDate']
      }

      let toDateError = '';
      if (this.state.errors && this.state.errors['toDate']) {
         toDateError = this.state.errors['toDate']
      }
     
      return (
         <>
            <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
               <Form onSubmit={this.handleSubmit}>
                  <Modal.Header style={{ background: '#3674AB', color: 'white' }}>
                     <Modal.Title>{listIcon}&nbsp;&nbsp;{translations[this.props.language].transactions}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Container fluid="true" style={{ marginLeft: 20}}>
                        {
                           this.props.currentUser.role === 'ACCOUNT' &&
                           <>
                              <Row>
                                 <SelectField formGroup={{ layout: 'horizontal', id: 'reportType', label: 'Includes', fieldType: 'select', value: 'USER', options: [{ code: "ACCOUNT", description: "All transactions for this account" }, { code: "USER", description: "My transactions only" }] }} onChange={this.handleChange} />
                              </Row>
                              <Row>&nbsp;</Row>
                           </>
                        }
                        <Row>
                           {/* checkbox is tricky.  This is the only way I could make the checked attribute works.  It's essentially forcing the checkbox to re-render. */}
                           {
                              this.state.reportRange === TODAY &&
                              <Form.Check name='reportOption' id={TODAY} type='radio' checked={true} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].today} value={TODAY} />
                           }
                           {
                              this.state.reportRange !== TODAY &&
                              <Form.Check name='reportOption' id={TODAY} type='radio' checked={false} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].today} value={TODAY} />
                           }
                        </Row>
                        <Row>
                           {
                              this.state.reportRange === MONTH &&
                              <Form.Check name='reportOption' id={MONTH} type='radio' checked={true} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].this_month} value={MONTH} />
                           }
                           {
                              this.state.reportRange !== MONTH &&
                              <Form.Check name='reportOption' id={MONTH} type='radio' checked={false} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].this_month} value={MONTH} />
                           }
                        </Row>
                        <Row>
                           {
                              this.state.reportRange === YTD &&
                              <Form.Check name='reportOption' id={YTD} type='radio' checked={true} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].ytd} value={YTD} />
                           }
                           {
                              this.state.reportRange !== YTD &&
                              <Form.Check name='reportOption' id={YTD} type='radio' checked={false} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].ytd} value={YTD} />
                           }
                        </Row>
                        <Row>
                           {
                              this.state.reportRange === CUSTOM &&
                              <Form.Check name='reportOption' id={CUSTOM} type='radio' checked={true} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].custom_range} value={CUSTOM} />
                           }
                           {
                              this.state.reportRange !== CUSTOM &&
                              <Form.Check name='reportOption' id={CUSTOM} type='radio' checked={false} onChange={this.handleSelectionMethodChange} label={translations[this.props.language].custom_range} value={CUSTOM} />
                           }
                        </Row>
                        <Row >
                           <Col>
                              <DateField onChange={this.handleChange} formGroup={{ id: 'fromDate', label: translations[this.props.language].from_mmddyyyy, disabled: this.state.reportRange !== CUSTOM, value: this.state.fromDate, handleDatePickerChange: this.handleDatePickerChangeFromDate }} ></DateField>
                              <span style={{ color: "red", fontWeight: "bold" }}>{fromDateError}</span>
                           </Col>
                        </Row>
                        <Row >
                        <Col>
                              <DateField onChange={this.handleChange} formGroup={{ id: 'toDate', label: translations[this.props.language].to_mmddyyyy, disabled: this.state.reportRange !== CUSTOM, value: this.state.toDate, handleDatePickerChange: this.handleDatePickerChangeToDate }} ></DateField>
                              <span style={{ color: "red", fontWeight: "bold" }}>{toDateError}</span>
                              </Col>
                        </Row>
                     </Container>
                  </Modal.Body>
                  <Modal.Footer>
                     <Button variant="primary" type="submit" >{translations[this.props.language].generate_report}</Button>
                     <Button variant="secondary" onClick={this.handleCloseClick}>{translations[this.props.language].close}</Button>
                  </Modal.Footer>
               </Form>
            </Modal>
         </>
      )
   }
}

export default connect(mapStateToProps)(TransactionReport);