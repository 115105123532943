import React, {Component} from 'react';
import { Card, Table } from 'react-bootstrap'
import {  calendarIcon } from '../../img/font_awesome/font_awesome';
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class NotableDates extends Component {

    render() {
        let notableDatesTable = <></>;
        if (this.props.notableDates) {
            notableDatesTable =
                <Table  size='sm'>
                    <tbody>
                    <tr>
                        {
                            this.props.notableDates.headings.map((heading, index) => {
                                return <th key={index} className='table-td-xs'>{heading}</th>
                            })

                        }
                    </tr>
                    {
                        this.props.notableDates.rows.map((row, index) => {
                            return <tr key={index}>
                                {
                                    row.values.map((value, index) => { return <td key={index} className="table-td-xs">{value}</td> })
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </Table>
        }

        let cardHeaderClassName = 'card-header-blue';
        if (this.props.cardHeaderClassName) {
            cardHeaderClassName = this.props.cardHeaderClassName;
        }
        return (
            <Card >
                <Card.Header className={cardHeaderClassName}>
                    {calendarIcon}&nbsp;&nbsp;Notable Dates
                </Card.Header>
                <Card.Body>
                    {notableDatesTable}
                </Card.Body>
            </Card>
        )
    }
}

export default connect(mapStateToProps)(NotableDates);
