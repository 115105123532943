import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { Form, Container, Row, Button } from 'react-bootstrap'

import Alert from '../common/modal/Alert';
import PageBanner from "../common/page/PageBanner";
import PrimaryButton from "../common/form/PrimaryButton";
import { ALERT_TYPES } from "../constants/constants";

import { setSelectedControlPanelType, ALaCarteRatesSpecialControlPanelKey } from "../../redux/actions/controlPanelActions";
import { checkIconGreen, spinnerIconGray } from "../../img/font_awesome/font_awesome"
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";
import Confirmation from '../common/modal/Confirmation'

const componentName = ALaCarteRatesSpecialControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

const mapStateToProps = state => {
 
  return {
    sideNavigationOpen: state.sideNavigationReducer,
    availableInstrumentCodes: state.instrumentCodesWithBlankReducer.codeDescriptionResultList,
    availableDocumentTypes: state.documentTypesWithBlankReducer.codeDescriptionResultList
  };
}

class ALaCarteRatesSpecial extends Component {

  state = {
    applySpecialRates: false,
    rates: [
      {
        documentType: '',
        instrumentCode: '',
        documentRate: '',
        firstPageRate: '',
        additionalPagesRate: ''
      }
    ],
    status: "",
    message: "",
    showAlert: false,
    alertMessage: "",
    showConfirmation: false
  }

	handleCloseConfirmation = () => {
		this.setState({
			showConfirmation: false
		});
	}

  handleClickSave = (event) => {
		event.preventDefault();
		this.setState({
			showConfirmation: true
		});
	}

  validate = () => {

    let allDocumentTypeInstrutmentCodes = [];
    let lastRate;

    for (let rate of this.state.rates) {
      if (rate.instrumentCode !== '' && rate.documentType === '') {
        return "Each special rate must have a document type if an instrument code is provided.";
      }

      if (rate.documentRate === 0 && rate.firstPageRate === 0 && rate.additionalPagesRate === 0) {
        return "Each special rate must have at least a document or page rate.";
      }

      if (rate.documentType === '0') {
        allDocumentTypeInstrutmentCodes.push(rate.instrumentCode);
      } else if (rate.documentType !== '' &&  allDocumentTypeInstrutmentCodes.indexOf(rate.instrumentCode) >= 0) {
        return "Duplicate instrument codes found; a duplicate rate has been defined for All document types."
      }
      
      if (!lastRate) {
        lastRate = rate;
      } else if (rate.instrumentCode === lastRate.instrumentCode && rate.documentType === lastRate.documentType) {
        return "Duplicate document-type instrument-code combinations found."
      } else {
        lastRate = rate;
      }

    }  

    return null;
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    
    this.handleReorganizeRows();
    let message = this.validate();
    if (message) {
      this.showAlert(message, ALERT_TYPES.WARNING);
      return;
    }


    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    console.log(this.state);

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/aLaCarteSpecialRates", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: "SUCCESS"
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );

  }

  // Add five blank entries
  handleMoreRows = (event) => {
    event.preventDefault();

    const currentLength = this.state.rates.length;
    if (currentLength < 10) { // 100 is the abitrary max length
      let blankArray = [
        {
          documentType: '',
          instrumentCode: '',
          documentRate: '',
          firstPageRate: '',
          additionalPagesRate: ''
        },
        {
          documentType: '',
          instrumentCode: '',
          documentRate: '',
          firstPageRate: '',
          additionalPagesRate: ''
        },
        {
          documentType: '',
          instrumentCode: '',
          documentRate: '',
          firstPageRate: '',
          additionalPagesRate: ''
        },
        {
          documentType: '',
          instrumentCode: '',
          documentRate: '',
          firstPageRate: '',
          additionalPagesRate: ''
        },
        {
          documentType: '',
          instrumentCode: '',
          documentRate: '',
          firstPageRate: '',
          additionalPagesRate: ''
        }
      ]; // Five blanks
      // If adding the blankArray exceeds 100 in length, shorten the blankArray so that it won't exceed 100.
      if ((currentLength + blankArray.length) > 100) { 
        blankArray.length = 100 - currentLength;
      }

      this.setState({
        status: '',
        rates: [...this.state.rates, ...blankArray]
      })
    } else {
      this.showAlert("The rate table has a limit of 100 entries.");
    }
  }

  handleChange = (event) => {
    const targetId = event.target.id;
    console.log(targetId);
    if (targetId.includes("documentType")) {
      const index = targetId.replace("documentType", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.documentType = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("instrumentCode")) {
      const index = targetId.replace("instrumentCode", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.instrumentCode = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("documentRate")) {
      const index = targetId.replace("documentRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.documentRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("firstPageRate")) {
      const index = targetId.replace("firstPageRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.firstPageRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else if (targetId.includes("additionalPagesRate")) {
      const index = targetId.replace("additionalPagesRate", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.additionalPagesRate = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates
      });
    } else {
      this.setState({
        status: "",
        [event.target.id]: event.target.value ? event.target.value : 0
      })
    }
  }

  handleReorganizeRows = (event) => {
    if (event) {
      event.preventDefault();
    }
    let rates = this.state.rates;
    rates = rates.filter(rate => (rate.documentType && rate.documentType !== '') || (rate.instrumentCode && rate.instrumentCode !== ''));  
    rates.sort((rate1, rate2) => ((rate1.documentType > rate2.documentType) ? 1 : (rate1.documentType === rate2.documentType) ? ((rate1.instrumentCode < rate2.instrumentCode) ? 1 : -1) : -1 ));
  
    if (rates.length === 0) {
      rates = [ {
        documentType: '',
        instrumentCode: '',
        documentRate: '',
        firstPageRate: '',
        additionalPagesRate: ''
      }]
    }
    for (let rate of rates) {
      rate.documentRate = rate.documentRate === '' ? 0 : rate.documentRate;
      rate.firstPageRate = rate.firstPageRate === '' ? 0 : rate.firstPageRate;
      rate.additionalPagesRate = rate.additionalPagesRate === '' ? 0 : rate.additionalPagesRate;
    }

    this.setState({
      status: '',
      rates: rates
    })
  }

  handleRemoveRow = (event) => {
    let indexToRemove = event.target.id.replace("remove", "");
    indexToRemove = parseInt(indexToRemove);
    let rates = this.state.rates;
    rates.splice(indexToRemove, 1);
    this.setState({
      status: "",
      rates: rates
    })
  }

  showAlert = (alertMessage) => {
    this.setState({
      showAlert: true,
      alertMessage
    })
  }

  hideAlert = () => {
    this.setState({
      showAlert: false,
      alertMessage: ""
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/aLaCarteSpecialRates", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        let rates = content.rates;
        if (!rates || rates.length === 0) {
          rates = [ {
            documentType: '',
            instrumentCode: '',
            documentRate: '',
            firstPageRate: '',
            additionalPagesRate: ''
          }]
        }
        this.setState( {
          status : "",
          applySpecialRates: content.applySpecialRates,
          rates
        });
       
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {
    let formTemplateMarginLeft = 60
    let formTemplateMarginRight = 20
    if (this.props.formOnly) {
      formTemplateMarginLeft = 0
      formTemplateMarginRight = 0
    } else if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    let pageButtons = [{ label: 'Add', onClickAction: this.handleMoreRows, className: 'btn btn-secondary btn-sm' }, { label: 'Organize', onClickAction: this.handleReorganizeRows, className: 'btn btn-secondary btn-sm' }]

    let firstDocumentTypeControl =
      <Form.Control as='select' id='documentType0' disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} style={{ minWidth: 130 }}>
        {
            this.props.availableDocumentTypes && // not sure why it can be undefined.  not sure why render gets called so many times when refreshing the browser.
            this.props.availableDocumentTypes.map((option, description) => {
              if (this.state.rates[0].documentType === option.code)
              return (<option key={option.code} selected value={option.code}>{option.description}</option>)
              else
                return (<option key={option.code} value={option.code}>{option.description}</option>)
            })
          
        }
      </Form.Control>

    if (this.state.applySpecialRates === false)
      firstDocumentTypeControl =
        <Form.Control id='documentType0' disabled as='select' onChange={this.handleChange} style={{ minWidth: 130 }}>
        {
          this.props.availableDocumentTypes && // not sure why it can be undefined.  not sure why render gets called so many times when refreshing the browser.
          this.props.availableDocumentTypes.map((option, description) => {
            if (this.state.rates[0].documentType === option.code)
              return (<option key={option.code} selected value={option.code}>{option.description}</option>)
            else
               return (<option key={option.code} value={option.code}>{option.description}</option>)
          })
        }
        </Form.Control>

    let firstInstrumentCodeFormControl =
      <Form.Control as='select' id='instrumentCode0' disabled={this.state.status === LOADING_STATUES.LOADING } onChange={this.handleChange} style={{ minWidth: 130 }}>
        {
          this.props.availableInstrumentCodes && // not sure why it can be undefined.  not sure why render gets called so many times when refreshing the browser.
          this.props.availableInstrumentCodes.map((option, description) => {
            if (this.state.rates[0].instrumentCode === option.code)
              return (<option key={option.code} selected value={option.code}>{option.description}</option>)
            else
               return (<option key={option.code} value={option.code}>{option.description}</option>)
          })
        }
      </Form.Control>
  
    if (this.state.applySpecialRates === false)
      firstInstrumentCodeFormControl = 
        <Form.Control as='select' id='instrumentCode0' disabled style={{ minWidth: 130 }}>
        {
          this.props.availableInstrumentCodes && // not sure why it can be undefined.  not sure why render gets called so many times when refreshing the browser.
          this.props.availableInstrumentCodes.map((option, description) => {
            if (this.state.rates[0].instrumentCode === option.code)
              return (<option key={option.code} selected value={option.code}>{option.description}</option>)
            else
               return (<option key={option.code} value={option.code}>{option.description}</option>)
          })
        }
        </Form.Control>
  

    let rateTable =
      <div>
        {
          this.state.rates.map((rate, index) => (
            (index > 0) &&
            <Form.Row>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                {
                  this.state.applySpecialRates !== false && 
                  <Form.Control as='select' id={'documentType' + index} disabled={this.state.status === LOADING_STATUES.LOADING } onChange={this.handleChange} style={{ minWidth: 130 }} >
                    {
                      this.props.availableDocumentTypes &&
                      this.props.availableDocumentTypes.map((option) => {
                        if (rate.documentType === option.code)
                            return (<option key={option.code} selected value={option.code}>{option.description}</option>)
                        else
                           return (<option key={option.code} value={option.code}>{option.description}</option>)
                      })
                    }
                  </Form.Control>
                }
                {      
                  this.state.applySpecialRates === false &&  
                  <Form.Control as='select' id={'documentType' + index} disabled style={{ minWidth: 130 }} >
                    {
                      this.props.availableDocumentTypes &&
                      this.props.availableDocumentTypes.map((option) => {
                        if (rate.documentType === option.code)
                            return (<option key={option.code} selected value={option.code}>{option.description}</option>)
                        else
                           return (<option key={option.code} value={option.code}>{option.description}</option>)
                      })
                    }
                  </Form.Control>
                }
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                {
                  this.state.applySpecialRates !== false &&    
                  <Form.Control as='select' id={'instrumentCode' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} style={{ minWidth: 130 }} >
                    {
                      this.props.availableInstrumentCodes &&
                      this.props.availableInstrumentCodes.map((option) => {
                        if (rate.instrumentCode === option.code)
                          return (<option key={option.code} selected value={option.code}>{option.description}</option>)
                        else
                          return (<option key={option.code} value={option.code}>{option.description}</option>)
                      })
                    }
                  </Form.Control>
                }
                {      
                  this.state.applySpecialRates === false &&
                  <Form.Control as='select' id={'instrumentCode' + index} disabled style={{ minWidth: 130 }} >
                    {
                      this.props.availableInstrumentCodes &&
                      this.props.availableInstrumentCodes.map((option) => {
                        if (rate.instrumentCode === option.code)
                          return (<option key={option.code} selected value={option.code}>{option.description}</option>)
                        else
                          return (<option key={option.code} value={option.code}>{option.description}</option>)
                      })
                    }
                  </Form.Control>
                }
              </div>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id={'documentRate' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.rates[index].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false &&
                  <Form.Control type='number' step='0.01' id={'documentRate' + index} disabled value={this.state.rates[index].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id={'firstPageRate' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.rates[index].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false && 
                  <Form.Control type='number' step='0.01' id={'firstPageRate' + index} disabled value={this.state.rates[index].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id={'additionalPagesRate' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.rates[index].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false &&   
                  <Form.Control type='number' step='0.01' id={'additionalPagesRate' + index} disabled value={this.state.rates[index].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }  
              </div>
              <div>&nbsp;</div>
              <Button id={'remove' + index} variant="danger" size="sm" style={{margin:1}} disabled={this.state.status === LOADING_STATUES.LOADING} onClick={this.handleRemoveRow}>Remove</Button>
            </Form.Row>
          ))
        }
      </div>

    return (
      <div style={{ marginLeft: formTemplateMarginLeft, marginRight: formTemplateMarginRight, marginTop:4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <PageBanner pageHeading="A La Carte Special Rates" buttons={pageButtons} />
        <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 50, marginTop: 10, marginLeft: 4, marginRight: 4, border: '3px solid darkGray', borderRadius: '5px' }}>
          <Row>&nbsp;</Row>
          <Form onSubmit={this.handleClickSave}>
            <Form.Row>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>Apply Special Rates:</div>
                <Form.Control id="applySpecialRates" as='select' disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} style={{ maxWidth: 130 }} >
                  {
                    this.state.applySpecialRates !== false &&
                    <option key="true" selected value="true">Yes</option>
                  }
                  {
                    this.state.applySpecialRates !== false &&
                    <option key="false" value="false">No</option>
                  }
                  {
                    this.state.applySpecialRates === false &&
                    <option key="true" value="true">Yes</option>
                  }
                  {
                    this.state.applySpecialRates === false &&
                    <option key="false" selected value="false">No</option>
                  }
                </Form.Control>
              </div>
            </Form.Row>
            <Form.Row>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>Document Type:</div>
                {firstDocumentTypeControl}
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>Instrument Code:</div>
                {firstInstrumentCodeFormControl}
              </div>
              <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>Document ($):</div>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id='documentRate0' disabled={this.state.status === LOADING_STATUES.LOADING } onChange={this.handleChange} value={this.state.rates[0].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false &&
                  <Form.Control type='number' step='0.01' id='documentRate0' disabled value={this.state.rates[0].documentRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>1st Page ($):</div>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id='firstPageRate0' disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.rates[0].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false &&
                  <Form.Control type='number' step='0.01' id='firstPageRate0' disabled value={this.state.rates[0].firstPageRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
              </div>
              <div style={{ display: 'inline-block', marginLeft: 4 }}>
                <div>Additional Pages ($):</div>
                {
                  this.state.applySpecialRates !== false &&
                  <Form.Control type='number' step='0.01' id='additionalPagesRate0' disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.rates[0].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                {
                  this.state.applySpecialRates === false &&
                  <Form.Control type='number' step='0.01' id='additionalPagesRate0' disabled value={this.state.rates[0].additionalPagesRate} style={{ maxWidth: 130, textAlign: 'right' }} />
                }
                </div>
            </Form.Row>
            {rateTable}
            <Row>&nbsp;</Row>
            <PrimaryButton buttonLabel="Submit" disabled={this.state.status === LOADING_STATUES.LOADING} />&nbsp;&nbsp;
            { 
              this.state.status === LOADING_STATUES.SUCCESS &&
              <span style={{width:18, verticalAlign: "bottom", paddingBottom: 1, display:"inline-block"}}>{checkIconGreen}</span>
            }
            { 
              this.state.status === LOADING_STATUES.LOADING &&
              <span style={{width:18, verticalAlign: "bottom", marginBottom: 1, display:"inline-block"}}>{spinnerIconGray}</span>
            }
          </Form>
        </Container>
        <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={ALERT_TYPES.WARNING}/>
        <Confirmation show={this.state.showConfirmation} cancelConfirmation={this.handleCloseConfirmation} proceedConfirmation={this.handleSubmit} question='The special rates for credit card will also be reset. Continue?' />
      </div>

    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(ALaCarteRatesSpecial);