import React, {Component} from 'react';
import { Card, Modal, Button } from 'react-bootstrap'

import { expandIcon } from '../../img/font_awesome/font_awesome';
import configData from "../../config/config.json";

class LoginPageSlides extends Component {

    state = {
        showLoginSlideModals: [false, false, false, false, false, false]
    }

    handleClick = (event) => {
        event.preventDefault();
        
        const clickedId = event.target.parentElement.parentElement.id; // find the closest parent
        console.log(clickedId);
        let show = [false, false, false, false, false, false];
        show[clickedId] = true;

        this.setState({
            showLoginSlideModals: show
        })
     }

     handleCloseClick = (event) => {
        event.preventDefault();
        this.setState({
             showLoginSlideModals: [false, false, false, false, false, false]
        })
    }


    render() {
	
        const slide1 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/one.jpg";
		const slide2 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/two.jpg";
		const slide3 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/three.jpg";
		const slide4 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/four.jpg";
		const slide5 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/five.jpg";
		const slide6 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/six.jpg";

        let slideArray = [slide1, slide2, slide3, slide4, slide5, slide6];
        let loginPageSlides = [];
        let loginPageSlideModals = [];

        for (var i = 0; i < this.props.numberOfSlides; i++) {
            loginPageSlides.push(
                <Card key={i} style={{ cursor: "pointer"}} >
                    <div id={i} style={{ position: 'relative', top: 0, left: 0 }} onClick={this.handleClick} >
                        <div style={{ position: 'relative', top: 0, left: 0 }}> <Card.Img variant="top" src={slideArray[i]} /></div>
                        <div style={{ position: 'absolute', bottom: 5, right: 15, color: '#408CC7' }}>  {expandIcon} </div>
                    </div>
                </Card>);
            loginPageSlideModals.push(
                <Modal key={i} show={this.state.showLoginSlideModals[i]} onHide={this.handleCloseClick} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body style={{ borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB' }}>
                        <img border="0" src={slideArray[i]} alt="THREE" class="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
                    </Modal.Footer>
                </Modal>
            );
        }

        if (loginPageSlides.length === 0) {
            loginPageSlides.push(<Card><Card.Img variant="top" src={slide1} /></Card>);
        }

        return (
            <>
                { loginPageSlides }
                { loginPageSlideModals }
            </>
        )
    }
}

export default LoginPageSlides;
