import React from 'react';
import { Row, Col, Container} from 'react-bootstrap'
import axios from "axios";
import configData from "../../config/config.json";

class LoginPageLinks extends React.Component {

	state = {
		paragraphs: [ ]
	}

	componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "auth/loginPageLinks", { withCredentials: true })
			.then(
				(response) => {
					const content = response.data.content;
					let paragraphs = content.paragraphs;
					if (!paragraphs || paragraphs.length === 0) {
						paragraphs = [{
							heading: '',
							paragraphContent: ''
						}]
					}

					this.setState({
						paragraphs
					});
				},
				(error) => {
					//   handleError(error, this.props.history);
				}
			);
	}

	render() {

		// no links
		if (!this.state.paragraphs || this.state.paragraphs.length === 0) {
			return (<Row className='align-items-center h-25'/>)
		}

		const numberOfLinks = this.state.paragraphs.length;

		// One link
		if (numberOfLinks === 1) {
			return (
				<Row className='align-items-center'  style={{height: '20%'}} >
					<Col lg={12} className='text-center' style={{ color: 'white'}}>
						<a target="_blank" rel="noopener noreferrer"  href={this.state.paragraphs[0].paragraphContent} style={{ color: 'white', fontWeight: 'bold' }}>{this.state.paragraphs[0].heading}</a>
					</Col>
				</Row>
			)
		}

		// Two links
		if (numberOfLinks === 2) {
			return (
				<Row className='align-items-center' style={{height: '20%'}}>
					<Col lg={6} className='text-center' style={{ borderRight: '3px solid white', color: 'white' }} >
						<a target="_blank" rel="noopener noreferrer"  href={this.state.paragraphs[0].paragraphContent} style={{ color: 'white', fontWeight: 'bold' }}>{this.state.paragraphs[0].heading}</a>
					</Col>
					<Col lg={6} className='text-center'>
						<a target="_blank" rel="noopener noreferrer"  href={this.state.paragraphs[1].paragraphContent} style={{ color: 'white', fontWeight: 'bold' }}>{this.state.paragraphs[1].heading}</a>
					</Col>
				</Row>
			)
		}

		// The LoginPageLinks.js in the control panel allows up to 12 links.
		// The rest of the code assumes there's a max of 12 links. 
		const numberOfRows = numberOfLinks > 6 ? 2 : 1;
		const numberOfRowOneLinks = Math.ceil(numberOfLinks / numberOfRows);
		const columnSize = Math.floor(12 / numberOfRowOneLinks);
		let rowOnefiller = <></>
		// 12 is not divisble by 5, so add a filler and treat it like there are six links
		if (numberOfRowOneLinks === 5) {
			rowOnefiller = <Col lg={columnSize} className='text-center' style={{ borderRight: '3px solid white', color: 'white' }} ></Col>
		}

		const numberOfRowTwoLinks = numberOfLinks - numberOfRowOneLinks;
		let rowTwofiller = <></>
		// If row one more links than row two, row two needs to add a filler to line up with row one.
		// If row one has only 5 links, it means it uses a filler, so row two need to add a filler to line up with row one.
		if (numberOfRowOneLinks > numberOfRowTwoLinks || numberOfRowOneLinks === 5) { 
			rowTwofiller = <Col lg={columnSize} className='text-center' style={{ borderRight: '3px solid white', color: 'white' }}  ></Col>
		}

		let rowOneLinks = [];
		for (let index = 0; index < numberOfRowOneLinks; index++) {
			rowOneLinks.push(
				<Col lg={columnSize} className='text-center' style={{ borderRight: '3px solid white', color: 'white' }} >
					<a target="_blank" rel="noopener noreferrer"  href={this.state.paragraphs[index].paragraphContent} style={{ color: 'white', fontWeight: 'bold' }}>{this.state.paragraphs[index].heading}</a>
				</Col>
			);
  		}

		// Row Two
		let rowTwoLinks = [];
		if (numberOfRows > 1) {
			for (let index = numberOfRowOneLinks; index < numberOfLinks; index++) {
				rowTwoLinks.push(
					<Col lg={columnSize} className='text-center' style={{ borderRight: '3px solid white', color: 'white' }} >
						<a target="_blank" rel="noopener noreferrer"  href={this.state.paragraphs[index].paragraphContent} style={{ color: 'white', fontWeight: 'bold' }}>{this.state.paragraphs[index].heading}</a>
					</Col>
				);
			}			
		}

		return (
			<>
			<Row className='align-items-center' style={{height: '15%'}} >
				<Col lg='12' >
				 <Container fluid="true" > 
					 <Row style={{marginBottom:10}}> 
						{rowOnefiller}
						{rowOneLinks}
					</Row> 
					{ 
						numberOfRows > 1 &&
					 	<Row>
							{rowTwofiller}
							{rowTwoLinks}
						</Row>  
					}
				 </Container>
				 </Col>
			</Row>			
			</>
		)
	}
}

export default LoginPageLinks;