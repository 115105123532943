const landlinkConfigReducer = (state = [{code: "IS_QQQ_SECTION", description: "false"}, {code: "IS_QQQQ_SECTION", description: "false"}], action) => {
  switch (action.type) {
    case 'LANDLINK_COUNTY_CONFIG':
  
   
      // action.results.forEach((countyConfigResult) => temparray.push() );

      // return temparray;
      return action.results;
    default:
      break;
  }
  return state;
}

export default landlinkConfigReducer;