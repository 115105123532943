import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, LoginPageSlidesControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = LoginPageSlidesControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class LoginPageSlides extends Component {

  state = {
    numberOfSlides: 0,
    status: "",
    message: ""
  }

  handleSubmit = (event) => {

    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageSlides", this.state, { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState({ 
          numberOfSlides: content.numberOfSlides,
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageSlides", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          numberOfSlides: content.numberOfSlides,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Login Page - Slides",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'numberOfSlides', label: 'Number Of Slides', fieldType: 'select', options: [{ code: 1, description: "1"}, {code : 2, description: "2"}, {code : 3, description: "3"}, {code : 4, description: "4"}, {code : 5, description: "5"}, {code : 6, description: "6"},], value: this.state.numberOfSlides, style: {minWidth: 200} }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(LoginPageSlides);