import React from 'react';

import { Button } from 'react-bootstrap'

export default class SecondaryButton extends React.Component {
   render() {
      if (this.props.buttonLabel !== undefined) {
         return <Button variant="secondary" onClick={this.props.onClick} >{this.props.buttonLabel}</Button>
      }
      return (
         <Button variant="secondary" onClick={this.props.onSave} >Submit</Button>
      )
   }
}