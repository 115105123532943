import axios from "axios";
import { saveAs } from 'file-saver';
import configData from "../../config/config.json";
import { handleBlobError, handleCallBack } from "./commonStuff";

export const ReportStatementKey = "statementReport";
export const ReportAccountAuditKey = "auditAccountsReport";
export const ReportUserAuditKey = "auditUsersReport";
export const ReportAccountTypeKey = "accountTypeReport";

export const setSelectedReportType = (selectedReportType) => {
    return {
        type: 'SET_SELECTED_REPORT_TYPE',
        selectedReportType
    }
}

const handleReportResults = (dispatch, reportCriteria, reportType) => {
  dispatch({ type: 'UPDATE_REPORT_CRITERIA', reportCriteria, reportType }); 
};

// Statement Report - PDF
export const reportStatement = (reportCriteria, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/statementReport", reportCriteria, {  responseType: 'blob', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportStatementKey);
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'StatementReport.pdf');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// Statement Report - Excel
export const exportStatement = (reportCriteria, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/statementExport", reportCriteria, {  responseType: 'arraybuffer', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportStatementKey);
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'StatementReportExport.xlsx');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// User Report - PDF
export const reportUser = (reportCriteria, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/userReport", reportCriteria, {  responseType: 'blob', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportUserAuditKey);
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'UserReport.pdf');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// User Report - Excel
export const exportUser = (reportCriteria, history, callback) => async dispatch => {
  dispatch({ type: 'LOADING', data: true }); 
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/userExport", reportCriteria, {  responseType: 'arraybuffer', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportUserAuditKey);
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'UserReportExport.xlsx');
      handleCallBack(callback); 
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// Account Report - PDF
export const reportAccount = (reportCriteria, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/accountReport", reportCriteria, {  responseType: 'blob', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportAccountAuditKey);
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'AccountReport.pdf');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// Account Report - Excel
export const exportAccount = (reportCriteria, history, callback) => async dispatch => {
 
  dispatch({ type: 'LOADING', data: true }); 
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/accountExport", reportCriteria, {  responseType: 'arraybuffer', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportAccountAuditKey);
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'AccountReport.xlsx');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};

// Account Type Report - PDF
export const reportAccountType = (reportCriteria, history, callback) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "report/accountTypeReport", reportCriteria, {  responseType: 'blob', withCredentials: true })
    .then((response) => {
      handleReportResults(dispatch, reportCriteria, ReportAccountTypeKey);
      let blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, 'AccountTypeReport.pdf');
      handleCallBack(callback);
    }, (error) => {
      handleBlobError(error, history, callback);
    });
};