import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import { connect } from "react-redux";

const mapStateToProps = state => {
   return {
       preauthAmount: state.countyConfigCreditCardExtraReducer.maxAmount,
       surchargeMessage: state.countyConfigCreditCardExtraReducer.surchargeMessage,
       searchRates: state.currentUserReducer.creditCardSearchRates
   };
}

class CreditCardPriceListWindow extends Component {


   render() {
    
      let searchRates = [];
      if (this.props.searchRates) {
         this.props.searchRates.forEach((searchRate, index) => {
            searchRates.push(<Row className="justify-content-md-left"><Col md='9'>{searchRate.code}:</Col><Col md='3'>${searchRate.description}</Col></Row>);
         })
         searchRates.push(<Row className="justify-content-md-left">&nbsp;</Row>);
      }
      if (this.props.surchargeMessage) {
         searchRates.push(<Row className="justify-content-md-left"><Col md='12'>{this.props.surchargeMessage}</Col></Row>);
         searchRates.push(<Row className="justify-content-md-left">&nbsp;</Row>);
      }  

      const priceListHeading = this.props.priceListHeading ? this.props.priceListHeading : "Price List"

      const continueLabel = this.props.continueLabel ? this.props.continueLabel : "Continue"

      return (
         <Modal show={this.props.show} onHide={this.props.handleCloseClicked} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  {priceListHeading}
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
               <Container >
                  <Row className="justify-content-md-left">
                     The following search activities will incur a charge.  To continue, it requires a credit card preauthorization of ${this.props.preauthAmount}.  Final and actual credit card charges will be computed based on the below activities:
                  </Row>
                  <Row className="justify-content-md-left">&nbsp;</Row>
                  {searchRates}
                  <Row className="justify-content-md-left">&nbsp;</Row>
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='12' className='align-self-end' style={{ textAlign: 'right' }}>
                        <Button variant="primary" style={{ marginRight: 8 }} onClick={this.props.handleContinueClicked}>{continueLabel}</Button>
                        <Button variant="secondary" onClick={this.props.handleCloseClicked}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default connect(mapStateToProps)(CreditCardPriceListWindow);
