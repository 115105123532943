const initialState = { 
      "timestamp":0,
      "totalRecordCount":0,
      "currentRecordCount":0,
      "searchResultList":[]
};

const searchResultsReducer = (state = initialState, action) => {

      switch (action.type) {
            case 'SHOW_FILTERED_SEARCH_RESULTS':
                  return action.searchResults;
            case 'UPDATE_SEARCH_RESULTS':
            case 'UPDATE_PAGINATED_SEARCH_RESULTS':
                  return action.searchResults;
            case 'UPDATE_TABLE_BATCH_RESULTS':
                  let object = state;
                  // Make sure the batch of results coming in belongs to the current results set by checking the timestamps.
                  let timestamp = action.searchResults.timestamp;
                  if (object.timestamp === timestamp) {
                        let array = object.searchResultList;
                        let batch = action.searchResults.batch;
                        let beginningIndex = (batch - 1) * 25;
                        Array.prototype.splice.apply(array, [beginningIndex, action.searchResults.searchResultList.length].concat(action.searchResults.searchResultList));
                        object.searchResultList = array;
                  }
                  return object;
            case 'LOGOUT_USER':
                  return {
                        "timestamp":0,
                        "totalRecordCount":0,
                        "currentRecordCount":0,
                        "searchResultList":[]   
                  }
            default:
                  break;
      }
      return state;
};
export default searchResultsReducer;