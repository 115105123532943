const auditLogTypesReducer = (state = [{code: "all", description: "All"}], action) => {
  switch (action.type) {
    case 'LANDSHARK_AUDIT_LOG_TYPES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default auditLogTypesReducer;