const searchResultsVisitedAppliedReducer = (state = false, action) => {

  if (action.type === "VISITED") {
      state = true;
  } else if (action.type === 'RESET_READ' || action.type === 'UPDATE_SEARCH_RESULTS' || action.type === 'LOGOUT_USER') {
      state = false;
  } 
  return state;
}

export default searchResultsVisitedAppliedReducer;