import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container, Table } from 'react-bootstrap'
import { connect } from "react-redux";

const PREFIX = 'ImageConfirmOrderWindow: ';

const mapStateToProps = state => {
   return {
     preauthAmount: state.countyConfigCreditCardExtraReducer.maxAmount
   };
 }

// Class notes.
//
// - props.confirmOrderWindow.*
// - action ( 'show' or any )
// - feeSchedule (the fee schedule for the charges)
// - show: boolean if the window should show
// - backClicked(): notification that the back button was clicked
// - buyOptionsClicked(): notification that the buy button was clicked
// - closeClicked(): notification that the window was closed
// - continueClicked(): notification that the continue button was clicked
// - reauthClicked(): notification that the reauth button was clicked
// feeSchedule: {
//   alreadyPurchasedMessage: null,
//   beginningBalance: { description: '', value: '' },
//   endingBalance: { description: '', value: '' },
//   insufficientBalance: false,
//   insufficientBalanceMessage: false,
//   multipleItemPurchase: null,
//   noPurchasingOptionsAvailableMessage: null,
//   singleItemPurchase: null
//},
// imageState: {
//   purchaseOptions: {
//   }
class ImageConfirmOrderWindow extends Component {
   constructor(props) {
      super(props);

      this.state = {
   };
   }

   componentDidMount = () => {
   }

   componentDidUpdate = (prevProps, prevState) => {
   }

   handleBackClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.backClicked();
   }

   handleBuyOptionsClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.buyOptionsClicked();
   }

   handleContinueClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.continueClicked();
   }

   handleReauthClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.reauthClicked();
   }

   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.closeClicked();
   }

   render() {
      if (!this.props.show) {
         return <></>;
      }

      let feeSchedule = this.props.feeSchedule;
      let purchaseOptions = this.props.imageState.purchaseOptions;


      let title = "";

      if (feeSchedule.singleItemPurchase) {
         title = feeSchedule.singleItemPurchase.item.description + ' Download';
      } else if (feeSchedule.multipleItemPurchase) {
         title = feeSchedule.multipleItemPurchase.itemHeaderDescription + ' Download';
      }

      if (feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && purchaseOptions.creditCardOptions && purchaseOptions.creditCardOptions.authorizationInfo.authorized) {
         title = "Exceeding Current Preauthorization"
      }

      return (
         <Modal show={this.props.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  {title}
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB' }}>
               <Container >
                  {
                     feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && purchaseOptions.creditCardOptions && !purchaseOptions.creditCardOptions.authorizationInfo.authorized &&
                     <Row className="justify-content-md-left" style={{ background: '#3674AB', color: 'white', fontSize: 20 }}>
                        Image download will incur a charge.  To continue, it requires a credit card preauthorization of ${this.props.preauthAmount}.  Final and actual credit card charges for this download are computed as below:
                     </Row>
                  }
                  {
                     feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && purchaseOptions.creditCardOptions && purchaseOptions.creditCardOptions.authorizationInfo.authorized &&
                     <Row className="justify-content-md-left" style={{ background: '#3674AB', color: 'white', fontSize: 20 }}>
                        Your charges are exceeding the current preauthorized amount.  To continue, it requires another round of credit card preauthorization of ${this.props.preauthAmount}.  Final and actual credit card charges for this download are computed as below:
                     </Row>
                  }
                  <Row className="justify-content-md-center">
                     <Col md="auto" style={{ border: '2px solid black', paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                        <Container>
                           <Row>
                              <Col>
                                 <Container>
                                    <Table borderless size="sm">
                                       <tbody>
                                          <tr>
                                             <td style={{ fontWeight: 'bold' }}>{feeSchedule.beginningBalance.description}</td>
                                             <td style={{ textAlign: 'right' }}>{feeSchedule.beginningBalance.value}</td>
                                          </tr>
                                          <tr>
                                             <td style={{ fontWeight: 'bold' }}>{feeSchedule.purchaseMessage}</td>
                                             <td>&nbsp;</td>
                                          </tr>
                                          {
                                             feeSchedule.singleItemPurchase &&
                                             <tr>
                                                <td>Download charges</td>
                                                <td style={{ textAlign: 'right' }}>{feeSchedule.singleItemPurchase.item.value}</td>
                                             </tr>
                                          }
                                          {
                                             feeSchedule.multipleItemPurchase &&
                                             <>
                                                <tr>
                                                   <td>Download charges</td>
                                                   <td>&nbsp;</td>
                                                </tr>
                                                {
                                                   feeSchedule.multipleItemPurchase.items.map((item) => {
                                                      return <>
                                                         <tr>
                                                            <td>{item.description}</td>
                                                            <td style={{ textAlign: 'right' }}>{item.value}</td>
                                                         </tr>
                                                      </>;
                                                   })
                                                }
                                                <tr>
                                                   <td>{feeSchedule.multipleItemPurchase.total.description}</td>
                                                   <td style={{ textAlign: 'right' }}>{feeSchedule.multipleItemPurchase.total.value}</td>
                                                </tr>
                                             </>
                                          }
                                          {
                                             feeSchedule.insufficientBalance &&
                                             <tr>
                                                <td style={{ fontWeight: 'bold' }}>{feeSchedule.endingBalance.description}</td>
                                                <td style={{ fontWeight: 'bold', textDecoration: 'underline', textAlign: 'right' }}>{feeSchedule.endingBalance.value}</td>
                                             </tr>
                                          }
                                          {
                                             !feeSchedule.insufficientBalance &&
                                             <tr>
                                                <td style={{ fontWeight: 'bold' }}>{feeSchedule.endingBalance.description}</td>
                                                <td style={{ textAlign: 'right' }}>{feeSchedule.endingBalance.value}</td>
                                             </tr>
                                          }
                                          {
                                             (feeSchedule.alreadyPurchasedMessage != null) &&
                                             <tr>
                                                <td colSpan="2" style={{ fontWeight: 'bold' }}>{feeSchedule.alreadyPurchasedMessage}</td>
                                             </tr>
                                          }
                                          {
                                             feeSchedule.insufficientBalance &&
                                             <tr>
                                                <td colSpan="2" style={{ fontWeight: 'bold' }}>{feeSchedule.insufficientBalanceMessage}</td>
                                             </tr>
                                          }
                                          {
                                             feeSchedule.insufficientBalance && !purchaseOptions.hasBuyOptions &&
                                             <tr>
                                                <td colSpan="2" style={{ fontWeight: 'bold' }}>{feeSchedule.noPurchasingOptionsAvailableMessage}</td>
                                             </tr>
                                          }
                                       </tbody>
                                    </Table>
                                 </Container>
                              </Col>
                           </Row>
                        </Container>
                     </Col>
                  </Row>
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='3' className='align-self-end' style={{ textAlign: 'left' }}>
                        <Button variant="secondary" onClick={this.handleBackClick}>Back</Button>
                     </Col>
                     <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                        {
                           feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && !purchaseOptions.creditCardOptions &&
                           <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleBuyOptionsClick}>Add to Escrow</Button>
                        }
                        {
                           feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && purchaseOptions.creditCardOptions && !purchaseOptions.creditCardOptions.authorizationInfo.authorized &&
                           <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleBuyOptionsClick}>Continue to Preauthorization</Button>
                        }
                        {
                           feeSchedule.insufficientBalance && purchaseOptions.hasBuyOptions && purchaseOptions.creditCardOptions && purchaseOptions.creditCardOptions.authorizationInfo.authorized &&
                           <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleReauthClick}>Continue to Preauthorization</Button>
                        }
                        {
                           !feeSchedule.insufficientBalance &&
                           <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleContinueClick}>{feeSchedule.downloadButtonText}</Button>
                        }
                        <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default connect(mapStateToProps)(ImageConfirmOrderWindow);
