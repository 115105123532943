import React from 'react';
import axios from "axios";
import configData from "../../config/config.json";
import { Container, Row, Col } from 'react-bootstrap'
import FormTemplate from "../common/form/FormTemplate";
import Alert from "../common/modal/Alert";
import CommonTop from "../top/CommonTop";
import { validateUser, trimUserFields } from "../common/function/userValidation.js";
import { LOADING_STATUES } from "../constants/constants";

class UserPassword extends React.Component {
	state = {
		id: 0,
		username: '',
		password: '',
		passwordConfirm: '',
		loadingStatus: '',
		showAlert: false,
		redirectToUserManager: false,
		errors: {}
	}

	handleChange = (event) => {
		event.preventDefault();
		this.setState({
			[event.target.id]: event.target.value,
			errors: {}
		})
	}

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/userManager');
	}

	validateAndSubmit = () => {
		let errors = validateUser(this.state, false);
		if (errors && Object.keys(errors).length > 0) {
			console.log(errors);
			this.setState({ errors });
			return;
		}

		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userPassword", this.state, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", redirectToUserManager: true },  this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ loadingStatus: "", redirectToUserManager: false }, this.showAlert(error.data.message));
				});
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();

		const trimmedUserFields = trimUserFields(this.state);
		this.setState({ ...trimmedUserFields }, function () {
			this.validateAndSubmit();
		});
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
		if (this.state.redirectToUserManager) {
			this.props.history.push('/userManager');
		}
	}

	componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "userManager/user/" + this.props.match.params.id, { withCredentials: true })
			.then(
				(response) => {
					const content = response.data.content;
					this.setState({
						id: content.id,
						username: content.username,
						password: content.password,
						passwordConfirm: content.password
					});

				},
				(error) => {
					console.log(error);
				}
			);
	}

	render() {
		let formGroupRows = [
			{
				formGroups: [{ id: 'password', label: 'Password', fieldType: 'password', value: this.state.password, required: true, error: this.state.errors['password'] }]
			},
			{
				formGroups: [{ id: 'passwordConfirm', label: 'Confirm Password', fieldType: 'password', value: this.state.passwordConfirm, required: true, error: this.state.errors['passwordConfirm'] }]
			}
		]

		const formProps = {
			componentName: "UserPassword",
			pageHeading: "Reset Password (" + this.state.username + ")",
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows
		}

		return (
			<>
				<CommonTop history={this.props.history} />
				<Container style={{ background: 'white'}}>
					<Row>
						<Col style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginTop: 0 }}>
							<FormTemplate {...formProps} />
						</Col>
					</Row>
				</Container>
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
			</>
				)
    }
}

export default UserPassword;
