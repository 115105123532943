import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, CreditCardExtraControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = CreditCardExtraControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName)),  
  }
}

class CreditCardExtra extends Component {

  state = {
    applySurcharge: false,
    surchargeMethod: 'dollars',
    surcharge: '0.0',
    surchargePercent: '0.0',
    maxAmount: '0.0',
    postToLandLink: true,
    chargeChargeCode: '',
    surchargeChargeCode: '',
    chargeFeeCode: '',
    surchargeFeeCode: '',
    status: '',
    message: ''
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/creditCardExtra", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/creditCardExtra", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          applySurcharge: content.applySurcharge,
          surchargeMethod: content.surchargeMethod ? content.surchargeMethod : 'dollars',
          surcharge: content.surcharge,
          surchargePercent: content.surchargePercent,
          maxAmount: content.maxAmount,
          postToLandLink: content.postToLandLink,
          chargeChargeCode: content.chargeChargeCode,
          surchargeChargeCode: content.surchargeChargeCode,
          chargeFeeCode: content.chargeFeeCode,
          surchargeFeeCode: content.surchargeFeeCode,
          status : ""
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Credit Card Extra",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          heading: 'LandLink', 
          formGroups: [{ id: 'postToLandLink', label: 'Post to LandLink', fieldType: 'select', value: this.state.postToLandLink }]
        },
        {
          formGroups: [ { id: 'chargeFeeCode', label: 'Fee Code', fieldType: 'text', disableField: this.state.postToLandLink === false, value: this.state.chargeFeeCode },
                        { id: 'chargeChargeCode', label: 'Charge Code', fieldType: 'text', disableField: this.state.postToLandLink === false, value: this.state.chargeChargeCode }]
        },
        {
          formGroups: [{ id: 'surchargeFeeCode', label: 'Surcharge Fee Code', fieldType: 'text',  disableField: this.state.postToLandLink === false, value: this.state.surchargeFeeCode },
                       { id: 'surchargeChargeCode', label: 'Surcharge Charge Code', fieldType: 'text',  disableField: this.state.postToLandLink === false, value: this.state.surchargeChargeCode }]
        },
        {
          heading: 'Preauthorization Max',
          formGroups: [{ id: 'maxAmount', label: 'Max Amount Per Charge ($)', fieldType: 'number', numberType: 'decimal', value: this.state.maxAmount }]
        },
        {
          heading: 'Surcharge',
          formGroups: [{ id: 'applySurcharge', label: 'Apply Surcharge', fieldType: 'select', value: this.state.applySurcharge }]
        },
        {
          formGroups: [{ id: 'surchargeMethod', label: 'Dollars or Percent', fieldType: 'select', disableField: this.state.applySurcharge === false, options: [{ code: 'dollars', description: 'Dollars'}, {code: 'percent', description: 'Percent'}],  value: this.state.surchargeMethod }]
        },
        {
          formGroups: [{ id: 'surcharge', label: 'Surcharge ($)', fieldType: 'number', numberType: 'decimal', hideRow: this.state.surchargeMethod !== 'dollars', disableField: this.state.applySurcharge === false, value: this.state.surcharge }]
        },
        {
          formGroups: [{ id: 'surchargePercent', label: 'Surcharge (%)', fieldType: 'number', numberType: 'decimal', hideRow: this.state.surchargeMethod !== 'percent', disableField: this.state.applySurcharge === false, value: this.state.surchargePercent}]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(CreditCardExtra);