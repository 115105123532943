import React, { Component } from 'react';
import { Card } from 'react-bootstrap'
import moment from 'moment';
import { paperPlaneIcon } from '../../img/font_awesome/font_awesome';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";
import axios from "axios";
import configData from "../../config/config.json";

const mapStateToProps = state => {
    return {
        language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class LoginPageLatestNews extends Component {
    state = {
        paragraphs: []
    }

    componentDidMount() {
        axios.get(configData.LANDSHARK_SERVER_URL + "auth/loginPageLatestNews", { withCredentials: true })
            .then(
                (response) => {
                    const content = response.data.content;
                    let paragraphs = content.paragraphs;
                    this.setState({
                        paragraphs
                    });
                },
                (error) => {
                    //   handleError(error, this.props.history);
                }
            );
    }

    render() {
        var now = moment();
        var date = now.format("MMMM D, yyyy");

        if (!this.state.paragraphs || this.state.paragraphs.length === 0) {
            return <></>
        }

        let newsItems = [];
        this.state.paragraphs.forEach((newsItem, index) => {
            newsItems.push(<li><span style={{ fontWeight: 'bold' }}>{newsItem.heading}</span>&nbsp; {newsItem.paragraphContent}</li>)
        })

        return (
            <div style={{ marginLeft: 10, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }}>
                <Card style={{ maxHeight: 150 }}>
                    <Card.Header>
                        {paperPlaneIcon}&nbsp;&nbsp;{translations[this.props.language].latest_news}&nbsp;-&nbsp;{date}
                    </Card.Header>
                    <Card.Body style={{ maxHeight: 150, paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                        <Card.Text style={{ maxHeight: 150, paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                            <ul className='list-card' style={{ maxHeight: 100, listStyle: 'none', paddingLeft: 0, marginLeft: 20 }}  >    
                                {newsItems}
                            </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default connect(mapStateToProps)(LoginPageLatestNews);
