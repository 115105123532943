import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap'

import PageBanner from "../common/page/PageBanner";
import CountyNotes from "../common/page/CountyNotes";
import { connect } from "react-redux";
import { setSelectedControlPanelType, UserLockout2ControlPanelKey } from "../../redux/actions/controlPanelActions";
import { LOADING_STATUES } from "../../components/constants/constants";
import Confirmation from '../common/modal/Confirmation'
import configData from "../../config/config.json";
import axios from "axios";
import { handleError } from '../../redux/actions/commonStuff';
import Alert from '../common/modal/Alert';
import Loading from '../common/modal/Loading';

const componentName = UserLockout2ControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

const mapStateToProps = (state) => {
  return {
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class UserLockout2 extends Component {

  state = {
    showConfirmation: false,
    showAlert:false,
    alertMessage:'',
    loadingStatus: ''
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  handleClick = (event) => {
    event.preventDefault();

    this.setState({
      showConfirmation: true
    });

  }

  handleSubmit = () => {
    if (this.showLoading() === true) {
      axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/endUserSessions", { withCredentials: true })
      .then(
        (response) => {
          this.setState( {
            loadingStatus : "",
            showAlert: true,
            alertMessage: response.data.message
          });
        },
        (error) => {
          handleError(error, this.props.history);
        }
      );
		}
  }

	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false
    });
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
  }

  render() {
    let formTemplateMarginLeft = 60
    if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    return (
      <>
        <div style={{ marginLeft: formTemplateMarginLeft, marginRight: 20, paddingBottom: 4, marginTop: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }}>
          <CountyNotes componentName={this.props.componentName} />
          <PageBanner pageHeading='Lockout - Step 2' />
          <Container fluid="true" style={{ paddingTop: 30, paddingLeft: 50, paddingBottom: 50, marginTop: 10, marginLeft: 4, marginRight: 4, border: '3px solid darkGray', borderRadius: '5px' }}>
            <Row>&nbsp;</Row>
            <Row><button onClick={this.handleClick} className="btn btn-primary">End User Sessions</button></Row>
          </Container>
        </div>
        <Confirmation show={this.state.showConfirmation} cancelConfirmation={this.handleCloseConfirmation} proceedConfirmation={this.handleSubmit} question='You are about to end all user sessions. Continue?' />
      	<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    )
  }
}
export default connect(mapStateToProps, matchDispatchToProps)(UserLockout2);