import React from 'react';
import { Carousel } from 'react-bootstrap'
import axios from "axios";
import configData from "../../config/config.json";

class LoginPageSlides extends React.Component {

	state = {
		numberOfSlides: 0
	}

	componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "auth/loginPageSlides", { withCredentials: true })
			.then(
				(response) => {
					this.setState({
						numberOfSlides: response.data.content.numberOfSlides
					});
				},
				(error) => {
					//   handleError(error, this.props.history);
				}
			);
	}

	render() {
		const slide1 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/one.jpg";
		const slide2 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/two.jpg";
		const slide3 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/three.jpg";
		const slide4 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/four.jpg";
		const slide5 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/five.jpg";
		const slide6 = configData.BROWSER_ROUTER_BASENAME + "/counties/" +  configData.COUNTY_DIRECTORY + "/six.jpg";

		if (!this.state.numberOfSlides || this.state.numberOfSlides <= 1) {
			return (
				<Carousel style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} autoPlay={false} controls={false} indicators={false} >
					{
						this.state.numberOfSlides === 1 &&
						<Carousel.Item>
							<img border="0" src={slide1} alt="ONE" class="img-fluid" />
						</Carousel.Item>
					}
				</Carousel>
			)
		}

		return (
			<>
				<Carousel style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"}}  autoPlay={true} controls={true} indicators={true}  interval={30000} >
					{
						this.state.numberOfSlides >= 1 &&
						<Carousel.Item>
							<img border="0" src={slide1} alt="ONE" class="img-fluid" />
						</Carousel.Item>
					}
					{
						this.state.numberOfSlides >= 2 &&
						<Carousel.Item>
							<img border="0" src={slide2} alt="TWO" class="img-fluid" />
						</Carousel.Item>
					}
					{
						this.state.numberOfSlides >= 3 &&
						<Carousel.Item>
							<img border="0" src={slide3} alt="THREE" class="img-fluid" />
						</Carousel.Item>
					}
					{
						this.state.numberOfSlides >= 4 &&
						<Carousel.Item>
							<img border="0" src={slide4} alt="FOUR" class="img-fluid" />
						</Carousel.Item>
					}
					{
						this.state.numberOfSlides >= 5 &&
						<Carousel.Item>
							<img border="0" src={slide5} alt="FIVE" class="img-fluid" />
						</Carousel.Item>
					}
					{
						this.state.numberOfSlides >= 6 &&
						<Carousel.Item>
							<img border="0" src={slide6} alt="SIX" class="img-fluid" />
						</Carousel.Item>
					}
				</Carousel>
			</>
		)
	}
}

export default LoginPageSlides;