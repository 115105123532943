import React, {Component} from 'react';
import { Modal, Button, Table } from 'react-bootstrap'
import axios from "axios";
import configData from "../../config/config.json";
import AjaxResult from "../../utils/AjaxResult";
import AjaxError from "../../utils/AjaxError";

export default class TaxLinks extends Component {

   state = {
      parcelIds: [],
      parcelIdLinks: [],
      header: 'Links To Tax System'
	 }

   handleCloseClick = () => {
      this.props.complete();
      this.forceUpdate();
   }

   getLink() {
      let request = { 
         id: this.props.docId
      };

      axios.post(configData.LANDSHARK_SERVER_URL + "tax/getLinks", request, { withCredentials: true })
       .then((response) => {
         let result = new AjaxResult(response);
         

         if (!result.isOk()) {
            result.handleError();
         } else {
            let content = result.content();
            if (!content.hasOwnProperty('parcelIds')) {
               this.handleCloseClick();
               alert(result.getErrorMessage());
            } else {
               this.setState( {
                  parcelIds: content.parcelIds,
                  parcelIdLinks: content.parcelIdLinks,
                  header: content.header
               });
            }
         }
      }, (error) => {
           let result = new AjaxError(error);
           result.handleError();
           this.handleCloseClick()
      });
   }

   componentDidMount() {
      if (!this.props.show) return;
      this.getLink();  
   }

   componentDidUpdate = (prevProps, prevState) => {
      // We get here is the IMAGE button is clicked or an internal state change
      // occurs.
      if (!this.props.show) return;
    
      // Reload state if hide->show or showing+dockey changed
      if (!prevProps.show || (this.props.docId !== prevProps.docId)) {
         this.getLink();
      }
   }

   render() {

      let parcelIds = this.state.parcelIds.map((row, index) => {
        let link = this.state.parcelIdLinks[index];
        return <tr>
          <a href={link} target="_blank" rel="noopener noreferrer">{row}</a>
        </tr>
     })

     let parcelIdsTable =
        <Table striped hover size="sm" >
           <thead>
              <tr>
                 Parcel IDs
              </tr>
           </thead>
           <tbody>
               {parcelIds}
           </tbody>
        </Table>

      return (

         <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
       
            <Modal.Header closeButton >
               <Modal.Title style={{color: '#3674AB'}}>{this.state.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black',  height:'50vh', borderBottom: '2px solid black', overflowY: 'scroll'}}>
               {parcelIdsTable}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
            </Modal.Footer>
         </Modal>
      
      )
   }
}