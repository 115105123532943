import React from 'react';
import { connect } from "react-redux";
import { Button, Row, Col, Container } from 'react-bootstrap'

import axios from "axios";

import CurrentUser from './CurrentUser';
import CurrentUserChangePassword from './CurrentUserChangePassword';
import Logout from './Logout';
import CreditCardImmediateChargeWindow from "../creditcard/CreditCardImmediateChargeWindow";
import EscrowRechargeAmountWindow from "../creditcard/EscrowRechargeAmountWindow";
import FlatFeeAddon from "../image/FlatFeeAddon"
import configData from "../../config/config.json";
import AjaxResult from "../../utils/AjaxResult";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"
import Alert from '../common/modal/Alert'

import landshark_logo from '../../img/trimin_landshark_verysmall.png';
import { refreshUsageBalance } from "../../redux/actions/otherLandLinkActions";
import { dollarIcon, imageIcon, redoIcon, nameIcon, warningSmallIcon } from "../../img/font_awesome/font_awesome"
import { backendLogger } from "../../logger.js"

import translations from "../../config/translation/information_main_translations.json"

const mapStateToProps = state => {
	return {
		countyConfigUserLockout: state.countyConfigUserLockoutReducer,
		countyConfigEfile: state.countyConfigEfileReducer,
		countyConfigEscrow: state.countyConfigEscrowReducer,
		countyConfigFlatFeeOverrideUISettings: state.countyConfigFlatFeeOverrideUISettingsReducer,
		appTitle: state.countyConfigCommonReducer.appTitle,
		countyName: state.countyConfigCommonReducer.countyName,
		currentUserId: state.currentUserReducer.userId,
		currentUserName: state.currentUserReducer.username,
		currentUserUsageBalance: state.currentUserReducer.usageBalance,
		paymentMethod: state.currentUserReducer.paymentMethod,
		accountType: state.currentUserReducer.accountType,
		role: state.currentUserReducer.role,
		language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'  
	};
}

const matchDispatchToProps = (dispatch) => {
	return {
		refreshUsageBalance: (userId) => dispatch(refreshUsageBalance(userId))
	}
}

const ALERT_MESSAGE = 'MESSAGE';

class InformationMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			showCurrentUser: false,
			showCurrentUserChangePassword: false,
			showEscrowRechargeAmountWindow: false,
			showLogout: false,
			alertWindow: this.createAlertWindowParams(false),
			flatFeeAddonWindow : { show: false }
		};
	}

    flatFeeAddonWindowCallbackCompleted = () => {
		const userId = { userId: this.props.currentUserId}
		
		this.props.refreshUsageBalance(userId);
        this.flatFeeAddonWindowCallbackCloseClicked();
    }

    flatFeeAddonWindowCallbackCloseClicked = () => {
        let newState = { flatFeeAddonWindow: { show: false }};
        this.setState(newState);
    }

	handleClickAbout = (event) => {
		event.preventDefault();
		this.props.showAbout(true);
	}

	handleClickCurrentUser = (event) => {
		if (event) {
			event.preventDefault();
		}
		this.setState({
			showCurrentUser: true
		});
	}

	handleCloseCurrentUser = () => {
		this.setState({
			showCurrentUser: false
		});
	}

	handleClickCurrentUserChangePassword = () => {
		this.setState({
			showCurrentUserChangePassword: true
		});
	}

	handleCloseCurrentUserChangePassword = () => {
		this.setState({
			showCurrentUserChangePassword: false
		});
	}

	handleClickLogout = (event) => {
		event.preventDefault();
		this.setState({
			showLogout: true
		});
	}

	handleCloseLogout = () => {
		this.setState({
			showLogout: false
		});
	}

	handleEscrowAddMoney = (event) => {
    	event.preventDefault();    
		this.setState({
			showEscrowRechargeAmountWindow: true
		})
	}

	handleEscrowAmountClose = () => {
		this.setState({
			showEscrowRechargeAmountWindow: false
		})
	}

	handleRechargeEscrowAccount = (depositAmount) => {
		this.setState({
			showEscrowRechargeAmountWindow: false
		});

		(async () => {
			await
				axios.post(configData.LANDSHARK_SERVER_URL + "escrowDeposit/initiateDeposit", 
					{amount: depositAmount.deposit}, { withCredentials: true })
					.then((response) => {
						let result = new AjaxResult(response);

						this.setState({
								showCreditCardEscrowRechargeWindow: true,
								authAndCaptureToken: result.content().tokenResults.token
						});
					}, (error) => {
						this.setState({ loadingStatus: "" }, function() {
							AjaxResponseHandler.handleError(error, this.showAlertText, this.props.history);
						}); 
					})
		})();
	}

	handleEscrowPaymentComplete = (transactionResponse) => {
		let request = transactionResponse;
		console.log(request);

		backendLogger.info("Attempting to call escrowDeposit/completeDeposit with transactionResponse: " + JSON.stringify(request));
		axios.post(configData.LANDSHARK_SERVER_URL + "escrowDeposit/completeDeposit", request, { withCredentials: true })
			.then((response) => {
				console.log('notify server of auth and capture response ', response);
				const userId = { userId: this.props.currentUserId}
				this.props.refreshUsageBalance(userId);

				this.setState({
						showCreditCardEscrowRechargeWindow: false,
				})
			}, (error) => {
						console.log("Error adding to escrow");
						this.setState({ loadingStatus: "", showCreditCardEscrowRechargeWindow: false }, function() {
								AjaxResponseHandler.handleError(error, this.showAlertText, this.props.history);
						}); 
			})
	}

	hideEscrowPaymentPage = () => {
		this.setState({
				showCreditCardEscrowRechargeWindow: false
		})
	}
	
	handleFlatFeePurchaseImages = (event) => {
		event.preventDefault();
		this.setState( { flatFeeAddonWindow : { show: true } } );
	}

	handleClickrefreshUsageBalance = (event) => {
		event.preventDefault();
		const userId = { userId: this.props.currentUserId}
		this.props.refreshUsageBalance(userId);
	}

    createAlertWindowParams = (show, message, callback, type) => {
        if (show) {
            return {
                callback: callback,
                message: message,
                show: show,
                type: type ? type : ALERT_MESSAGE
            };
        } else {
            return {
                callback: null,
                message: '',
                show: show,
                type: ALERT_MESSAGE
            };
        }
    }

    addAlert = (state, alertWindowParams) => {
		state.alertWindow = alertWindowParams;
    }
	
    showAlert = (alertWindowParams) => {
		this.setState({
		  alertWindow: alertWindowParams
		})
	  }
	
    showAlertText = (msg) => {
        this.showAlert(this.createAlertWindowParams(true, msg, null, ALERT_MESSAGE));
    }

	hideAlert = () => {
        // After the alert is confirmed, close the alert and pay for the order.
        let callback = this.state.alertWindow.callback;
        let params = this.createAlertWindowParams(false);
		this.setState({
			    alertWindow: params
		    },
            () => { if (callback) callback(); }
        );
	}

	render() {
		return (
			<>
				<Container fluid="true" >
					<Row className='align-items-end'>
						<Col lg={8}>
							<Container style={{marginLeft: 0}}>
								<Row>
									<Col>
										<span style={{ color: '#3674AB', fontSize: 32, fontVariant: 'small-caps', fontWeight: 'bold' }}>{this.props.countyName} {this.props.appTitle}</span>
									</Col>
								</Row>
								<Row>
									<Col>
										<span style={{ fontSize: 20, fontWeight: 500, marginTop: 50 }}>&nbsp;powered by&nbsp;</span>
										<img border="0" src={landshark_logo} alt="Trimin Landshark" style={{ paddingBottom: 15 }} />
									</Col>
								</Row>
								{
									this.props.countyConfigUserLockout && this.props.countyConfigUserLockout.lockoutInPlace === true &&
									<Row>
										<Col>
											<div id="arrow" className="circle bounce" data-target="#introText">
												<span className="bounce" > {warningSmallIcon} <span style={{ position: 'relative', color: 'red', fontSize: 20, fontWeight: 'bold' }}>{this.props.countyConfigUserLockout.messageHeadline}</span></span>
											</div>
										</Col>
									</Row>
								}
							</Container>
						</Col>
						<Col lg={4} >
							<table className='float-right' style={{ marginRight: 20, fontSize: 16, fontWeight: 500 }}>
								<tbody>
									<tr>
										<td>
											<div style={{ color: 'gray' }}>
												<span className="align-bottom">{translations[this.props.language].hello}, {this.props.currentUserName}</span> 
												{ 
													this.props.role !== 'GUEST' &&
													<Button variant="primary" className='btn-sm' onClick={this.handleClickCurrentUser} style={{ marginLeft: 5 }}>{nameIcon}</Button> 
												}
												<Button variant="danger" className='btn-sm' style={{ marginLeft: 5}} onClick={this.handleClickLogout}>{translations[this.props.language].logout}</Button> 
											</div>
										</td>
									</tr>
									<hr style={{ backgroundColor: '#000', marginTop: 6, marginBottom: 6, marginLeft: 0, marginRight: 0}}></hr>
									<tr>
										{
											this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY' && this.props.role !== 'GUEST' && this.props.accountType === 'A_LA_CARTE' && this.props.paymentMethod === 'ESCROW' &&
										 	this.props.countyConfigEscrow.reloadByCreditCard &&
											<td>
												<span className="align-bottom" style={{ color: 'gray' }}>{this.props.currentUserUsageBalance}
													<Button variant="primary" className='btn-sm' onClick={this.handleClickrefreshUsageBalance} style={{ marginLeft: 5, marginBottom: 6 }}>{redoIcon}</Button>
													{
														this.props.role == 'ACCOUNT' &&
														<Button variant="primary" className='btn-sm' onClick={this.handleEscrowAddMoney} style={{ marginLeft: 5, marginBottom: 6 }}>Add {dollarIcon} </Button>
													}
												</span>
											</td>
										}
										{
											this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY' && this.props.role !== 'GUEST' && this.props.accountType === 'A_LA_CARTE' && this.props.paymentMethod === 'ESCROW' &&
											!this.props.countyConfigEscrow.reloadByCreditCard &&
											<td>
												<span className="align-bottom" style={{ color: 'gray' }}>{this.props.currentUserUsageBalance}
													<Button variant="primary" className='btn-sm' onClick={this.handleClickrefreshUsageBalance} style={{ marginLeft: 5, marginBottom: 6 }}>{redoIcon}</Button>
												</span>
											</td>
										}
										{
											this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY' && this.props.role !== 'GUEST' && this.props.accountType === 'A_LA_CARTE' && this.props.paymentMethod === 'INVOICE' &&
											<td>
												<span className="align-bottom" style={{ color: 'gray' }}>{this.props.currentUserUsageBalance} </span>
											</td>
										}
										{
											this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY' && this.props.role !== 'GUEST' && this.props.accountType === 'FLAT_FEE' &&
											<td>
												<span className="align-bottom" style={{ color: 'gray' }}>{this.props.currentUserUsageBalance}
													<Button variant="primary" className='btn-sm' onClick={this.handleClickrefreshUsageBalance} style={{ marginLeft: 5, marginBottom: 6 }}>{redoIcon}</Button> 
													{
														this.props.role == 'ACCOUNT' && this.props.countyConfigEscrow.reloadByCreditCard &&
														<>
															<Button variant="primary" className='btn-sm' onClick={this.handleEscrowAddMoney} style={{ marginLeft: 5, marginBottom: 6 }}>Add {dollarIcon}</Button> 
														</>
													}
													{
														this.props.role == 'ACCOUNT' && this.props.countyConfigFlatFeeOverrideUISettings.accountAdminReload &&
														<>
															<Button variant="primary" className='btn-sm' onClick={this.handleFlatFeePurchaseImages} style={{ marginLeft: 5, marginBottom: 6 }}>Add {imageIcon}</Button> 
														</>
													}

												</span>
											</td>
										}
										{
											this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY' && this.props.role !== 'GUEST' && this.props.accountType === 'A_LA_CARTE' && this.props.paymentMethod === 'CREDIT_CARD' &&
											<td>
												<span className="align-bottom" style={{ color: 'gray' }}>{this.props.currentUserUsageBalance} </span>
											</td>
										}
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				</Container>
                <Alert show={this.state.alertWindow.show} hideAlert={this.hideAlert} message={this.state.alertWindow.message} type={this.state.alertWindow.type} />
				<CurrentUser show={this.state.showCurrentUser} openCurrentUserChangePassword={this.handleClickCurrentUserChangePassword} closeCurrentUser={this.handleCloseCurrentUser} history={this.props.history}/>
				<CurrentUserChangePassword show={this.state.showCurrentUserChangePassword} openCurrentUser={this.handleClickCurrentUser} closeCurrentUserChangePassword={this.handleCloseCurrentUserChangePassword} history={this.props.history}/>
				<CreditCardImmediateChargeWindow show={this.state.showCreditCardEscrowRechargeWindow} token={this.state.authAndCaptureToken}
                    cancelPayment={this.hideEscrowPaymentPage} makePayment={this.handleEscrowPaymentComplete} title='Escrow Deposit - Credit Card Authorization' />
				<EscrowRechargeAmountWindow show={this.state.showEscrowRechargeAmountWindow} buyClicked={this.handleRechargeEscrowAccount} closeClicked={this.handleEscrowAmountClose} />
                <FlatFeeAddon
                    show={this.state.flatFeeAddonWindow.show}
                    completed={this.flatFeeAddonWindowCallbackCompleted}
                    closeClicked={this.flatFeeAddonWindowCallbackCloseClicked} />
				<Logout show={this.state.showLogout} closeLogout={this.handleCloseLogout} history={this.props.history} />
			</>
		)
	}
}

export default connect(mapStateToProps, matchDispatchToProps)(InformationMain);