import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, FlatFeeOverrideControlPanelKey  } from "../../redux/actions/controlPanelActions";
import { validateNumberOfImagesCostPair } from "../../utils/CodeValueValidation";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = FlatFeeOverrideControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class FlatFeeOverride extends Component {

  state = {
    countyAdminReload: false,
    accountAdminReload: false,
    rates: [
      {
        option: false,
        unlimitedNumberOfPages: false,
        numberOfPages: "",
        cost: ""
      },
      {
        option: false,
        unlimitedNumberOfPages: false,
        numberOfPages: "",
        cost: ""
      },
      {
        option: false,
        unlimitedNumberOfPages: false,
        numberOfPages: "",
        cost: ""
      },
      {
        option: false,
        unlimitedNumberOfPages: false,
        numberOfPages: "",
        cost: ""
      },
      {
         option: false,
        unlimitedNumberOfPages: false,
        numberOfPages: "",
        cost: ""
      }
    ],
    status: "",
    message: "",
    errors: {}
  }

  validateUnimited = () => {
    let errors = {};
    let unlimitedRowNumber = -1;
    for (const index of [0, 1, 2, 3, 4].keys()) {
      let currentRowSelected = (this.state.rates[index].option === true || this.state.rates[index].option === 'true');
      let currentRowUnlimited = currentRowSelected && (this.state.rates[index].unlimitedNumberOfPages === true || this.state.rates[index].unlimitedNumberOfPages === 'true');
      if (unlimitedRowNumber > -1 && currentRowSelected === true) {
        errors['unlimitedNumberOfPages' + unlimitedRowNumber] = "Unlimited should be last.";
        this.setState({ errors: errors });
        return false;
      } else if (currentRowUnlimited === true) {
        unlimitedRowNumber = index;
      }
    }

    return true;
  }
  
  validateRatePairs = () => {
    let errors = {};
    for (const index of [0, 1, 2, 3, 4].keys()) {
      let currentRowSelected = (this.state.rates[index].option === true || this.state.rates[index].option === 'true');
      if (currentRowSelected) {
        let formIsValid = validateNumberOfImagesCostPair(errors, this.state.rates[index].unlimitedNumberOfPages, this.state.rates[index].numberOfPages, 'numberOfPages' + index, this.state.rates[index].cost, 'cost' + index);
        if (!formIsValid) {
          this.setState({ errors: errors });
          return false;
        }
      }
    }
    return true;
  }

  handleValidation = () => {
    if (!this.validateUnimited()) {
      return;
    }
 
    if (!this.validateRatePairs()) {
      return;
    }
   
    this.setState({ errors: {} });
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.handleValidation()) {
      return;
    }

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/flatFeeOverride", this.state, { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState({
          status: LOADING_STATUES.SUCCESS,
          countyAdminReload: content.countyAdminReload,
          accountAdminReload: content.accountAdminReload,
          rates: content.rates
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );

  }

  handleChange = (event) => {
    // this.setState({
    //   [event.target.id]: event.target.value ? event.target.value : 0
    // })
    const targetId = event.target.id;
    if (targetId.includes("option")) {
      const index = targetId.replace("option", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.option = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates,
        errors: {}
      });
    } else if (targetId.includes("unlimitedNumberOfPages")) {
      const index = targetId.replace("unlimitedNumberOfPages", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.unlimitedNumberOfPages = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates,
        errors: {}
      });
    } else if (targetId.includes("numberOfPages")) {
      const index = targetId.replace("numberOfPages", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.numberOfPages = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates,
        errors: {}
      });
    } else if (targetId.includes("cost")) {
      const index = targetId.replace("cost", "");
      let rates = [...this.state.rates];
      let rate = { ...rates[index] };
      rate.cost = event.target.value;
      rates[index] = rate;
      this.setState({ 
        status: "",
        rates,
        errors: {}
      });
    } else {
      this.setState({
        status: "",
        [event.target.id]: event.target.value ? event.target.value : 0,
        errors: {}
      })
    }
  }

	componentDidMount() {
    this.props.setSelectedControlPanelType();
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/flatFeeOverride", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          status : "",
          countyAdminReload: content.countyAdminReload,
          accountAdminReload: content.accountAdminReload,
          rates: content.rates
        });
       
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  createRateRow = index => {
    return (
      {
        formGroups: [
        { id: 'option' + index, label: 'Option ' + (index+1), fieldType: 'select', 
          disableField: this.state.accountAdminReload === 'false' || this.state.accountAdminReload === false, 
          value: this.state.rates[index] && this.state.rates[index].option ? this.state.rates[index].option : false, 
          error: this.state.errors['option' + index] 
        },
        {
          id: 'unlimitedNumberOfPages' + index, label: 'Unlimited Images', fieldType: 'select',
          disableField: this.state.accountAdminReload === 'false' || this.state.accountAdminReload === false || this.state.rates[index].option === 'false' || this.state.rates[index].option === false,
          value: this.state.rates[index] && this.state.rates[index].unlimitedNumberOfPages ? this.state.rates[index].unlimitedNumberOfPages : false, 
          error: this.state.errors['unlimitedNumberOfPages' + index]
        },
        {
          id: 'numberOfPages' + index, label: 'Number of images', fieldType: 'number',
          disableField: this.state.accountAdminReload === 'false' || this.state.accountAdminReload === false || this.state.rates[index].option === 'false' || this.state.rates[index].option === false || this.state.rates[index].unlimitedNumberOfPages === 'true' || this.state.rates[index].unlimitedNumberOfPages === true,
          value: this.state.rates[index] ? this.state.rates[index].numberOfPages : "", 
          style: { maxWidth: 155 }, 
          error: this.state.errors['numberOfPages' + index]
        },
        {
          id: 'cost' + index, label: 'Cost ($)', fieldType: 'number', numberType: 'decimal',
          disableField: this.state.accountAdminReload === 'false' || this.state.accountAdminReload === false || this.state.rates[index].option === 'false' || this.state.rates[index].option === false,
          value: this.state.rates[index] ? this.state.rates[index].cost : "", 
          style: { maxWidth: 155 }, 
          error: this.state.errors['cost' + index]
        }]
      }
    )
  }
 
  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Flat Fee Usage Override",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'countyAdminReload', label: 'Reload by County Admin', fieldType: 'select', value: this.state.countyAdminReload }]
        },
        {
          formGroups: [{ id: 'accountAdminReload', label: 'Reload by Account Admin', fieldType: 'select', value: this.state.accountAdminReload }]
        },
        this.createRateRow(0),
        this.createRateRow(1),
        this.createRateRow(2),
        this.createRateRow(3),
        this.createRateRow(4)
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(FlatFeeOverride);