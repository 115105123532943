import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faSquare, faCheckCircle, faCopyright, faCreditCard, faFileCode, faIdCard, faUser, faBookmark, faRegistered, faFileAlt, faAddressBook } from '@fortawesome/free-regular-svg-icons'
import '../../landshark.css';

export const checkIconGreen = <FontAwesomeIcon icon={faCheckCircle} size="2x" style={{ color: 'green' }} /> 

export const checkIcon = <FontAwesomeIcon icon={faCheckCircle} size="2x" /> 

export const registeredLandIcon = <FontAwesomeIcon icon={faRegistered} className="side-navlink-icon" size="1x"  /> 
export const registeredLandActiveIcon = <FontAwesomeIcon icon={faRegistered} className="side-navlink-icon-active" size="1x"  /> 

export const nameIcon = <FontAwesomeIcon icon={faUser} className="side-navlink-icon" size="1x"  /> 
export const nameActiveIcon = <FontAwesomeIcon icon={faUser} className="side-navlink-icon-active" size="1x"  /> 

export const fileIcon = <FontAwesomeIcon icon={faFileAlt}  size="1x" /> 
export const fileActiveIcon = <FontAwesomeIcon icon={faFileAlt} className="side-navlink-icon-active" size="1x" /> 

export const fileCodeIcon = <FontAwesomeIcon icon={faFileCode}  size="1x" /> 

export const copyrightIcon = <FontAwesomeIcon icon={faCopyright}  size="sm" /> 

export const bookmarkIcon = <FontAwesomeIcon icon={faBookmark}  size="1x" />

export const userIcon = <FontAwesomeIcon icon={faUser} className="side-navlink-icon" size="1x"  /> 
export const userIconBlack = <FontAwesomeIcon icon={faUser} className="side-navlink-icon" size="1x" style={{ color: 'black' }} /> 
export const userActiveIcon = <FontAwesomeIcon icon={faUser} className="side-navlink-icon-active" size="1x"  /> 

export const accountIcon = <FontAwesomeIcon icon={faIdCard} className="side-navlink-icon" size="1x"  /> 
export const accountIconBlack = <FontAwesomeIcon icon={faIdCard} className="side-navlink-icon" size="1x" style={{ color: 'black' }} /> 
export const accountActiveIcon = <FontAwesomeIcon icon={faIdCard} className="side-navlink-icon-active" size="1x"  /> 

export const creditCardIcon = <FontAwesomeIcon icon={faCreditCard} className="side-navlink-icon" size="1x"  /> 
export const creditCardActiveIcon = <FontAwesomeIcon icon={faCreditCard} className="side-navlink-icon-active" size="1x"  /> 

export const grantorGranteeBookIcon = <FontAwesomeIcon icon={faAddressBook} className="side-navlink-icon" size="1x"  /> 
export const grantorGranteeBookActiveIcon = <FontAwesomeIcon icon={faAddressBook} className="side-navlink-icon-active" size="1x"  /> 

export const platIcon = <FontAwesomeIcon icon={faSquare} className="side-navlink-icon" size="1x"  /> 
export const platActiveIcon = <FontAwesomeIcon icon={faSquare} className="side-navlink-icon-active" size="1x"  /> 

export const questionIcon = <FontAwesomeIcon icon={faQuestionCircle}  size="2x" /> 
