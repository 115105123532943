import React, {Component} from 'react';
import { Card } from 'react-bootstrap'
import { paperPlaneIcon, expandIcon } from '../../img/font_awesome/font_awesome';
import LatestNewsBig from './LatestNewsBig';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class LatestNews extends Component {
    
    state = {
        showModal: false
    }

    handleClick = () => {
        this.setState({
            showModal : true
        })
    }

    handleClose = () => {
        this.setState({
            showModal : false
        })
    }

    render() {
        let expandSpan = <></>
        if (this.props.newsItems) {
            expandSpan = <span onClick={this.handleClick}  style={{ cursor: "pointer", float: 'right' }}>{expandIcon}</span>
        }

        return (
            <>
                <Card>
                    <Card.Header>
                        {paperPlaneIcon}&nbsp;&nbsp;{translations[this.props.language].latest_news}{expandSpan}
                    </Card.Header>
                    <Card.Body style={{paddingTop:0, paddingLeft: 0, paddingRight:0, paddingBottom: 0}}>
                        <ul className='list-card' style={{ listStyle: 'none', paddingLeft: 0, marginLeft: 20 }}  >
                            <li>&nbsp;</li>
                            {
                                this.props.newsItems.map((newsItem, index) => {
                                    return <li key={index}><span style={{ fontWeight: 'bold' }}>{newsItem.heading}</span>&nbsp; {newsItem.paragraphContent}</li>
                                })
                            }
                        </ul>
                    </Card.Body>
                </Card>
                <LatestNewsBig show={this.state.showModal} newsItems={this.props.newsItems} handleClose={this.handleClose} />
            </>
        )
    }
}

export default connect(mapStateToProps)(LatestNews);
