import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, BillingScheduleControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";
import Alert from '../common/modal/Alert';

const componentName = BillingScheduleControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class BillingSchedule extends Component {
  state = {
    automaticBilling: false,
    renewalDay: "1",
    renewalTime: "0:00",
    lastRanMonthlyRenewalJobPhase1: '',
    lastRanMonthlyRenewalJobPhase2: '',
		showAlert: false,
		alertMessage: "",
    status: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
    
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/billingSchedule", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: "SUCCESS"
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );

  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  getStateFromContent = (content) => {
    return {
      automaticBilling: content.automaticBilling,
      renewalDay: content.renewalDay,
      renewalTime: content.renewalTime,
      lastRanMonthlyRenewalJobPhase1: content.lastRanMonthlyRenewalJobPhase1,
      lastRanMonthlyRenewalJobPhase2: content.lastRanMonthlyRenewalJobPhase2,
      status : ""
    };
  }

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

  handleRunBilling = (event) => {
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/runBilling", this.state, { withCredentials: true })
    .then(
      (response) => {
        let newState = this.getStateFromContent(response.data.content);
        this.setState(newState, this.showAlert(response.data.message));
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

	componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/billingSchedule", { withCredentials: true })
    .then(
      (response) => {
        let newState = this.getStateFromContent(response.data.content);
        this.setState(newState);
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }
  
  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Schedule" ,
      onChange: this.handleChange,
      onSeconaryClick: this.handleRunBilling,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      secondaryButtonLabel: "Run Billing",
      formGroupRows: [
        {
          formGroups: [{ id: 'automaticBilling', label: 'Automatic Billing', fieldType: 'select', value: this.state.automaticBilling }]
        },
        {
          formGroups: [{ id: 'renewalDay', label: 'Renewal Day of the Month', fieldType: 'select', value: this.state.renewalDay, 
            options : [{code: "1", description: "1"}, {code: "2", description: "2"}, {code: "3", description: "3"}, 
                       {code: "4", description: "4"}, {code: "5", description: "5"}, {code: "6", description: "6"}, 
                       {code: "7", description: "7"}, {code: "8", description: "8"}, {code: "9", description: "9"}, 
                       {code: "10", description: "10"}, {code: "11", description: "11"}, {code: "12", description: "12"}, 
                       {code: "13", description: "13"}, {code: "14", description: "14"}, {code: "15", description: "15"}, 
                       {code: "16", description: "16"}, {code: "17", description: "17"}, {code: "18", description: "18"}, 
                       {code: "19", description: "19"}, {code: "20", description: "20"}, {code: "21", description: "21"}, 
                       {code: "22", description: "22"}, {code: "23", description: "23"}, {code: "24", description: "24"}, 
                       {code: "25", description: "25"}, {code: "26", description: "26"}, {code: "27", description: "27"}, 
                       {code: "28", description: "28"}]}]
        },
        {
          formGroups: [{ id: 'renewalTime', label: 'Time of Day to Renew', fieldType: 'text', value: this.state.renewalTime }]
        },
        {
          formGroups: [
            { id: 'lastRanMonthlyRenewalJobPhase1', label: 'Last Ran Phase 1 (mark phase)', 
              fieldType: 'text', value: this.state.lastRanMonthlyRenewalJobPhase1 },
            { id: 'lastRanMonthlyRenewalJobPhase2', label: 'Last Ran Phase 2 (charge phase)', 
              fieldType: 'text', value: this.state.lastRanMonthlyRenewalJobPhase2, disabled: true }
          ]
        }
      ]
    }

    return (
      <>
        <FormTemplate {...formProps} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(BillingSchedule);