const initialState = false;

const searchSequenceRewindReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REWIND_SEARCH_SEQUENCE':
      return true;
    case 'RESET_REWIND_SEARCH_SEQUENCE':
      return false;
    default:
      break;
  }
  return state;
}

export default searchSequenceRewindReducer;