import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import SideNavigationLabel from "../side/SideNavigationLabel";
import SideNavigationToggle from "../side/SideNavigationToggle";
import SideNavigationNavLink from "../side/SideNavigationNavLink";

import UserReport from "./UserReport";
import AccountReport from "./AccountReport";
import StatementReport from "./StatementReport";
import AccountTypeReport from "./AccountTypeReport";

import CommonTop from "../../components/top/CommonTop";

import { ReportAccountTypeKey, ReportAccountAuditKey, ReportUserAuditKey, ReportStatementKey } from "../../redux/actions/reportActions";

import { accountTypeIcon, accountTypeActiveIcon, accountIcon, accountActiveIcon, statementIcon, statementActiveIcon,  userIcon, userActiveIcon, fileIcon } from "../../img/font_awesome/font_awesome";


const mapStateToProps = state => {
	return {
		countyConfigReports: state.countyConfigReportsReducer,
		selectedReportType: state.reportTypeReducer.selectedReportType,
		role: state.currentUserReducer.role,
	};
}

class Reports extends React.Component {

	render() {

		let statementReportsProps = {
			image: fileIcon,
			name: "Statement:"
		}

		let statementReportProps = {
			id: "statementReportNavLink",
			link: "/reports/StatementReport",
			selected: this.props.selectedReportType === ReportStatementKey,
			image: this.props.selectedReportType === ReportStatementKey ? statementActiveIcon: statementIcon,
			name: "Statement"
		}

		let auditReportsProps = {
			image: fileIcon,
			name: "Audit Details:"
		}

		let accountReportProps = {
			id: "accountReportNavLink",
			link: "/reports/AccountReport",
			selected: this.props.selectedReportType === ReportAccountAuditKey,
			image: this.props.selectedReportType === ReportAccountAuditKey ? accountActiveIcon: accountIcon,
			name: "Account"
		}

		let userReportProps = {
			id: "userReportNavLink",
			link: "/reports/UserReport",
			selected: this.props.selectedReportType === ReportUserAuditKey,
			image: this.props.selectedReportType === ReportUserAuditKey ? userActiveIcon: userIcon,
			name: "User"
		}
		
		let summaryReportsProps = {
			image: fileIcon,
			name: "Transaction Summary:"
		}

		let accountTypeReportProps = {
			id: "accountTypeReportNavLink",
			link: "/reports/AccountTypeReport",
			selected: this.props.selectedReportType === ReportAccountTypeKey,
			image: this.props.selectedReportType === ReportAccountTypeKey ? accountTypeActiveIcon: accountTypeIcon,
			name: "Account Type"
		}

		let defaultRoute = <Route path="/reports" component={StatementReport} />;
		if ("AccountReport" === this.props.selectedReportType) {
			defaultRoute = <Route path="/reports" component={AccountReport} />;
		} else if ("AccountTypeReport" === this.props.selectedReportType) {
			defaultRoute = <Route path="/reports" component={AccountTypeReport} />;
		} else if("UserReport" === this.props.selectedReportType) {
			defaultRoute = <Route path="/reports" component={UserReport} />;
		}

		return (
			
			<div>
				<CommonTop history={this.props.history} />
				<div style={{ float: "left", minWidth: 38, maxWidth: 280 }}>
					<SideNavigationToggle />
					<Navbar bg="light" style={{ padding: 0, display: "block" }}>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Nav className="flex-column" style={{ width: '100%', float: "left", marginLeft: 2 }}>	
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...auditReportsProps} /></Nav.Link>
							{
								this.props.countyConfigReports.auditAccountsReport && 
								<SideNavigationNavLink {...accountReportProps} />
							}
							
							{
								this.props.countyConfigReports.auditUsersReport && 
								<SideNavigationNavLink {...userReportProps} />
							}
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...statementReportsProps} /></Nav.Link>
							{
								this.props.countyConfigReports.statementReport && 
								<SideNavigationNavLink {...statementReportProps} />
							}
							{
								this.props.countyConfigReports.accountTypeReport &&  (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') && 
								<>
								<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...summaryReportsProps} /></Nav.Link>
								<SideNavigationNavLink {...accountTypeReportProps} />
								</>
							}
						</Nav>
					</Navbar>
				</div>
				<div style={{paddingBottom: 4}}>
					<Switch>
						{
							this.props.countyConfigReports.accountTypeReport && 
							<Route path="/reports/AccountTypeReport" component={AccountTypeReport} />
						}
						{
							this.props.countyConfigReports.auditAccountsReport && 
							<Route path="/reports/AccountReport" component={AccountReport} />
						}
						{
							this.props.countyConfigReports.auditUsersReport && 
							<Route path="/reports/UserReport" component={UserReport} />
						}
						{
							this.props.countyConfigReports.statementReport && 
							<Route path="/reports/StatementReport" component={StatementReport} />
						}
						{defaultRoute}
					</Switch>
				</div>
			</div>	
		);
	}
}

export default connect(mapStateToProps)(Reports);