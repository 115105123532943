import React from 'react';

import { Modal, Container, Col, Row } from 'react-bootstrap'
import { spinnerIconWhiteBig } from "../../../img/font_awesome/font_awesome"

export default class Loading extends React.Component {
   render() {
      return (
         <Modal show={this.props.loadingIndicator}  backdrop="static" keyboard={false} size="sm" aria-labelledby="contained-modal-title-vcenter"  centered >
            <Modal.Body>
                  <Container fluid="true" style={{ padding: 20, background: 'darkGray', margin: 0}}>
                  <Row className="justify-content-md-center">
                        <Col className="text-center" style={{  color: 'white',  fontSize: 25}}>
                           {spinnerIconWhiteBig} Loading
                        </Col>
                    </Row>
                  </Container>
            </Modal.Body>
         </Modal>
      )
   }
}