import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'

// Taken from https://codepen.io/robertkirsz/pen/ZvorjB

// Properties:
// - backgroundImage The image URL.
// - bigClassName The class name for the bigger container
// - className The class name for the container
// - dialogClassName The class name governing the dialog size
// - ignorePageClicked function(pageNumber) called when the ignore page button is clicked
// - keepPageClicked function(pageNumber) called when the keep page button is clicked
// - pageNumber The page number
// - tile The window title
// - width, height The CSS size for the container
// - supports child components
class ZoomableImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            bigStyle: {
                backgroundImage: 'url(' + this.props.backgroundImage + ')',
                backgroundPosition: '0% 0%',
                backgroundSize: '300%'
            },
            smallStyle: {
                backgroundImage: 'url(' + this.props.backgroundImage + ')',
                backgroundPosition: '0% 0%',
                backgroundSize: '350%'
            }
        };
    }

    handleBigMouseMove = (event) => {
        const { left, top, width, height } = event.target.getBoundingClientRect();
        const x = (event.pageX - left) / width * 100;
        const y = (event.pageY - top) / height * 100;

        this.setState(prevState => ({
            bigStyle: {
                ...prevState.bigStyle,
                backgroundPosition: `${x}% ${y}%`
            }
        }))
    }

    handleSmallMouseMove = (event) => {
        const { left, top, width, height } = event.target.getBoundingClientRect();
        const x = (event.pageX - left) / width * 100;
        const y = (event.pageY - top) / height * 100;

        // backgroundSize: '500%', 
        this.setState(prevState => ({
            smallStyle: {
                ...prevState.smallStyle,
                backgroundPosition: `${x}% ${y}%`
            }
        }))
    }

    close() {
        this.setState({ modal: false });
    }

    open() {
        this.setState({ modal: true });
    }

    handleCloseClick = (event) => {
        this.close();
    }

    handleDoubleClick = (event) => {
        if (event) {
            event.preventDefault();
        }

        this.open();
    }

    handleClick = (event) => {
        if (event) {
            event.preventDefault();
        }

        this.open();
    }

    handleMouseOut = (event) => {
        this.close();
    }

    handleKeepPageClicked = (event) => {
        this.setState({ modal: false }, function () {
            this.props.keepPageClicked();
        });
    }

    handleIgnorePageClicked = (event) => {
        this.setState({ modal: false }, function () {
            this.props.ignorePageClicked();
        });
    }

    render() {
        return <>
            <figure className={this.props.className} onMouseMove={this.handleSmallMouseMove}
                onClick={this.handleClick}
                style={this.state.smallStyle}>
                {this.props.children}
            </figure>
            {this.state.modal &&
                <Modal show={this.state.modal} dialogClassName={this.props.dialogClassName}
                    onHide={this.handleCloseClick}
                    aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Header closeButton >
                        <Modal.Title style={{ color: '#3674AB' }}>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: '#3674AB', color: 'white', fontSize: 20 }}>
                        <Container>
                            <Row className="justify-content-md-center">
                                <Col className="text-center" style={{ color: 'white', fontSize: 25 }}>
                                    <figure className={this.props.bigClassName} onMouseMove={this.handleBigMouseMove}
                                        style={this.state.bigStyle}>
                                        {this.props.children}
                                    </figure>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row style={{ margin: 5 }} >
                                <Col md='12' className='align-self-end' style={{ textAlign: 'right' }}>
                                    <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleKeepPageClicked}>Keep Page</Button>
                                    <Button variant="secondary" onClick={this.handleIgnorePageClicked}>Ignore Page</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal >
            }
        </>;
    }
}

export default ZoomableImage;
