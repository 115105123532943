import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'
import axios from "axios";
import configData from "../../config/config.json";
import { infoIcon } from '../../img/font_awesome/font_awesome'
import AjaxError from "../../utils/AjaxError";
import AjaxResult from "../../utils/AjaxResult";
import { disableContextMenu } from "../common/function/contextMenuUtils"
import Loading from '../common/modal/Loading';
import { LOADING_STATUES } from "../constants/constants";
import ZoomableImage from '../common/page/ZoomableImage';

const PREFIX = 'PreviewWindow: ';

const ACTION_PAGE_SELECTION_METHOD_CHANGED = 'PAGE_SELECTION_METHOD_CHANGED';
const ACTION_PAGES_CHANGED = 'PAGES_CHANGED';
const ACTION_PAGE_TOGGLED = 'PAGE_TOGGLED';
const ACTION_PAGE_IGNORED = 'IGNORE_PAGE';
const ACTION_PAGE_KEPT = 'KEEP_PAGE';

const CSV_PAGES = 'CSV_PAGES';
const ENTIRE_DOCUMENT = 'ENTIRE_DOCUMENT';
const THUMBNAIL_VIEW_SIZE = 5;

// Number of thumbnails to jump.
const BIG_JUMP = THUMBNAIL_VIEW_SIZE;
const SMALL_JUMP = 1;

const IMAGE_CONTENT_TYPE_PREFIX = 'data:image/jpeg;base64,';

// Class notes.
// - thumbnail related IDs should be of the form xyz_SEQUENCENUMBER.
//
// - downloadClicked(params) handles a download click
// - closeClicked() handles the window closing
// - show: boolean if the window should show
// - docKey: the document key to show, use the empty string for nothing
// - instCode: the instrument code of the document
// - version
class PreviewWindow extends Component {
   constructor(props) {
      super(props);

      this.state = {
         jumpToPage: '',
         loadingStatus: '',
         uiTitle: '',
         uiTitleBeginningOfSentence: ''
      };

      this.version = 0;

      this.updateStateFromProps(true, false);
   }

   addShowLoading = (newState) => {
      newState.loadingStatus = LOADING_STATUES.LOADING;
   }

   showLoading = () => {
      if (this.state.loadingStatus === "") {
         this.setState({
            loadingStatus: LOADING_STATUES.LOADING
         })
         return true;
      }
      return false;
   }

   hideLoading = (message, status) => {
      this.setState({
         loadingStatus: ""
      })
   }

   processImagePreviewSettingsResults = (results) => {
      let newState = {};

      // Though the header is included, there is no need for it here.

      if (results && results.pagesSelected) {
         newState.pageSelectionMethod = results.pagesSelected.pageSelectionMethod;
         newState.selectedAsCsv = results.pagesSelected.selectedAsCsv;

         // State can be updated asynchronously, so
         // I update the thumbnails during setState().
         newState.selectedAsBoolean = results.pagesSelected.selectedAsBoolean;
      } else {
         newState.pageSelectionMethod = ENTIRE_DOCUMENT;
         newState.selectedAsCsv = '';
         newState.selectedAsBoolean = [];
      }

      this.updateState(function (state, props) {
         state.pageSelectionMethod = newState.pageSelectionMethod;
         state.selectedAsCsv = newState.selectedAsCsv;

         // Update the selected flags.  
         // Though I don't think it's likely, I do a sanity check on the page numbers.
         for (let i = 0; i < state.thumbnails.length; ++i) {
            let pageIdx = state.thumbnails[i].page - 1;

            if (pageIdx < newState.selectedAsBoolean.length) {
               state.thumbnails[i].selected = newState.selectedAsBoolean[pageIdx];
            } else {
               state.thumbnails[i].selected = false;
            }
         }

         return state;
      });
   }

   processGetThumbnailsResults = (results, callSetState) => {
      let newState = callSetState ? {} : this.state;
      if (results && results.header) {
         newState.documentNumber = results.header.documentNumber;
         newState.documentType = results.header.documentType;
         newState.pageCount = results.header.pageCount;
         newState.uiTitle = results.header.uiTitle;
         newState.uiTitleBeginningOfSentence = results.header.uiTitleBeginningOfSentence;
      }

      if (results && results.pagesSelected) {
         newState.pageSelectionMethod = results.pagesSelected.pageSelectionMethod;
         newState.selectedAsCsv = results.pagesSelected.selectedAsCsv;
      } else {
         newState.pageSelectionMethod = ENTIRE_DOCUMENT;
         newState.selectedAsCsv = '';
      }

      if (results && results.thumbnails) {
         newState.thumbnails = new Array(results.thumbnails.length);

         for (let i = 0; i < newState.thumbnails.length; ++i) {
            let source = results.thumbnails[i];

            newState.thumbnails[i] = {
               image: IMAGE_CONTENT_TYPE_PREFIX + source.base64,
               page: source.page,
               selected: source.selected
            };
         }
      } else {
         newState.thumbnails = [];
      }

      if (callSetState) {
         this.updateState(newState);
      }
   }

   getThumbnailIdx = (id) => {
      // xyz_SEQNUMBER
      let i = id.lastIndexOf('_');
      let seqNumber = parseInt(id.substring(i + 1));

      return seqNumber - 1;
   }

   // 0 = none, otherwise 1-n.
   getFirstThumbnailShowing = () => {
      if (!this.state.thumbnails.length) return 0;
      if (this.getTotalPageCount() === 0) return 0;

      return this.state.thumbnails[0].page;
   }

   getTotalPageCount = () => {
      return this.state.pageCount;
   }

   // Gets the current view info after applying a thumbnail offset.
   getCurrentViewInfo = (offset) => {
      var i = this.getFirstThumbnailShowing();

      // If there is no thumbnail showing, adding an offset will do
      // nothing for us.
      if (i > 0) i += offset;

      return this.getViewInfo(i);
   }

   // Look at the internals here to understand the returned data.
   // firstThumbnail: 1-pageCount; outside of this the view info indicates
   //   nothing is being viewed.
   getViewInfo = (firstThumbnail) => {
      var view = {
         pageCount: this.getTotalPageCount(),
         thumbnail: {}
      };

      view.thumbnail.fullPages = Math.trunc(view.pageCount / THUMBNAIL_VIEW_SIZE);
      view.thumbnail.partialPageCount = view.pageCount - (view.thumbnail.fullPages * THUMBNAIL_VIEW_SIZE);

      if ((firstThumbnail < 1) || (firstThumbnail > view.pageCount)) {
         view.thumbnail.firstPage = -1;
         view.thumbnail.lastPage = -1;
         view.thumbnail.pageNumber = -1;
         view.thumbnail.pageCount = 0;
      } else {
         view.thumbnail.firstPage = firstThumbnail;
         view.thumbnail.lastPage = Math.min(firstThumbnail + THUMBNAIL_VIEW_SIZE - 1, view.pageCount);
         view.thumbnail.pageNumber = Math.trunc((firstThumbnail - 1) / THUMBNAIL_VIEW_SIZE) + 1;
         view.thumbnail.pageCount = view.thumbnail.lastPage - view.thumbnail.firstPage + 1;
      }

      view.thumbnail.isFirstPage = (view.thumbnail.firstPage == 1);
      view.thumbnail.isFullPage = (view.thumbnail.pageCount === THUMBNAIL_VIEW_SIZE);
      view.thumbnail.isLastPage = (view.thumbnail.lastPage == view.pageCount);
      view.thumbnail.isPartialPage = (view.thumbnail.pageCount > 0) && (view.thumbnail.pageCount !== THUMBNAIL_VIEW_SIZE);

      return view;
   }

   getCanPerformNextJump = (n) => {
      // We don't scroll past the last page.
      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isLastPage) return false;

      // We'll show any page with at least the requested number of thumbs.
      var newView = this.getCurrentViewInfo(n);

      if (newView.thumbnail.pageCount < n) return false;
      return true;
   }

   getCanPerformPrevJump = (n) => {
      // We don't scroll back past the first page.
      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isFirstPage) return false;

      // We'll show any page with thumbnails.
      var newView = this.getCurrentViewInfo(-n);

      return (newView.thumbnail.pageCount > 0);
   }

   getCanPerformFirstJump = () => {
      // We don't scroll back past the first page.
      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isFirstPage) return false;

      // We'll show any page with thumbnails.
      return (currentView.pageCount > 0);
   }

   getCanPerformLastJump = () => {
      // We don't scroll past the last page.
      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isLastPage) return false;

      // We'll show any page with thumbnails.
      return (currentView.pageCount > 0);
   }

   getThumbnailKey = (idx) => {
      return this.props.docKey + '_' + this.state.thumbnails[idx].page;
   }

   handleImageRightClick = (event) => {
      disableContextMenu(event);
   }

   handleChange = (event) => {
      this.updateState({
         [event.target.id]: event.target.value
      })
   }

   handlePageSelectionMethodChange = (event) => {
      if (event) {
         event.preventDefault();
      }

      // Update the new value so the server gets it.
      this.setState({ pageSelectionMethod: event.target.id }, function () {
         this.updatePreviewSettings(ACTION_PAGE_SELECTION_METHOD_CHANGED, 0);
      });
   }

   handleSelectedPagesChanged = (event) => {
      // Since the user is typing here, select the "pages" form of entry.
      this.updateState({
         pageSelectionMethod: CSV_PAGES,
         selectedAsCsv: event.target.value
      });
   }

   handleSelectedPagesBlur = (event) => {
      // Update the new value so the server gets it.
      this.setState({ selectedAsCsv: event.target.value }, function () {
         this.updatePreviewSettings(ACTION_PAGES_CHANGED, 0);
      });
   }

   handleThumbnailKept = (pageNumber) => {
      this.updatePreviewSettings(ACTION_PAGE_KEPT, pageNumber);
   }

   handleThumbnailIgnored = (pageNumber) => {
      this.updatePreviewSettings(ACTION_PAGE_IGNORED, pageNumber);
   }

   handleThumbnailChecked = (event) => {
      if (event) {
         event.preventDefault();
      }

      let idx = this.getThumbnailIdx(event.target.id);
      let page = this.state.thumbnails[idx].page;

      this.updatePreviewSettings(ACTION_PAGE_TOGGLED, page);
   }

   handleThumbnailClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      let idx = this.getThumbnailIdx(event.target.id);
      let page = this.state.thumbnails[idx].page;

      this.updatePreviewSettings(ACTION_PAGE_TOGGLED, page);
   }

   handleDownloadClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      if (this.state.selectedAsCsv.trim().length === 0) {
         alert('There are no pages selected.');
         return;
      }

      this.props.downloadClicked(
         {
            documentKey: this.props.docKey,
            instrumentCode: this.props.instCode,
            pageSelectionMethod: this.state.pageSelectionMethod,
            pagesSelected: this.state.selectedAsCsv,
            pageCount: this.getTotalPageCount()
         });
   }

   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      this.props.closeClicked();
   }

   handleGoClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      let page = parseInt(this.state.jumpToPage, 10);

      if (isNaN(page) || (page < 1) || (page > this.getTotalPageCount())) {
         alert("Document does not have a page " + this.state.jumpToPage);
      } else {
         this.gotoPage(this.state.jumpToPage);
      }
   }

   handleFirstClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isFirstPage) {
         alert("Already at first page.");
      } else {
         this.gotoPage(1);
      }
   }

   handleLastClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isLastPage) {
         alert("Already at last page.");
      } else {
         let page = Math.max(1, currentView.pageCount - THUMBNAIL_VIEW_SIZE + 1);

         this.gotoPage(page);
      }
   }

   handlePrevSmallJumpClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isFirstPage) {
         alert("Already at first page.");
      } else {
         let page = Math.max(1, currentView.thumbnail.firstPage - SMALL_JUMP);

         this.gotoPage(page);
      }
   }

   handlePrevBigJumpClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isFirstPage) {
         alert("Already at first page.");
      } else {
         let page = Math.max(1, currentView.thumbnail.firstPage - BIG_JUMP);

         this.gotoPage(page);
      }
   }

   handleNextSmallJumpClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isLastPage) {
         alert("Already at last page.");
      } else {
         let page = Math.min(currentView.pageCount, currentView.thumbnail.firstPage + SMALL_JUMP);

         this.gotoPage(page);
      }
   }

   handleNextBigJumpClick = (event) => {
      if (event) {
         event.preventDefault();
      }

      var currentView = this.getCurrentViewInfo(0);

      if (currentView.thumbnail.isLastPage) {
         alert("Already at last pages.");
      } else {
         let page = Math.min(currentView.pageCount, currentView.thumbnail.firstPage + BIG_JUMP);

         this.gotoPage(page);
      }
   }

   populateImagePreviewSettings = (request) => {
      request.documentType = this.state.documentType;
      request.documentNumber = this.state.documentNumber;
      request.pageCount = this.state.pageCount;
      request.pageSelectionMethod = this.state.pageSelectionMethod;
      request.selectedAsCsv = this.state.selectedAsCsv;
   }

   gotoPage = (page) => {
      let thumbnailRequest = {};

      this.populateImagePreviewSettings(thumbnailRequest);
      thumbnailRequest.page = page;

      let me = this;
      let newState = { loadingStatus: '' };

      this.setState({ loadingStatus: LOADING_STATUES.LOADING });

      (async () => {
         await
            axios.post(configData.LANDSHARK_SERVER_URL + "imaging/getThumbnails", thumbnailRequest, { withCredentials: true })
               .then((response) => {
                  let result = new AjaxResult(response);

                  if (!result.isOk()) {
                     result.handleError();
                  } else {
                     me.processGetThumbnailsResults(result.content(), true);
                  }

                  me.setState(newState);
               }, (error) => {
                  me.setState(newState);

                  let result = new AjaxError(error);

                  result.handleError();
               })
      })();
   }

   updatePreviewSettings = (action, page) => {
      let request = {
         imagePreviewSettingChange: action,
         imagePreviewSettings: {},
         page: page
      };

      this.populateImagePreviewSettings(request.imagePreviewSettings);

      let me = this;
      let newState = { loadingStatus: '' };

      this.setState({ loadingStatus: LOADING_STATUES.LOADING });

      (async () => {
         await
            axios.post(configData.LANDSHARK_SERVER_URL + "imaging/updateImagePreviewSettings", request, { withCredentials: true })
               .then((response) => {
                  let result = new AjaxResult(response);

                  if (!result.isOk()) {
                     result.handleError();
                  } else {
                     me.processImagePreviewSettingsResults(result.content());
                  }

                  me.setState(newState);
               }, (error) => {
                  me.setState(newState);

                  let result = new AjaxError(error);

                  result.handleError();
               });
      })();
   }

   updateStateFromProps = (createIfMissing, callSetState) => {
      let source = this.props.previewWindow;

      if (!source) {
         if (!createIfMissing) return;

         source = {
            action: '',
            content: null,
            event: {},
            instrumentCode: '',
            show: false,
            version: (this.version < 1) ? 1 : this.version + 1
         }
      }

      if (source.version <= this.version) return;

      console.log(PREFIX + 'updating state from props version ' + source.version + ' state version ' + this.version);
      console.log(PREFIX + 'updating state from source', source);

      let newState = callSetState ? {} : this.state;

      newState.show = source.show;
      this.instrumentCode = source.instrumentCode;  // get from this.props.instCode after refactored
      this.version = source.version;

      if (source.action === 'show') {
         if (callSetState) {
            this.updateState(newState);
         }
      } else {
         newState.event = source.event;

         if (callSetState) {
            this.updateState(newState);
         }

         this.processGetThumbnailsResults(source.content, callSetState);
      }
   }

   updateState = (newState) => {
      this.setState(newState);
   }

   render() {
      console.log(PREFIX + 'rendering state version ' + this.version);

      this.updateStateFromProps(false, false);

      let thumbnails = this.state.thumbnails;
      let totalPageCount = this.getTotalPageCount();

      var modalSize = '';

      if (thumbnails.length == 0) {
         modalSize = '';
      } else if (totalPageCount > 3) {
         modalSize = 'xl';
      } else if (thumbnails.length > 2) {
         modalSize = 'lg';
      }

      let title = this.state.uiTitleBeginningOfSentence + " has " + totalPageCount + " page" + (totalPageCount == 1 ? '.' : 's.');

      return (
         <>
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            <Modal show={this.state.show} onHide={this.handleCloseClick} size={modalSize} aria-labelledby="contained-modal-title-vcenter" centered>
               {
                  totalPageCount === 0 &&
                  <>
                     <Modal.Header closeButton>
                        <Modal.Title style={{ color: '#3674AB' }}>{infoIcon}&nbsp;<span style={{ verticalAlign: 'super' }}>No images on file</span></Modal.Title>
                     </Modal.Header>
                     <Modal.Body style={{ background: '#3674AB', color: 'white', fontSize: 20 }}>
                        <Container>
                           {this.state.uiTitleBeginningOfSentence} does not have any images on file.
                        </Container>
                     </Modal.Body>
                     <Modal.Footer >
                        <Container >
                           <Row>
                              <Col md="12" className='align-self-end' style={{ textAlign: 'right' }}>
                                 <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
                              </Col>
                           </Row>
                        </Container>
                     </Modal.Footer>
                  </>
               }
               {
                  totalPageCount > 0 &&
                  <>
                     <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                     </Modal.Header>
                     <Modal.Body style={{ background: '#408CC7' }}>
                        <Container>
                           <Row className="justify-content-md-center">
                              {
                                 (thumbnails.length >= 1) && thumbnails[0] &&
                                 <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                                    <Container>
                                       <Row>
                                          <Col md="9" style={{ padding: 4, color: thumbnails[0].selected ? 'dimgray' : 'lightgray', fontWeight: 'bold' }}>Page {thumbnails[0].page}</Col>
                                          <Col md="3" style={{ textAlign: 'right', padding: 0 }}>
                                             {/* checkbox is tricky.  This is the only way I could make the checked attribute works.  It's essentially forcing the checkbox to re-render. */}
                                             {
                                                thumbnails.length > 1 && thumbnails[0].selected && // Show the checkbox only if there's more than 1 thumbnails
                                                <Form.Check id='thumbnail_1' type="checkbox" checked={true} onChange={this.handleThumbnailChecked} />
                                             }
                                             {
                                                thumbnails.length > 1 && !thumbnails[0].selected && // Show the checkbox only if there's more than 1 thumbnails
                                                <Form.Check id='thumbnail_1' type="checkbox" checked={false} onChange={this.handleThumbnailChecked} />
                                             }
                                          </Col>
                                       </Row>
                                       <Row >
                                          <Col>
                                             <ZoomableImage key={this.getThumbnailKey(0)}
                                                backgroundImage={thumbnails[0].image}
                                                bigClassName='bigPreview' className='preview' dialogClassName='zoomerDialog'
                                                title={'Page ' + thumbnails[0].page}
                                                keepPageClicked={() => this.handleThumbnailKept(thumbnails[0].page)}
                                                ignorePageClicked={() => this.handleThumbnailIgnored(thumbnails[0].page)}>
                                                <img id='img_1' width='100%' height='100%' alt=''
                                                   onContextMenu={this.handleImageRightClick} className='thumbnail-img'
                                                   src={thumbnails[0].image} ></img>
                                             </ZoomableImage>
                                          </Col>
                                       </Row>
                                    </Container>
                                 </Col>
                              }
                              {
                                 (thumbnails.length >= 2) && thumbnails[1] &&
                                 <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                                    <Container>
                                       <Row>
                                          <Col md="9" style={{ padding: 4, color: thumbnails[1].selected ? 'dimgray' : 'lightgray', fontWeight: 'bold' }}>Page {thumbnails[1].page}</Col>
                                          <Col md="3" style={{ textAlign: 'right', padding: 0 }}>
                                             {
                                                thumbnails[1].selected &&
                                                <Form.Check id='thumbnail_2' type="checkbox" checked={true} onChange={this.handleThumbnailChecked} />
                                             }
                                             {
                                                !thumbnails[1].selected &&
                                                <Form.Check id='thumbnail_2' type="checkbox" checked={false} onChange={this.handleThumbnailChecked} />
                                             }
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <ZoomableImage key={this.getThumbnailKey(1)}
                                                backgroundImage={thumbnails[1].image}
                                                bigClassName='bigPreview' className='preview' dialogClassName='zoomerDialog'
                                                title={'Page ' + thumbnails[1].page}
                                                keepPageClicked={() => this.handleThumbnailKept(thumbnails[1].page)}
                                                ignorePageClicked={() => this.handleThumbnailIgnored(thumbnails[1].page)}>
                                                <img id='img_2' width='100%' height='100%' alt=''
                                                   onContextMenu={this.handleImageRightClick} className='thumbnail-img'
                                                   src={thumbnails[1].image} ></img>
                                             </ZoomableImage>
                                          </Col>
                                       </Row>
                                    </Container>
                                 </Col>
                              }
                              {
                                 (thumbnails.length >= 3) && thumbnails[2] &&
                                 <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                                    {thumbnails[2] &&
                                       <Container>
                                          <Row>
                                             <Col md="9" style={{ padding: 4, color: thumbnails[2].selected ? 'dimgray' : 'lightgray', fontWeight: 'bold' }}>Page {thumbnails[2].page}</Col>
                                             <Col md="3" style={{ textAlign: 'right', padding: 0 }}>
                                                {
                                                   thumbnails[2].selected &&
                                                   <Form.Check id='thumbnail_3' type="checkbox" checked={true} onChange={this.handleThumbnailChecked} />
                                                }
                                                {
                                                   !thumbnails[2].selected &&
                                                   <Form.Check id='thumbnail_3' type="checkbox" checked={false} onChange={this.handleThumbnailChecked} />
                                                }
                                             </Col>
                                          </Row>
                                          <Row>
                                             <Col>
                                                <ZoomableImage key={this.getThumbnailKey(2)}
                                                   backgroundImage={thumbnails[2].image}
                                                   bigClassName='bigPreview' className='preview' dialogClassName='zoomerDialog'
                                                   title={'Page ' + thumbnails[2].page}
                                                   keepPageClicked={() => this.handleThumbnailKept(thumbnails[2].page)}
                                                   ignorePageClicked={() => this.handleThumbnailIgnored(thumbnails[2].page)}>
                                                   <img id='img_3' width='100%' height='100%' alt=''
                                                      onContextMenu={this.handleImageRightClick} className='thumbnail-img'
                                                      src={thumbnails[2].image} ></img>
                                                </ZoomableImage>
                                             </Col>
                                          </Row>
                                       </Container>
                                    }
                                 </Col>
                              }
                              {
                                 (thumbnails.length >= 4) && thumbnails[3] &&
                                 <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                                    <Container>
                                       <Row>
                                          <Col md="9" style={{ padding: 4, color: thumbnails[3].selected ? 'dimgray' : 'lightgray', fontWeight: 'bold' }}>Page {thumbnails[3].page}</Col>
                                          <Col md="3" style={{ textAlign: 'right', padding: 0 }}>
                                             {
                                                thumbnails[3].selected &&
                                                <Form.Check id='thumbnail_4' type="checkbox" checked={true} onChange={this.handleThumbnailChecked} />
                                             }
                                             {
                                                !thumbnails[3].selected &&
                                                <Form.Check id='thumbnail_4' type="checkbox" checked={false} onChange={this.handleThumbnailChecked} />
                                             }
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <ZoomableImage key={this.getThumbnailKey(3)}
                                                backgroundImage={thumbnails[3].image}
                                                bigClassName='bigPreview' className='preview' dialogClassName='zoomerDialog'
                                                title={'Page ' + thumbnails[3].page}
                                                keepPageClicked={() => this.handleThumbnailKept(thumbnails[3].page)}
                                                ignorePageClicked={() => this.handleThumbnailIgnored(thumbnails[3].page)}>
                                                <img id='img_4' width='100%' height='100%' alt=''
                                                   onContextMenu={this.handleImageRightClick} className='thumbnail-img'
                                                   src={thumbnails[3].image} ></img>
                                             </ZoomableImage>
                                          </Col>
                                       </Row>
                                    </Container>
                                 </Col>
                              }
                              {
                                 (thumbnails.length >= 5) && thumbnails[4] &&
                                 <Col md="auto" style={{ paddingLeft: 2, paddingRight: 2, margin: 5, marginLeft: 10, background: 'white' }}>
                                    <Container>
                                       <Row>
                                          <Col md="9" style={{ padding: 4, color: thumbnails[4].selected ? 'dimgray' : 'lightgray', fontWeight: 'bold' }}>Page {thumbnails[4].page} of {totalPageCount}</Col>
                                          <Col md="3" style={{ textAlign: 'right', padding: 0 }}>
                                             {
                                                thumbnails[4].selected &&
                                                <Form.Check id='thumbnail_5' type="checkbox" checked={true} onChange={this.handleThumbnailChecked} />
                                             }
                                             {
                                                !thumbnails[4].selected &&
                                                <Form.Check id='thumbnail_5' type="checkbox" checked={false} onChange={this.handleThumbnailChecked} />
                                             }
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <ZoomableImage key={this.getThumbnailKey(4)}
                                                backgroundImage={thumbnails[4].image}
                                                bigClassName='bigPreview' className='preview' dialogClassName='zoomerDialog'
                                                title={'Page ' + thumbnails[4].page}
                                                keepPageClicked={() => this.handleThumbnailKept(thumbnails[4].page)}
                                                ignorePageClicked={() => this.handleThumbnailIgnored(thumbnails[4].page)}>
                                                <img id='img_5' width='100%' height='100%' alt=''
                                                   onContextMenu={this.handleImageRightClick} className='thumbnail-img'
                                                   src={thumbnails[4].image} ></img>
                                             </ZoomableImage>
                                          </Col>
                                       </Row>
                                    </Container>
                                 </Col>
                              }
                              {
                                 (thumbnails.length >= 1) &&
                                 <span><i>Hover over to zoom in, or click an image to see a bigger image.</i></span>
                              }
                           </Row>

                           {
                              (totalPageCount > THUMBNAIL_VIEW_SIZE) && (thumbnails.length > 1) && // Page navigation
                              <Row >
                                 <Col md="3" style={{ padding: 0 }}>
                                    <Button disabled={!this.getCanPerformFirstJump()} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderLeft: '3px solid #6C757D', borderRight: 'none', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} onClick={this.handleFirstClick}>First</Button>
                                    <Button disabled={!this.getCanPerformPrevJump(BIG_JUMP)} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderLeft: '3px solid #6C757D', borderRight: 'none', borderRadius: '0px', margin: 0 }} onClick={this.handlePrevBigJumpClick}>Prev {BIG_JUMP}</Button>
                                    <Button disabled={!this.getCanPerformPrevJump(SMALL_JUMP)} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderLeft: '3px solid #6C757D', borderRight: '3px solid #6C757D', borderRadius: '0px', margin: 0 }} onClick={this.handlePrevSmallJumpClick}>Prev {SMALL_JUMP}</Button>
                                 </Col>
                                 <Col md="6" style={{ textAlign: 'center', fontWeight: 500 }}>
                                    Jump to page:
                                    <Form.Control id='jumpToPage' style={{ width: 100, margin: 5, display: 'inline-block' }} type='number' min={1} max={totalPageCount} step={1} precision={0} onChange={this.handleChange} />
                                    <Button variant="secondary" onClick={this.handleGoClick}>Go</Button>
                                 </Col>
                                 <Col md="3" style={{ padding: 0, textAlign: 'right' }}>
                                    <Button disabled={!this.getCanPerformNextJump(SMALL_JUMP)} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderLeft: '3px solid #6C757D', borderRight: '3px solid #6C757D', borderRadius: '0px', margin: 0 }} onClick={this.handleNextSmallJumpClick}>Next {SMALL_JUMP}</Button>
                                    <Button disabled={!this.getCanPerformNextJump(BIG_JUMP)} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderRight: '3px solid #6C757D', borderLeft: 'none', borderRadius: '0px', margin: 0 }} onClick={this.handleNextBigJumpClick}>Next {BIG_JUMP}</Button>
                                    <Button disabled={!this.getCanPerformLastJump()} variant="secondary" style={{ fontWeight: 'bold', background: 'white', color: '#6C757D', borderTop: '3px solid #6C757D', borderBottom: '3px solid #6C757D', borderRight: '3px solid #6C757D', borderLeft: 'none', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} onClick={this.handleLastClick}>Last</Button>
                                 </Col>
                              </Row>
                           }
                        </Container>
                     </Modal.Body>
                     <Modal.Footer >
                        <Container >
                           <Row style={{ margin: 5 }} >
                              {
                                 totalPageCount && // Page selector
                                 <Col md="9" style={{ fontWeight: 'bold', padding: 0 }}>
                                    <Container>
                                       <Row>
                                          <Col>
                                             <Form.Check name='downloadOption' id='ENTIRE_DOCUMENT' type='radio' checked={this.state.pageSelectionMethod === ENTIRE_DOCUMENT} onChange={this.handlePageSelectionMethodChange} style={{ marginBottom: 9 }} label={'All Pages'} />
                                          </Col>
                                       </Row>
                                       <Row>
                                          <Col>
                                             <Form.Check name='downloadOption' id='CSV_PAGES' type='radio' checked={this.state.pageSelectionMethod === CSV_PAGES} onChange={this.handlePageSelectionMethodChange} style={{ display: 'inline-block' }} label={'Pages'} />
                                             <Form.Control id='selectedAsCsv' style={{ width: 200, margin: 5, display: 'inline-block' }} type='text' onChange={this.handleSelectedPagesChanged} onBlur={this.handleSelectedPagesBlur} value={this.state.selectedAsCsv} />
                                             <span style={{ fontWeight: 'normal' }}>Ex.: 1, 3, 7-9, 11 (Comma-delimited and may contain ranges)</span>
                                          </Col>
                                       </Row>

                                    </Container>
                                 </Col>
                              }
                           </Row>
                           
                           <Row>
                              <Col md="12" className='align-self-end' style={{ textAlign: 'right' }}>
                                 <Button variant="primary" style={{ marginRight: 8 }} onClick={this.handleDownloadClick}>Continue</Button>
                                 <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                              </Col>
                           </Row>

                        </Container>
                     </Modal.Footer>
                  </>
               }
            </Modal>
         </>
      )
   }
}

export default PreviewWindow;
