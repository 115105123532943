import React from 'react';
import { connect } from "react-redux";
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import PageBanner from '../common/page/PageBanner'
import Alert from '../common/modal/Alert';
import AccountSelectionList   from "../common/selection/AccountSelectionList";
import CommonTop from "../../components/top/CommonTop";
import Paginator from '../common/page/Paginator'
import {getPaginatorHelper} from '../common/page/PaginatorHelper'
import Loading from '../common/modal/Loading';
import Confirmation from '../common/modal/Confirmation';
import configData from "../../config/config.json";
import axios from "axios";
import { saveAs } from 'file-saver';

import { UserListComponetName, updateUserListFilters } from '../../redux/actions/userManagerActions';
import { LOADING_STATUES, ALERT_TYPES } from "../constants/constants";

const mapStateToProps = state => {

	return {
		paginationPage: state.usersPaginationReducer,
		userListFilters: state.userListFiltersReducer,
		accounts: state.currentUserReducer.allowableAccounts,
		role: state.currentUserReducer.role 
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserListFilters: (filters) => dispatch(updateUserListFilters(filters)),
		userListAccountFilter: (accountId, accountName) => dispatch({ type: 'SET_USER_LIST_ACCOUNT_FILTER', accountFilter: {accountId, accountName} })

	}
}

class UserList extends React.Component {

	state = {
		filteredList: [],
		selectedId: 0,
		selectedUsername: '',
		statusType: this.props.userListFilters.statusType ? this.props.userListFilters.statusType : "ALL",
		filterText: this.props.userListFilters.filterText ? this.props.userListFilters.filterText : '',
		accountId: this.props.userListFilters.accountId ? this.props.userListFilters.accountId : '',
		accountName: this.props.userListFilters.accountId ? this.props.userListFilters.accountName : '',
		status: '',
		showDeleteConfirmationDialog: false,
		showActivateConfirmationDialog: false,
		showAlert: false,
		alertMessage: "",
		alertType: ALERT_TYPES.OK,
		showAccountList: false
	}

	showLoading = () => {
		if (this.state.status === "") {
			this.setState({
				status: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleTextFilter = (filterText) => {
		this.setState({ filterText: filterText },  function() { this.handleFilter() } )
	}

	handleFinderFilterReset = () => {
		this.setState({ accountId: 0 },  function() { this.handleFilter() } )
	}
	 
	handleCloseAccountLookup = (selectedId, selectedName) => {
		this.setState({
		  showAccountList: false,
		  accountId: selectedId,
		  accountName: selectedName
		},  function() { this.handleFilter() } );
	}
	
	handleFinderFilterClick = () => {
		this.setState( {
			showAccountList : true
		  });
	}

	handleSelectFilter = (filterSelected) => {
		this.setState({statusType: filterSelected},  function() {this.handleFilter()});
	}

	handleFilter = () => {
		if (this.showLoading() !== true) {
			return;
		}

		let filters = {filterText: this.state.filterText, statusType: this.state.statusType, accountId: this.state.accountId };
		axios.post(configData.LANDSHARK_SERVER_URL + "userManager/users", filters, { withCredentials: true })
		.then((response) => {
			this.setState({filteredList: response.data.content.users, status: '' })
			this.props.updateUserListFilters(filters);
		}, (error) => {
			this.setState({ status: '' }, this.showWarning(error.message));
		});
	}

	handleRowEdit = (event) => {
		event.preventDefault();
		this.props.history.push('/userManager/' + event.target.closest("tr").id);
	}

	handleRowPassword = (event) => {
		event.preventDefault();
		this.props.history.push('/userManager/userPassword/' + event.target.closest("tr").id);
	}

	// Delete methods
	handleDeleteUser = () => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userDelete", { userId: this.state.selectedId, filterText: this.state.filterText, statusType: this.state.statusType, accountId: this.state.accountId  }, { withCredentials: true })
				.then((response) => {
					this.setState({ status: '', filteredList: response.data.content.users },  this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ status: '' }, this.showWarning(error.message));
				});

			this.props.history.push('/userManager');
		}
	}

	showDeleteConfirmation = (event) => {
		let selectedId =  event.target.closest("tr").id;
		let selectedUsername = event.target.closest("tr").getAttribute("name");
	
		this.setState({
			selectedId,
			selectedUsername,
			showDeleteConfirmationDialog: true
		})
	}

	hideDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmationDialog: false
		})
	}
	// Delete methods - End


	// Activate/deactivate methods

	hideActivateConfirmation = () => {
		this.setState({
			showActivateConfirmationDialog: false
		})
	}
	
	handleChangeUserStatus = (event) => {
		event.preventDefault();
		let userId = event.target.closest("tr").id;
		let userStatus = event.target.closest("tr").getAttribute("userStatus");
		if (userStatus === 'L') {
			this.setState({ selectedId: userId, activateQuestion: 'User was locked out due to suspicious activties.  Continue to activate?', showActivateConfirmationDialog: true })
		} else if (userStatus === 'P') {
			this.setState({  selectedId: userId, activateQuestion: 'User has not completed self registration.  Continue to activate?', showActivateConfirmationDialog: true })
		} else {
			this.setState({ selectedId: userId}, () => this.submitUserStatus());
		}
	}
	
	submitUserStatus = () => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userChangeStatusList", { userId: this.state.selectedId, filterText: this.state.filterText, statusType: this.state.statusType, accountId: this.state.accountId  }, { withCredentials: true })
				.then((response) => {
					this.setState({ status: '', filteredList: response.data.content.users }, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ status: '' }, this.showWarning(error.message));
				});
		}
	}

	//  Activate/deactivate methods - End

	// New User

	openNewUserEntry = (event) => {
		event.preventDefault();
		this.props.history.push('/userManager/0');
	}

	exportData = (event) => {
		event.preventDefault();
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userExport", { filterText: this.state.filterText, statusType: this.state.statusType, accountId: this.state.accountId }, { responseType: 'arraybuffer', withCredentials: true })
				.then((response) => {
					let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					saveAs(blob, 'users.xlsx');
					this.setState({ status: '' })
				}, (error) => {
					this.setState({ status: '' }, this.showAlert(error.message));
				});	
		}
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }

	showWarning = (alertMessage) => {
		this.setState({
		  alertType: ALERT_TYPES.WARNING,
		  showAlert: true,
		  alertMessage
		})
	}
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: "",
			alertType: ALERT_TYPES.OK
		}, this.props.history.push('/userManager'))
	}

	componentDidMount() {
		this.handleFilter();
	}

	render() {
		
		let buttons = [{ label: 'New', onClickAction: this.openNewUserEntry }, { label: 'Export', onClickAction: this.exportData, className: 'btn btn-secondary' }]

		const selectFilterProps = {
			selectFilter: this.handleSelectFilter,
			options: [{code: "ALL", description: "All Users"}, {code: "A", description: "Active Only"}, {code: "I", description: "Inactive Only"}, { code: "P", description: "Pending Only" }],
			selected: this.state.statusType
		}

		const textFilterProps = {
			textFilter: this.handleTextFilter,
			filterText: this.state.filterText
		}

		const finderFilterProps = {
			finderFilterClick: this.handleFinderFilterClick,
			finderFilterReset: this.handleFinderFilterReset,
			name: this.state.accountName,
			id: this.state.accountId
		  }

		const numberOfVisibleRecords = 10;
		let numberOfPages = Math.ceil ( this.state.filteredList.length / numberOfVisibleRecords );
		let paginationHelper = getPaginatorHelper(this.props.paginationPage, this.state.filteredList, numberOfVisibleRecords);

		return (
			<>
				<CommonTop history={this.props.history} />
				<div style={{ marginRight: 40, marginLeft: 40, marginTop: 4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
					{ 	
						(this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') &&
						<PageBanner pageHeading='Users' textFilterProps={textFilterProps} selectFilterProps={selectFilterProps} finderFilterProps={finderFilterProps} buttons={buttons} />
					}
					{ 	
						(this.props.role !== 'TRIMIN' && this.props.role !== 'COUNTY') &&
						<PageBanner pageHeading='Users' textFilterProps={textFilterProps} selectFilterProps={selectFilterProps} buttons={buttons} />
					}
					<Container fluid="true">
						<Row>
							<Col style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginTop: 20, marginLeft: 20,  marginRight: 20, border: '3px solid darkGray', borderRadius: '5px' }}>
								<Table striped hover size="sm" >
									<thead>
										<tr>
											<th style={{width:'20%'}} scope="col">Username</th>
											<th style={{width:'25%'}} scope="col">Name</th>
											<th style={{width:'25%'}} scope="col">Account</th>
											<th style={{width:'10%'}} scope="col">User Type</th>
											<th style={{width:'20%'}} scope="col"></th>
										</tr>
									</thead>

									<tbody>
										{
											paginationHelper.currentPageRecordList.map((userResult) => {
												let inactiveBadge = '';
												if ( userResult.status === 'I') {
													inactiveBadge = "inactive";
												} else if ( userResult.status === 'P') {
													inactiveBadge = "pending";
												} else if ( userResult.status === 'L') {
													inactiveBadge = "locked!"
												}
												return (
													<tr style={{ cursor: "pointer" }} id={userResult.id} name={userResult.username} userStatus={userResult.status} onClick={this.handleRowClick}>
														<td>{userResult.username}<span className='badge badge-light'>{inactiveBadge}</span></td>
														<td>{userResult.firstName} {userResult.lastName} </td>
														<td>{userResult.accountName}</td>
														<td>{userResult.role}</td>
														<td style={{ float: 'right' }}>
															<Button variant="primary" size='sm' onClick={this.handleRowEdit}>Edit</Button>&nbsp;
															<Button variant="secondary" size='sm' onClick={this.handleRowPassword}>Password</Button>&nbsp;
															{
																userResult.status === 'A' &&
																<><Button variant="danger" size='sm' onClick={this.handleChangeUserStatus}>Deactivate</Button>&nbsp;</>
															}	
															{
																userResult.status !== 'A' &&
																<><Button variant="secondary" size='sm' onClick={this.handleChangeUserStatus}>&nbsp;&nbsp;Activate&nbsp;&nbsp;</Button>&nbsp;</>
															}
															<Button variant="danger" size='sm' onClick={this.showDeleteConfirmation} >Delete</Button>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>&nbsp;</Row>
						<Row>
							<Col style={{marginLeft: 4}}>Displaying Results: {paginationHelper.currentPageStartingRecordNumber}-{paginationHelper.currentPageEndingRecordNumber} of {this.state.filteredList.length}</Col>
							<Col><Paginator style={{justifyContent: 'center'}} componentName={UserListComponetName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></Paginator></Col>
						</Row>
					</Container>
				</div>
				<Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
				<Confirmation show={this.state.showDeleteConfirmationDialog} cancelConfirmation={this.hideDeleteConfirmation} proceedConfirmation={this.handleDeleteUser} question={'Delete User ' + this.state.selectedUsername+ '?'} />
				<Confirmation show={this.state.showActivateConfirmationDialog} cancelConfirmation={this.hideActivateConfirmation} proceedConfirmation={this.submitUserStatus}  question={this.state.activateQuestion} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
				<AccountSelectionList showModal={this.state.showAccountList} codeDescriptionResults={this.props.accounts} onHide={(selectedId, selectedName) => this.handleCloseAccountLookup(selectedId, selectedName)} />
			</>

		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);