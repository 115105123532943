// Usage from https://github.com/axios/axios#handling-errors
// Should be invoked from the axios error function.
class AjaxError {
    // Pass the parameter from the error function.
    constructor(error) {
        this.error = error;
    }

    // Handle an error by notifying the user.
    // It's presumed an error has occurred.
    handleError = () => {
        let msg = this.getErrorMessage();

        alert(msg);
        console.log(msg);
    }

    // A message is always returned.
    getErrorMessage = () => {
        return 'Error: ' + this.getRawErrorMessage();
    }

    getRawErrorMessage = () => {
        let msg;

        if (this.error.response && this.error.response.data && this.error.response.data.message) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            msg = this.error.response.data.message;
        } else if (this.error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            msg = 'An unexpected error 101 has occurred.';
        } else {
            msg = 'An unexpected error 102 has occurred.';
        }

        return msg;
    }

    toString = (obj) => {
        if (JSON.stringify) {
            return JSON.stringify(obj);
        } else {
            return obj;
        }
    }
}

export default AjaxError;
