import React, { Component } from 'react';
import { Modal,  Container, Row, Col, Button } from 'react-bootstrap';
import { parse } from 'query-string';
import axios from "axios";
import AjaxResult from "../../../utils/AjaxResult";

import configData from "../../../config/config.json";

const PREFIX = 'CreditCardSearchAuthWindow: ';

class CreditCardSearchAuthWindow extends Component {
    
  constructor(props) {
    super(props);
    console.log(PREFIX + "constructed with props", props);
    this.authFormRef = React.createRef();
    this.setState( {show: props.show} );
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = configData.AUTHORIZE_ACCEPTJS_URL;
    this.setState( {show: this.props.show} );
  }

  componentDidUpdate(prevProps, prevState) {
    // If we're now showing the window and we haven't created the AuthorizeNetIFrame, do so now
    if (this.state.show && !window.AuthorizeNetIFrame) {
      this.submitAuthorizeDotNetForm();
      window.AuthorizeNetIFrame = {
        onReceiveCommunication: queryStr => {
          const message = parse(queryStr);
          console.log(PREFIX + 'processing message from Authorize.net.');
          if (typeof message === 'object' && message !== null) {
            switch (message.action) {
              case 'transactResponse':
                const json = JSON.parse(message.response);
                this.creditCardAuthWindowCallbackPayClicked(json);
                break;

              case 'resizeWindow':
                break;

              case 'cancel':
                this.handleAuthorizeDotNetCancelClicked();
                break;
              default:
                this.handleAuthorizeDotNetCancelClicked();
            };
          }
        },
      };
    } else if (!this.state.show) {
      // If weren't now showing the window and the AuthorizeNetIFrame is still here, we
      // delete it so it doesn't intercept any iframecommunicator calls.
      if (window.AuthorizeNetIFrame) {
        delete window.AuthorizeNetIFrame;
      }
    }
  }

  handleAuthorizeDotNetCancelClicked() {
    this.props.handleCloseClicked();
  }

  creditCardAuthWindowCallbackPayClicked = (authTrans) => {

    axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorizationResponse", authTrans, { withCredentials: true })
      .then((response) => {

        let result = new AjaxResult(response);

        if (!result.isOk()) {
          result.handleError();
        } else {
          if (result.content().authorizationOkay) {
            this.props.handleAuthorizedSubmit();
          } else {
            alert('Failed to process credit card authorization from Authorize.net.  Note, you will not be charged.  Please contact the county or TriMin support for assitance');
          }
        }
      }, (error) => {
        alert('Failed to process credit card authorization from Authorize.net.  Note, you will not be charged.  Please contact the county or TriMin support for assitance');
      })
  }

  componentWillUnmount() {
    if (window.AuthorizeNetIFrame) {
      delete window.AuthorizeNetIFrame;
    }
  }

  submitAuthorizeDotNetForm() {
    if (this.authFormRef.current) {
      this.authFormRef.current.submit();
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleCloseClicked} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Credit Card Preauthorization
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#408CC7' }}>
          <iframe title='Credit Card Preauthorization for Searches' id="load_payment" name="load_payment" width="100%" height="500px" scrolling="yes" />
          <form ref={this.authFormRef} id="send_hptoken" action={configData.AUTHORIZE_PAYMENT_URL} method="post" target="load_payment">
            <input type="hidden" name="token" value={this.props.token} />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row style={{ margin: 5 }} >
              <Col md='12' className='align-self-end' style={{ textAlign: 'right' }}>
                <Button variant="secondary" onClick={this.props.handleCloseClicked}>Cancel</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default React.memo(CreditCardSearchAuthWindow);
