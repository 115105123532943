import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, HomeNotableDatesControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = HomeNotableDatesControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class HomeNotableDates extends Component {

  state = {
    showNotableDates: false,
    showSeparateIndexes: false,
    useVerificationDate: false,
    abstractVerified: false,
    abstractLegalVerified: false,
    abstractPosted: false,
    abstractLegalPosted: false,
    abstractIndexBegin: false,
    abstractImageBegin: false,
    abstractImageThru: false,
    torrensVerified: false,
    torrensLegalVerified: false,
    torrensPosted: false,
    torrensLegalPosted: false,
    torrensIndexBegin: false,
    torrensImageBegin: false,
    torrensImageThru: false,
    torrensCertificateThru: false,
    lienVerified: false,
    lienPosted: false,
    lienIndexBegin: false,
    lienImageBegin: false,
    lienImageThru: false,
    status: "",
    message: ""
  }

  handleSubmit = (event) => {

    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/notableDates", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/notableDates", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          showNotableDates: content.showNotableDates,
          showSeparateIndexes: content.showSeparateIndexes,
          useVerificationDate: content.useVerificationDate,
          abstractVerified: content.abstractVerified,
          abstractLegalVerified: content.abstractLegalVerified,
          abstractPosted: content.abstractPosted,
          abstractLegalPosted: content.abstractLegalPosted,
          abstractIndexBegin: content.abstractIndexBegin,
          abstractImageBegin: content.abstractImageBegin,
          abstractImageThru: content.abstractImageThru,
          torrensVerified: content.torrensVerified,
          torrensLegalVerified: content.torrensLegalVerified,
          torrensPosted: content.torrensPosted,
          torrensLegalPosted: content.torrensLegalPosted,
          torrensIndexBegin: content.torrensIndexBegin,
          torrensImageBegin: content.torrensImageBegin,
          torrensImageThru: content.torrensImageThru,
          torrensCertificateThru: content.torrensCertificateThru,
          lienVerified: content.lienVerified,
          lienPosted: content.lienPosted,
          lienIndexBegin: content.lienIndexBegin,
          lienImageBegin: content.lienImageBegin,
          lienImageThru: content.lienImageThru,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {

    let disableLegal = this.state.showSeparateIndexes === false || this.state.showSeparateIndexes === 'false';

    const formProps = {
      componentName: componentName,
      pageHeading: "Home - Notable Dates",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'showNotableDates', label: 'Use Notable Dates', fieldType: 'select', primaryCriteria: true, value: this.state.showNotableDates, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'showSeparateIndexes', label: 'Show Separate Indexes', fieldType: 'select',  value: this.state.showSeparateIndexes, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'useVerificationDate', label: 'Verified Date as Thru Date', fieldType: 'select', value: this.state.useVerificationDate, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'abstractIndexBegin', label: 'Abstract Index Begin', fieldType: 'select', primaryCriteria: true, value: this.state.abstractIndexBegin, style: {minWidth: '11rem'} },
                       { id: 'torrensIndexBegin', label: 'Torrens Index Begin', fieldType: 'select', primaryCriteria: true, value: this.state.torrensIndexBegin, style: {minWidth: '11rem'} },
                       { id: 'lienIndexBegin', label: 'Lien Index Begin', fieldType: 'select', primaryCriteria: true, value: this.state.lienIndexBegin, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'abstractPosted', label: 'Indexed', fieldType: 'select', value: this.state.abstractPosted, style: { minWidth: '11rem' } },
                       { id: 'torrensPosted', label: 'Indexed', fieldType: 'select', value: this.state.torrensPosted, style: { minWidth: '11rem' } },
                       { id: 'lienPosted', label: 'Indexed', fieldType: 'select', value: this.state.lienPosted, style: { minWidth: '11rem' } }]
        },
        {
          formGroups: [{ id: 'abstractLegalPosted', label: 'Legal Indexed', fieldType: 'select', disableField: disableLegal, value: this.state.abstractLegalPosted, style: { minWidth: '11rem' } },
                       { id: 'torrensLegalPosted', label: 'Legal Indexed', fieldType: 'select', disableField: disableLegal,  value: this.state.torrensLegalPosted, style: { minWidth: '11rem' } },
                       { id: 'bogus1', label: '', fieldType: 'select', value: '', disableField: true, style: {minWidth: '11rem'}  } ]
        },
        {
          formGroups: [{ id: 'abstractVerified', label: 'Verified', fieldType: 'select', value: this.state.abstractVerified, style: {minWidth: '11rem'} }, 
                       { id: 'torrensVerified', label: 'Verified', fieldType: 'select', value: this.state.torrensVerified, style: {minWidth: '11rem'} }, 
                       { id: 'lienVerified', label: 'Verified', fieldType: 'select', value: this.state.lienVerified, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'abstractLegalVerified', label: 'Legal Verified', fieldType: 'select', disableField: disableLegal, value: this.state.abstractLegalVerified, style: {minWidth: '11rem'} }, 
                       { id: 'torrensLegalVerified', label: 'Legal Verified', fieldType: 'select', disableField: disableLegal, value: this.state.torrensLegalVerified, style: {minWidth: '11rem'} },
                       { id: 'bogus2', label: '', fieldType: 'select', value: '', disableField: true, style: {minWidth: '11rem'}  } ]
        },
        {
          formGroups: [{ id: 'abstractImageBegin', label: 'Image Begin', fieldType: 'select', value: this.state.abstractImageBegin, style: {minWidth: '11rem'} },
                       { id: 'torrensImageBegin', label: 'Image Begin', fieldType: 'select', value: this.state.torrensImageBegin, style: {minWidth: '11rem'} },
                       { id: 'lienImageBegin', label: 'Image Begin', fieldType: 'select', value: this.state.lienImageBegin, style: {minWidth: '11rem'} }]
        },
        {
          formGroups: [{ id: 'abstractImageThru', label: 'Image Thru', fieldType: 'select', value: this.state.abstractImageThru, style: {minWidth: '11rem'} },
                       { id: 'torrensImageThru', label: 'Image Thru', fieldType: 'select', value: this.state.torrensImageThru, style: {minWidth: '11rem'} },
                       { id: 'lienImageThru', label: 'Image Thru', fieldType: 'select', value: this.state.lienImageThru, style: {minWidth: '11rem'} } ]
        }, 
        {
          formGroups: [{ id: 'bogus3', label: '', fieldType: 'select', value: '', disableField: true, style: {minWidth: '11rem'}  },
                       { id: 'torrensCertificateThru', label: 'Certficate Thru', fieldType: 'select', value: this.state.torrensCertificateThru,  style: {minWidth: '11rem'} },
                       { id: 'bogus4', label: '', fieldType: 'select', value: '', disableField: true, style: {minWidth: '11rem'}  } ]
        }
      ]  
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(HomeNotableDates);