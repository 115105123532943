const initialState = 1;

const searchDetailsReleasesPaginationReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_DETAILS_RELEASES_PAGE':
                  return action.pageNumber;
            case 'LOGOUT_USER':
                        return 1;                  
            default:
                  break;
      }
      return state;
};

export default searchDetailsReleasesPaginationReducer;