import React from 'react';
import { connect } from "react-redux";
import FormTemplate from "../common/form/FormTemplate";
import Alert from '../common/modal/Alert';
import configData from "../../config/config.json";
import axios from "axios";
import { AccountsControlPanelKey, setSelectedControlPanelType  }  from "../../redux/actions/controlPanelActions";
import { SearchByAddressKey, SearchByBookAndPageKey, SearchByDateKey, SearchByDocumentNumberKey,
	SearchByLegalDescriptionKey, SearchByNameKey, SearchByParcelIdKey, SearchByTorrensCertificateKey, SearchByTorrensTextKey, SearchByTractIndexNMIKey,
	SpecialSearchGrantorGranteeBookKey, SpecialSearchPlatKey, SpecialSearchTaxLienKey, SpecialSearchTractBookIdKey, SpecialSearchTractBookLegalKey } from "../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../components/constants/constants";


const componentName = AccountsControlPanelKey;

const mapStateToProps = (state) => {
	return {
		sideNavigationOpen: state.sideNavigationReducer
	};
}

const matchDispatchToProps = (dispatch) => {
	return { 
		setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
	}
}
  
class AccountPageAccess extends React.Component {
	state = {
		id: 0,
		searchByAddress: false,
		searchByBookAndPage: false,
		searchByDate: false,
		searchByDocumentNumber: false,
		searchByLegalDescription: false,
		searchByName: false,
		searchByParcelId: false,
		searchByTorrensCertificate: false,
		searchByTorrensText: false,
		searchByTractBookId: false,
		searchByTractBookLegal: false,
		searchByTractIndexNMI: false,
		searchByGrantorGranteeBook: false,
		searchByPlat: false,
		searchByTaxLien: false,
		loadingStatus: '',     // Account has another field called status, so I need to call this field loadingStatus.
		showAlert: false,
		alertMessage: "",
		redirect: false
	  }

	handleChange = (event) => {
		this.setState({
		  [event.target.id]: event.target.value
		})
	}
	
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.showLoading() === true) {
			const { loadingStatus, showAlert, alertMessage, redirect, ...request } = this.state; // remove UI only fields before submitting
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountPageAccess", request, { withCredentials: true })
				.then((response) => {
					this.setState({loadingStatus: "", redirect: true}, this.showAlert(response.data.message))
				}, (error) => {
					this.setState({loadingStatus: "", redirect: false}, this.showAlert(error.message));
				});
		}
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/controlPanel/accounts');
	}

	componentDidMount() {
		
		axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/accountPageAccess/" + this.props.match.params.id, { withCredentials: true })
		.then(
		  (response) => {
			const content = response.data.content;
			this.setState( {
				id: content.id,
				searchByAddress: content.searchByAddress,
				searchByBookAndPage: content.searchByBookAndPage,
				searchByDate: content.searchByDate,
				searchByDocumentNumber: content.searchByDocumentNumber,
				searchByLegalDescription: content.searchByLegalDescription,
				searchByName: content.searchByName,
				searchByParcelId: content.searchByParcelId,
				searchByTorrensCertificate: content.searchByTorrensCertificate,
				searchByTorrensText: content.searchByTorrensText,
				searchByTractBookId: content.searchByTractBookId,
				searchByTractBookLegal: content.searchByTractBookLegal,
				searchByTractIndexNMI: content.searchByTractIndexNMI,
				searchByGrantorGranteeBook: content.searchByGrantorGranteeBook,
				searchByPlat: content.searchByPlat,
				searchByTaxLien: content.searchByTaxLien
			});
			
		  },
		  (error) => {
			console.log(error);
		  }
		);
	  }

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	hideLoading = (message, status) => {
		if (status === 'ERROR') {
			this.setState({
				loadingStatus: ""
			})
		} else {
			this.setState({
				loadingStatus: ""
			})
		}
	}
	
	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}

	render() {

		let formGroupRows = [
		{
          formGroups: [{ id: SearchByAddressKey, label: 'Address', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByAddress === true || this.state.searchByAddress=== 'true' }]
        },
        {
          formGroups: [{ id: SearchByBookAndPageKey, label: 'Book & Page', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByBookAndPage === true || this.state.searchByBookAndPage=== 'true' }]
        },
        {
          formGroups: [{ id: SearchByDateKey, label: 'Date', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByDate === true || this.state.searchByDate=== 'true' } ]
        },
        {
          formGroups: [{ id: SearchByDocumentNumberKey, label: 'Document Number', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByDocumentNumber === true || this.state.searchByDocumentNumber=== 'true' } ]
        },
		{
			formGroups: [{ id: SpecialSearchGrantorGranteeBookKey, label: 'Grantor Grantee Book', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByGrantorGranteeBook === true || this.state.searchByGrantorGranteeBook === 'true'  }]
		},
        {
          formGroups: [{ id: SearchByLegalDescriptionKey, label: 'Legal Descriptions', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByLegalDescription === true || this.state.searchByLegalDescription=== 'true'  } ]
        },
        {
          formGroups: [{ id: SearchByNameKey, label: 'Name', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByName === true || this.state.searchByName=== 'true' }]
        },
        {
          formGroups: [{ id: SearchByParcelIdKey, label: 'Parcel Id', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByParcelId === true || this.state.searchByParcelId=== 'true' }]
        },
		{
			formGroups: [{ id: SpecialSearchPlatKey, label: 'Plat', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByPlat === true || this.state.searchByPlat === 'true'  }]
		},
        {
          formGroups: [{ id: SpecialSearchTaxLienKey, label: 'Tax Lien', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTaxLien === true || this.state.searchByTaxLien === 'true'  }]
        },
        {
          formGroups: [{ id: SearchByTorrensCertificateKey, label: 'Torrens Certificate', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTorrensCertificate === true || this.state.searchByTorrensCertificate=== 'true' }, 
		               { id: SearchByTorrensTextKey, label: 'Torrens Text', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTorrensText === true || this.state.searchByTorrensText=== 'true' }]
        },
		{
		  formGroups: [{ id: SpecialSearchTractBookIdKey, label: 'Tract Book Id', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractBookId === true || this.state.searchByTractBookId === 'true' },
		  	           { id: SpecialSearchTractBookLegalKey, label: 'Tract Book Legal', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractBookLegal === true || this.state.searchByTractBookLegal === 'true' }]
		},
		{
		  formGroups: [{ id: SearchByTractIndexNMIKey, label: 'Tract Index(NMI)', fieldType: 'select', style: {minWidth: '10rem'}, value: this.state.searchByTractIndexNMI === true || this.state.searchByTractIndexNMI === 'true' }]
		}
		]

		const formProps = {
			componentName: "AccountPageAccess",
			pageHeading: "Access - " + this.props.match.params.name,
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows
		}	

		return (
		<>
			<FormTemplate {...formProps} />
			<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
		</>
		)
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(AccountPageAccess);
