import React, {Component} from 'react';
import { connect } from "react-redux";

const mapStateToProps = state => {
   return { 
      countyConfig: {...state.countyConfigSearchReducer, ...state.countyConfigReportsReducer, ...state.countyConfigEfileReducer}
   }
 }

class CountyNotes extends Component {
   
   render() {
      let key = this.props.componentName + 'CountyNote';
      let countyNotes =  this.props.countyConfig[key];
      if (countyNotes)
         return  <div style={{ paddingTop:5,  paddingBottom:5, paddingLeft:10}}>{countyNotes}</div>
      else 
         return <div style={{paddingTop:0}}></div>
   }
}

export default connect(mapStateToProps)(CountyNotes);