import React, {Component} from 'react';

// If there are more than one history link of the same kind, react doesn't recreate the component
// if you click on them consecutively.
// For example, if there are two Grantor Grante Book links in the history and if you click on them 
// consecutively, react will reuse the component but the state of the component will not get updated.
// It will do mapStateToProps -> props but it won't do props -> component state.  
// This is a workaround to force react to create a SearchHistory component in between.  In doing so, 
// the Search component will be created anew.
class SearchHistory extends Component {

  componentDidMount() {
    this.props.history.push('/search/' + this.props.match.params.searchType + '/' + this.props.match.params.searchHistoryNumber);
  }

  render() {
    return (
      <>Loading.....</>
    )
  }
}

export default SearchHistory;
