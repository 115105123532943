import React, {Component} from 'react';

import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { connect } from "react-redux";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { setSelectedControlPanelType, UserLockoutControlPanelKey  } from "../../redux/actions/controlPanelActions";
import { LOADING_STATUES } from "../constants/constants";

const componentName = UserLockoutControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class UserLockout extends Component {

  state = {
    messageLogin: '',
    messageHeadline: '',
    lockoutInPlace: false,
    status: ''
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    const { status, ...request } = this.state; // remove UI only fields before submitting
    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userLockout", request, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value
    })
  }

  hideLoading = (message, status) => {
		if (status === 'ERROR') {
			this.setState({
				status: ""
			})
		} else {
			this.setState({
				status: ""
			})
		}
	}

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/userLockout", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          lockoutInPlace: content.lockoutInPlace,
          messageLogin: content.messageLogin,
          messageHeadline: content.messageHeadline,
          status : ""
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Lockout - Step 1",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'lockoutInPlace', label: 'System Lockout', fieldType: 'select', value: this.state.lockoutInPlace }]
        },
        {
          formGroups: [{ id: 'messageLogin', label: 'Message - Login Unavailable', fieldType: 'text', required:true, value: this.state.messageLogin, lgSize: 10 }]
        },
        {
          formGroups: [{ id: 'messageHeadline', label: 'Message - Headline', fieldType: 'text', required: true,  value: this.state.messageHeadline, lgSize: 10 }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(UserLockout);