import React from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap'
import FormTemplate from "../common/form/FormTemplate";
import Alert from '../common/modal/Alert';
import CommonTop from "../../components/top/CommonTop";
import configData from "../../config/config.json";
import usStates from "../../config/us_states.json";
import Loading from '../common/modal/Loading';
import Confirmation from '../common/modal/Confirmation';
import { formatPhone, formatInternationalPhone, formatZip } from "../common/function/formattingUtils.js";
import { validateUser, trimUserFields } from "../common/function/userValidation.js";
import {LOADING_STATUES } from "../constants/constants";

const mapStateToProps = (state) => {
	return {
		userTypes: state.userTypesReducer.codeDescriptionResultList,
		userListFilters: state.userListFiltersReducer,
		currentUser: state.currentUserReducer
	};
}

class UserEntry extends React.Component {
	state = {
		id: 0,
		username: '',
		role: 'USER',
		accountId: '',
		accountName: '',
		firstName: '',
		lastName: '',
		company: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		email: '',
		phoneFormatType: 'STANDARD',
		phoneInternational: '',
		phoneStandard: '',
		status: 'A',
		password: '',
		passwordConfirm: '',
		loadingStatus: '',
		showDeleteConfirmationDialog: false,
		showActivateConfirmationDialog: false,
		allowableUserTypes: this.props.userTypes,
		allowableAccounts: [],
		errors: {},
		showAlert: false,
		alertMessage: "",
		alertType: '',
		redirect: false,
		activateQuestion: 'Continue?'
	}

	// Delete methods
	handleDeleteUser = () => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userDelete", { userId: this.state.id, filterText: this.props.userListFilters.filterText, includeInactive: this.props.userListFilters.includeInactive }, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", redirect: true },  this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ loadingStatus: "", redirect: false,  alertType: 'WARNING'  }, this.showAlert(error.message));
				});
		}
	}

	showDeleteConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showDeleteConfirmationDialog: true
		})
	}

	hideDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmationDialog: false
		})
	}
	// Delete methods - End

	handleChange = (event) => {
		event.preventDefault();

		let value = event.target.value;
		if (event.target.id === 'phoneStandard') {
			value = formatPhone(value, this.state.phoneStandard);
		} else if (event.target.id === 'phoneInternational') {
			value = formatInternationalPhone(value);
		} else if (event.target.id === 'zip') {
			value = formatZip(value);
		}

		this.setState({
			[event.target.id]: value,
			errors: {}
		})
	}

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/userManager');
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage,
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: "",
			alertType: ''
		})
	
		if (this.state.redirect) {
			this.props.history.push('/userManager');
		}
	}

	validateAndSubmit = () => {
		let errors = validateUser(this.state, this.state.id === 0, this.state.id === 0);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}

		if (this.showLoading() === true) {
			const { passwordConfirm, loadingStatus, showDeleteConfirmationDialog, showActivateConfirmationDialog,
					allowableUserTypes, allowableAccounts,
				 	showAlert, alertMessage, alertType, redirect, activateQuestion, 
				 	phoneInternational, phoneStandard,
				 	...request } = this.state; // remove UI only fields before submitting

			if (this.state.phoneFormatType === 'INTERNATIONAL') {
				request.phone = this.state.phoneInternational;
			} else {
				request.phone = this.state.phoneStandard;
			}

			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/user", request, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", redirect: true },  this.showAlert(response.data.message));
				}, (error) => { 
					this.setState({ loadingStatus: "", redirect: false, alertType: 'WARNING' }, this.showAlert(error.response.data.message));
				});
		}
	}

   handleSubmit = (event) => {
		event.preventDefault();

		const trimmedUserFields = trimUserFields(this.state);
		this.setState({ ...trimmedUserFields }, function () {
			this.validateAndSubmit();
		});
	}

	// Activate/deactivate methods

	hideActivateConfirmation = () => {
		this.setState({
			showActivateConfirmationDialog: false
		})
	}

	handleChangeUserStatus = (event) => {
		event.preventDefault();
		if (this.state.status === 'L') { 
			this.setState({ activateQuestion: 'User was locked out due to suspicious activties.  Continue to activate?', showActivateConfirmationDialog: true })
		} else if (this.state.status === 'P') { 
			this.setState({ activateQuestion: 'User has not completed self registration.  Continue to activate?', showActivateConfirmationDialog: true })
		} else {
			this.submitUserStatus();
		}
	}

	submitUserStatus = () => {
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "userManager/userChangeStatusSingle", { userId: this.state.id, filterText: this.props.userListFilters.filterText, includeInactive: this.props.userListFilters.includeInactive }, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", status: response.data.content.status },  this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ loadingStatus: "", alertType: 'WARNING' }, this.showAlert(error.message));
				});
		}
	}

	//  Activate/deactivate methods - End

	componentDidMount() {

		this.setState({
			allowableUserTypes: this.props.currentUser.allowableUserTypes,
			allowableAccounts: this.props.currentUser.allowableAccounts
		});
		
		this.setState({
			accountId: this.props.currentUser.allowableAccounts[0].code
		});
		 
		// new user
		if (this.props.match.params.id === '0') {
			return;
		}

		axios.get(configData.LANDSHARK_SERVER_URL + "userManager/user/" + this.props.match.params.id, { withCredentials: true })
			.then(
				(response) => {
					const content = response.data.content;
					this.setState({
						id: content.id,
						username: content.username,
						firstName: content.firstName,
						lastName: content.lastName,
						accountId: "" + content.accountId, // convert to a string, so it can match to the options in the dropdown
						accountName: content.accountName,
						role: content.role,
						company: content.company,
						phoneFormatType: content.phoneFormatType,
						phoneStandard: content.phoneFormatType === 'STANDARD' ? content.phone: '',
						phoneInternational: content.phoneFormatType === 'INTERNATIONAL' ? content.phone: '',
						address: content.address,
						city: content.city,
						state: content.state,
						zip: content.zip,
						email: content.email,
						status: content.status,
					});

				},
				(error) => {
					console.log(error);
				}
			);
	}

	render() {

		let disabledFlag = this.state.status !== 'A' && this.state.id !== 0;

		// Not disabled, show a dropdown.  Otherwise, show a text field.
		let roleField = {
			 id: 'role', label: 'User Type', fieldType: 'select', value: this.state.role,  primaryCriteria: true, options: this.state.allowableUserTypes 
		}
		if (disabledFlag) {
			roleField = {
				 id: 'role', label: 'User Type', fieldType: 'text', value: this.state.role, disabled: true 
			}
		}

		// Not disabled, show a dropdown.  Otherwise, show a text field.
		let accountField = {
			formGroups: [{ id: 'accountId', label: 'Account', fieldType: 'select', options: this.state.allowableAccounts, value: this.state.accountId, required: true,  primaryCriteria: true, disabled: disabledFlag },
						roleField]
		}
		if (disabledFlag) {
			accountField = {
				formGroups: [{ id: 'accountName', label: 'Account', fieldType: 'text', value: this.state.accountName, disabled: true }, roleField]
			}
		}

		let formGroupRows = [
			accountField,
		
			{
				formGroups: [{ id: 'firstName', label: 'First Name', fieldType: 'text', value: this.state.firstName, required: true, primaryCriteria: true, disabled: disabledFlag, error: this.state.errors['firstName'] }, { id: 'lastName', label: 'Last Name', fieldType: 'text', value: this.state.lastName, required: true, primaryCriteria: true,  disabled: disabledFlag, error: this.state.errors['lastName'] }]
			},
			{
				formGroups: [{ id: 'email', label: 'Email', fieldType: 'email', value: this.state.email, required: true, primaryCriteria: true, disabled: disabledFlag, error: this.state.errors['email'] }]
			},
			{
				formGroups: [{ id: 'company', label: 'Company', fieldType: 'text', value: this.state.company, required: false, disabled: disabledFlag, error: this.state.errors['company'] }]
			},
			{
				formGroups: [{ id: 'address', label: 'Address', fieldType: 'text', value: this.state.address, required: false, disabled: disabledFlag, style: {minWidth: 300}, error: this.state.errors['address'] }]
			},
			{
				formGroups: [{ id: 'city', label: 'City', fieldType: 'text', value: this.state.city, required: false, disabled: disabledFlag, error: this.state.errors['city'] }, { id: 'state', label: 'State', fieldType: 'select', value: this.state.state, options: usStates, required: false, disabled: disabledFlag }, { id: 'zip', label: 'Zip  (ex. 55111)', fieldType: 'zip', style: {maxWidth: 80}, value: this.state.zip, required: false, disabled: disabledFlag, error: this.state.errors['zip'] }]
			},
			{
				formGroups: [{ id: 'phoneFormatType', label: 'Phone Format', fieldType: 'select', options: [{ code: "STANDARD", description: "(XXX) XXX-XXXX" }, { code: "INTERNATIONAL", description: "International" }], value: this.state.phoneFormatType, required: false, disabled: disabledFlag, error: this.state.errors['phoneFormatType'] },
				             { id: 'phoneStandard', label: 'Phone (ex. (612) 555-1234)', fieldType: 'tel', value: this.state.phoneStandard, required: false, disabled: disabledFlag, hideField: this.state.phoneFormatType !== 'STANDARD', error: this.state.errors['phoneStandard'] },
							 { id: 'phoneInternational', label: 'Phone country code (ex. 8613123456789)', fieldType: 'tel_international', value: this.state.phoneInternational, required: false, disabled: disabledFlag, hideField: this.state.phoneFormatType !== 'INTERNATIONAL', error: this.state.errors['phoneInternational'] } ]
			}
		]
			
		// If new user, show the User Name and Password fields in the entry form.
		if (this.state.id === 0) {
			formGroupRows.splice(0, 0, { formGroups: [{ id: 'password', label: 'Password', fieldType: 'password', value: this.state.password, required: true, error: this.state.errors['password'] }, { id: 'passwordConfirm', label: 'Confirm Password', fieldType: 'password', value: this.state.passwordConfirm, required: true, error: this.state.errors['passwordConfirm'] }] });
			formGroupRows.splice(0, 0, { formGroups: [{ id: 'username', label: 'Username', fieldType: 'text', value: this.state.username, required: true, error: this.state.errors['username'] }] });
		}

		let changeStatusLabel = 'Activate';
		if (this.state.status === 'A') {
			changeStatusLabel = 'Deactivate';
		}


		let pageButtons = [{ label: changeStatusLabel, onClickAction: this.handleChangeUserStatus, className: 'btn btn-secondary btn-sm' }, { label: 'Delete', onClickAction: this.showDeleteConfirmation, className: 'btn btn-danger btn-sm' }]
		const formProps = {
			componentName: "UserEntry",
			pageHeading: "User (" + (this.state.id > 0 ? this.state.username + ")" : 'new*)'),  // Existing user, show user id.  New user, show [new]
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows,
			pageButtons: (this.state.id > 0 ? pageButtons : null) // Existing user, show page buttons.  New user, don't.
		}

		return (
			<>
				<CommonTop history={this.props.history} />
				<div style={{ marginRight: 80, marginLeft: 80, marginTop: 0 }}>
					<Container>
						<Row>
							<Col style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 2, marginTop: 0 }}>
								<FormTemplate {...formProps} />
							</Col>
						</Row>
					</Container>
				</div>
				<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
				<Confirmation show={this.state.showDeleteConfirmationDialog} cancelConfirmation={this.hideDeleteConfirmation} proceedConfirmation={this.handleDeleteUser} question={"Delete user?"} />
				<Confirmation show={this.state.showActivateConfirmationDialog} cancelConfirmation={this.hideActivateConfirmation} proceedConfirmation={this.submitUserStatus} question={this.state.activateQuestion} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType}  />
			</>
		)
	}
}

export default connect(mapStateToProps, null)(UserEntry);