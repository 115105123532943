import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, ReloadConfigurationControlPanelKey } from "../../redux/actions/controlPanelActions";

import moment from 'moment';

import configData from "../../config/config.json";
import { LOADING_STATUES } from "../constants/constants";
import Loading from '../common/modal/Loading';
import Alert from '../common/modal/Alert';
import AjaxResult from "../../utils/AjaxResult";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"

const componentName = ReloadConfigurationControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class ReloadConfiguration extends Component {

  state = {
    message:"",
    showAlert: false,
    alertMessage: "",
    loadingStatus: ""
  }

	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.showLoading() === true) {
      let request = {};
      let newState = { loadingStatus: ''};

      axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/reloadConfiguration", request, { withCredentials: true })
      .then(
        (response) => {
          let result = new AjaxResult(response);

          if (!result.isOk()) {
             result.handleError();
             this.setState(newState);
          } else {
            this.setState(newState, this.showAlert(response.data.message));
          }
        },
        (error) => {
          AjaxResponseHandler.handleError(error, this.showAlert, null);
          this.setState(newState);
        }
      );
		}
	}

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	}
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

  componentDidMount() {
    this.props.setSelectedControlPanelType();
  }

  render() {
  
    const formProps = {
      componentName: componentName,
      pageHeading: "Reload Configuration",
      onSubmit: this.handleSubmit,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      message: this.state.message,
      formGroupRows: [
      ]
    }

    return (
      <>
        <FormTemplate {...formProps} />
        <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
        <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
      </>
    );
  }
}

export default connect(null, matchDispatchToProps)(ReloadConfiguration);