import React, { Component } from "react"
import { connect } from "react-redux";

import PreviewWindow from "../search/PreviewWindow";
import ImageConfirmOrderWindow from "../search/ImageConfirmOrderWindow";
import EscrowAddonWindow from "../search/EscrowAddonWindow"
import FlatFeeAddon from "../image/FlatFeeAddon"
import CreditCardAuthWindow from "../search/CreditCardAuthWindow";
import CreditCardImmediateChargeWindow from "../creditcard/CreditCardImmediateChargeWindow";
import axios from "axios";
import configData from "../../config/config.json";
import Alert from '../common/modal/Alert'
import AjaxResult from "../../utils/AjaxResult";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"

import { LOADING_STATUES } from "../constants/constants";
import Loading from '../common/modal/Loading';
import { saveAs } from 'file-saver';
import { backendLogger } from "../../logger.js"
import { refreshUsageBalance, refreshUsageBalanceFromContent } from "../../redux/actions/otherLandLinkActions";

// Child state actions.
const ACTION_SHOW = 'show';
const ACTION_UPDATE = '';

const ALERT_MESSAGE = 'MESSAGE';
const ALERT_WARNING = 'WARNING';

const PREFIX = 'Thumbnails: ';

const matchDispatchToProps = (dispatch) => {
    return { 
        refreshUsageBalanceFromContent: (content) => dispatch(refreshUsageBalanceFromContent(content)),
        refreshUsageBalance: (userId) => dispatch(refreshUsageBalance(userId))
      }
}

const mapStateToProps = state => {
    return {
        currentUserId: state.currentUserReducer.userId,
    };
}

// This class deals with displaying an image.
// This starts off with the rendering of thumbnails and moves thru
// the image window workflow in order to arrive at the final image.
//
// The current implementation assumes the component will come thru
// as show=false and will be updated later.

// Props:
// - show: boolean if the window should show
// - docKey: the document key to show, use the empty string for nothing
// - instCode: the instrument code of the document
// - complete(): notification that the component has completed
class Thumbnails extends Component {
    constructor(props) {
        super(props);

        this.imageState = null;
        this.version = 0;

        this.state = {
            confirmOrderWindow: {
                show: false,
                feeSchedule: {
                    alreadyPurchasedMessage: null,
                    beginningBalance: { description: '', value: ''},
                    endingBalance: { description: '', value: ''},
                    insufficientBalance: false,
                    insufficientBalanceMessage: null,
                    multipleItemPurchase: null,
                    purchaseMessage: null,
                    noPurchasingOptionsAvailableMessage: null,
                    singleItemPurchase: null
                },
                imageState: {
                    purchaseOptions: {
        
                    }
                }
            },
            escrowAddonWindow : this.createEscrowAddonWindowState(ACTION_UPDATE, null, false),
            flatFeeAddonWindow : this.createFlatFeeAddonWindowState(false),
            creditCardAuthWindow : this.createCreditCardAuthWindowState(ACTION_UPDATE, null, false),
            previewWindow : this.createPreviewWindowState(ACTION_UPDATE, null, false),

            alertWindow: {
                callback: null,
                message: '',
                show: false,
                type: ''
            },
            loadingStatus: "",
            authAndCaptureToken: '',
            showCreditCardEscrowRechargeWindow: false
        };
    }

    addShowLoading = (newState) => {
        newState.loadingStatus = LOADING_STATUES.LOADING;
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
    }

    loadThumbnails = () => {
        console.log(PREFIX + this.props.docKey + ': loading thumbnails');
        this.setState({loadingStatus: LOADING_STATUES.LOADING });

        let request = { 
            documentType: this.props.docKey.substring(0, 1),
            documentNumber: this.props.docKey.substring(1)
        };
      
        let me = this;
        let newState = {loadingStatus: ''};

        (async () => {
            axios.post(configData.LANDSHARK_SERVER_URL + "imaging/getThumbnails", request, { withCredentials: true })
                .then((response) => {

                    let result = new AjaxResult(response);

                    if (!result.isOk()) {
                        result.handleError();
                        me.setState(newState, function() {
                            me.props.complete();
                        });
                    } else {
                        // Show previews
                        newState.previewWindow = me.createPreviewWindowState(ACTION_UPDATE, result.content(), true);
                        me.setState(newState);
                    }
                }, (error) => {
                    AjaxResponseHandler.handleError(error, me.showAlertText, null);
                    me.setState(newState, function() {
                        me.props.complete();
                    });
                })
            })();
    }

    componentDidMount = () => {
        if (!this.props.show) return;
        this.loadThumbnails();
    }

    componentDidUpdate = (prevProps, prevState) => {
        // We get here is the IMAGE button is clicked or an internal state change
        // occurs.
        if (!this.props.show) return;
        
        // Reload state if hide->show or showing+dockey changed
        if (!prevProps.show || (this.props.docKey !== prevProps.docKey)) {
            this.loadThumbnails();
        }
    }

    cancelImageState = (func) => {
        if (!this.imageState) return;

        let me = this;
        let request = this.createImageStateReference();
      
        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "imaging/cancelImageState", request, { withCredentials: true })
                    .then((response) => {
                      
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else {
                            me.imageState = null;
                            func();
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, me.showAlertText, null);
                        me.hideLoading();
                    })
        })();
    }

    confirmOrderWindowCallbackBackClicked = (state) => {
        // We want the preview window to show with the same state.
        let me = this;

        function after() {
            let newState = {};
        
            me.addShowConfirmOrderWindow(newState, false);
            me.addShowPreviewWindow(newState, true);
            me.updateState(newState);
        }

        this.cancelImageState(after);
    }

    confirmOrderWindowCallbackCloseClicked = (state) => {
        // Close the confirm window.
        let me = this;

        function after() {
            let newState = {};
    
            me.addShowConfirmOrderWindow(newState, false);
            me.setState(newState, function() {
                this.props.complete(); 
            });
        }

        this.cancelImageState(after);
    }

    confirmOrderWindowCallbackBuyOptionsClicked = (state) => {
        // image window ask for buy options
        // 'state' param isn't used.
     
        if (this.imageState.purchaseOptions.flatFeeOptions) {
            let newState = {};
            
            this.addShowConfirmOrderWindow(newState, false);
            newState.flatFeeAddonWindow = this.createFlatFeeAddonWindowState(true);
            this.updateState(newState);

            // The state has been updated by the previous line.
        } else if (this.imageState.purchaseOptions.escrowOptions) {
            let newState = {};
            let content = {
                imageState: this.imageState
            };
            
            this.addShowConfirmOrderWindow(newState, false);
            newState.escrowAddonWindow = this.createEscrowAddonWindowState(ACTION_UPDATE, content, true);
            this.updateState(newState);

            // The state has been updated by the previous line.
        } else if (this.imageState.purchaseOptions.creditCardOptions) {
            let newState = {};
            let content = {
                imageState: this.imageState
            };
            
            this.addShowConfirmOrderWindow(newState, false);
            this.creditCardAuthWindowShow(newState, content);
            this.updateState(newState);
            // The state has been updated by the previous line.
        } else {
            alert('Buy options are not supported.');
            return;
        }

    }

    confirmOrderWindowCallbackContinueClicked = (state) => {
        this.setState({loadingStatus: LOADING_STATUES.LOADING }, function() {
            this.confirmOrderWindowCallbackContinueClicked2(); 
        });
    }

    confirmOrderWindowCallbackContinueClicked2 = (state) => {
        // image window accepted charges
        // 'state' param isn't used.
     
        // Complete the payment.
        let me = this;
        let request = this.createImageStateReference();

        // No content returned, just ok or not.
        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "imaging/payDownloadOrder", request, { withCredentials: true })
                    .then((response) => {
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                            me.hideLoading();
                        } else if (result.content().status === 'PAID') {
                            this.props.refreshUsageBalanceFromContent(result.content().usageBalanceResult);

                            // Close the confirm window, put up the image window.
                            let newState = {};

                            me.addShowConfirmOrderWindow(newState, false);
                            me.imageWindowShow(newState);

                            // The state has been updated by the previous line.
                            // Chris bxp alert(result.content().usageBalanceResult.usageBalance);
                        } else {
                            me.showAlert(
                                me.createAlertWindowParams(true, 'There are insufficient funds.', 
                                    function() {
                                        me.recomputeDownloadOrder();
                                    }, ALERT_WARNING));
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, me.showAlertText, null);
                        me.hideLoading();
                    })
        })();
    }

    createCreditCardAuthWindowState = (action, content, show) => {
        content = content || {};

        let newState = {
            action: action,
            message: content.flatFeeMessage,
            show: show,
            token: content.token,
            version: ++this.version,
            event: {
                backClicked: this.creditCardAuthWindowCallbackBackClicked,
                closeClicked: this.creditCardAuthWindowCallbackCloseClicked,
                payClicked: this.creditCardAuthWindowCallbackPayClicked,
            }
        }

        return newState;
    }

    creditCardAuthWindowCallbackBackClicked = (state) => {
        console.log(PREFIX + 'creditCardAuthWindowCallbackBackClicked');

        // We want the preview window to show with the same state.
        let newState = {};
        
        this.addShowCreditCardAuthWindow(newState, false);
        this.addShowConfirmOrderWindow(newState, true);
        this.updateState(newState);
    }

    creditCardAuthWindowCallbackPayClicked = (authTrans) => {
        let request = authTrans;
        let me = this;

        (async () => {
          await
            axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorizationResponse", request, { withCredentials: true })
              .then((response) => {
                console.log(PREFIX + 'notify server of authorization response ', response);
    
                let result = new AjaxResult(response);
    
                if (!result.isOk()) {
                    result.handleError();
                } else {

                    if (result.content().authorizationOkay) {
                        // Close the confirm window, put up the image window.
                        let newState = {};

                        me.addShowCreditCardAuthWindow(newState, false);
                        me.addAlert(newState, me.createAlertWindowParams(true, 'Authorization successful', me.confirmOrderWindowCallbackContinueClicked, ALERT_MESSAGE));
                        me.updateState(newState);
                    } else {
                        let newState = {};
                        me.addShowCreditCardAuthWindow(newState, false);
                        alert('Failed to process credit card authorization from Authorize.net.  Note, you will ' +
                              'not be charged.  Please contact the county or TriMin support for assitance');
                    }
                }
              }, (error) => {
                let newState = {};
                me.addShowCreditCardAuthWindow(newState, false);

                AjaxResponseHandler.handleError(error, me.showAlertText, null);
                me.hideLoading();
            })
        })();
    }

    creditCardAuthWindowCallbackCloseClicked = (state) => {
        console.log(PREFIX + 'creditCardAuthWindowCallbackCloseClicked');

        // Close the window.
        let newState = {};

        this.addShowCreditCardAuthWindow(newState, false);
        this.setState(newState, function() {
            this.props.complete(); 
        });
    }

    creditCardExceedingPreauthWindowCallbackContinueClicked = (state) => {
        let newState = {};
        this.addShowConfirmOrderWindow(newState, false);
        this.creditCardAuthWindowShow(newState, {});

        this.updateState(newState);

    }

    createEscrowAddonWindowState = (action, content, show) => {
        content = content || {};

        let newState = {
            action: action,
            imageState: content.imageState,
            show: show,
            version: ++this.version,
            event: {
                backClicked: this.escrowAddonWindowCallbackBackClicked,
                buyClicked: this.escrowAddonWindowCallbackBuyClicked,
                closeClicked: this.escrowAddonWindowCallbackCloseClicked
            }
        }

        return newState;
    }

    escrowAddonWindowCallbackBackClicked = (state) => {

        // We want the preview window to show with the same state.
        let newState = {};

        this.addShowEscrowAddonWindow(newState, false);
        this.addShowConfirmOrderWindow(newState, true);
        this.updateState(newState);
    }

    escrowAddonWindowCallbackBuyClicked = (state) => {
        let me = this;
        let newState = {};
        this.addShowEscrowAddonWindow(newState, false);
        this.updateState(newState);

        (async () => {
          await
            axios.post(configData.LANDSHARK_SERVER_URL + "escrowDeposit/initiateDeposit", 
              {amount: state.deposit}, { withCredentials: true })
              .then((response) => {
                let result = new AjaxResult(response);

                this.setState({
                    showCreditCardEscrowRechargeWindow: true,
                    authAndCaptureToken: result.content().tokenResults.token
                });
              }, (error) => {
                this.setState({ loadingStatus: "" }, function() {
                  AjaxResponseHandler.handleError(error, me.showAlertText, me.props.history);
                }); 
              })
        })();
    }

    escrowAddonWindowCallbackCloseClicked = (state) => {
     
        // Close the window.
        let newState = {};

        this.addShowEscrowAddonWindow(newState, false);
        this.setState(newState, function() {
            this.props.complete(); 
        });
}

    createFlatFeeAddonWindowState = (show) => {
        let newState = {
            show: show,
            imageStateReference: { imageState: this.imageState }
        };
                
        return newState;
    }

    flatFeeAddonWindowCallbackCompleted = () => {
        // Close the window.
        let newState = {};

        this.addShowFlatFeeAddonWindow(newState, false);
        this.setState(newState, function() {
            this.confirmOrderWindowCallbackContinueClicked(); 
        });
    }

    flatFeeAddonWindowCallbackBackClicked = () => {

        // We want the preview window to show with the same state.
        let newState = {};
        
        this.addShowFlatFeeAddonWindow(newState, false);
        this.addShowConfirmOrderWindow(newState, true);
        this.updateState(newState);
    }

    flatFeeAddonWindowCallbackCloseClicked = () => {
        // Close the window.
        let newState = {};

        this.addShowFlatFeeAddonWindow(newState, false);
        this.setState(newState, function() {
            this.props.complete(); 
        });
    }

    addImageStateReference = (obj) => {
        obj.imageStateReference = this.createImageStateReference();
        return obj;
    }

    createImageStateReference = () => {
        let request = {
            imageStateGuid: this.imageState.guid
        };

        return request;
    }

    createPreviewWindowState = (action, content, show) => {
        return {
            action: action,
            content: content,
            instrumentCode: this.props.instCode,
            show: show,
            version: ++this.version
        }
    }

    previewWindowCallbackDownloadClicked = (state) => {
        // image window preview ok
      
        // I could just pass the incoming state as the request, however that
        // would mean the caller would know what we wanted the state for,
        // which would be a leaky abstraction.
        let request = {
            documentKey: state.documentKey,
            instrumentCode: state.instrumentCode,
            pagesSelected: state.pagesSelected,
            pageSelectionMethod: state.pageSelectionMethod,
            pageCount: state.pageCount
        };

        this.computeDownloadOrder(request, 'computeDownloadOrder');
    }
        
    recomputeDownloadOrder = () => {
        let request = this.createImageStateReference();

        this.computeDownloadOrder(request, 'recomputeDownloadOrder');
    }

    computeDownloadOrder = (request, action) => {
        // image window preview ok
        
        // Create new state, close the preview window as the default.
        let me = this;

        // Get the order details.
        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "imaging/" + action, request, { withCredentials: true })
                    .then((response) => {
                    
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else {
                            // Open the confirmation window.
                            let newState = {};

                            me.addShowPreviewWindow(newState, false);
                            newState.confirmOrderWindow = {
                                feeSchedule: result.content().feeSchedule,
                                imageState: result.content().imageState,
                                show: true
                            };

                            // Save some info for paying a confirmed payment.
                            me.imageState = result.content().imageState;
                            me.updateState(newState);
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, me.showAlertText, null);
                        me.hideLoading();
                    })
        })();
    }

    previewWindowCallbackCloseClicked = (state) => {
        let newState = {};

        this.addShowPreviewWindow(newState, false);
        this.setState(newState, function() {
            this.props.complete(); 
        });
    }

    // Show the escrow add on window.
    // Provided in case escrow obtains any further options
    // other than a static window with none.
    escrowAddOnWindowShow = (newState, content) => {
        newState.escrowAddonWindow = this.createEscrowAddonWindowState(ACTION_UPDATE, content, true);
        this.updateState(newState);
    }

    // Show the credit card authorization window.
    creditCardAuthWindowShow = (newState, content) => {
        // Get the window options.
        let me = this;
        let request = {};

        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", request, { withCredentials: true })
                    .then((response) => {
                        console.log(PREFIX + 'authorize response', response);

                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                        } else {
                            content.token = result.content().token;
                            newState.creditCardAuthWindow = me.createCreditCardAuthWindowState(ACTION_UPDATE, content, true);
                            me.updateState(newState);
                        }
                    }, (error) => {
                        AjaxResponseHandler.handleError(error, me.showAlertText, null);
                        me.hideLoading();
                    })
        })();
    }

    // Escrow Reacharge Window methods:
    hideEscrowPaymentPage = () => {
        this.setState({
            showCreditCardEscrowRechargeWindow: false
        })
    }

    handleEscrowPaymentComplete = (transactionResponse) => {
        let request = transactionResponse;
        let me = this;

        console.log(request);
    
        backendLogger.info("Attempting to call escrowDeposit/completeDeposit with transactionResponse: " + JSON.stringify(request));
        axios.post(configData.LANDSHARK_SERVER_URL + "escrowDeposit/completeDeposit", request, { withCredentials: true })
          .then((response) => {
            this.props.refreshUsageBalance({userId: this.props.currentUserId});
    
            this.setState({
                showCreditCardEscrowRechargeWindow: false,
            })
          }, (error) => {
                console.log("Error adding to escrow");
                this.setState({ loadingStatus: "", showCreditCardEscrowRechargeWindow: false }, function() {
                    AjaxResponseHandler.handleError(error, me.showAlertText, null);
                }); 
          })
    }

    // Show the image window.
    imageWindowShow = (newState) => {
        this.addShowLoading(newState);
        
        let me = this;
        this.setState(newState, function() {
            me.props.complete();
            me.imageWindowShowStep2( {} );
        });
    }

    imageWindowShowStep2 = (newState) => {
        let me = this;
        let request = this.createImageStateReference();

        (async () => {
            await
                axios.post(configData.LANDSHARK_SERVER_URL + "imaging/getImage", request, { withCredentials: true })
                    .then((response) => {
                   
                        let result = new AjaxResult(response);

                        if (!result.isOk()) {
                            result.handleError();
                            me.hideLoading();
                        } else if (!result.content().preview) {
                            // bxp is this test even necessary ?
                            // deprecated -- const byteCharacters = atob(result.content().pdf);
                            const byteCharacters = Buffer.from(result.content().pdf, 'base64').toString('binary');
                            const byteArrays = [];

                            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                const slice = byteCharacters.slice(offset, offset + 512);
                                const byteNumbers = new Array(slice.length);

                                for (let i = 0; i < slice.length; i++) {
                                    byteNumbers[i] = slice.charCodeAt(i);
                                }

                                const byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }

                            const blob = new Blob(byteArrays, { type: 'application/pdf' });
                           
                            let fileName = result.content().header.uiSaveAsImageName;
                            let suffix = 'pdf';

                            if (!fileName) {
                                fileName = 'image' + '.' + suffix;
                            }

                            fileName = fileName.replace('%%SUFFIX%%', suffix);

                            me.updateState(newState);
                            saveAs(blob, fileName);
                            me.hideLoading();
                        } else {
                            alert('There is no content to show');
                            me.updateState(newState);
                        }

                        // It's ok to leave this.imageState set on the rare chance there is a
                        // problem displaying the image.
                        }, (error) => {
                            AjaxResponseHandler.handleError(error, me.showAlertText, null);
                            me.hideLoading();
                        })
        })();

    }
      
    addShowConfirmOrderWindow = (state, show) => {
        state.confirmOrderWindow = { ...this.state.confirmOrderWindow, show: show};
    }

    addShowEscrowAddonWindow = (state, show) => {
        state.escrowAddonWindow = this.createEscrowAddonWindowState(ACTION_SHOW, null, show);
    }

    addShowFlatFeeAddonWindow = (state, show) => {
        state.flatFeeAddonWindow = this.createFlatFeeAddonWindowState(show);
    }

    addShowCreditCardAuthWindow = (state, show) => {
        state.creditCardAuthWindow = this.createCreditCardAuthWindowState(ACTION_SHOW, null, show);
    }

    addShowPreviewWindow = (state, show) => {
        state.previewWindow = this.createPreviewWindowState(ACTION_SHOW, null, show);
    }

    updateState = (newState) => {
        this.setState(newState);
     }
    
    createAlertWindowParams = (show, message, callback, type) => {
        if (show) {
            return {
                callback: callback,
                message: message,
                show: show,
                type: type ? type : ALERT_MESSAGE
            };
        } else {
            return {
                callback: null,
                message: '',
                show: show,
                type: ALERT_MESSAGE
            };
        }
    }

    addAlert = (state, alertWindowParams) => {
		state.alertWindow = alertWindowParams;
    }
	
    showAlert = (alertWindowParams) => {
		this.setState({
		  alertWindow: alertWindowParams
		})
	  }
	
    showAlertText = (msg) => {
        this.showAlert(this.createAlertWindowParams(true, msg, null, ALERT_MESSAGE));
    }

	hideAlert = () => {
        // After the alert is confirmed, close the alert and pay for the order.
        let callback = this.state.alertWindow.callback;
        let params = this.createAlertWindowParams(false);
		this.setState({
			    alertWindow: params
		    },
            () => { if (callback) callback(); }
        );
	}

    render() {
        if (true) {
            console.log("BEGIN thumbnails ###################################");
            console.log("preview: " + this.state.previewWindow.show)
            console.log("confirm: " + this.state.confirmOrderWindow.show)
            console.log("flat fee add on: " + this.state.flatFeeAddonWindow.show)
            console.log("END thumbnails ###################################");
        }

        return (
            <>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <PreviewWindow previewWindow={this.state.previewWindow} 
                    docKey={this.props.docKey} 
                    instCode={this.props.instCode} 
                    closeClicked={this.previewWindowCallbackCloseClicked} 
                    downloadClicked={this.previewWindowCallbackDownloadClicked} />
                <ImageConfirmOrderWindow 
                    show={this.state.confirmOrderWindow.show} 
                    feeSchedule={this.state.confirmOrderWindow.feeSchedule} 
                    imageState={this.state.confirmOrderWindow.imageState} 
                    backClicked={this.confirmOrderWindowCallbackBackClicked}
                    buyOptionsClicked={this.confirmOrderWindowCallbackBuyOptionsClicked}
                    closeClicked={this.confirmOrderWindowCallbackCloseClicked}
                    continueClicked={this.confirmOrderWindowCallbackContinueClicked}
                    reauthClicked={this.creditCardExceedingPreauthWindowCallbackContinueClicked} />
                <FlatFeeAddon
                    show={this.state.flatFeeAddonWindow.show}
                    imageStateReference={this.state.flatFeeAddonWindow.imageStateReference}
                    backClicked={this.flatFeeAddonWindowCallbackBackClicked}
                    completed={this.flatFeeAddonWindowCallbackCompleted}
                    closeClicked={this.flatFeeAddonWindowCallbackCloseClicked} />
                <CreditCardAuthWindow creditCardAuthWindow={this.state.creditCardAuthWindow} />
                <EscrowAddonWindow escrowAddonWindow={this.state.escrowAddonWindow} />
                <CreditCardImmediateChargeWindow show={this.state.showCreditCardEscrowRechargeWindow} token={this.state.authAndCaptureToken}
                    cancelPayment={this.hideEscrowPaymentPage} makePayment={this.handleEscrowPaymentComplete} title='Escrow Deposit - Credit Card Authorization' />
                <Alert show={this.state.alertWindow.show} hideAlert={this.hideAlert} message={this.state.alertWindow.message} type={this.state.alertWindow.type} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(Thumbnails);