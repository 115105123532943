const countyConfigSearch2Reducer = (state = [{}], action) => {
  switch (action.type) {
    case 'LANDSHARK_COUNTY_CONFIG_SEARCH2':
      return action.results;
    default:
      break;
  }
  return state;
}

export default countyConfigSearch2Reducer;