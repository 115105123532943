
 const paymentMethodTypesAllReducer = (state = [], action) => {
  switch (action.type) {
    case 'LANDSHARK_PAYMENT_METHOD_TYPES_ALL':
      return action.results;
    default:
      break;
  }
  return state;
}

export default paymentMethodTypesAllReducer;