import * as All1 from './font_awesome1'
import * as All2 from './font_awesome2'

export const atlasIcon = All1.atlasIcon;
export const atlasActiveIcon = All1.atlasActiveIcon;
export const arrowDownIcon = All1.arrowDownIcon;
export const arrowAltDownIcon = All1.arrowAltDownIcon;
export const arrowRightIcon = All1.arrowRightIcon;
export const arrowRightActiveIcon = All1.arrowRightActiveIcon;
export const arrowUpIcon = All1.arrowUpIcon;
export const checkIcon = All2.checkIcon;
export const checkIconGreen = All2.checkIconGreen;
export const spinnerIconGray = All1.spinnerIconGray;
export const spinnerIconWhiteSmall = All1.spinnerIconWhiteSmall;
export const spinnerIconWhiteBig = All1.spinnerIconWhiteBig;
export const dateIcon = All1.dateIcon;
export const dateActiveIcon = All1.dateActiveIcon;
export const imageIcon = All1.imageIcon;
export const platIcon = All2.platIcon;
export const platActiveIcon = All2.platActiveIcon;
export const addressIcon = All1.addressIcon;
export const addressActiveIcon = All1.addressActiveIcon; 
export const bookIcon = All1.bookIcon;
export const bookActiveIcon = All1.bookActiveIcon;

export const documentNumberIcon = All1.documentNumberIcon;
export const documentNumberActiveIcon = All1.documentNumberActiveIcon;
export const dollarIcon = All1.dollarIcon;
export const dollarActiveIcon = All1.dollarActiveIcon;
export const dollarIconBlack = All1.dollarIconBlack;
export const parcelIcon = All1.parcelIcon;
export const parcelActiveIcon = All1.parcelActiveIcon; 
export const legalIcon = All1.legalIcon;
export const legalActiveIcon = All1.legalActiveIcon;
export const homeIcon = All1.homeIcon;
export const homeActiveIcon = All1.homeActiveIcon; 
export const searchIcon = All1.searchIcon;
export const searchActiveIcon = All1.searchActiveIcon;
export const caretSquareDownIcon = All1.caretSquareDownIcon;
export const caretSquareUpIcon = All1.caretSquareUpIcon;
export const chevronCircleLeftIcon = All1.chevronCircleLeftIcon;
export const chevronLeftIcon = All1.chevronLeftIcon;
export const chevronRightIcon = All1.chevronRightIcon;
export const statementIcon = All1.statementIcon; 
export const statementActiveIcon = All1.statementActiveIcon; 
export const accountTypeIcon = All1.accountTypeIcon; 
export const accountTypeActiveIcon = All1.accountTypeActiveIcon; 
export const commonIcon = All1.commonIcon;
export const commonActiveIcon = All1.commonActiveIcon; 
export const flatFeeIcon = All1.flatFeeIcon;
export const flatFeeActiveIcon = All1.flatFeeActiveIcon; 
export const unitRatesIcon = All1.unitRatesIcon;
export const unitRatesActiveIcon = All1.unitRatesActiveIcon; 
export const creditCardIcon = All1.creditCardIcon; 
export const creditCardActiveIcon = All1.creditCardActiveIcon; 
export const cartPlusIcon = All1.cartPlusIcon; 
export const cartPlusActiveIcon = All1.cartPlusActiveIcon;
export const escrowReloadIcon = All1.escrowReloadIcon; 
export const escrowReloadActiveIcon = All1.escrowReloadActiveIcon;
export const userPurgeIcon = All1.userPurgeIcon;
export const userPurgeActiveIcon = All1.userPurgeActiveIcon;
export const userClockIcon = All1.userClockIcon;
export const userClockActiveIcon = All1.userClockActiveIcon;
export const userLockIcon = All1.userLockIcon;
export const userLockActiveIcon = All1.userLockActiveIcon;
export const doorOpenIcon = All1.doorOpenIcon;
export const doorOpenActiveIcon = All1.doorOpenActiveIcon;
export const databaseIcon = All1.databaseIcon;
export const databaseActiveIcon = All1.databaseActiveIcon;
export const stopWatchIcon = All1.stopWatchIcon;
export const stopWatchActiveIcon = All1.stopWatchActiveIcon;
export const stethoscopeIcon = All1.stethoscopeIcon;
export const stethoscopeActiveIcon = All1.stethoscopeActiveIcon;
export const cogIconBlack = All1.cogIconBlack;
export const paperclipIconBlue = All1.paperclipIconBlue;
export const loginIcon = All1.loginIcon;
export const loginActiveIcon = All1.loginActiveIcon; 
export const minusIcon = All1.minusIcon;
export const filterIcon = All1.filterIcon;

export const registeredLandIcon = All2.registeredLandIcon;
export const registeredLandActiveIcon = All2.registeredLandActiveIcon;
export const nameIcon = All2.nameIcon; 
export const nameActiveIcon = All2.nameActiveIcon; 
export const fileIcon = All2.fileIcon; 
export const fileActiveIcon = All2.fileActiveIcon; 
export const fileCodeIcon = All2.fileCodeIcon; 
export const grantorGranteeBookIcon = All2.grantorGranteeBookIcon;
export const grantorGranteeBookActiveIcon = All2.grantorGranteeBookActiveIcon;
export const copyrightIcon = All2.copyrightIcon; 
export const bookmarkIcon = All2.bookmarkIcon; 
export const userIcon = All2.userIcon;
export const userIconBlack = All2.userIconBlack;
export const userActiveIcon = All2.userActiveIcon; 
export const accountIcon = All2.accountIcon; 
export const accountIconBlack = All2.accountIconBlack; 
export const accountActiveIcon = All2.accountActiveIcon;  

export const creditCardIcon2 = All2.creditCardIcon;
export const creditCardActiveIcon2 = All2.creditCardActiveIcon;

export const redoIcon = All1.redoIcon;

export const warningIcon = All1.warningIcon; 
export const warningSmallIcon = All1.warningSmallIcon; 
export const infoIcon = All1.infoIcon; 
export const infoSmallIcon = All1.infoSmallIcon; 
export const backwardSmallIcon = All1.backwardSmallIcon;
export const fileDownloadSmallIcon = All1.fileDownloadSmallIcon;

export const questionIcon = All2.questionIcon;

export const auditLogPurgeIcon = All1.removeFormatIcon;
export const auditLogPurgeActiveIcon = All1.removeFormatActiveIcon;

export const paperPlaneIcon = All1.paperPlaneIcon;
export const mobileIcon = All1.mobileIcon;
export const commentIcon = All1.commentIcon;
export const externalLinkIcon = All1.externalLinkIcon;
export const calendarIcon = All1.calendarIcon;
export const expandIcon = All1.expandIcon;
export const listIcon = All1.listIcon;

export const fileImportIcon = All1.fileImportIcon;
export const fileImportActiveIcon = All1.fileImportActiveIcon;

export const languageIcon = All1.languageIcon;
export const languageActiveIcon = All1.languageActiveIcon;

