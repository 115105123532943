import React from 'react';
import { connect } from "react-redux";
import { copyrightIcon } from "../../img/font_awesome/font_awesome"
import landshark_logo from '../../img/trimin_landshark_verysmall.png';
import packageJson from '../../../package.json';

const mapStateToProps = state => {
    return {
        serverProductVersions: state.serverProductVersionsReducer.combinedVersion
    };
}

class Footer extends React.Component {

    handleTriminClick = (event) => {
		event.preventDefault();
		window.open("https://www.trimingov.com/");
	};

	render() {
		let newDate = new Date()
		let year = newDate.getFullYear();

		if (year > 2022) { // If the current year is 2021, show only 2021.  Otherwise, show a range of 2021-currrent year.
			year = '2022-' + year;
		}

        return (
            <footer style={{ borderTop: '3px solid black' }}>
                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}>
                    <div>
                        <img border="0" src={landshark_logo} alt="Trimin Landshark" style={{ paddingBottom: 5, cursor: "pointer" }} onClick={this.handleTriminClick} />
                        <span style={{ marginLeft: 5, verticalAlign: 'bottom' }}>v{packageJson.version}u{this.props.serverProductVersions}</span>
                        <br />Designed, engineered and supported in St Paul, MN.  Part of the land record managment software suite by TriMin Systems.  
                    </div>
                    <div>{copyrightIcon} {year} TriMin Systems Inc.  All Rights Reserved.</div>
                </div>
            </footer>
        )
	}
}

export default connect(mapStateToProps)(Footer);
