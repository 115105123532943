import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import { parse } from 'query-string';
import {backendLogger} from "../../logger.js"

import configData from "../../config/config.json";

class CreditCardEfilePayment extends Component {

  constructor(props) {
    super(props)
    this.authFormRef = React.createRef();
    this.state = {previousShow: this.props.show};
  }

  handleAuthorizeDotNetCancelClicked = () => {
    this.props.cancelPayment();
  }

  handleAuthorizeDotNetPayClicked = (json) => {
    this.props.makePayment(json);
  }

  handleCancelClick = (event) => {
    if (event) {
       event.preventDefault();
    }
    this.props.cancelPayment();
  }

  componentDidUpdate() {
    if (this.props.show && !this.state.previousShow) {
      this.setState( {previousShow: this.props.show} );
      this.submitAuthorizeDotNetForm();
      
      window.AuthorizeNetIFrame = {
        onReceiveCommunication: queryStr => {
          console.log('received communication: ', queryStr);
  
          const message = parse(queryStr);
          if (this.isValidMessage(message)) {
  
            switch (message.action) {
              case 'transactResponse':
                const json = JSON.parse(message.response);
                console.log('processing transactRepsponse: ', json);
                this.handleAuthorizeDotNetPayClicked(json);
                backendLogger.info("Recieved called back from iframecommunicator.html: " + JSON.stringify(json));
                break;
  
              case 'resizeWindow':
                break;
  
              case 'cancel':
                this.handleAuthorizeDotNetCancelClicked();
                break;
              default:
                console.log('Unknown message action from authorize.net:[' + message.action + ']')
            };
          }
        },
      };
    } else if (!this.props.show && this.state.previousShow) {
      this.setState( {previousShow: this.props.show} );

      if (window.AuthorizeNetIFrame) {
        delete window.AuthorizeNetIFrame;
      }
    } else {
      // Show = previous show, no change
    }
  }

  submitAuthorizeDotNetForm() {
    if (this.authFormRef.current) {
      this.authFormRef.current.submit();
    }
  }

  isValidMessage(o) {
    return typeof o === 'object' && o !== null
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = configData.AUTHORIZE_ACCEPTJS_URL;

    window.AuthorizeNetIFrameLogging = {
      log: logStr => {
        backendLogger.info(logStr);
      },
    };
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleCancelClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Credit Card Payment For E File
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ background: '#408CC7' }}>
          <iframe title='Credit Card Payment for Efile'  id="load_payment" name="load_payment" width="100%" height="500px" scrolling="yes" />
          <form ref={this.authFormRef} id="send_hptoken" action={configData.AUTHORIZE_PAYMENT_URL} method="post" target="load_payment">
            <input type="hidden" name="token" value={this.props.token} />
          </form>
        </Modal.Body>
        <Modal.Footer >
          <Container >
            <Row style={{ margin: 5 }} >
              <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                <Button variant="secondary" onClick={this.handleCancelClick}>Cancel</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default CreditCardEfilePayment;