import React from 'react';
import { Form } from 'react-bootstrap'
import '../../../landshark.css';

// Radio Buttons element. 
// Its options are defaulted to Yes and No if options are not provided. 
export default class RadioGroup extends React.Component {

   render() {

      // if options are not provided, assume it's a Yes or No dropdown.
      let options = this.props.formGroup.options;
      if (!options) {
         options = [{code : true, description: "Yes"}, { code: false, description: "No"}]
      }

      let radioButtons = 
      <>
         {
            options.map((option) => {
                  return <Form.Check name={this.props.formGroup.id} type='radio' checked={this.props.formGroup.value === option.code} id={option.code} label={option.description} onChange={this.props.onChange} />
            })
         }
      </>

      let fontWeight = 'normal';
      if (this.props.formGroup.primaryCriteria) {
         fontWeight = 'bold';
      }

      let clazzName = 'form-inline-block';
      return (
         <div className={clazzName} style={{ marginLeft: 4 }}>
            <div style={{ fontWeight }}>{this.props.formGroup.label}: &nbsp;</div> 
            {radioButtons}
         </div>
      )
   }
}