import React from 'react';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { 
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class SideNavigationLabel extends React.Component {

    render () {
    	let label = <div style={{textAlign:"left"}}> <span style={{width:18, display:"inline-block"}}>{this.props.image}</span></div>;
    	if (this.props.sideNavigationOpen) {
      		label =  <div style={{textAlign:"left"}}> <span style={{width:18, display:"inline-block"}}>{this.props.image}</span><span>&nbsp;&nbsp;{this.props.name}&nbsp;&nbsp;</span><span style={{"backgroundColor":"lightGray"}} className="badge badge-secondary">{this.props.badge}</span> </div>;
    	}
       return  (label)
     }
 }

 export default connect(mapStateToProps)(SideNavigationLabel);