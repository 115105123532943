import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByBookAndPageKey, SearchByBookAndPageUrl } from "../../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../constants/constants";
import translations from "../../../config/translation/search_by_book_and_page_translations.json";

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByBookAndPageKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByBookAndPage: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByBookAndPageUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    notableDates: state.notableDatesReducer,
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    isBookType: state.landlinkConfigReducer.bookType,
    bookLabel: state.countyConfigCommonReducer.bookLabel,
    bookTypes: state.bookTypesReducer.codeDescriptionResultList,
    book: previousSearchKeyValue.book ? previousSearchKeyValue.book : null,
    page: previousSearchKeyValue.page ? previousSearchKeyValue.page : null,
    bookType: previousSearchKeyValue.bookType ? previousSearchKeyValue.bookType : 'ALL',
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'
  };
}

class SearchByBookAndPage extends Component {

  state = {
    book: this.props.book,
    page: this.props.page,
    bookType: this.props.bookType,
    loadingStatus: '',
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByBookAndPage(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.book) {
      errors["book"] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    this.setState({
      submitMessage: '',
      book: '',
      page: '',
      bookType: this.props.isBookType === 'true' ? 'all' : null
    })
  }

  render() {

    let formGroupRows = [
      {
        formGroups: [{ id: 'book', label: this.props.language !== 'ENGLISH' ? translations[this.props.language].book : this.props.bookLabel, primaryCriteria: true, fieldType: 'text', value: this.state.book, required: true, error: this.state.errors['book'] }]
      },
      {
        formGroups: [{ id: 'page', label: translations[this.props.language].page, fieldType: 'text', value: this.state.page, required: false }]
      }
    ]

    if (this.props.isBookType === true) {
      formGroupRows.push({
        formGroups: [{ id: 'bookType', label: translations[this.props.language].book_type, fieldType: 'select', value: this.state.bookType, options: this.props.bookTypes }]
      });
    }



    const formProps = {
      componentName: componentName,
      pageHeading: this.props.language !== 'ENGLISH' ? translations[this.props.language].search_by_book_and_page : "Search by " + this.props.bookLabel + " & Page",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      primaryButtonLabel: translations[this.props.language].submit,
      clearButtonLabel: translations[this.props.language].clear,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }
    return <FormTemplate {...formProps} />
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByBookAndPage);