import React, {Component} from 'react';
import { connect } from "react-redux";
import { CardColumns } from 'react-bootstrap'
import axios from "axios";
import configData from "../../config/config.json";

import CommonTop from "../../components/top/CommonTop";

import ContactUs from './ContactUs';
import Disclaimer from './Disclaimer';
import LatestNews from './LatestNews';
import Links from './Links';
import NotableDates from './NotableDates';
import Transactions from './Transactions';
import LoginPageSlides from './LoginPageSlides';
import { LOADING_STATUES } from "../constants/constants";
import Loading from '../common/modal/Loading';

const mapStateToProps = state => {
  return {
    currentUser: state.currentUserReducer
  };
}

const matchDispatchToProps = (dispatch) => {
  return {
    handleLockout: (lockout) =>  dispatch({ type: 'SET_LOCKOUT_IN_PLACE', lockout })
  }
}

class Home extends Component {

    state = {
        contactUsCard: false,
        disclaimerCard: false,
        latestNewsCard: false,
        linksCard: false,
        loginSlidesCard: false,
        notableDatesCard: false,
        transactionsCard: false,

        numberOfSlides: 0,
        contactPhone: '',
        contactEmail: '',

        notableDates: null,
        transactionTotals: null,

        links: [],
        newsItems: [],
        loadingStatus: ""
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }
    
      hideLoading = (message, status) => {
        if (status === 'ERROR') {
          this.setState({
            loadingStatus: "",
            submitMessage: ""
          })
        } else {
          this.setState({
            loadingStatus: "",
            submitMessage: message
          })
        }
      }

    componentDidMount() {

        this.showLoading();

        axios.get(configData.LANDSHARK_SERVER_URL + "home/all", { withCredentials: true })
        .then(
            (response) => {
                this.props.handleLockout(response.data.lockout);

                const content = response.data.content;

                let links = content.links.paragraphs;
                if (!links || links.length === 0) {
                    links = []
                }

                let newsItems = content.latestNews.paragraphs;
                if (!newsItems || newsItems.length === 0) {
                    newsItems = []
                }

                this.setState( {
                  contactUsCard: content.selections.contactUs,
                  disclaimerCard: content.selections.disclaimer,
                  latestNewsCard: content.selections.latestNews,
                  linksCard: content.selections.links,
                  loginSlidesCard: content.selections.loginSlides,
                  notableDatesCard: content.selections.notableDates,
                  transactionsCard: content.selections.transactions,
                  numberOfSlides: content.numberOfSlides.numberOfSlides,
                  contactPhone: content.common.contactPhone,
                  contactEmail: content.common.contactEmail,
                  notableDates: content.notableDates,
                  transactionTotals: content.transactionTotals,
                  links,
                  newsItems
                },
                function() {   // callback function after the state is set
                    this.hideLoading();
                });
            },
            (error) => {
                //   handleError(error, this.props.history);
                this.hideLoading();
            }
        );
    }


    render() {
        return (
            <>
                <CommonTop history={this.props.history} />
                <div style={{paddingBottom: 2, marginLeft: 10, marginRight: 6, marginTop: 2 }} >
                <CardColumns style={{marginTop: 20, marginLeft:50, marginRight: 50}}>
                    {   
                        this.state.loginSlidesCard &&
                        <LoginPageSlides numberOfSlides={this.state.numberOfSlides} />
                    }
                    {
                        this.state.linksCard &&
                        <Links links={this.state.links} />
                    }
                    {
                        this.state.notableDatesCard &&
                        <NotableDates notableDates={this.state.notableDates} />
                    }
                    {
                        this.state.transactionsCard && this.props.currentUser.role !== 'GUEST' &&
                        <Transactions transactionTotals={this.state.transactionTotals} />
                    }
                    {
                        this.state.latestNewsCard &&
                        <LatestNews newsItems={this.state.newsItems} />
                    }
                    {
                        this.state.contactUsCard &&
                        <ContactUs contactEmail={this.state.contactEmail} contactPhone={this.state.contactPhone} ></ContactUs>
                    }
                    {
                        this.state.disclaimerCard &&
                        <Disclaimer links={this.state.links} />
                    }
                </CardColumns>
                </div>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            </>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(Home);