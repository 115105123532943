const initialState = { 
      "timestamp":0,
      "batch":1,
      "totalRecordCount":0,
      "currentRecordCount":0,
      "searchResultList":[]
};

const searchResultsFilterGrantorGranteeBookReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'FILTER_GRANTOR_GRANTEE_BOOK_ID':
                  const document = action.document;      
                  let index = -1;
                  for (let i = 0; i < state["searchResultList"].length; i++) {
                        if (document.id == state.searchResultList[i].id) {
                              index = i;
                              break;
                        }
                  }
                  
                  if (index > -1) { // if found, remove the document
                        state.searchResultList.splice(index, 1); 
                        state.totalRecordCount -= 1
                  } else if (state.totalRecordCount < 100) { // if not found, insert the document
                        state.searchResultList.push(document);
                        if (action.sortKey === 'id') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.id > document2.id) ? 1 : ((document2.id > document1.id) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.id > document1.id) ? 1 : ((document1.id > document2.id) ? -1 : 0))
                              }
                        } else if (action.sortKey === 'fromName') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.fromName > document2.fromName) ? 1 : ((document2.fromName > document1.fromName) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.fromName > document1.fromName) ? 1 : ((document1.fromName > document2.fromName) ? -1 : 0))
                              }
                        } else if (action.sortKey === 'fromDate') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.fromDateComparing > document2.fromDateComparing) ? 1 : ((document2.fromDateComparing > document1.fromDateComparing) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.fromDateComparing > document1.fromDateComparing) ? 1 : ((document1.fromDateComparing > document2.fromDateComparing) ? -1 : 0))
                              }
                        }
                        state.totalRecordCount += 1
                  }             
                  state.timestamp += 1;
                  return state;
            
            case 'SORT_FILTERED_GRANTOR_GRANTEE_BOOK':
                  if (action.sortKey === 'id') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.id > document2.id) ? 1 : ((document2.id > document1.id) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.id > document1.id) ? 1 : ((document1.id > document2.id) ? -1 : 0))
                        }
                  } else if (action.sortKey === 'fromName') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.fromName > document2.fromName) ? 1 : ((document2.fromName > document1.fromName) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.fromName > document1.fromName) ? 1 : ((document1.fromName > document2.fromName) ? -1 : 0))
                        }
                  } else if (action.sortKey === 'fromDate') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.fromDateComparing > document2.fromDateComparing) ? 1 : ((document2.fromDateComparing > document1.fromDateComparing) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.fromDateComparing > document1.fromDateComparing) ? 1 : ((document1.fromDateComparing > document2.fromDateComparing) ? -1 : 0))
                        }
                  }
                  return state;
            
            case 'UPDATE_SEARCH_RESULTS':    
            case 'LOGOUT_USER':
                  return {
                        "timestamp":0,
                        "totalRecordCount":0,
                        "currentRecordCount":0,
                        "searchResultList":[]   
                  }
            default:
                  break;
      }
      return state;
};
export default searchResultsFilterGrantorGranteeBookReducer;