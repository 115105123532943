import React, { Component } from "react"
import { Container, Col, Row, Table, FormCheck } from 'react-bootstrap'

import { connect } from "react-redux";

import { displayGrantorGranteeBookDetails } from '../../../redux/actions/searchActions'
import SearchPaginator from '../../common/page/SearchPaginator'
import {getPaginatorHelper} from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import { LOADING_STATUES } from "../../constants/constants";
const componentName = 'SearchResultsTable';

const mapStateToProps = state => {

    return {
        searchResults: state.searchResultsReducer,
        paginationPage: state.searchResultsPaginationReducer,    
        searchResultsSort: state.searchResultsSortReducer,     
        filterApplied: state.searchResultsFilterAppliedFilter,
        searchResultsFilter: state.searchResultsFilterGrantorGranteeBookReducer,
        searchResultsVisited: state.searchResultsVisitedReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        select: (id, description, callback) => {
            if (id) {
                dispatch(displayGrantorGranteeBookDetails(id, description, callback))
            }
        }, // For some reason, id can be null sometimes, so checking to see if id has a value.
        filterResults: (document, sortKey, sortDirection, callback) => dispatch({ type: 'FILTER_GRANTOR_GRANTEE_BOOK_ID', document, sortKey, sortDirection }, callback())
    }
}

class GrantorGranteeBookSearchResultsTable extends Component {
    state = {
        selectedId: null,
        loadingStatus: ''
    }

    showLoading = () => {
        if (this.state.loadingStatus === '') {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
            alert(message);
        }
        this.setState({
            loadingStatus: ""
        })
    }

    handleRowClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        if (this.showLoading() === true) {
            this.setState({ selectedId: clickedId })
            this.props.select(clickedId, event.target.closest("tr").getAttribute("name"), this.hideLoading);
        }
    }

    handleCheckFilterLimit = () => {
        if (this.props.searchResultsFilter.totalRecordCount >= 100) {
            this.setState(
            {
                showAlert: true,
                alertMessage: 'You have reached the limit of selecting up to 100 records.'
            });
        }
    }

    handleCheckClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        this.props.searchResults.searchResultList.map((searchResult) => {
            if (searchResult.docNumber == clickedId) {
                this.props.filterResults(searchResult, this.props.searchResultsSort.sortKey, this.props.searchResultsSort.sortDirection, this.handleCheckFilterLimit);
            }
        });
    }

    checkSelected = (id) => {
        for (const element of this.props.searchResultsFilter.searchResultList) {
            if (element.id == id) {
                return true;
            }
        }
        return false;
    }

    render() {

        let filteredList = this.props.searchResults.searchResultList;
        const numberOfVisibleRecords = 100;
        let numberOfPages = Math.ceil ( filteredList.length / numberOfVisibleRecords );
		let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);

        let displayResults = paginationHelper.currentPageStartingRecordNumber === paginationHelper.currentPageEndingRecordNumber ? 
                                paginationHelper.currentPageStartingRecordNumber :
                                paginationHelper.currentPageStartingRecordNumber + "-" + paginationHelper.currentPageEndingRecordNumber;

        return (
            <>
        
            <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                    <Table responsive striped bordered hover size="sm" style={{ maxHeight: 600, overflowY: 'auto',  display: 'inline-block'}} >
                        <thead style={{ display: 'table', width: '100%',   tableLayout: 'fixed'}}>
                            <tr>
                                <th className="table-td-xs" scope="col" style={{ width: '4%' }}></th>
                                <th className="table-td-xs" scope="col" style={{ width: '32%' }}>Book Reference ID<br />Type</th>
                                <th className="table-td-xs" scope="col" style={{ width: '32%' }}>From Name<br />To Name</th>
                                <th className="table-td-xs" scope="col" style={{ width: '32%' }}>From Date<br />To Date</th>
                            </tr>
                        </thead>
                        <tbody key={this.props.paginationPage} style={{ display: 'table', width: '100%', tableLayout: 'fixed', cursor: "pointer"}} >
                            {
                                paginationHelper.currentPageRecordList.map((searchResult) => {
                                    let selectedClass = this.state.selectedId == searchResult.docNumber ? "table-active" : ""; // highlight the selected row
                                    const selected = this.checkSelected(searchResult.id);

                                    let cursorStyle = {cursor: 'wait' };
                                    if (searchResult.id) {
                                        cursorStyle = { cursor: 'pointer' }
                                    }

                                    let badgeClass = 'badge-light';
                                    if (this.props.searchResultsVisited instanceof Set && this.props.searchResultsVisited.has(searchResult.id+"")) {
                                        cursorStyle = {...cursorStyle, ...{color: 'darkgray'}} 
                                        badgeClass = 'badge-info';
                                    }

                                    return (
                                        <tr className={selectedClass} style={cursorStyle} id={searchResult.docNumber} name={searchResult.docNumber} onClick={this.handleRowClick}>
                                            { 
                                                selected &&
                                                <td className="table-td-xs" style={{ width: '4%' }} ><FormCheck onChange={this.handleCheckClick} checked={true} disabled={this.props.filterApplied}></FormCheck></td>
                                            }
                                            { 
                                                !selected &&
                                                <td className="table-td-xs" style={{ width: '4%' }} ><FormCheck onChange={this.handleCheckClick} checked={false} disabled={this.props.filterApplied}></FormCheck></td>
                                            }
                                            <td className="table-td-xs" style={{ width: '34%' }} ><strong>{searchResult.docNumber}</strong><br /><strong>{searchResult.type}</strong><br/></td>
                                            <td className="table-td-xs" style={{ width: '33%' }} >{searchResult.fromName}<br />{searchResult.toName}<br/></td>
                                            <td className="table-td-xs" style={{ width: '33%' }} >{searchResult.fromDate}<br/>{searchResult.toDate}</td>
                                      </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row>
                        <Col style={{ marginLeft: 4}} ><SearchPaginator style={{justifyContent: 'center'}} componentName={componentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></SearchPaginator></Col>
					</Row>
                    <Row>
                        <Col style={{ marginLeft: 4}} >Displaying Results: {displayResults} of {filteredList.length}</Col>
                    </Row>
                </Container>
        
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrantorGranteeBookSearchResultsTable);