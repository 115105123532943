import React, {Component} from 'react';
import { connect } from "react-redux";
import { Container, Row, Col } from 'react-bootstrap'
import Alert from '../common/modal/Alert';
import axios from "axios";
import configData from "../../config/config.json";
import usStates from "../../config/us_states.json";
import translations from "../../config/translation/efile_translations.json";
import FormTemplate from "../common/form/FormTemplate";
import { LOADING_STATUES } from "../constants/constants";
import CommonTop from "../top/CommonTop";
import EfileConfirmation from "./EfileConfirmation";
import CreditCardEfilePayment from "./CreditCardEfilePayment";
import moment from 'moment';
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"
import AjaxResult from "../../utils/AjaxResult";
import {backendLogger} from "../../logger.js"
import { validateUser } from "../common/function/userValidation.js";

const componentName = "efile";

const mapStateToProps = state => {
  return {
    countyStateCode: state.productPropertiesReducer.countyStateCode,
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    countyConfigEfile: state.countyConfigEfileReducer
  };
}

const matchDispatchToProps = (dispatch) => {
  return {
    handleLockout: (lockout) =>  dispatch({ type: 'SET_LOCKOUT_IN_PLACE', lockout }),
    updateUsageBalance: (usageBalance) =>  dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance })
  }
}

const PAYMENT_METHOD_CREDIT_CARD = 'CREDIT_CARD';

class Efile extends Component {

  state = {
    selectedFile: null,
    fileKey: moment(),
    efileType: 'LR',
    numberOfPages: 0,
    audtofillChecked: false,
    name: '',
    email: '',
    city: '',
    state: this.props.countyStateCode ? this.props.countyStateCode: 'MP',
    zip: '',
    address1: '',
    address2: '',
    submitMessage: '',
    uploadedFile: '',
    paymentMethod: '',
    transactionNumber: '',
    imageId: '',
    showConfirmationDialog: false,
    loadingStatus: '',
    firstPageCharge: '',
    additionalPagesCharge: '',
    surcharge: '',
    totalCharge: '',

    userName: '',
    userAddress: '',
    userCity: '',
    userState: '',
    userZip: '',
    userEmail: '',

    showAlert: false,
		alertMessage: "",
    efileSubmitPending: false, 

    acknowledgement: false,

    errors: {}
  }

  validate = () => {
    if (!this.state.selectedFile) {
      this.setState(
        { errors: { 'uploadedFile': translations[this.props.language].required } }
      );
      return false;
    }

    let errors = validateUser(this.state, false, false);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}

    if (this.props.countyConfigEfile.acknowledgement === true && !this.state.acknowledgement) {
      this.setState(
        { errors: { 'acknowledgement': 'Please read and select the checkbox to acknowledge.'} }
      );
      return false;
    }

    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validate()) {
      const formData = new FormData();
      formData.append("image", this.state.selectedFile);

      const allowedFormats = new Set(["application/pdf", "image/tiff"]);

      if (!allowedFormats.has(this.state.selectedFile.type)) {
        this.setState(
          { errors: { 'uploadedFile': translations[this.props.language].invalid_file_format } }
        );
        return false;
      }

      if (this.state.selectedFile.size > 25000000) {
        this.setState(
          { errors: { 'uploadedFile': translations[this.props.language].exceeds_max_size } }
        );
        return false;
      }

      formData.append('json',
        new Blob([JSON.stringify(
          {
            efileType: this.state.efileType,
            numberOfPages: this.state.numberOfPages,
            name: this.state.name,
            email: this.state.email,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            address1: this.state.address1,
            address2: this.state.address2,

            transactionNumber: this.state.transactionNumber,
            imageId: this.state.imageId,

            firstPageCharge: this.state.firstPageCharge,
            additionalPagesCharge: this.state.additionalPagesCharge,
            surcharge: this.state.surcharge,
            totalCharge: this.state.totalCharge
          }
        )], { type: "application/json" }));

      if (this.showLoading() === true) {
        axios({ method: "post", url: configData.LANDSHARK_SERVER_URL + "efile/image", data: formData, headers: { "Content-Type": "multipart/form-data" }, withCredentials: true })
          .then((response) => {
            let content = response.data.content;
            this.setState(
              {
                numberOfPages: content.numberOfPages,
                firstPageCharge: content.firstPageCharge,
                additionalPagesCharge: content.additionalPagesCharge,
                paymentMethod: content.paymentMethod,
                surcharge: content.surcharge,
                totalCharge: content.totalCharge,
                imageId: content.imageId,
                loadingStatus: ""
              }, this.showConfirmation);
          }, (error) => {
            this.setState({ loadingStatus: "" }, function() {
              AjaxResponseHandler.handleError(error, this.showAlert, this.props.history);
            }); 
       });
      }

    }
  }

  componentDidMount() {
		axios.get(configData.LANDSHARK_SERVER_URL + "currentUser/profile", { withCredentials: true })
		.then(
			(response) => {
				const content = response.data.content;
				this.setState( {
               userName: content.firstName + ' ' + content.lastName,
               userAddress: content.address,
               userCity: content.city,
               userState: content.state,
               userZip: content.zip,
               userEmail: content.email
				});
			},
			(error) => {
			   console.log(error);
			}
		);
	}

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    } 
    return false;
  }

  hideLoading = (message, status) => {
    if (status === 'ERROR') {
      alert(message);
      this.setState({
        loadingStatus: "",
        submitMessage: ""
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message
      })
    }
  }

  handleClear = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      fileKey: moment(),
      selectedFile: null,
      efileType : 'LR',
      numberOfPages: 0,
      audtofillChecked: false,
      name : '',
      email : '',
      address1 : '',
      address2 : '',
      city: '',
      state: 'MP',
      zip: '',
      uploadedFile: '',
      submitMessage: '',
      transactionNumber: '',
      imageId: '',
      firstPageCharge: '',
      additionalPagesCharge: '',
      surcharge: '',
      totalCharge: '',
      showConfirmationDialog: false,
      acknowledgement: false,
      
      errors: {}
    })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showConfirmation = () => {
    console.log("method: " + this.state.paymentMethod);

    this.setState({
      showConfirmationDialog: true
    })
  }
	
	hideConfirmation = () => {
		this.setState({
			showConfirmationDialog: false
		})
	}

  hidePaymentPage = () => {
    this.setState({
      showCreditCardPaymentDialog: false
    })
  }

  handlePay = () => {
    if (this.state.paymentMethod != PAYMENT_METHOD_CREDIT_CARD) {
      this.efileSubmit(0);
      return;
    }

    let me = this;

    (async () => {
      await
        axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/efileCapture", 
          {amount: this.state.totalCharge}, { withCredentials: true })
          .then((response) => {
            this.props.handleLockout(response.data.lockout);
            let result = new AjaxResult(response);

            this.setState({
                efileSubmitPending: false,
                showCreditCardPaymentDialog: true,
                showConfirmationDialog: false,
                authAndCaptureToken: result.content().tokenResults.token
            });
          }, (error) => {
            this.setState({ loadingStatus: "" }, function() {
              AjaxResponseHandler.handleError(error, this.showAlert, this.props.history);
            }); 
          })
    })();
  }

  efileSubmit = (transactionId) => {
    if (this.showLoading() === true) {
      axios.post(configData.LANDSHARK_SERVER_URL + "efile/submission",
        {
          efileType: this.state.efileType,
          numberOfPages: this.state.numberOfPages,
          name: this.state.name,
          email: this.state.email,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          address1: this.state.address1,
          address2: this.state.address2,

          paymentTransactionId: transactionId,
          transactionNumber: this.state.transactionNumber,
          imageId: this.state.imageId,

          firstPageCharge: this.state.firstPageCharge,
          additionalPagesCharge: this.state.additionalPagesCharge,
          surcharge: this.state.surcharge,
          totalCharge: this.state.totalCharge
        }, { withCredentials: true })
        .then((response) => {
          this.props.handleLockout(response.data.lockout);
          let content = response.data.content;
          this.handleClear();
          this.setState(
            {
              efileSubmitPending: false, 
              loadingStatus: "",
              transactionNumber: content.transactionNumber
            }, this.showAlert("Transaction " + content.transactionNumber + " was successfully submitted.  Check your email for a confirmation."));
          this.props.updateUsageBalance(content.usageBalance);
        }, (error) => {
          this.setState({ efileSubmitPending: false, loadingStatus: "" }, this.showAlert(error.message));
        });
    } else {
      this.setState( {efileSubmitPending: false});
    }
  }

  handleComplete = (transactionResponse) => {
    let request = transactionResponse;
    console.log(request);

    backendLogger.info("Attempting to call creditcard/efileAuthAndCaptureResponse with transactionResponse: " + JSON.stringify(request));
    axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/efileAuthAndCaptureResponse", request, { withCredentials: true })
      .then((response) => {
        console.log('notify server of auth and capture response ', response);
        this.props.handleLockout(response.data.lockout);

        this.setState({
          showCreditCardPaymentDialog: false,
          paymentTransactionId: request.transId
        })
      }, (error) => {
        this.setState({ loadingStatus: "", showCreditCardPaymentDialog: false }, this.showAlert(error.message));
      })

    this.efileSubmit(transactionResponse.transId);
  }
  
  // On file select (from the pop up)
  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0], errors: {} });
  };    

  onAutoFillChange = event => {
    event.preventDefault();
    if (this.state.audtofillChecked && this.state.audtofillChecked === true) {
      this.setState({
        audtofillChecked: false,
        name : '',
        email : '',
        address1 : '',
        address2 : '',
        city: '',
        state: 'MP',
        zip: '',
        errors: {}
      })
    } else {
      this.setState({
        audtofillChecked: true,
        name : this.state.userName,
        email : this.state.userEmail,
        address1 : this.state.userAddress,
        address2 : '',
        city: this.state.userCity,
        state: this.state.userState,
        zip: this.state.userZip,
        errors: {}
      })
    }
  };   
  
  onAcknowledgementChange = event => {
    event.preventDefault();
    if (this.state.acknowledgement && this.state.acknowledgement === true) {
      this.setState({
        acknowledgement: false,
        errors: {}
      })
    } else {
      this.setState({
        acknowledgement: true,
        errors: {}
      })
    }
  }; 

  showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
    let me = this;

		this.setState({
			showAlert: false,
			alertMessage: ""
		}, function() {
      if (me.state.efileSubmitPending) {
        me.efileSubmit('');
      }
    })
	}

  render() {

    let formGroupRows = [       
      {
        formGroups: [
          { 
            id: 'efileType', 
            label: translations[this.props.language].document_type, 
            fieldType: 'select', 
            primaryCriteria: true, 
            options: [{ code: 'LR', description: 'Land Record'}, { code: 'MAP', description: 'Map'}, { code: 'MAR', description: 'Marriage License'}, { code: 'UCC1', description: 'UCC-1'}, { code: 'UCC2', description: 'UCC-2'}],
            value: this.state.efileType
          }
        ]
      },
      {
        formGroups: [{ id: 'uploadedFile', fieldType: 'file', label: translations[this.props.language].document_upload, key: this.state.fileKey, onChange: this.onFileChange,  error: this.state.errors['uploadedFile'] }]
      },
      {
        formGroups: [{ fieldType: 'blankrow' }]
      },
      {
        formGroups: [{ id: 'autofill', fieldType: 'checkbox', label: translations[this.props.language].use_profile, onChange: this.onAutoFillChange, checked: this.state.audtofillChecked, error: this.state.errors['audtofill'] }]
      },
      {
        formGroups: [{ id: 'name', label: translations[this.props.language].name, fieldType: 'text', value: this.state.name, required: true, error: this.state.errors['name'] },
                     { id: 'email', label: translations[this.props.language].email, fieldType: 'email', value: this.state.email, required: true, error: this.state.errors['email'] } ]
      },
      {
        formGroups: [{ id: 'address1', label: translations[this.props.language].address1, fieldType: 'text', required: true, value: this.state.address1, error: this.state.errors['address1'] }, 
                     { id: 'address2', label: translations[this.props.language].address2, fieldType: 'text', placeholder: '(Optional)', value: this.state.address2, error: this.state.errors['address2'] }]
      },
      {
        formGroups: [{ id: 'city', label: translations[this.props.language].city, fieldType: 'text', value: this.state.city, required: true, error: this.state.errors['city'] }, 
                     { id: 'state', label: translations[this.props.language].state, fieldType: 'select', value: this.state.state, options: usStates, required: true }, 
                     { id: 'zip', label: translations[this.props.language].zip, fieldType: 'zip', style: {maxWidth: 80}, required: true, maxLength: 5, value: this.state.zip, error: this.state.errors['zip'] }]
      }
    ]

     if (this.props.countyConfigEfile.acknowledgement === true) {
      formGroupRows.push({
        formGroups: [{ fieldType: 'blankrow' }]      
      });
      // When Turnkey has the new translation file, we will need to check for the Languages Config setting.  If Language Config is true, use the translation file for the acknowledgement statement.
      formGroupRows.push({
        formGroups: [{ id: 'acknowledgement', fieldType: 'checkbox', label: this.props.countyConfigEfile.acknowledgementStatement, onChange: this.onAcknowledgementChange, checked: this.state.acknowledgement, error: this.state.errors['acknowledgement'] }]
      });
    }
 
    const formProps = {
      componentName: componentName,
      pageHeading: translations[this.props.language].e_file,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      formOnly: true,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formGroupRows: formGroupRows
    }

    return <>
    <CommonTop history={this.props.history} />
    <div style={{ marginTop: 0 }}>
					<Container>
						<Row >
							<Col style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginTop: 0 }}>
								  <FormTemplate {...formProps} />
							</Col>
						</Row>
					</Container>
				</div>
    <EfileConfirmation 
      show={this.state.showConfirmationDialog} 
      cancelConfirmation={this.hideConfirmation} proceedConfirmation={this.handlePay} 
      firstPageCharge={this.state.firstPageCharge} 
      additionalPagesCharge={this.state.additionalPagesCharge} 
      paymentMethod={this.state.paymentMethod}
      surcharge={this.state.surcharge}  
      totalCharge={this.state.totalCharge} 
      numberOfPages={this.state.numberOfPages} />
    <CreditCardEfilePayment
      show={this.state.showCreditCardPaymentDialog} token={this.state.authAndCaptureToken}
      cancelPayment={this.hidePaymentPage} makePayment={this.handleComplete} />
    <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
    </>
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(Efile);
