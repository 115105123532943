import axios from "axios";
import configData from "../../config/config.json";
import AjaxResponseHandler from "../../utils/AjaxResponseHandler"

const handleLandSharkConfigResults = (dispatch, results) => {
  results.namedContentResultList.forEach((result) => {
    dispatch({ type: result.name, results: result.baseContent });
  });
};

export const getLandSharkConfig = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/landshark")
    .then(
      (response) => { handleLandSharkConfigResults(dispatch, response.data.content); },
      (error) => { console.log(error); }
    );
};

export const getNotableDates = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/notableDates")
    .then(
      (response) => {dispatch({type: 'LOAD_NOTABLE_DATES' , result: response.data.content}) },
      (error) => { console.log(error); }
    );
};

export const getServerProductVersions = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/productVersions")
    .then(
      (response) => {dispatch({type: 'SERVER_PRODUCT_VERSIONS' , result: response.data.content}) },
      (error) => { console.log(error); }
    );
};

const handleLandSharkConfigCodeDescriptionResults = (dispatch, results) => {
  results.namedCodeDescriptionResultList.forEach((result) => {
    dispatch({ type: result.name, results: result.codeDescriptionResults })
  }
  );
};

export const bootstrap = (afterFunction) => {
  let request = {};

  // Form the URL from the current page URL.
  console.log('bootstrapping');
  console.log("PUBLIC_URL [" + window.trimin.PUBLIC_URL + "]");

  let url = window.trimin.PUBLIC_URL + "/landsharkui/product.json";

  console.log("loading configuration from " + url);

  return (axios.get(url, request, { withCredentials: true })
      .then((response) => {
        const content = response.data;

        console.log("configuration:");
        console.log(content);

        configData.COUNTY_DIRECTORY = content.COUNTY_DIRECTORY;
        configData.BROWSER_ROUTER_BASENAME = content.BROWSER_ROUTER_BASENAME;
        configData.LANDSHARK_SERVER_URL = content.LANDSHARK_SERVER_URL;
        configData.AUTHORIZE_PAYMENT_URL = content.AUTHORIZE_PAYMENT_URL;
        configData.AUTHORIZE_ACCEPTJS_URL = content.AUTHORIZE_ACCEPTJS_URL;
        

        console.log("final configuration");
        console.log(configData);

        if (afterFunction) afterFunction();
      }, (error) => {
        AjaxResponseHandler.handleError(error, AjaxResponseHandler.consoleLogHandler);

        console.log("**********************************");
        console.log("**** CANNOT LOAD CONFIGURATION ***");
        console.log("**********************************");

        if (afterFunction) afterFunction();
      })
  );
}

export const getLandSharkCodeDescriptionConfig = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/landsharkcodedescription")
    .then(
      (response) => { handleLandSharkConfigCodeDescriptionResults(dispatch, response.data.content); },
      (error) => { console.log(error); }
    );
};

const handleLandLinkConfigResults = (dispatch, results) => {
  results.namedCodeDescriptionResultList.forEach((result) => {

    if (result.name === 'LANDLINK_COUNTY_CONFIG') {
      // See the list in HelperDataCountyConfigServiceImpl.java in LandLink.
      dispatch({ type: result.name, results: result.codeDescriptionResults })
      // result.codeDescriptionResults.codeDescriptionResultList.forEach((countyConfigResult) => dispatch({ type: countyConfigResult.code, result: countyConfigResult }));
    } else if (result.name === 'LANDLINK_BOOK_TYPES' ||
      result.name === 'LANDLINK_DOCUMENT_TYPES' ||
      result.name === 'LANDLINK_DOCUMENT_TYPES_WITH_BLANKS' ||
      result.name === 'LANDLINK_INSTRUMENT_CODES' ||
      result.name === 'LANDLINK_INSTRUMENT_CODES_WITH_BLANKS' ||
      result.name === 'LANDLINK_INSTRUMENT_GROUPS' ||
      result.name === 'LANDLINK_OTHER_LOT_CODES' ||
      result.name === 'LANDLINK_PLATS' || result.name === 'LANDLINK_PLATS_SORTED_BY_NUMBER' || result.name === 'LANDLINK_PLAT_NAMES' || result.name === 'LANDLINK_PLAT_NUMBERS') {
      dispatch({ type: result.name, results: result.codeDescriptionResults })
    }
  }
  );
};

export const getLandLinkConfig = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/landlink")
    .then(
      (response) => { handleLandLinkConfigResults(dispatch, response.data.content); },
      (error) => { console.log(error); }
    );
};

const handlePlatResults = (dispatch, results) => {
  dispatch({ type: 'LOAD_PLAT_RESULTS', results });
};

export const getPlatList = () => async dispatch => {
  await axios.get(configData.LANDSHARK_SERVER_URL + "helperdata/plats")
    .then(
      (response) => { handlePlatResults(dispatch, response.data.content); },
      (error) => { console.log(error); }
    );
};
