import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import { connect } from "react-redux";

const mapStateToProps = state => {
   return {
      preauthAmount: state.countyConfigCreditCardExtraReducer.maxAmount,
      surchargeMessage: state.countyConfigCreditCardExtraReducer.surchargeMessage,
      searchRates: state.currentUserReducer.creditCardSearchRates
   };
}

class CreditCardSearchReAuthWindow extends Component {

   render() {

      let searchRates = [];
      if (this.props.searchRates) {
         this.props.searchRates.forEach((searchRate, index) => {
            searchRates.push(<Row key={searchRate.code} className="justify-content-md-left"><Col md='8'>{searchRate.code}:</Col><Col md='4'>${searchRate.description}</Col></Row>);
         })
         searchRates.push(<Row key={'emptyline1'} className="justify-content-md-left">&nbsp;</Row>);
      }
      if (this.props.surchargeMessage) {
         searchRates.push(<Row key={'surchargeMessage'}  className="justify-content-md-left"><Col md='8'>{this.props.surchargeMessage}</Col></Row>);
         searchRates.push(<Row key={'emptyline2'} className="justify-content-md-left">&nbsp;</Row>);
      }
    
      return (
         <Modal show={this.props.show} onHide={this.props.handleCloseClicked} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title>
                  Exceeding Current Credit Card Preauthorization
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
               <Container >
                  <Row className="justify-content-md-left">
                     Your charges are exceeding the current preauthorized amount.  To continue, it requires another round of credit card preauthorization of ${this.props.preauthAmount}.  Final and actual credit card charges are computed based on the below activities:
                  </Row>
                  <Row className="justify-content-md-left">&nbsp;</Row>
                  {searchRates}
               </Container>
            </Modal.Body>
            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='12' className='align-self-end' style={{ textAlign: 'right' }}>
                        <Button variant="primary" style={{ marginRight: 8 }} onClick={this.props.handleContinueClicked}>Preauthorize credit card for ${this.props.preauthAmount}</Button>
                        <Button variant="secondary" onClick={this.props.handleCloseClicked}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default connect(mapStateToProps)(CreditCardSearchReAuthWindow);
