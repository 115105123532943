import React from 'react';

import { Button } from 'react-bootstrap'

export default class PrimaryButton extends React.Component {
   render() {
      if (this.props.buttonLabel !== undefined) {
         return <Button variant="primary" type="submit" disabled={this.props.disabled}>{this.props.buttonLabel}</Button>
      }
      return (
         <Button variant="primary" type="submit" disabled={this.props.disabled} >Submit</Button>
      )
   }
}