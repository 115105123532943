import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from "./redux/store/index";

import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import packageJson from '../package.json';

import { bootstrap, getLandLinkConfig, getLandSharkConfig, getLandSharkCodeDescriptionConfig, getServerProductVersions, getNotableDates } from './redux/actions/helperActions'

// This MUST come first.
bootstrap( function() {
    console.log('bootstrap complete for homepage [' + (packageJson.homepage ? packageJson.homepage : '') +
        '] version [' + packageJson.version + ']');
    
    store.dispatch(getLandLinkConfig());
    store.dispatch(getLandSharkConfig());
    store.dispatch(getLandSharkCodeDescriptionConfig());
    store.dispatch(getServerProductVersions());
    store.dispatch(getNotableDates());
    
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});

