import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByDocumentNumberKey, SearchByDocumentNumberUrl } from "../../../redux/actions/searchActions";
import { LOADING_STATUES } from "../../constants/constants";
import translations from "../../../config/translation/search_by_document_number_translations.json";

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByDocumentNumberKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByDocumentNumber: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByDocumentNumberUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    notableDates: state.notableDatesReducer,
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    documentTypes: state.documentTypesReducer.codeDescriptionResultList,
    isPropertyCode: state.landlinkConfigReducer.propertyCode,
    allOnlySearch: state.countyConfigSearch2Reducer.allOnlySearch,
    documentType: previousSearchKeyValue.documentType ? previousSearchKeyValue.documentType : 'ALL',
    fromDocumentNumber: previousSearchKeyValue.fromDocumentNumber ? previousSearchKeyValue.fromDocumentNumber : null,
    toDocumentNumber: previousSearchKeyValue.toDocumentNumber ? previousSearchKeyValue.toDocumentNumber : null,
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'
  };
}

class SearchByDocumentNumber extends Component {

  state = {
    documentType: this.props.documentType,
    fromDocumentNumber: this.props.fromDocumentNumber,
    toDocumentNumber: this.props.toDocumentNumber,
    loadingStatus: "",
    submitMessage: '',
  
    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchDetail();
    this.props.resetSearchSequenceDocumentNumber();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByDocumentNumber(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.fromDocumentNumber) {
      errors["fromDocumentNumber"] = translations[this.props.language].required;
      this.setState({ errors: errors });
      return false;
    }

    if (this.state.toDocumentNumber) {

      if (/\D/.test(this.state.fromDocumentNumber)) {
        errors["fromDocumentNumber"] = translations[this.props.language].numeric_only_range;
        this.setState({ errors: errors });
        return false;
      }

      if (/\D/.test(this.state.toDocumentNumber)) {
        errors["toDocumentNumber"] = translations[this.props.language].numeric_only_range;
        this.setState({ errors: errors });
        return false;
      }

      if (parseInt(this.state.toDocumentNumber) < parseInt(this.state.fromDocumentNumber)) {
        errors["fromDocumentNumber"] = translations[this.props.language].invalid_numeric_range;
        errors["toDocumentNumber"] = translations[this.props.language].invalid_numeric_range;
        this.setState({ errors: errors });
        return false;
      }
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();
    this.setState({
      submitMessage: '',
      fromDocumentNumber: '',
      toDocumentNumber: '',
      documentType: 'ALL'
    })
  }

  render() {

    let formGroupRows = []

    if (this.props.isPropertyCode === true && this.props.allOnlySearch !== true && this.props.allOnlySearch !== 'true') {
      formGroupRows.push({
        formGroups: [{ id: 'documentType', label: translations[this.props.language].document_type, fieldType: 'select', value: this.state.documentType, options: this.props.documentTypes }]
      });
    }

    formGroupRows.push({
      formGroups: [{ id: 'fromDocumentNumber', label: translations[this.props.language].document_number, primaryCriteria: true, value: this.state.fromDocumentNumber, required: true, fieldType: 'text', error: this.state.errors['fromDocumentNumber'] }]
    });

    formGroupRows.push({
      formGroups: [{ id: 'toDocumentNumber', label: translations[this.props.language].to_document_number, value: this.state.toDocumentNumber, fieldType: 'text', error: this.state.errors['toDocumentNumber'] }]
    });

    const formProps = {
      componentName: componentName,
      pageHeading: translations[this.props.language].search_by_document_number,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      primaryButtonLabel: translations[this.props.language].submit,
      clearButtonLabel: translations[this.props.language].clear,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,
      
      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return (
      <>
        <FormTemplate {...formProps} />
      </>
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByDocumentNumber);