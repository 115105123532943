const initialState = {
  searchByAddress: null,
  searchByBookAndPage: null,
  searchByDate: null,
  searchByDocumentNumber: null,
  searchByGrantorGranteeBook: null,
  searchByLegalDescription: null,
  searchByName: null,
  searchByParcelId: null,
  searchByPlat: null,
  searchByTaxLien: null,
  searchByTorrensCertificate: null,
  searchByTorrensText: null,
  searchByTractBookId: null,
  searchByTractBookLegal: null,
  searchByTractIndexNMI: null
}

const searchCriteriaReducer =  (state = initialState, action)  => {
  switch (action.type) {
    case 'UPDATE_SEARCH_CRITERIA':
      let type = action.newHistory.type;
      state[type] = action.newHistory.displayCriteria;
      return state;
    case 'RESET_SEARCH_CRITERIA':
      state[action.componentName] = null;
      return state;
    case 'LOGOUT_USER':
      return {
        searchByAddress: null,
        searchByBookAndPage: null,
        searchByDate: null,
        searchByDocumentNumber: null,
        searchByGrantorGranteeBook: null,
        searchByLegalDescription: null,
        searchByName: null,
        searchByParcelId: null,
        searchByPlat: null,
        searchByTaxLien: null,
        searchByTorrensCertificate: null,
        searchByTorrensText: null,
        searchByTractBookId: null,
        searchByTractBookLegal: null,
        searchByTractIndexNMI: null
      };
    default:
          break;
  }
  return state;
}

export default searchCriteriaReducer;
