const initialState = {     
      includeInactive: false,
	filterText: ''
}

const accountListFiltersReducer = (state = initialState, action) => {

      switch (action.type) {
            case 'SET_ACCOUNT_LIST_FILTERS':
                  return {
                        ...state,
                        statusType: action.filters.statusType,
                        filterText: action.filters.filterText
                  }
            case 'LOGOUT_USER':
                  return {
                        statusType: 'ALL',
                        filterText: ''
                  }
            default:
                  return state;
      }

};
export default accountListFiltersReducer;