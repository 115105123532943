import React from 'react';

import { chevronLeftIcon, chevronRightIcon } from '../../img/font_awesome/font_awesome'
import { Button} from 'react-bootstrap'
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: () => { dispatch ({type: 'TOGGLE_SIDE_NAVIGATION'})}
  }
}

class SideNavigationToggle extends React.Component {
	
  state = {
    isToggleOn: true
  }
    
  handleClick = () => {
    this.setState({isToggleOn: !this.state.isToggleOn}, this.props.toggle());
  }

  render() {
    let buttonLeftPadding = 14;
    let buttonIcon = chevronRightIcon;
    if (this.state.isToggleOn) {
      buttonLeftPadding = 226;
      buttonIcon = chevronLeftIcon;
    }

    return (
      <Button onClick={this.handleClick} style={{float: "right", display:"block", paddingLeft: buttonLeftPadding, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, outline : 0}}>
        {buttonIcon}
       </Button>
    )
       
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigationToggle);