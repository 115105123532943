import React, { Component } from 'react';
import Pagination from 'react-bootstrap/Pagination'
import { connect } from "react-redux";
import { setSelectedPaginationPage } from "../../../redux/actions/paginatorActions";

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedPaginationPage: (componentName, activePageNumber) => dispatch(setSelectedPaginationPage(componentName, activePageNumber))
	}
}

class Paginator extends Component {
  
   state = {
      startingPageNumber: 1,  // current starting pagination number
      activePageNumber: 1, // currently selected page number
   }

   handlePageClick = (event) => {
      event.preventDefault();
      let newActivePageNumber = Number(event.target.id);
      this.setState({
         activePageNumber: newActivePageNumber
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, newActivePageNumber);
   }

   handleFirstPageClick = (event) => {
      event.preventDefault();
   
      this.setState({
         startingPageNumber: 1,
         activePageNumber: 1
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, 1);
   }

   handleForwardOnePageClick = (event) => {
      event.preventDefault();
      let newActivePageNumber = this.state.activePageNumber + 1; // move forward 1 page
      if (newActivePageNumber > this.props.numberOfPages) { // make sure it stays within the upper bound
         newActivePageNumber = this.props.numberOfPages;
      }

      let newStartingPageNumber = this.state.startingPageNumber;
      let endingPageNumber = this.state.startingPageNumber + this.props.numberOfVisibleRecords - 1;
      if (newActivePageNumber > endingPageNumber) { // if the active page is not in view, scroll forward
         newStartingPageNumber = newActivePageNumber - this.props.numberOfVisibleRecords + 1;
      }
      
      this.setState({
         startingPageNumber: newStartingPageNumber,
         activePageNumber: newActivePageNumber
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, newActivePageNumber);
   }

   handleBackwardOnePageClick = (event) => {
      event.preventDefault();
      let newActivePageNumber = this.state.activePageNumber - 1; // move backward 1 page
      if (newActivePageNumber < 1) { // make sure it stays within the lower bound
         newActivePageNumber = 1;
      }

      let newStartingPageNumber = this.state.startingPageNumber;
      if (newActivePageNumber < newStartingPageNumber) { // if the active page is not in view, scroll backward
         newStartingPageNumber = newActivePageNumber;
      }

      if (newStartingPageNumber < 1) { // make sure it stays within the lower bound
         newStartingPageNumber = 1;
      }
      
      this.setState({
         startingPageNumber: newStartingPageNumber,
         activePageNumber: newActivePageNumber
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, newActivePageNumber);
   }

   handleForwardTenPagesClick = (event) => {
      event.preventDefault();
      let newActivePageNumber = this.state.activePageNumber + 10; // move forward 10 pages
      if (newActivePageNumber > this.props.numberOfPages) { // make sure it stays within the upper bound
         newActivePageNumber = this.props.numberOfPages;
      }

      let newStartingPageNumber = this.state.startingPageNumber;
      let endingPageNumber = newStartingPageNumber + this.props.numberOfVisibleRecords - 1;
      while (newActivePageNumber > endingPageNumber) { // while the active page is not visible, scroll forward until visible
         newStartingPageNumber += this.props.numberOfVisibleRecords;
         endingPageNumber = newStartingPageNumber + this.props.numberOfVisibleRecords - 1;
      }

      if (newStartingPageNumber > this.props.numberOfPages - this.props.numberOfVisibleRecords + 1) { // make sure it stays within the upper bound
         newStartingPageNumber = this.props.numberOfPages - this.props.numberOfVisibleRecords + 1;
      }
   
      this.setState({
         startingPageNumber: newStartingPageNumber,
         activePageNumber: newActivePageNumber
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, newActivePageNumber);
   }

   handleBackwardTenPagesClick = (event) => {
      event.preventDefault();
      let newActivePageNumber = this.state.activePageNumber - 10; // move backward 10 pages
      if (newActivePageNumber < 1) { // make sure it stays within the lower bound
         newActivePageNumber = 1;
      }

      let newStartingPageNumber = this.state.startingPageNumber;
      while (newActivePageNumber < newStartingPageNumber) { // while the active page is not visible, scroll backward until visible
         newStartingPageNumber -= this.props.numberOfVisibleRecords;
      }

      if (newStartingPageNumber < 1) { // make sure it stays within the lower bound
         newStartingPageNumber = 1;
      }
      
      this.setState({
         startingPageNumber: newStartingPageNumber,
         activePageNumber: newActivePageNumber
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, newActivePageNumber);
   }

   handleLastPageClick = (event) => {
      event.preventDefault();
      let newStartingPageNumber = this.props.numberOfPages - this.props.numberOfVisibleRecords + 1;
      this.setState({
         startingPageNumber: newStartingPageNumber,
         activePageNumber: this.props.numberOfPages
      })

      this.props.setSelectedPaginationPage(this.props.componentName, this.props.numberOfPages);
   }

	componentDidMount() {
      this.setState({
         startingPageNumber: 1,
         activePageNumber: 1
      })
      
      this.props.setSelectedPaginationPage(this.props.componentName, 1);
   }

   render() {

      let endingNumber = this.state.startingPageNumber + this.props.numberOfVisibleRecords;
      if (this.props.numberOfPages < this.props.numberOfVisibleRecords) {
         endingNumber = this.state.startingPageNumber + this.props.numberOfPages;
      }     

      let paginationItems = [];
      for (let number = this.state.startingPageNumber; number < endingNumber; number++) {
         paginationItems.push(
            <Pagination.Item key={number} id={number} style={{ borderTop: '3px solid darkGray', borderBottom: '3px solid darkGray' }} onClick={this.handlePageClick} active={number === this.state.activePageNumber}> 
               {number}
            </Pagination.Item>,
         );
      }
      
      let firstPagePageLink = <></>
      let lastPagePageLink = <></>

      // round corners
      let backwardOnePagePageLink = <Pagination.Item key="backward1" id="backward1" style={{ border: '3px solid darkGray', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onClick={this.handleBackwardOnePageClick}>Prev</Pagination.Item>
      let forwardOnePagePageLink = <Pagination.Item key="forward1" id="forward1" style={{ border: '3px solid darkGray', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }} onClick={this.handleForwardOnePageClick}>Next</Pagination.Item>
      
      let backwardTenPagesPageLink = <></>
      let forwardTenPagesPageLink = <></>

      if (this.props.numberOfPages > this.props.numberOfVisibleRecords) {
         // round corners
         firstPagePageLink = <Pagination.Item key="first" id="first" style={{ borderTop: '3px solid darkGray', borderBottom: '3px solid darkGray', borderLeft: '3px solid darkGray', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} onClick={this.handleFirstPageClick}>First</Pagination.Item>
         lastPagePageLink = <Pagination.Item key="last" id="last" style={{ borderTop: '3px solid darkGray', borderBottom: '3px solid darkGray', borderRight: '3px solid darkGray', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }} onClick={this.handleLastPageClick}>Last</Pagination.Item>
         
         // change to sharp corners, as they are no longer the rightmost and leftmost page links.
         backwardOnePagePageLink = <Pagination.Item key="backward1" id="backward1" style={{ border: '3px solid darkGray' }} onClick={this.handleBackwardOnePageClick}>Prev</Pagination.Item>
         forwardOnePagePageLink = <Pagination.Item key="forward1" id="forward1" style={{ border: '3px solid darkGray' }} onClick={this.handleForwardOnePageClick}>Next</Pagination.Item>
   
         if (this.props.numberOfPages > 30) { // show the "move 10 pages" links only if the number of pages is greater than 30
            backwardTenPagesPageLink = <Pagination.Item key="backward10" id="backward10" style={{ borderTop: '3px solid darkGray', borderBottom: '3px solid darkGray', borderLeft: '3px solid darkGray' }} onClick={this.handleBackwardTenPagesClick}>-10</Pagination.Item>
            forwardTenPagesPageLink = <Pagination.Item key="forward10" id="forward10" style={{ borderTop: '3px solid darkGray', borderBottom: '3px solid darkGray', borderRight: '3px solid darkGray' }} onClick={this.handleForwardTenPagesClick}>+10</Pagination.Item>
         }
      }
      
      return (
         <>
            {  this.props.numberOfPages > 1 &&  // show pagination only if there's more than 1 page
               <Pagination size="sm">
                  {firstPagePageLink}
                  {backwardTenPagesPageLink}
                  {backwardOnePagePageLink}
                  {paginationItems}
                  {forwardOnePagePageLink}
                  {forwardTenPagesPageLink}
                  {lastPagePageLink}
               </Pagination>
            }
         </>
      )
   }
}


export default connect(null, mapDispatchToProps)(Paginator);