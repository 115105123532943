import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByAddressKey, SearchByAddressUrl } from "../../../redux/actions/searchActions";
import { validateDates } from "../../../utils/DateValidation";
import { LOADING_STATUES } from "../../constants/constants";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";

import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByAddressKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByAddress: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByAddressUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {
  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  return {
    notableDates: state.notableDatesReducer,
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    instrumentGroups: state.instrumentGroupsReducer.codeDescriptionResultList,
    fromRecordedDate: previousSearchKeyValue.fromRecordedDate ? previousSearchKeyValue.fromRecordedDate : moment('01/01/1900').format('L'),
    toRecordedDate: previousSearchKeyValue.toRecordedDate ? previousSearchKeyValue.toRecordedDate : moment().format('L'),
    instrumentGroup: previousSearchKeyValue.instrumentGroup ? previousSearchKeyValue.instrumentGroup : 'ALL',
    streetNumber: previousSearchKeyValue.streetNumber ? previousSearchKeyValue.streetNumber : null,
    streetName: previousSearchKeyValue.streetName ? previousSearchKeyValue.streetName : null,
    city: previousSearchKeyValue.city ? previousSearchKeyValue.city : null
  };
}

class SearchByAddress extends Component {

  state = {
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    instrumentGroup: this.props.instrumentGroup,
    streetNumber: this.props.streetNumber,
    streetName: this.props.streetName,
    city: this.props.city,
    loadingStatus: "",
    submitMessage: "",

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: "",
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByAddress(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};

    if (!this.state.streetNumber) {
      errors["streetNumber"] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    if (!this.state.streetName) {
      errors["streetName"] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    if (this.state.streetName.length < 3) {
      errors["streetName"] = "Three characters minimum.";
      this.setState({ errors: errors });
      return false;
    }

    if (this.state.city && this.state.city.length < 3) {
      errors["city"] = "Three characters minimum, or leave blank.";
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();
    this.setState({
      streetNumber: '',
      streetName: '',
      city: '',
      instrumentGroup: 'ALL',
      fromRecordedDate: moment('01/01/1900').format('L'),
      toRecordedDate: moment().format('L'),
      submitMessage: '',
      errors: {}
    })
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Search by Address",
      onChange: this.handleChange,
      onClear: this.handleClear,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,
      formGroupRows: [
        {
          formGroups: [{ id: 'streetNumber', label: 'House Number', primaryCriteria: true, fieldType: 'text', required: true, value: this.state.streetNumber, error: this.state.errors['streetNumber'] }]
        },
        {
          formGroups: [{ id: 'streetName', label: 'Street Name (3 characters min.)', primaryCriteria: true, fieldType: 'text', required: true, value: this.state.streetName, error: this.state.errors['streetName'] }]
        },
        {
          formGroups: [{ id: 'city', label: 'City/Town', fieldType: 'text', value: this.state.city, error: this.state.errors['city'] }]
        },
        {
          formGroups: [{ id: 'instrumentGroup', label: 'Instrument Group', fieldType: 'select', options: this.props.instrumentGroups, value: this.state.instrumentGroup }]
        },
        {
          formGroups: [{ id: 'fromRecordedDate', label: 'From Date', fieldType: 'date', value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
        },
        {
          formGroups: [{ id: 'toRecordedDate', label: 'To Date', fieldType: 'date', value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
        }

      ],

      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return (
      <FormTemplate {...formProps} />
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByAddress);
