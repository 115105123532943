import React, {Component} from 'react';

import { Card, Container, Row, Col } from 'react-bootstrap' 
import { externalLinkIcon } from '../../img/font_awesome/font_awesome';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class Links extends Component {

    render() {
        let links = <></>;
        if (this.props.links && this.props.links.length > 0) {
            links = <ul style={{ listStyle: 'none' }}>
                {
                    this.props.links.map((link, index) => {
                        return <li key={index}>{externalLinkIcon}&nbsp; <a target="_blank" rel="noopener noreferrer" href={link.paragraphContent} style={{ color: '#408CC7', fontWeight: 'bold' }}>{link.heading}</a></li>
                    })
                }
            </ul>
        }
    
        return (
            <Card>
                <Card.Header>
                    {externalLinkIcon}&nbsp;&nbsp;{translations[this.props.language].links}
                </Card.Header>
                <Card.Body style={{ color: '#408CC7' }}>
                    {links}
                </Card.Body>
            </Card>
        )
    }
}

export default connect(mapStateToProps)(Links);
