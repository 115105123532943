import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, ReportsControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = ReportsControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class Reports extends Component {

  state = {
    statementReport: false,
    statementReportCountyNote: "",
    auditAccountsReport: false,
    auditAccountsReportCountyNote: "",
    auditUsersReport: false,
    auditUsersReportCountyNote: "",
    accountTypeReport: false,
    accountTypeReportCountyNote: "",

    status: "",
    message: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/reports", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();
    
    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/reports", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          statementReport: content.statementReport ? content.statementReport : false,
          statementReportCountyNote: content.statementReportCountyNote,
          auditAccountsReport: content.auditAccountsReport ? content.auditAccountsReport : false,
          auditAccountsReportCountyNote: content.auditAccountsReportCountyNote,
          auditUsersReport: content.auditUsersReport ? content.auditUsersReport : false,
          auditUsersReportCountyNote: content.auditUsersReportCountyNote,
          accountTypeReport: content.accountTypeReport ? content.accountTypeReport : false,
          accountTypeReportCountyNote: content.accountTypeReportCountyNote,
          status : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Reports",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'auditAccountsReport', label: 'Account', fieldType: 'select', style: {minWidth: 150}, value: this.state.auditAccountsReport, lgSize: 2 }, 
                       { id: 'auditAccountsReportCountyNote', label: 'County Notes', fieldType: 'text', style: {minWidth: 1000}, value: this.state.auditAccountsReportCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: 'auditUsersReport', label: 'User', fieldType: 'select', style: {minWidth: 150}, value: this.state.auditUsersReport, lgSize: 2 }, 
                       { id: 'auditUsersReportCountyNote', label: 'County Notes', fieldType: 'text', style: {minWidth: 1000}, value: this.state.auditUsersReportCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: 'statementReport', label: 'Statement', fieldType: 'select', style: {minWidth: 150}, value: this.state.statementReport, lgSize: 2 }, 
                       { id: 'statementReportCountyNote', label: 'County Notes', fieldType: 'text', style: {minWidth: 1000}, value: this.state.statementReportCountyNote, lgSize: 10 }]
        },
        {
          formGroups: [{ id: 'accountTypeReport', label: 'Acccount Type', fieldType: 'select', style: {minWidth: 150}, value: this.state.accountTypeReport, lgSize: 2 }, 
                       { id: 'accountTypeReportCountyNote', label: 'County Notes', fieldType: 'text', style: {minWidth: 1000}, value: this.state.accountTypeReportCountyNote, lgSize: 10 }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(Reports);