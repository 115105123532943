import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, Button, Form, Container } from 'react-bootstrap'
import FieldRow from "../common/form/FieldRow";
import Loading from '../common/modal/Loading';

import { validatePassword } from "../common/function/userValidation.js";

import axios from "axios";
import configData from "../../config/config.json";
import { LOADING_STATUES, ALERT_TYPES } from "../constants/constants";
import Alert from '../common/modal/Alert';

import { userActiveIcon } from "../../img/font_awesome/font_awesome"

const mapStateToProps = (state) => {
   return {
      currentUser: state.currentUserReducer
   };
}

class CurrentUserChangePassword extends Component {

   state = {
      password: "",
		passwordConfirm: "",
      loadingStatus: '',
      errors: {},
      alertType: ALERT_TYPES.OK
   }

   handleChange = (event) => {
      event.preventDefault();
    
      let value = event.target.value;
      this.setState({
         [event.target.id]: value,
         errors: {}
      })
   }

   showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}


   handleSubmit = (event) => {
		event.preventDefault();
      let errors = validatePassword(this.state);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({errors});
			return;
		}

		if (this.showLoading() === true) {
			let { loadingStatus, errors, alertType, showAlert, passwordConfirm, ...request } = this.state; // remove UI only fields before submitting
			axios.post(configData.LANDSHARK_SERVER_URL + "currentUser/password", request, { withCredentials: true })
				.then((response) => {
					this.setState({loadingStatus: ""}, this.showAlert("Password updated.")); 
				}, (error) => {
					 this.setState({loadingStatus: ""}, this.showAlert(error.response.data.message));
				});
		}
		}
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.setState({  errors: {} })
      this.props.closeCurrentUserChangePassword();
   }

   handleBack = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.setState({  errors: {} })
      this.props.closeCurrentUserChangePassword();
      this.props.openCurrentUser();
   }

   showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		}, this.handleCloseClick)
	  }

   showWarning = (alertMessage) => {
		this.setState({
		  alertType: ALERT_TYPES.WARNING,
		  showAlert: true,
		  alertMessage
		})
	}
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

   render() {

		let formGroupRows = [
			{
				formGroups: [{ id: 'password', label: 'New Password', fieldType: 'password', value: this.state.password, required: true,  primaryCriteria: true, error: this.state.errors['password']  } ]
			},
			{
				formGroups: [ { id: 'passwordConfirm', label: 'Confirm Password', fieldType: 'password', value: this.state.passwordConfirm, required: true, primaryCriteria: true,  error: this.state.errors['passwordConfirm']  }]
			}
		]

      return (
         <>
            <Modal show={this.props.show} onHide={this.handleCloseClick} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
               <Form onSubmit={this.handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title style={{color: '#3674AB'}}>{userActiveIcon} {this.props.currentUser.username} </Modal.Title>
               </Modal.Header>
               <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white'}}>
                  <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 50, marginTop: 10, background: 'white', color: 'black', border: '2px solid black',  borderRadius: '5px' }}>
                     <br/>
                        {
                           formGroupRows.map((formGroupRow, index) => {
                              return (
                                 <FieldRow key={index} formGroupRow={formGroupRow} onChange={this.handleChange} />
                              );
                           })
                        }
                  </Container>
               </Modal.Body>
               <Modal.Footer>
                  <Button className='mr-auto' variant="secondary"  onClick={this.handleBack} >Back</Button>
                  <Button variant="primary" type="submit" >Update</Button>
                  <Button variant="secondary" onClick={this.handleCloseClick}>Close</Button>
               </Modal.Footer>
               </Form>
            </Modal>
            <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
            <Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType}/>
         </>
      )
   }
}

export default connect(mapStateToProps, null)(CurrentUserChangePassword);