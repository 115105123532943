import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap'
import { connect } from "react-redux";

const mapStateToProps = state => {
   return {
      applySurcharge: state.countyConfigEscrowReducer.applySurcharge,
      creditCardSurcharge: state.countyConfigEscrowReducer.creditCardSurcharge,
      creditCardSurchargePercent: state.countyConfigEscrowReducer.creditCardSurchargePercent,
      minReloadAmount: state.countyConfigEscrowReducer.minReloadAmount,
      maxReloadAmount: state.countyConfigEscrowReducer.maxReloadAmount,
      isCreditCardSurchargePercent: state.countyConfigEscrowReducer.creditCardSurchargeMethod === 'percent' ? true : false
   };
}

class EscrowRechargeAmountWindow extends Component {
  
   state = {
      deposit: 0.00,
      surcharge: 0.00,
      totalCharge: 0.00
   };
  
   onSubmit = (event) => {
      if (event) {
         event.preventDefault();
      }

      let amt = Number(this.state.deposit);
      if (amt > 0) {
        this.props.buyClicked({
           deposit: this.state.deposit
        });
      } else {
        alert("Amount must be greater than zero");
      }
   }
   
   handleCloseClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.closeClicked();
   }

   handleDepositChanged = (event) => {
      this.handleEntryChanged(event);
   }

   handleDepositBlur = (event) => {
      this.handleEntryChanged(event);
   }

   handleEntryChanged = (event) => {
      const deposit = event.target.value;
      
      let surcharge = 0.00;

      if ((this.props.applySurcharge === true || this.props.applySurcharge === 'true') && this.props.isCreditCardSurchargePercent) {
         // Doing all this just to make sure rounding correctly
         surcharge = Math.round((this.props.creditCardSurchargePercent + Number.EPSILON) * 100) / 100
         surcharge = surcharge/100;
         surcharge = deposit * surcharge;
         surcharge = Math.round((surcharge + Number.EPSILON) * 100) / 100
      } else if (this.props.applySurcharge === true || this.props.applySurcharge === 'true') {
         surcharge = this.props.creditCardSurcharge
      }

      const totalCharge = deposit * 1 + surcharge;

      this.setState( {
         deposit,
         surcharge,
         totalCharge
      });
   }

   render() {
    
      let surchargeText = '$' + this.props.creditCardSurcharge;

      if (this.props.isCreditCardSurchargePercent) {
         surchargeText = this.props.creditCardSurchargePercent + '%';
      }

      const depositEntry = <Form.Control id='deposit' style={{ width: 200, display: 'inline-block', textAlign: 'right' }} type='number' onChange={this.handleDepositChanged}
         onBlur={this.handleDepositBlur} value={this.state.deposit} step={0.01} min={this.props.minReloadAmount} max={this.props.maxReloadAmount} precision={2} />

      return (
         <Modal show={this.props.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
            <Form onSubmit={this.onSubmit} >
            <Modal.Header >
               <Modal.Title style={{color: '#3674AB'}}>
                  Escrow Deposit
               </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 18}}>
               <Container >
                  <Row>
                     <Col>
                           <Container>
                              <Row><Col lg={1}></Col><Col lg={5}>Deposit amount (${this.props.minReloadAmount} - ${this.props.maxReloadAmount} max):</Col><Col lg={5} style={{ textAlign: 'end' }}>${depositEntry}</Col><Col lg={1}></Col></Row>
                              {
                                 this.props.applySurcharge === true && <>
                                    <Row><Col lg={1}></Col><Col lg={5}>Credit card fee ({surchargeText}):</Col><Col lg={5} style={{textAlign: 'end'}}>${this.state.surcharge}</Col><Col lg={1}></Col></Row>
                                    <Row ><Col lg={1}></Col><Col lg={10} style={{ textAlign: 'right' }}><hr style={{ borderColor: 'white' }} /></Col><Col lg={1}></Col></Row>
                                    <Row style={{fontWeight: 'bold'}}><Col lg={1}></Col><Col lg={5}>Total to be charged:</Col><Col lg={5} style={{textAlign: 'right'}}>${this.state.totalCharge}</Col><Col lg={1}></Col></Row>
                                    <Row ><Col lg={1}></Col><Col lg={10} style={{ textAlign: 'right' }}><hr style={{ borderColor: 'white' }} /></Col><Col lg={1}></Col></Row>
                                 </>
                              }
                           </Container>
                     </Col>
                  </Row>
               </Container>
            </Modal.Body>

            <Modal.Footer >
               <Container >
                  <Row style={{ margin: 5 }} >
                     <Col md='12' className='align-self-end' style={{ textAlign: 'right' }}>
                        <Button variant="primary" type="submit" style={{ marginRight: 8 }} >Continue to Credit Card</Button>
                        <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
                     </Col>
                  </Row>
               </Container>
            </Modal.Footer>
            </Form>
         </Modal>
      )
   }
}

export default connect(mapStateToProps)(EscrowRechargeAmountWindow);