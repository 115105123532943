
export const getPaginatorHelper = (pageNumber, recordList, numberOfVisibleRecords) => {
  
   let zeroBasedPageNumber = pageNumber - 1;
	let zeroBasedStartingRecordIndex = (zeroBasedPageNumber * numberOfVisibleRecords);
   let zeroBasedEndingRecordIndex = zeroBasedStartingRecordIndex + numberOfVisibleRecords;
   
	let currentPageRecordList = recordList.slice(zeroBasedStartingRecordIndex, zeroBasedEndingRecordIndex);
	let currentPageStartingRecordNumber = recordList.length > 0 ? zeroBasedStartingRecordIndex + 1 : 0;
   let currentPageEndingRecordNumber = zeroBasedEndingRecordIndex <= recordList.length ? zeroBasedEndingRecordIndex : recordList.length;
   
   return {currentPageRecordList, currentPageStartingRecordNumber, currentPageEndingRecordNumber};
};