import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, CommonControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../../components/constants/constants";
import { formatPhone } from "../common/function/formattingUtils.js";

const componentName = CommonControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName)),
  }
}

class Common extends Component {

  state = {
    appTitle: "",
    countyName: "",

    contactName: "",
    contactTitle: "",
    contactPhone: "",
    contactEmail: "",

    bookLabel: "",
    torrensLabel: ""
  }

  handleSubmit = (event) => {

    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/common", this.state, { withCredentials: true })
    .then(
      (response) => {
        this.setState({ 
          status: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;

    if (event.target.id === 'contactPhone') {
      value = formatPhone(value, this.state.contactPhone);
    } else {
      value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    }
    this.setState({
      status: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/common", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          appTitle: content.appTitle,
          countyName: content.countyName,
          contactName: content.contactName,
          contactTitle: content.contactTitle,
          contactPhone: content.contactPhone,
          contactEmail: content.contactEmail,
          bookLabel: content.bookLabel,
          torrensLabel: content.torrensLabel,
          status : ""
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Common",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.status === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.status === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [ { id: 'countyName', label: 'County Name', fieldType: 'text', required: true, value: this.state.countyName },
                        { id: 'appTitle', label: 'App Title', fieldType: 'text', required: true, value: this.state.appTitle } ]
        },
        {
          formGroups: [ { id: 'contactName', label: 'Contact Name', fieldType: 'text', required: false, value: this.state.contactName },
                        { id: 'contactTitle', label: 'Title ', fieldType: 'text', required: false, value: this.state.contactTitle },
                        { id: 'contactPhone', label: 'Phone (ex. (612) 555-1234)', fieldType: 'tel', required: false, value: this.state.contactPhone },
                        { id: 'contactEmail', label: 'Email ', fieldType: 'email', required: false, value: this.state.contactEmail } ]
        },
        {
          formGroups: [ { id: 'bookLabel', label: 'Book Label', fieldType: 'text', required: true, value: this.state.bookLabel },
                        { id: 'torrensLabel', label: 'Torrens Label', fieldType: 'text', required: true, value: this.state.torrensLabel }]
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(Common);