export const handleError = (error, history) => {
  var response = error.response;
  if (response && response.status === 403) {
       if (response.data.message && response.data.message != '') {
        alert(response.data.message);
       }
       history.push('/login');
  } 
};

export const handleBlobError = (error, history, callback) => {
  var response = error.response;
  if (response && response.status === 403) {
    history.push('/login');
  } else {
    var blob = new Blob([response.data], { type: 'application/json' });
    var fileReader = new FileReader();
    fileReader.onload = function() {
        let data = JSON.parse(this.result);
        if (response.status === 403 && data.status === 'LOGIN') {
          history.push('/login');
        } else if (response.status === 500) {
          callback(data.message, data.status);
        }
    };
    fileReader.readAsText(blob);
  }
};

export const handleCallBack = (callback, foundRecords, content) => {
  if (!callback) {
    return;
  }

  let message = '';
  if (foundRecords === false) {
      message = 'No Records Found.'
  }
  callback(message, "OK", content);
};

