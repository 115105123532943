const initialState = {sortKey: 'docNumber', sortDirection: 'ASC'};

const searchResultsSortReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_SEARCH_RESULTS_SORT':
                  return action.sort;
            case 'LOGOUT_USER':
                  return {sortKey: 'docNumber', sortDirection: 'ASC'};
            default:
                  break;
      }
      return state;
};
export default searchResultsSortReducer;