import axios from "axios";

import configData from "../../config/config.json";

export const refreshUsageBalance = (userId) => async dispatch => {
  await axios.post(configData.LANDSHARK_SERVER_URL + "currentUser/usageBalance", userId, { withCredentials: true })
    .then((response) => {
      dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: response.data.content.usageBalance });
    }, (error) => {
       alert("Cannot refresh usage balance");
      // handleError(error, history);
    });
};

export const refreshUsageBalanceFromContent = (content) => async dispatch => {
    dispatch({ type: 'UPDATE_CURRENT_USER_USAGE_BALANCE', usageBalance: content.usageBalance });
};