
const initialState = { 
  "userId": "",
  "username": "",
  "usageBalance" : "",
  "searchHistoryList": null,
  "tabList": null,
  "searchList": null,
  "language": "ENGLISH"
};

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CURRENT_USER':
      return action.currentUser;
    case 'UPDATE_CURRENT_USER_USAGE_BALANCE':
        return { ...state, usageBalance: action.usageBalance }
    case 'LANDSHARK_ACCOUNTS':
          return { ...state, allowableAccounts: action.results.codeDescriptionResultList }
    case 'LOGOUT_USER':
          return { 
            "userId": "",
            "username": "",
            "usageBalance" : "",
            "searchHistoryList": null,
            "tabList": null,
            "searchList": null,
            "language": "ENGLISH"
          };
    default:
      break;
  }
  return state;
}

export default currentUserReducer;