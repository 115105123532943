const initialState = null;

const seachSequenceStartedReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_SEARCH_SEQUENCE':
      return initialState;
    case 'START_SEARCH_SEQUENCE':
      return action.search;
    default:
      break;
  }
  return state;
}

export default seachSequenceStartedReducer;