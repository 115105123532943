import { HomeNotableDatesControlPanelKey } from "../actions/controlPanelActions";

const initialState = {     
      selectedControlPanelType : HomeNotableDatesControlPanelKey
}

const controlPanelTypeReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_CONTROL_PANEL_TYPE':
                  return {
                        selectedControlPanelType: action.selectedControlPanelType  
                  }
            default:
                  return state;
      }
     
};
export default controlPanelTypeReducer;