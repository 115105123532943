import React, { Component } from "react"
import { Container, Col, Row, Table, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import SearchResultsDetailsNoResults from './SearchResultsDetailsNoResults'
import Paginator from '../../common/page/Paginator'
import { getPaginatorHelper } from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import LegalDetails from './LegalDetails';
import moment from 'moment';
import Thumbnails from "../../image/Thumbnails";

export const SearchResultsDetailsLegalDescriptionsComponetName = 'SearchResultsDetailsLegalDescriptions';

const mapStateToProps = state => {

    let searchHistory = state.searchHistoryReducer.searchHistoryList[0];

    let fromRecordedDate;
    let toRecordedDate;
    if (searchHistory && searchHistory.displayCriteria) {
        const fromRecordedDateCriteria = searchHistory.displayCriteria.filter(criteria => {
            return criteria.key === 'fromRecordedDate'
        })

        if (Array.isArray(fromRecordedDateCriteria) && fromRecordedDateCriteria.length > 0) {
            fromRecordedDate = fromRecordedDateCriteria[0].value;
        }

        const toRecordedDateCriteria = searchHistory.displayCriteria.filter(criteria => {
            return criteria.key === 'toRecordedDate'
        })
        if (Array.isArray(toRecordedDateCriteria) && toRecordedDateCriteria.length > 0) {
            toRecordedDate = toRecordedDateCriteria[0].value;
        }
    }

    return {  
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsTractBookReducer,
       
        paginationPageLegalDescriptions: state.searchDetailsLegalDescriptionsPaginationReducer,
        // If the recorded dates from the original search are available, use them in the Grantor/Grantee link searches.
        fromRecordedDate: fromRecordedDate ? fromRecordedDate : moment('01/01/1900').format('L'),
        toRecordedDate: toRecordedDate ? toRecordedDate : moment().format('L'),
        currentUser: state.currentUserReducer
    };
}

class TractBookSearchResultsDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imagingWindows: this.createImagingWindowsWindowState('', false),
            loadingStatus: "",
            showLegalDetails: false
        };
    }

    hideLoading = (message, status) => {
        if (status === 'ERROR') {
          alert(message);
        }
        
        this.setState({
            loadingStatus: ""
        })
      }
    
    showLegalDetails = (event) => {
        event.preventDefault();
        this.setState({
            showLegalDetails: true
        })
    }

    hideLegalDetails = () => {
        this.setState({
            showLegalDetails: false
        })
      }

      // May return null if not found.
    getSelectedSearchResult = () => {
        if (!this.props.searchResults || (this.props.searchResultsDetails === 0)) {
            return null;
        }

        let searchResult = this.props.searchResults.searchResultList.find((searchResult) => {
            return this.props.searchResultsDetails == searchResult.docId;
        });

        return searchResult;
    }

    // Can return null.
    getSelectedSearchResultInfo = () => {
        let searchResult = this.getSelectedSearchResult();

        if (!searchResult) {
            return null;
        }

		let info = { 
            documentType: searchResult.docType, 
            documentNumber: searchResult.docNumber,
            documentKey: searchResult.docType + searchResult.docNumber,
            instrumentCode: ''      // there is none for this doc type
        };

        return info;
    }

    handleImageClick = (event) => {
        if (event) {
            event.preventDefault();
         }
           
        let info = this.getSelectedSearchResultInfo();

        if (!info) {
            alert("Select a document.");
            return; 
        }

        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(info, true)
        } );
    }

    handleImageComplete = () => {
        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(null, false)
        } );
    }

    createImagingWindowsWindowState = (searchResult, show) => {
        return {
            docKey: searchResult ? searchResult.documentKey : '',
            instCode: searchResult ? searchResult.instrumentCode : '',
            show: show
        }
    }

    render() {
        const numberOfVisibleRecords = 10;

        let selectedSearchResult = this.getSelectedSearchResult();

        if (!selectedSearchResult) {
            return (<SearchResultsDetailsNoResults />)
        }

        let { docNumber, docId, instrumentDescription, recordedDateTime, legalSearchResults,  } = selectedSearchResult;
      
        let numberOfLegalSearchResultsPages = Math.ceil ( legalSearchResults.length / numberOfVisibleRecords );
        let paginationHelperLegalDescriptions = getPaginatorHelper(this.props.paginationPageLegalDescriptions, legalSearchResults, numberOfVisibleRecords);

        return (
            <>
            <Col style={{ paddingLeft: 0, marginRight: 0, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <Container key={this.props.searchResultsDetails} style={{ paddingLeft: 10, paddingTop: 10, paddingRight: 0, marginRight: 0 }}  fluid="true">
                        <Row >
                            <Col>
                                <h5>
                                <span><strong>{instrumentDescription}</strong> </span>
                                <span style={{float: 'right'}}>
                                {
                                    this.props.currentUser.imageRetrieval &&
                                    <Button variant="primary" size="sm" onClick={this.handleImageClick} >Image</Button>
                                }
                                </span>
                                </h5>
                            </Col>

                        </Row>
                        <hr style={{ background: 'black', marginTop: 2, marginBottom: 2 }}></hr>
                        
                    <div>
                        <div><strong>ID: </strong>{docNumber} &nbsp;</div>
                        <div><strong>Recorded Date and Time: </strong>{recordedDateTime} &nbsp;</div>
                       
                       <Table striped bordered hover variant="dark"  size="sm" style={{marginBottom: 0, marginTop: 10}}>
                            <thead><tr><th  scope="col">Legal Description&nbsp;<Button style={{fontSize: '0.7em', fontWeight: 'bold', paddingTop: 0, paddingBottom: 1}} variant="primary"  onClick={this.showLegalDetails} >Details</Button> </th></tr></thead>
                            <tbody>
                                {
                                    paginationHelperLegalDescriptions.currentPageRecordList.map(legalDescription => {
                                        return <tr id={legalDescription.docId}>
                                                <td className="table-td-xs">
                                                    {legalDescription.description}
                                                </td>
                                               </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col><Paginator style={{marginBottom:0}} componentName={SearchResultsDetailsLegalDescriptionsComponetName} numberOfPages={numberOfLegalSearchResultsPages} numberOfVisibleRecords={numberOfVisibleRecords}  ></Paginator></Col>
                        </Row>
                       
                        <div style={{marginTop:10}}>&nbsp;</div>
                    </div>
                </Container>
            </Col>
            <Loading loadingIndicator={this.state.loadingStatus === 'LOADING'} />
            <LegalDetails show={this.state.showLegalDetails} hideLegalDetails={this.hideLegalDetails} title={docNumber} key={docId} docId={docId}  />
            <Thumbnails show={this.state.imagingWindows.show} 
                    docKey={this.state.imagingWindows.docKey} 
                    instCode={this.state.imagingWindows.instCode} 
                    complete={this.handleImageComplete} />
            </>
        )
    }
}

export default connect(mapStateToProps)(TractBookSearchResultsDetails);