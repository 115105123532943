import React, { Component } from 'react';
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap'
import { logout } from "../../redux/actions/loginActions";
import translations from "../../config/translation/logout_translations.json";

const mapStateToProps = state => {
   return {
     language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
   };
 }

const matchDispatchToProps = (dispatch) => {
   return {
      logout: (history) => dispatch(logout(history))
   }
}

class Logout extends Component {

   handleYesClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.closeLogout();
      this.props.logout(this.props.history);
   }

   handleNoClick = (event) => {
      if (event) {
         event.preventDefault();
      }
      this.props.closeLogout();
   }

   render() {
      return (
         <Modal show={this.props.show} onHide={this.handleNoClick}  size="lg"  aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
               <Modal.Title style={{color: '#3674AB'}}>{translations[this.props.language].logout}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black', background: '#3674AB', color: 'white',  fontSize: 20}}>
               {translations[this.props.language].continue}?
            </Modal.Body>
            <Modal.Footer>
               <Button variant="primary" onClick={this.handleYesClick}>{translations[this.props.language].yes}</Button>
               <Button variant="secondary" onClick={this.handleNoClick}>{translations[this.props.language].no}</Button>
            </Modal.Footer>
         </Modal>
      )
   }
}

export default connect(mapStateToProps, matchDispatchToProps)(Logout);