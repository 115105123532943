const documentTypesReducer = (state = [{code: "ALL", description: "All"}], action) => {
  switch (action.type) {
    case 'LANDLINK_DOCUMENT_TYPES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default documentTypesReducer;