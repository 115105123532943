import React from 'react';
import { connect } from "react-redux";

import { Modal, Container, Col, Row, Button, Form, InputGroup, Table } from 'react-bootstrap'
import Paginator from '../page/Paginator'
import { getPaginatorHelper } from '../page/PaginatorHelper'
import Loading from '../modal/Loading';

import { arrowAltDownIcon } from "../../../img/font_awesome/font_awesome"
import { LOADING_STATUES } from "../../constants/constants";

export const PlatSeclectionListComponentName = "PlatSeclectionListComponentName";

const mapStateToProps = state => {
    return {
        paginationPage: state.platSelectionListPaginationReducer,
        allPlatNameList: state.platsReducer.codeDescriptionResultList,
        allPlatNumberList: state.platsByNumberReducer.codeDescriptionResultList
    };
}

class PlatSeclectionList extends React.Component {
    state = {
        sort: 'platName',
        filteredList: this.props.allPlatNameList,
        filterText: this.props.filterText? this.props.filterText : '',
        loadingStatus: ''
    }

    handleReset = () => {
        if (this.state.sort === 'platName') {
            this.setState({
                filteredList: this.props.allPlatNameList,
                filterText: '',
            });
        } else {
            this.setState({
                filteredList: this.props.allPlatNumberList,
                filterText: '',
            });
        }
      }

    handleTextFilter = (filterText) => {
		this.setState({ filterText: filterText },  function() { this.handleFilter() } )
	}

    handleFilter = () => {    
        if (!this.state.filterText || this.state.filterText.trim() === '') {
            return;
        }
    
        if (this.state.sort === 'platName') {
            let index;
            for (index = 0; index < this.props.allPlatNameList.length; index++) {
                const description = this.props.allPlatNameList[index].description;
                if (description.toUpperCase() >= this.state.filterText.toUpperCase()) {
                    break;
                }
            }
            const filteredList = this.props.allPlatNameList.slice(index, this.props.allPlatNameList.length);
            this.setState({filteredList});
        } else {
            let index;
            for (index = 0; index < this.props.allPlatNumberList.length; index++) {
                const code = this.props.allPlatNumberList[index].code.toUpperCase();
                const codeNumeric = !isNaN(code);
                const filterText = this.state.filterText.toUpperCase(); 
                const filterTextNumeric = !isNaN(filterText);
                // If both the code and the filter text are numeric, compare them numerically.
                if (codeNumeric && filterTextNumeric && parseInt(code) >= parseInt(filterText)) {
                    break;
                // If the code is not numeric, such as 123A or A123, and the filter text is numeric, such as 123 or 456.
                } else if (!codeNumeric && filterTextNumeric) {
                    const indexFirstNumber = code.search(/[0-9]/);
                    // If the code starts with a number, such as 123A.
                    if (indexFirstNumber === 0) {
                        // Locate the index of the first non-numeric character.
                        const indexFirstNonNumber = code.search(/[^0-9]/);
                        // Substring out leading number.  For example, substring 123 from 123A.
                        const number = code.substring(0, indexFirstNonNumber);
                        // Compare the substring'd number and the filter text numerically.
                        if (parseInt(number) >= parseInt(filterText)) {
                            break;
                        }
                    // else the code is something such as A123 or ABC, it's alphanumeric and it's greater than a number, so break.
                    } else {
                        break;
                    }
                // if both the code and the filter text are not numeric, compare them non-numerically.
                } else if (!codeNumeric && !filterTextNumeric && code >= filterText) {
                    break;
                }
            }
            const filteredList = this.props.allPlatNumberList.slice(index, this.props.allPlatNumberList.length);
            this.setState({filteredList});
        }
    }

    handleCloseClick = () => {
        this.props.onHide();
    }

    handleRowClick = (event) => {
        this.props.onHide(event.target.closest("tr").id, event.target.closest("tr").getAttribute("name"));
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            filterText: event.target.value
        })
    }

    handleSortChange = (event) => {
        event.preventDefault();
        if (this.state.sort === 'platName') {
            this.setState({
                sort: 'platNumber',
                filteredList: this.props.allPlatNumberList,
                filterText: ''
            })
        } else {
            this.setState({
                sort: 'platName',
                filteredList: this.props.allPlatNameList,
                filterText: ''
            })
        }
    }
    
     showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

    handleSubmit = (event) => {
        event.preventDefault();

        // 13 is Enter. 
        if (event.type === 'keyPress' && event.keyCode !== 13) {
            const entry = String.fromCharCode(event.keyCode);
            this.setState({
                filterText: this.state.filterText + entry
            })

            return;
        }
        this.handleTextFilter(this.state.filterText);
    }

    componentDidMount() {
        this.handleFilter();
	}

    render() {
        let filteredList = this.state.filteredList;
        if (!filteredList) {
            return <></>
        }

        const numberOfVisibleRecords = 10;
        let numberOfPages = Math.ceil(filteredList.length / numberOfVisibleRecords);
        let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);
       
        return (
            <>
            <Modal show={this.props.showModal} aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-width" >
                <Modal.Header style={{ borderBottom: '2px solid #3674AB' }} >
                    <Modal.Title id="contained-modal-title-vcenter" style={{color: '#3674AB'}}>
                        Click row to select plat:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid" style={{paddingTop: 0, paddingBottom: 0}}>
                    <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2 }}>
                                <Row>
                                    <Col md={4} >
                                        <span>Sort list by:</span>
                                        {   
                                            this.state.sort === 'platName' &&
                                            <Form.Control id='sort' as='select' onChange={this.handleSortChange}  >
                                                <option value="platName" selected>Plat Name</option>
                                                <option value="platNumber">Plat Number</option>
                                            </Form.Control>
                                        }
                                        {   
                                            this.state.sort === 'platNumber' &&
                                            <Form.Control id='sort' as='select' onChange={this.handleSortChange}  >
                                                <option value="platName">Plat Name</option>
                                                <option value="platNumber" selected>Plat Number</option>
                                            </Form.Control>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                            <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2 }}>
                                <Row>
                                    <Col>Position list to:</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form onSubmit={this.handleSubmit}>
                                            <InputGroup>
                                                <Form.Control onChange={this.handleChange} type='text' value={this.state.filterText} />
                                                <InputGroup.Append>
                                                    <Button  style={{ color: "white" }} type="submit" >{arrowAltDownIcon}</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form>
                                    </Col>
                                    <Col>
                                        <Button variant="secondary" onClick={this.handleReset} >Reset</Button>
                                    </Col>
                                </Row>
                            </Container>

                        <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2, border: '3px solid darkGray', borderRadius: '5px' }}>

                            <Table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th className="table-td-xs" scope="col" style={{width: '80%'}}>Name</th>
                                        <th className="table-td-xs" scope="col" style={{width: '20%'}}>Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paginationHelper.currentPageRecordList.map((record) => {
                                            return (
                                                <tr style={{ cursor: "pointer" }} id={record.code} name={record.description} onClick={this.handleRowClick} >
                                                    <td className="table-td-xs" style={{width: '80%'}}>{record.description}</td>
                                                    <td className="table-td-xs" style={{width: '20%'}}>{record.code}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Container>
                        <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, marginRight: 2, marginTop: 2 }}>
                            <Row>
                                <Col><Paginator style={{ justifyContent: 'center' }} componentName={PlatSeclectionListComponentName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages} ></Paginator></Col>
                            </Row>
                            <Row>
                                <Col>Displaying Results: {paginationHelper.currentPageStartingRecordNumber}-{paginationHelper.currentPageEndingRecordNumber} of {filteredList.length}</Col>
                            </Row>
                        </Container>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCloseClick}>Close</Button>
                </Modal.Footer>
            </Modal>
             <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
             </>
        )
    }
}

export default connect(mapStateToProps)(PlatSeclectionList);
