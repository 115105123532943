import React, { Component } from "react"

import { Container, Col, Row, Table, FormCheck } from 'react-bootstrap'

import { connect } from "react-redux";

import { displayDetails, SEARCH_RESULTS_PAGE_SIZE} from '../../../redux/actions/searchActions'
import SearchPaginator from '../../common/page/SearchPaginator'
import { getPaginatorHelper } from '../../common/page/PaginatorHelper'
import Loading from '../../common/modal/Loading';
import { LOADING_STATUES } from "../../constants/constants";

import CreditCardSearchAuthWindow from "../../../components/common/form/CreditCardSearchAuthWindow";
import CreditCardPriceListWindow from "../../../components/common/form/CreditCardPriceListWindow";
import CreditCardPriceListReAuthWindow from "../../../components/common/form/CreditCardPriceListReAuthWindow"

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff"
import AjaxResult from "../../../utils/AjaxResult";

import Alert from '../../common/modal/Alert';
import { ALERT_TYPES } from "../../constants/constants";

export const SearchResultsTableComponetName = 'SearchResultsTable';

const mapStateToProps = state => {
    return {
        creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        searchResults: state.searchResultsReducer,
        paginationPage: state.searchResultsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        selectedRow:  state.searchResultsTableSelectedRowReducer,
        rewind: state.searchSequenceRewindReducer,
        searchResultsSort: state.searchResultsSortReducer,         
        filterApplied: state.searchResultsFilterAppliedFilter,
        searchResultsFilter: state.searchResultsFilterReducer,
        searchResultsVisited: state.searchResultsVisitedReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        select: (id, description, callback) => dispatch(displayDetails(id, description, callback)),
        filterResults: (document, sortKey, sortDirection, callback) => dispatch({ type: 'FILTER_DOCUMENT_ID', document, sortKey, sortDirection }, callback()),
        resetRewindSearch: () =>  dispatch({ type: 'RESET_REWIND_SEARCH_SEQUENCE'})  
    }
}

class SearchResultsTable extends Component {

    state = {
        selectedDocumentId: null,
        selectedDocumentName: '',
        loadingStatus: '',
        tableLoading: true,
        
        token: null,

        creditCardPriceListWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        
        showAlert: false,
        alertMessage: ""
    }

    showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
	}

    handleCreditCardPriceListClose = () => {
        this.setState(
            {
                creditCardPriceListWindowShow: false
            });
    }

    handleCreditCardPriceListContinue = () => {
        this.setState(
            {
                creditCardPriceListWindowShow: false,
                creditCardAuthorizeDotNetWindowShow: true
            });
    }

    handleCreditCardAuthorizeDotNetWindowClose = () => {
        this.setState(
            {
                creditCardAuthorizeDotNetWindowShow: false
            });
    }

    showLoading = () => {
        if (this.state.loadingStatus === '') {
            this.setState({
                loadingStatus: LOADING_STATUES.LOADING
            })
            return true;
        }
        return false;
    }

    hideLoading = (message, status, content) => {
        if (content.paymentNeededAgain === true) {
            this.setState({
                loadingStatus: "",
                submitMessage: "",
                creditCardPriceListReAuthWindowShow: true
            })
        } else if (content.paymentNeeded === true) {
            this.setState({
                loadingStatus: "",
                submitMessage: "",
                creditCardPriceListWindowShow: true
            })
        } else if (status === 'ERROR') {
            this.setState({
                loadingStatus: "",
                submitMessage: ""
            })
        } else {
            this.setState({
                loadingStatus: "",
                submitMessage: message,
                creditCardPriceListWindowShow: false,
                creditCardAuthorizeDotNetWindowShow: false,
                creditCardPriceListReAuthWindowShow: false
            })
        }
    }

    handleAuthorizedSubmit = () => {
        this.props.select(this.state.selectedDocumentId, this.state.selectedDocumentName, this.hideLoading);
    }

    handleCreditCardPriceListReAuth = () => {
        this.setState({creditCardAuthorizeDotNetWindowShow:true});
    }
    
    handleCreditCardPriceListReAuthWindowClose = () => {
        this.setState(
            {
                creditCardPriceListReAuthWindowShow: false
            });
    }

    handleCheckFilterLimit = () => {
        if (this.props.searchResultsFilter.totalRecordCount >= 100) {
            this.setState(
            {
                showAlert: true,
                alertMessage: 'You have reached the limit of selecting up to 100 records.'
            });
        }
    }

    handleCheckClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        this.props.searchResults.searchResultList.map((searchResult) => {
            if (searchResult.docId == clickedId) {
                this.props.filterResults(searchResult, this.props.searchResultsSort.sortKey, this.props.searchResultsSort.sortDirection, this.handleCheckFilterLimit);
            }
        });
    }

    handleRowClick = (event) => {
        const clickedId = event.target.closest("tr").id; // find the closest parent tr
        this.props.resetRewindSearch();
        if (clickedId && this.showLoading() === true) {
            const selectedDocumentName = event.target.closest("tr").getAttribute("name");
            this.setState({ selectedDocumentId: clickedId, selectedDocumentName })
            this.props.select(clickedId, selectedDocumentName, this.hideLoading);
        }
    }

    checkSelected = (docId) => {
        
        for (const element of this.props.searchResultsFilter.searchResultList) {
            if (element.docId === docId) {
                return true;
            }
        }
        return false;
    }

    componentDidMount() {
        if (this.props.creditCardUser) {
            axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
                .then((response) => {
                    let result = new AjaxResult(response);
                    if (!result.isOk()) {
                        result.handleError();
                    } else {
                        this.setState({ token: result.content().token });
                    }
                }, (error) => {
                    handleError(error, this.props.history);
                })
        }
    }


    render() {
        // Only want to show the credit card entry screen once the first time the user goes to enter their CC info
        console.log('CreditCardAuthorizeDotNetWindowShow: ' + this.state.creditCardAuthorizeDotNetWindowShow);


        let loadingLabel = this.props.tableLoading === true ? 'Loading' : "Displaying";
        let bookPageBoookTypeHeader = <th className="table-td-xs" scope="col" style={{ width: '18%' }} >Recorded Date<br /> {this.props.bookLabel}/Page</th>

        let filteredList = this.props.searchResults.searchResultList;
        const numberOfVisibleRecords = SEARCH_RESULTS_PAGE_SIZE;
        let numberOfPages = Math.ceil ( filteredList.length / numberOfVisibleRecords );
        let paginationHelper = getPaginatorHelper(this.props.paginationPage, filteredList, numberOfVisibleRecords);

        let displayResults = paginationHelper.currentPageStartingRecordNumber === paginationHelper.currentPageEndingRecordNumber ? 
                                paginationHelper.currentPageStartingRecordNumber :
                                paginationHelper.currentPageStartingRecordNumber + "-" + paginationHelper.currentPageEndingRecordNumber;

        let truncatedResultWarning = <></>;
        if (filteredList.length >= 1000) {
            truncatedResultWarning = <span className='badge badge-danger'>Results truncated.  If needed, narrow criteria and search again.</span>
        }

        // The authorize.net window in CreditCardSearchAuthWindow needs to re-render every time apparently.  I tried to use the authorize.net token as the component key, but apparently, 
        // the token either is too big or has characteers that didn't work out. Therefore, I'm using a random number to force it re-render each time.
        const creditCardAuthorizeDotNetWindowKey = Math.ceil(Math.random() * 1000000000) + "";
        let  selectedDocumentId = 0;
        if (this.props.rewind === true) {
            selectedDocumentId = this.props.selectedRow;
        } else {
            selectedDocumentId = this.state.selectedDocumentId;
        }

        return (
            <>
                    <Container fluid="true" style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                        <Table responsive striped bordered hover size="sm" style={{ maxHeight: 600, marginBottom:0, overflowY: 'auto',  display: 'inline-block'}} >
                            <thead style={{ display: 'table', width: '100%',   tableLayout: 'fixed'}}>
                                <tr>
                                    <th className="table-td-xs" scope="col" style={{ width: '4%' }}></th>
                                    <th className="table-td-xs" scope="col" style={{ width: '26%' }}>Instrument<br />Document #</th>
                                    {bookPageBoookTypeHeader}
                                    <th className="table-td-xs" scope="col" style={{ width: '22%' }} >Grantors<br />Grantees</th>
                                    <th className="table-td-xs" scope="col" style={{ width: '22%' }}>References<br />Legals</th>
                                </tr>
                            </thead>
                            { 
                                this.props.searchResults.timestamp > 0 &&
                                <tbody key={this.props.searchResults.timestamp} style={{ display: 'table', width: '100%', tableLayout: 'fixed', cursor: "pointer"}} >
                                {
                                    paginationHelper.currentPageRecordList.map((searchResult) => {
                                        const selectedClass = selectedDocumentId == searchResult.docId ? "table-active" : ""; // highlight the selected row

                                        const grantorsBadge = searchResult.grantorSearchResults && searchResult.grantorSearchResults.length > 1 ? "more" : "";
                                        const grantorOneDisplayName = searchResult.grantorSearchResults && searchResult.grantorSearchResults.length > 0 && searchResult.grantorSearchResults[0] ? searchResult.grantorSearchResults[0].displayName : '';
                                        const granteesBadge = searchResult.granteeSearchResults && searchResult.granteeSearchResults.length > 1 ? "more" : "";
                                        const granteeOneDisplayName = searchResult.granteeSearchResults && searchResult.granteeSearchResults.length > 0 && searchResult.granteeSearchResults[0] ? searchResult.granteeSearchResults[0].displayName : '';

                                        const referenceDocumentsBadge = searchResult.referenceDocumentSearchResults && searchResult.referenceDocumentSearchResults.length > 1 ? "more" : "";
                                        const referenceDocumentOne = searchResult.referenceDocumentSearchResults && searchResult.referenceDocumentSearchResults.length > 0 && searchResult.referenceDocumentSearchResults[0] ? searchResult.referenceDocumentSearchResults[0].displayDocNumber : '';

                                        const legalDescriptionsBadge = searchResult.legalSearchResults && searchResult.legalSearchResults.length > 1 ? "more" : "";
                                        const legalDescriptionOne = searchResult.legalSearchResults && searchResult.legalSearchResults.length > 0 && searchResult.legalSearchResults[0] ? searchResult.legalSearchResults[0].description : '';

                                        const selected = this.checkSelected(searchResult.docId);

                                       
                                        let cursorStyle = {cursor: 'wait' };
                                        if (searchResult.docId) {
                                            cursorStyle = { cursor: 'pointer' }
                                        }

                                        let badgeClass = 'badge-light';
                                        if (this.props.searchResultsVisited instanceof Set && this.props.searchResultsVisited.has(searchResult.docId+"")) {
                                            cursorStyle = {...cursorStyle, ...{color: 'darkgray'}} 
                                            badgeClass = 'badge-info';
                                        }

                                        return (
                                            <tr  className={selectedClass} style={cursorStyle} id={searchResult.docId} name={searchResult.displayDocNumber} onClick={this.handleRowClick}>
                                                { 
                                                    selected &&
                                                    <td className="table-td-xs" style={{ width: '4%' }} ><FormCheck onChange={this.handleCheckClick} checked={true} disabled={this.props.filterApplied}></FormCheck></td>
                                                }
                                                { 
                                                    !selected &&
                                                    <td className="table-td-xs" style={{ width: '4%' }} ><FormCheck onChange={this.handleCheckClick} checked={false} disabled={this.props.filterApplied}></FormCheck></td>
                                                }
                                                <td className="table-td-xs" style={{ width: '26%' }} ><strong>{searchResult.instrumentDescription}</strong><br />{searchResult.displayDocNumber ? searchResult.displayDocNumber : 'Loading'}<br /></td>
                                                <td className="table-td-xs" style={{ width: '18%' }} >{searchResult.recordedDate}<br />{searchResult.displayBookAndPage}</td>
                                                <td className="table-td-xs" style={{ width: '22%' }} >{grantorOneDisplayName}&nbsp;<span className='badge {badgeClass}'>{grantorsBadge}</span><br />{granteeOneDisplayName}&nbsp;<span className='badge {badgeClass}'>{granteesBadge}</span></td>
                                                <td className="table-td-xs" style={{ width: '22%' }} >{referenceDocumentOne}&nbsp;<span className='badge {badgeClass}'>{referenceDocumentsBadge}</span><br />{legalDescriptionOne}&nbsp;<span className='badge {badgeClass}'>{legalDescriptionsBadge}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            }
                        </Table>
                        <Row>
                        <Col style={{ marginLeft: 4}} >{loadingLabel} Results: {displayResults} of {filteredList.length} {truncatedResultWarning}</Col>
                        </Row>
                        <Row >
                        <Col style={{ marginLeft: 4}} ><SearchPaginator style={{ justifyContent: 'center' }} componentName={SearchResultsTableComponetName} numberOfVisibleRecords={numberOfVisibleRecords} numberOfPages={numberOfPages}></SearchPaginator></Col>
                        </Row>
                    </Container>
                
                <Alert show={this.state.showAlert} hideAlert={this.hideAlert} type={ALERT_TYPES.INFO} message={this.state.alertMessage} />
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <CreditCardPriceListWindow show={this.state.creditCardPriceListWindowShow} handleContinueClicked={this.handleCreditCardPriceListContinue} handleCloseClicked={this.handleCreditCardPriceListClose} />
                {this.state.creditCardAuthorizeDotNetWindowShow && <CreditCardSearchAuthWindow show={this.state.creditCardAuthorizeDotNetWindowShow} handleAuthorizedSubmit={this.handleAuthorizedSubmit} handleCloseClicked={this.handleCreditCardAuthorizeDotNetWindowClose} token={this.state.token} />}
                <CreditCardPriceListReAuthWindow show={this.state.creditCardPriceListReAuthWindowShow} handleContinueClicked={this.handleCreditCardPriceListReAuth} handleCloseClicked={this.handleCreditCardPriceListReAuthWindowClose} />
 
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsTable);