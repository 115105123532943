const initialState = false;

const searchResultsTableLoadingReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_TABLE_LOADING':
                  return action.tableLoading;
            default:
                  break;
      }
      return state;
};
export default searchResultsTableLoadingReducer;