import React from 'react';

import InformationMain from "./InformationMain";
import Navigationbar from "./Navigationbar";

export default class CommonTop extends React.Component {
	
    render()  {
       return( 
           <div>
				<InformationMain history={this.props.history}  />
                <Navigationbar />
           </div>
        )
     }
}