const initialState = 1;

const accountSelectionListPaginationReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'SET_SELECTED_ACCOUNT_SELECTION_PAGE':
                  return action.pageNumber;
            case 'LOGOUT_USER':
                  return 1;
            default:
                  break;
      }
      return state;
};
export default accountSelectionListPaginationReducer;