import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import SideNavigationLabel from "../side/SideNavigationLabel";
import SideNavigationToggle from "../side/SideNavigationToggle";
import SideNavigationNavLink from "../side/SideNavigationNavLink";
import SideNavigationNumberedNavLink from "../side/SideNavigationNumberedNavLink";

import SearchByAddress from "./searchBy/SearchByAddress";
import SearchByBookAndPage from "./searchBy/SearchByBookAndPage";
import SearchByDate from "./searchBy/SearchByDate";
import SearchByDocumentNumber from "./searchBy/SearchByDocumentNumber";
import SearchByLegalDescription from "./searchBy/SearchByLegalDescription";
import SearchByName from "./searchBy/SearchByName";
import SearchByParcelId from "./searchBy/SearchByParcelId";
import SearchByTorrensCertificate from "./searchBy/SearchByTorrensCertificate";
import SearchByTorrensText from "./searchBy/SearchByTorrensText";
import SearchByTractIndexNMI from "./searchBy/SearchByTractIndexNMI"

import SpecialSearchPlat from "./searchBy/SpecialSearchPlat";
import SpecialSearchGrantorGranteeBook from "./searchBy/SpecialSearchGrantorGranteeBook";
import SpecialSearchTaxLien from "./searchBy/SpecialSearchTaxLien";
import SearchResults from "./searchResults/SearchResults";
import TractBookSearchResults from "./searchResults/TractBookSearchResults";
import TaxLienSearchResults from "./searchResults/TaxLienSearchResults";
import TorrensSearchResults from "./searchResults/TorrensSearchResults";
import GrantorGranteeBookSearchResults from "./searchResults/GrantorGranteeBookSearchResults";
import SpecialSearchTractBookId from './searchBy/SpecialSearchTractBookId';
import SpecialSearchTractBookLegal from './searchBy/SpecialSearchTractBookLegal';

import CommonTop from "../../components/top/CommonTop";

import SearchHistory from './searchBy/SearchHistory';

import { SearchByAddressKey, SearchByBookAndPageKey, SearchByDateKey, SearchByDocumentNumberKey, 
	SearchByLegalDescriptionKey, SearchByNameKey, SearchByParcelIdKey, SearchByTorrensCertificateKey, SearchByTorrensTextKey,
	SpecialSearchTaxLienKey, SpecialSearchPlatKey, SpecialSearchGrantorGranteeBookKey, SpecialSearchTractBookIdKey, SpecialSearchTractBookLegalKey, SearchByTractIndexNMIKey } from "../../redux/actions/searchActions"; 

import { atlasIcon, atlasActiveIcon, addressIcon, addressActiveIcon, bookIcon, bookActiveIcon, dateIcon, dateActiveIcon,
		documentNumberIcon, documentNumberActiveIcon, 
		nameIcon, nameActiveIcon, parcelIcon, parcelActiveIcon, platIcon, platActiveIcon,
		legalIcon, legalActiveIcon, registeredLandIcon, registeredLandActiveIcon,  dollarIcon, dollarActiveIcon,
		bookmarkIcon, searchIcon, grantorGranteeBookIcon, grantorGranteeBookActiveIcon } from "../../img/font_awesome/font_awesome"


import translations from "../../config/translation/search_translations.json"

const mapStateToProps = state => {
	return {
		countyConfigSearch: state.countyConfigSearchReducer,
		selectedSearchType: state.searchTypeReducer.selectedSearchType,
		torrensLabel: state.countyConfigCommonReducer.torrensLabel,
		bookLabel: state.countyConfigCommonReducer.bookLabel,
		isTorrens : state.landlinkConfigReducer.torrens,
		searchHistoryList: state.searchHistoryReducer.searchHistoryList,
		searchList: state.currentUserReducer.searchList,
		role: state.currentUserReducer.role,
		language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'  
	};
}

class Search extends React.Component {

	render() {

		// Search By
		let searchByProps = {
			id: "searchByNavLink",
			image: searchIcon,
			name: translations[this.props.language].search_by + ":"
		}

		let searchByAddressProps = {
			id: "searchByAddressNavLink",
			link: "/search/" + SearchByAddressKey,
			image: this.props.selectedSearchType === SearchByAddressKey ? addressActiveIcon: addressIcon,
			selected: this.props.selectedSearchType === SearchByAddressKey,
			name: "Address"
		}

		let searchByBookAndPageProps = {
			id: "searchByBookAndPageNavLink",
			link: "/search/searchByBookAndPage",
			image: this.props.selectedSearchType === SearchByBookAndPageKey ? bookActiveIcon : bookIcon,
			selected: this.props.selectedSearchType === SearchByBookAndPageKey,
			name:  this.props.language !== 'ENGLISH' ? translations[this.props.language].book_and_page :  this.props.bookLabel + " & Page"
		}

		let searchByDateProps = {
			id: "searchByDateNavLink",
			link: "/search/" + SearchByDateKey,
			image: this.props.selectedSearchType === SearchByDateKey ? dateActiveIcon : dateIcon,
			selected: this.props.selectedSearchType === SearchByDateKey,
			name: translations[this.props.language].date
		}

		let searchByDocumentNumberProps = {
			id: "searchByDocumentNumberNavLink",
			link: "/search/" + SearchByDocumentNumberKey,
			image: this.props.selectedSearchType === SearchByDocumentNumberKey ? documentNumberActiveIcon : documentNumberIcon,
			selected: this.props.selectedSearchType === SearchByDocumentNumberKey,
			name: translations[this.props.language].document_number
		}

		let searchByNameProps = {
			id: "searchByNameNavLink",
			link: "/search/" + SearchByNameKey,
			image: this.props.selectedSearchType === SearchByNameKey ? nameActiveIcon: nameIcon,
			selected: this.props.selectedSearchType === SearchByNameKey,
			name: translations[this.props.language].name
		}

		let searchByLegalDescriptionProps = {
			id: "searchByLegalDescriptionNavLink",
			link: "/search/" + SearchByLegalDescriptionKey,
			image: this.props.selectedSearchType === SearchByLegalDescriptionKey ? legalActiveIcon: legalIcon,
			selected: this.props.selectedSearchType === SearchByLegalDescriptionKey,
			name: "Legal Description"
		}

		let searchByParcelIdProps = {
			id: "searchByParcelIdNavLink",
			link: "/search/" + SearchByParcelIdKey,
			image: this.props.selectedSearchType === SearchByParcelIdKey ? parcelActiveIcon: parcelIcon,
			selected: this.props.selectedSearchType === SearchByParcelIdKey,
			name: "Parcel ID"
		}

		let searchByTorrensCertificateProps = {
			id: "searchByTorrensCertificateNavLink",
			link: "/search/" + SearchByTorrensCertificateKey,
			image: this.props.selectedSearchType === SearchByTorrensCertificateKey ? registeredLandActiveIcon : registeredLandIcon,
			selected: this.props.selectedSearchType === SearchByTorrensCertificateKey,
			name: this.props.torrensLabel + " Certficate"
		}

		let searchByTorrensTextProps = {
			id: "searchByTorrensTextNavLink",
			link: "/search/" + SearchByTorrensTextKey,
			image: this.props.selectedSearchType === SearchByTorrensTextKey ? registeredLandActiveIcon : registeredLandIcon,
			selected: this.props.selectedSearchType === SearchByTorrensTextKey,
			name: this.props.torrensLabel + " Text"
		}

		let searchByTractIndexNMIProps = {
			id: "searchByTractIndexNMINavLink",
			link: "/search/" + SearchByTractIndexNMIKey,
			image: this.props.selectedSearchType === SearchByTractIndexNMIKey ? legalActiveIcon: legalIcon,
			selected: this.props.selectedSearchType === SearchByTractIndexNMIKey,
			name: translations[this.props.language].tract_index
		}

		let searchByList = [];
		if (this.props.countyConfigSearch.searchByAddress && this.props.searchList.includes('searchByAddress')) {
			searchByList.push(searchByAddressProps);
		}

		if (this.props.countyConfigSearch.searchByBookAndPage && this.props.searchList.includes('searchByBookAndPage')) {
			searchByList.push(searchByBookAndPageProps);
		}

		if (this.props.countyConfigSearch.searchByDate && this.props.searchList.includes('searchByDate')) {
			searchByList.push(searchByDateProps);
		}

		if (this.props.countyConfigSearch.searchByDocumentNumber && this.props.searchList.includes('searchByDocumentNumber')) {
			searchByList.push(searchByDocumentNumberProps);
		}

		if (this.props.countyConfigSearch.searchByName && this.props.searchList.includes('searchByName')) {
			searchByList.push(searchByNameProps);
		}

		if (this.props.countyConfigSearch.searchByLegalDescription && this.props.searchList.includes('searchByLegalDescription')) {
			searchByList.push(searchByLegalDescriptionProps);
		}

		if (this.props.countyConfigSearch.searchByParcelId && this.props.searchList.includes('searchByParcelId')) {
			searchByList.push(searchByParcelIdProps);
		}
		
		if (this.props.isTorrens === true && this.props.countyConfigSearch.searchByTorrensCertificate && this.props.searchList.includes('searchByTorrensCertificate')) {
			searchByList.push(searchByTorrensCertificateProps);
		}

		if (this.props.isTorrens === true && this.props.countyConfigSearch.searchByTorrensText && this.props.searchList.includes('searchByTorrensText')) {
			searchByList.push(searchByTorrensTextProps);
		}

		if (this.props.countyConfigSearch.searchByTractIndexNMI && this.props.searchList.includes('searchByTractIndexNMI')) {
			searchByList.push(searchByTractIndexNMIProps);
		}

		// Sort the SearchBy links
		searchByList.sort((a, b) => 
			{ 
				if (a.name > b.name) {
					return 1;
			  	} else if (a.name < b.name) {
					return -1;
			  	}
			  	return 0;
			}
		)

		// Special Searches
		let specialSearchesProps = {
			id: "specialSearchesNavLink",
			image: searchIcon,
			name: "Special Searches:"
		}

		let searchByGrantorGranteeBookProps = {
			id: "searchByGrantorGranteeBookNavLink",
			link: "/search/" + SpecialSearchGrantorGranteeBookKey,
			image: this.props.selectedSearchType === SpecialSearchGrantorGranteeBookKey ? grantorGranteeBookActiveIcon : grantorGranteeBookIcon,
			selected: this.props.selectedSearchType === SpecialSearchGrantorGranteeBookKey,
			name: "Grantor Grantee Book"
		}

		let searchByPlatProps = {
			id: "searchByPlatNavLink",
			link: "/search/" + SpecialSearchPlatKey,
			image: this.props.selectedSearchType === SpecialSearchPlatKey ? platActiveIcon : platIcon,
			selected: this.props.selectedSearchType === SpecialSearchPlatKey,
			name: "Plat"
		}

		let searchByTaxLienProps = {
			id: "searchByTaxLienNavLink",
			link: "/search/" + SpecialSearchTaxLienKey,
			image: this.props.selectedSearchType === SpecialSearchTaxLienKey ? dollarActiveIcon : dollarIcon,
			selected: this.props.selectedSearchType === SpecialSearchTaxLienKey,
			name: "Tax Lien"
		}

		let searchByTractBookIdProps = {
			id: "searchByTractBookIdNavLink",
			link: "/search/" + SpecialSearchTractBookIdKey,
			image: this.props.selectedSearchType === SpecialSearchTractBookIdKey ? atlasActiveIcon: atlasIcon,
			selected: this.props.selectedSearchType === SpecialSearchTractBookIdKey,
			name: "Tract Book ID"
		}
		
		let searchByTractBookLegalProps = {
			id: "searchByTractBookLegalNavLink",
			link: "/search/" + SpecialSearchTractBookLegalKey,
			image: this.props.selectedSearchType === SpecialSearchTractBookLegalKey ? atlasActiveIcon: atlasIcon,
			selected: this.props.selectedSearchType === SpecialSearchTractBookLegalKey,
			name: "Tract Book Legal"
		}

		let specialSearchesList = []

		if (this.props.countyConfigSearch.searchByGrantorGranteeBook && this.props.searchList.includes('searchByGrantorGranteeBook')) {
			specialSearchesList.push(searchByGrantorGranteeBookProps);
		}

		if (this.props.countyConfigSearch.searchByPlat && this.props.searchList.includes('searchByPlat')) {
			specialSearchesList.push(searchByPlatProps);
		}
		
		if (this.props.countyConfigSearch.searchByTaxLien && this.props.searchList.includes('searchByTaxLien')) {
			specialSearchesList.push(searchByTaxLienProps);
		}

		if (this.props.countyConfigSearch.searchByTractBookId && this.props.searchList.includes('searchByTractBookId')) {
			specialSearchesList.push(searchByTractBookIdProps);
		}

		if (this.props.countyConfigSearch.searchByTractBookLegal && this.props.searchList.includes('searchByTractBookLegal')) {
			specialSearchesList.push(searchByTractBookLegalProps);
		}

		// Sort the Special Searches links
		specialSearchesList.sort((a, b) => 
			{ 
				if (a.name > b.name) {
					return 1;
			  	} else if (a.name < b.name) {
					return -1;
			  	}
			  	return 0;
			}
		)

		// Recent Searches
		let recentSearchesProps = {
			image: bookmarkIcon,
			name: translations[this.props.language].recent_searches + ":"
		}

		// Set the /search Route based on the selectedSearchType.
		let defaultRoute;
		if ("searchByAddress" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByAddress} />;
		} else if ("searchByBookAndPage" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByBookAndPage} />;
		} else if("searchByDate" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByDate} />;
		} else if ("searchByDocumentNumber" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByDocumentNumber} />;
		} else if("searchByLegalDescription" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByLegalDescription} />;
		} else if ("searchByName" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByName} />;
		} else if("searchByParcelId" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByParcelId} />;
		} else if ("searchByTorrensCertificate" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByTorrensCertificate} />;
		} else if ("searchByTorrensText" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByTorrensText} />;
		} else if ("specialSearchGrantorGranteeBook" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SpecialSearchGrantorGranteeBook} />;
		} else if ("specialSearchPlat" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SpecialSearchPlat} />;
		} else if("specialSearchTaxLien" === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SpecialSearchTaxLien} />;
		} else if ('specialSearchTractBookId' === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SpecialSearchTractBookId} />;
		} else if ('specialSearchTractBookLegal' === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SpecialSearchTractBookLegal} />;
		} else if ('searchByTractIndexNMI' === this.props.selectedSearchType) {
			defaultRoute = <Route path="/search" component={SearchByTractIndexNMI} />;
		}

		if (!defaultRoute) {
			if ("searchByAddress" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByAddress} />;
			} else if ("searchByBookAndPage" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByBookAndPage} />;
			} else if("searchByDate" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByDate} />;
			} else if ("searchByDocumentNumber" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByDocumentNumber} />;
			} else if("searchByLegalDescription" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByLegalDescription} />;
			} else if ("searchByName" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByName} />;
			} else if("searchByParcelId" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByParcelId} />;
			} else if ("searchByTorrensCertificate" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByTorrensCertificate} />;
			} else if ("searchByTorrensText" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByTorrensText} />;
			} else if ("specialSearchGrantorGranteeBook" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SpecialSearchGrantorGranteeBook} />;
			} else if ("specialSearchPlat" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SpecialSearchPlat} />;
			} else if("specialSearchTaxLien" === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SpecialSearchTaxLien} />;
			} else if ('specialSearchTractBookId' === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SpecialSearchTractBookId} />;
			} else if ('specialSearchTractBookLegal' === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SpecialSearchTractBookLegal} />;
			} else if ('searchByTractIndexNMI' === this.props.countyConfigSearch.defaultSearch) {
				defaultRoute = <Route path="/search" component={SearchByTractIndexNMI} />;
			}
		}

		return (
				<div>
				<CommonTop history={this.props.history} />
				
				<div style={{ float: "left", minWidth: 38, maxWidth: 280 }}>
					<SideNavigationToggle />
					<Navbar bg="light" style={{ padding: 0, display: "block" }}>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Nav className="flex-column" style={{ width: '100%', float: "left", marginLeft: 2 }}>
							<Nav.Link eventKey="disabled" disabled style={{ fontWeight: 'bold', background: "gray", color: "black"}} className='side-nav-header' ><SideNavigationLabel {...searchByProps} /></Nav.Link>
							{
								searchByList.map((searchBy) => <SideNavigationNavLink {...searchBy} />)
							}
							<Nav.Link eventKey="disabled" disabled className='side-nav-header' style={{ fontWeight: 'bold', background: "gray", color: "black"}} ><SideNavigationLabel {...specialSearchesProps} /></Nav.Link>
							{
								specialSearchesList.map((searchBy) => <SideNavigationNavLink {...searchBy} />)
							}
							{
								this.props.role !== 'GUEST' &&
								<Nav.Link eventKey="disabled" disabled className='side-nav-header' style={{ fontWeight: 'bold', background: "gray", color: "black"}} ><SideNavigationLabel {...recentSearchesProps} /></Nav.Link>
							}
							{
								this.props.role !== 'GUEST' &&
								this.props.searchHistoryList.map((search, index) => {
								search.index = index + 1;
								return (<SideNavigationNumberedNavLink  key={"searchHistory" + search.index}  {...search} />);
								})
							}
						</Nav>
					</Navbar>
				</div>
				<div style={{paddingBottom: 4}}>
					<Switch>
						{/* Search Resultes routes */}
						<Route path="/search/SearchResults" component={SearchResults} />
						<Route path="/search/TractBookSearchResults" component={TractBookSearchResults} />
						<Route path="/search/GrantorGranteeBookSearchResults" component={GrantorGranteeBookSearchResults} />
						<Route path="/search/TaxLienSearchResults" component={TaxLienSearchResults} />
						<Route path="/search/TorrensSearchResults" component={TorrensSearchResults} />
						<Route path="/search/searchHistory/:searchType/:searchHistoryNumber" component={SearchHistory} />

						{/* Search History routes */}
						{
							this.props.countyConfigSearch.searchByAddress && 
							<Route path="/search/searchByAddress/:searchHistoryNumber" component={SearchByAddress} />
						}
						{
							this.props.countyConfigSearch.searchByBookAndPage && 
							<Route path="/search/searchByBookAndPage/:searchHistoryNumber" component={SearchByBookAndPage} />
						}
						{
							this.props.countyConfigSearch.searchByDate && 
							<Route path="/search/searchByDate/:searchHistoryNumber" component={SearchByDate} />
						}
						{
							this.props.countyConfigSearch.searchByDocumentNumber && 
							<Route path="/search/searchByDocumentNumber/:searchHistoryNumber" component={SearchByDocumentNumber} />
						}
						{
							this.props.countyConfigSearch.searchByLegalDescription && 
							<Route path="/search/searchByLegalDescription/:searchHistoryNumber" component={SearchByLegalDescription} />
						}
						{
							this.props.countyConfigSearch.searchByName && 
							<Route path="/search/searchByName/:searchHistoryNumber" component={SearchByName} />
						}
						{
							this.props.countyConfigSearch.searchByParcelId && 
							<Route path="/search/searchByParcelId/:searchHistoryNumber" component={SearchByParcelId} />
						}
						{
							this.props.countyConfigSearch.searchByTorrensCertificate && 
							<Route path="/search/searchByTorrensCertificate/:searchHistoryNumber" component={SearchByTorrensCertificate} />
						}	
						{
							this.props.countyConfigSearch.searchByTorrensText && 
							<Route path="/search/searchByTorrensText/:searchHistoryNumber" component={SearchByTorrensText} />
						}						
						{
							this.props.countyConfigSearch.searchByGrantorGranteeBook && 
							<Route path="/search/searchByGrantorGranteeBook/:searchHistoryNumber" component={SpecialSearchGrantorGranteeBook} />
						}						
						{
							this.props.countyConfigSearch.searchByPlat && 
							<Route path="/search/searchByPlat/:searchHistoryNumber" component={SpecialSearchPlat} />
						}
						{
							this.props.countyConfigSearch.searchByTaxLien && 
							<Route path="/search/searchByTaxLien/:searchHistoryNumber" component={SpecialSearchTaxLien} />
						}
						{
							this.props.countyConfigSearch.searchByTractBookId && 
							<Route path="/search/searchByTractBookId/:searchHistoryNumber" component={SpecialSearchTractBookId} />
						}
						{
							this.props.countyConfigSearch.searchByTractBookLegal && 
							<Route path="/search/searchByTractBookLegal/:searchHistoryNumber" component={SpecialSearchTractBookLegal} />
						}
						{
							this.props.countyConfigSearch.searchByTractIndexNMI && 
							<Route path="/search/searchByTractIndexNMI/:searchHistoryNumber" component={SearchByTractIndexNMI} />
						}
				
						{/* New Searches routes */}
						{
							this.props.countyConfigSearch.searchByAddress && 
							<Route path="/search/searchByAddress" component={SearchByAddress} />
						}
						{
							this.props.countyConfigSearch.searchByBookAndPage && 
							<Route path="/search/searchByBookAndPage" component={SearchByBookAndPage} />
						}
						{
							this.props.countyConfigSearch.searchByDate && 
							<Route path="/search/searchByDate" component={SearchByDate} />
						}
						{
							this.props.countyConfigSearch.searchByDocumentNumber && 
							<Route path="/search/searchByDocumentNumber" component={SearchByDocumentNumber} />
						}
						{
							this.props.countyConfigSearch.searchByLegalDescription && 
							<Route path="/search/searchByLegalDescription" component={SearchByLegalDescription} />
						}
						{
							this.props.countyConfigSearch.searchByName && 
							<Route path="/search/searchByName" component={SearchByName} />
						}
						{
							this.props.countyConfigSearch.searchByParcelId && 
							<Route path="/search/searchByParcelId" component={SearchByParcelId} />
						}
						{
							this.props.countyConfigSearch.searchByTorrensCertificate && 
							<Route path="/search/searchByTorrensCertificate" component={SearchByTorrensCertificate} />
						}	
						{
							this.props.countyConfigSearch.searchByTorrensText && 
							<Route path="/search/searchByTorrensText" component={SearchByTorrensText} />
						}						
						{
							this.props.countyConfigSearch.searchByGrantorGranteeBook && 
							<Route path="/search/searchByGrantorGranteeBook" component={SpecialSearchGrantorGranteeBook} />
						}						
						{
							this.props.countyConfigSearch.searchByPlat && 
							<Route path="/search/searchByPlat" component={SpecialSearchPlat} />
						}
						{
							this.props.countyConfigSearch.searchByTaxLien && 
							<Route path="/search/searchByTaxLien" component={SpecialSearchTaxLien} />
						}
						{
							this.props.countyConfigSearch.searchByTractBookId && 
							<Route path="/search/searchByTractBookId" component={SpecialSearchTractBookId} />
						}	
						{
							this.props.countyConfigSearch.searchByTractBookLegal && 
							<Route path="/search/searchByTractBookLegal" component={SpecialSearchTractBookLegal} />
						}	
						{
							this.props.countyConfigSearch.searchByTractIndexNMI && 
							<Route path="/search/searchByTractIndexNMI" component={SearchByTractIndexNMI} />
						}	
			
						{defaultRoute}						
					</Switch>
				</div>
				</div>
		);
	}
}

export default connect(mapStateToProps)(Search);
