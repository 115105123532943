import React from 'react';
import { Form } from 'react-bootstrap'
import '../../../landshark.css';

// Select element. 
// Its options are defaulted to Yes and No if options are not provided. 
export default class SelectField extends React.Component {

   render() {

      let clazzName = 'form-inline-block';
      if (this.props.formGroup.layout === 'horizontal') {
         clazzName = 'form-inline search-results-sort-dropdown ';
      }

      // if options are not provided, assume it's a Yes or No dropdown.
      let options = this.props.formGroup.options;
      if (!options) {
         options = [{code : true, description: "Yes"}, { code: false, description: "No"}]
      }

      let size = this.props.formGroup.size ? this.props.formGroup.size : "md";

      let formControl = 
         <Form.Control size={size} id={this.props.formGroup.id} style={ this.props.formGroup.style} as={this.props.formGroup.fieldType}  onChange={this.props.onChange}  >
         {
               // 'disabled' is assumed false
               options.map((option) => {
               if (this.props.formGroup.value === option.code)
                  return (<option key={option.code} selected disabled={!!option.disabled} value={option.code}>{option.description}</option>)
               else
                  return (<option key={option.code} disabled={!!option.disabled} value={option.code}>{option.description}</option>)
            })
         }
         </Form.Control>

      if (this.props.formGroup.disableField == true || this.props.disabled === true) {
         formControl = 
            <Form.Control size={size}  id={this.props.formGroup.id} style={ this.props.formGroup.style} as={this.props.formGroup.fieldType} disabled onChange={this.props.onChange} >
            {
               // 'disabled' is assumed false
               options.map((option, description) => {
                  if (this.props.formGroup.value === option[0])
                     return (<option  selected value={option[0]}>{option[1]}</option>)
                  else
                     return (<option  value={option[0]}>{option[1]}</option>)
               })
            }
            </Form.Control>
      }

      let buttonControl = <></>
      if (this.props.selectButton) {
         buttonControl = this.props.selectButton;
      }

      let fontWeight = 'normal';
      if (this.props.formGroup.primaryCriteria) {
          fontWeight = 'bold';
      }

      let label = this.props.formGroup.label ? this.props.formGroup.label + ':' : <>&nbsp;</>;

      return (
         <div className={clazzName} style={{ fontWeight }}>
            <div>{label}&nbsp;</div> 
            {formControl}
            {buttonControl}
         </div>
      )
   }
}