import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';

import { InputGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import {momentDateFormat} from "../../../../src/utils/DateValidation";

const InputWithButton = (props) => {
  return (
    <div>
      <InputGroup>
        <InputGroup.Append  >
          <Button style={{ color: "white", zIndex: 0 }} onClick={props.onClick} ><FontAwesomeIcon icon={faCalendar} /></Button>
        </InputGroup.Append>
      </InputGroup>
    </div>

  );
};

export default class DateField extends Component {

  state = {
    datePickerDate: this.props.formGroup.value,
    textInputDate: this.props.formGroup.value
  }

  static getDerivedStateFromProps(props, current_state) {
    if (props.formGroup.value !== current_state.textInputDate) {
      return {
        textInputDate: props.formGroup.value
      }
    } 
    return null;
  }

  handleDatePickerChange = (date) => {
    this.setState({textInputDate:moment(date).format('L')});
    this.setState({datePickerDate:date});

    this.props.formGroup.handleDatePickerChange(date);

  }

  handleDateInputChange = (event) => {
    this.handleChange(event);
    this.props.onChange(event);
  }

  handleChange = (event) => {
    this.setState({
      textInputDate: event.target.value
    })
   
    let momentDate = moment(event.target.value, momentDateFormat, true);

    if(momentDate.isValid()){
        this.setState({
          datePickerDate: event.target.value
        })
    }
  }
  
  render() {
    let selected = '';
    if (this.state.datePickerDate) {
      selected = moment(this.state.datePickerDate).toDate();
    }
 
    const inputProps = { }
   
    let fontWeight = 'normal';
    if (this.props.formGroup.primaryCriteria) {
        fontWeight = 'bold';
    }

    let disabled = false;
    if (this.props.formGroup.disabled) {
      disabled = true;
    }

    return (
      <div>
        <label style={{ fontWeight, marginBottom:0 }}>{this.props.formGroup.label}:</label> <br />
        <div className="form-group form-inline" style={{ marginBottom:0 }} >
          <input type='text' className='form-control' id={this.props.formGroup.id} disabled={disabled} value={this.state.textInputDate} onChange={this.handleDateInputChange} />
          <DatePicker selected={selected} onChange={(date) => this.handleDatePickerChange(date)} customInput={<InputWithButton inputProps={{ inputProps }} />} />
        </div>
      </div>
    )
  }
}