import React, { Component } from 'react';
import { connect } from "react-redux";
import { loadDocuments, setSelectedSearchType, SearchByParcelIdKey, SearchByParcelIdUrl } from '../../../redux/actions/searchActions';
import { LOADING_STATUES } from "../../constants/constants";
import Alert from '../../common/modal/Alert';
import Loading from '../../common/modal/Loading';
import moment from 'moment';

const componentName = SearchByParcelIdKey;

const matchDispatchToProps = (dispatch) => {
	return {
		setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
		searchByParcelId: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByParcelIdUrl)),
		resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName })
	}
}

class SearchByParcelIdExternal extends Component {
	state = {
	  showAlert: false,
		alertMessage: 'Could not find requested Parcel ID',
		loadingStatus: LOADING_STATUES.LOADING
	}

	searchCallback = (message, okay, content) => {
		if (message === 'No Records Found.') {
			this.setState({
				showAlert: true,
				loadingStatus: LOADING_STATUES.SUCCESS
			})
		} else {
			this.setState({
				loadingStatus: LOADING_STATUES.SUCCESS
			})
		}
	}

	hideAlert = () => {
		this.props.history.push('/search');
	}

	componentDidMount() {
		this.props.setSelectedSearchType();
    let parcelIdParam = this.props.match.params.id;

		let request = {			
			  fromRecordedDate: moment('01/01/1900').format('L'),
		    toRecordedDate: moment().format('L'),
		    parcelId: parcelIdParam
		}
		this.props.searchByParcelId(request, this.props.history, this.searchCallback);
  }

  render() {
		return (
			<>
				<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING } />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
			</>
		)

	}
}

export default connect(null, matchDispatchToProps)(SearchByParcelIdExternal);