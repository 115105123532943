import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'
import { paperPlaneIcon } from '../../img/font_awesome/font_awesome';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class LatestNewsBig extends Component {

   render() {
      return (
         <>
            <Modal show={this.props.show} onHide={this.props.handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
               <Form onSubmit={this.handleSubmit}>
               <Modal.Header  style={{ background: '#3674AB', color: 'white'}}>
                  <Modal.Title>{paperPlaneIcon}&nbsp;&nbsp;{translations[this.props.language].latest_news}</Modal.Title>
               </Modal.Header>
               <Modal.Body >
                     <ul style={{ listStyle: 'none' }}  >
                        <li>&nbsp;</li>
                        {
                           this.props.newsItems.map((newsItem, index) => {
                              return <li key={index}><span style={{ fontWeight: 'bold' }}>{newsItem.heading}</span>&nbsp; {newsItem.paragraphContent}</li>
                           })
                        }
                     </ul>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" onClick={this.props.handleClose}>Close</Button>
               </Modal.Footer>
               </Form>
            </Modal>
         
         </>
      )
   }
}

export default connect(mapStateToProps)(LatestNewsBig);