
const  paymentMethodTypesNonALaCarteReducer = (state = [], action) => {
  switch (action.type) {
    case 'LANDSHARK_PAYMENT_METHOD_TYPES_NON_A_LA_CARTE':
      return action.results;
    default:
      break;
  }
  return state;
}

export default paymentMethodTypesNonALaCarteReducer;