import React from 'react';
import { connect } from "react-redux";

const mapStateToProps = state => {
  return { 
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class SideNavigationNumberedLabel extends React.Component {
	render(){
	    let label = <div style={{textAlign:"left"}}  title={this.props.title}><span style={{width:18, display:"inline-block", textAlign:"center"}}>{this.props.number}</span></div>
		if (this.props.sideNavigationOpen) {
   			label = <div style={{textAlign:"left"}}  title={this.props.title}><span style={{width:18, display:"inline-block", textAlign:"center"}}>{this.props.number}</span><span>&nbsp;&nbsp;{this.props.name}&nbsp;&nbsp;</span><span style={{"backgroundColor":"lightGray"}} className="badge badge-secondary">{this.props.badge}</span></div>
   		}
   		return (label) 
    }
}

export default connect(mapStateToProps)(SideNavigationNumberedLabel);