import React, {Component} from 'react';
import { Card } from 'react-bootstrap'
import { mobileIcon } from '../../img/font_awesome/font_awesome';
import translations from "../../config/translation/home_translations.json"
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
      language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH',
    };
}

class ContactUs extends Component {
    render() {
        return (
            <Card>
                <Card.Header>
                    {mobileIcon}&nbsp;&nbsp;{translations[this.props.language].contact_us} 
                </Card.Header>
                <Card.Body>
                
                        <>
                        <ul style={{ listStyle: 'none' }}  >
                            <li><span style={{ fontWeight: 'bold' }}>Email:</span> {this.props.contactEmail}</li>
                            <li><span style={{ fontWeight: 'bold' }}>Phone:</span> {this.props.contactPhone}</li>
                        </ul>
                        </>
                
                </Card.Body>
            </Card>
        )
    }
}

export default connect(mapStateToProps)(ContactUs);