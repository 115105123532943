const auditTransactionTypesReducer = (state = [{code: "all", description: "All"}], action) => {
  switch (action.type) {
    case 'LANDSHARK_TRANSACTION_LOG_TYPES':
      return action.results;
    default:
      break;
  }
  return state;
}

export default auditTransactionTypesReducer;

