import React, {Component} from 'react';
import { InputGroup, Button, Form } from 'react-bootstrap'
import { searchIcon  } from "../../../img/font_awesome/font_awesome"


class TextFilter extends Component {
   state = {
		filterText: this.props.textFilterProps.filterText ? this.props.textFilterProps.filterText : ''
	}

   handleChange = (event) => {
      event.preventDefault();
		this.setState({
			filterText: event.target.value
		})
   }
   
   handleSubmit = (event) => {
      event.preventDefault();

  		// 13 is Enter. 
		if (event.type === 'keydown' && event.keyCode !== 13) {
         return;
		}

      this.props.textFilterProps.textFilter(this.state.filterText);   
    }

   render() {
      return (
         <Form onSubmit={this.handleSubmit}>
            <InputGroup>
               <Form.Control value={this.state.filterText} onChange={this.handleChange} onKeyDown={this.props.handleSubmit} />
               <InputGroup.Append>
                  <Button style={{ color: "white" }} type="submit" >{searchIcon}</Button>
               </InputGroup.Append>
            </InputGroup>
         </Form>
      )
   }
}

export default TextFilter;