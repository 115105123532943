import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from "axios";
import FormTemplate from "../common/form/FormTemplate";
import { setSelectedControlPanelType, LoginPageRegistrationControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import { LOADING_STATUES } from "../constants/constants";

const componentName = LoginPageRegistrationControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

class LoginPageRegistration extends Component {

  state = {
    forgotPasswordLink: false,
    selfRegistration: false,
    emaileExpireIn: 4,
    loadingStatus: '',
    message: ''
  }

  handleSubmit = (event) => {

    event.preventDefault();
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageRegistration", this.state, { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState({ 
          forgotPasswordLink: content.forgotPasswordLink,
          selfRegistration: content.selfRegistration,
          emaileExpireIn: content.emaileExpireIn,
          loadingStatus: LOADING_STATUES.SUCCESS
        });
      },
      (error) => {
        handleError(error, this.props.history);
      }
    );
  }

  handleChange = (event) => {
    let value = event.target.value;
    value = value === "true" || value === "false" ? JSON.parse(event.target.value) : value;
    this.setState({
      loadingStatus: "",
      [event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value)
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageRegistration", { withCredentials: true })
    .then(
      (response) => {
        const content = response.data.content;
        this.setState( {
          forgotPasswordLink: content.forgotPasswordLink,
          selfRegistration: content.selfRegistration,
          emaileExpireIn: content.emaileExpireIn,
          loadingStatus : ""
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    const formProps = {
      componentName: componentName,
      pageHeading: "Login Page - Registration & Recovery",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      formGroupRows: [
        {
          formGroups: [{ id: 'selfRegistration', label: 'Self Registration', fieldType: 'select', value: this.state.selfRegistration }]
        },
        {
          formGroups: [{ id: 'forgotPasswordLink', label: "Forgot User/Password Link", fieldType: 'select', value: this.state.forgotPasswordLink }]
        },
        {
          formGroups: [{ id: 'emaileExpireIn', label: 'Emails expire in (hours)', fieldType: 'number', required: true,  value: this.state.emaileExpireIn} ] 
        }
      ]
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(null, matchDispatchToProps)(LoginPageRegistration);