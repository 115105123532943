import React from 'react';

import { Button } from 'react-bootstrap'

export default class CancelButton extends React.Component {
   render() {
      return (
         <Button variant="secondary" onClick={this.props.onCancel}>Cancel</Button>
      )
   }
}