const initialState = {
  statementReport: {},
  auditAccountsReport: {},
  auditUsersReport: {},
  accountTypeReport: {}
}

const reportCriteriaReducer =  (state = initialState, action)  => {
 
  switch (action.type) {
    case 'UPDATE_REPORT_CRITERIA':
      let reportType = action.reportType;
      state[reportType] = action.reportCriteria;
      return state;
    case 'LOGOUT_USER':
      return {
        statementReport: {},
        auditAccountsReport: {},
        auditUsersReport: {},
        accountTypeReport: {}
      };
    default:
          break;
  }
  return state;
}

export default reportCriteriaReducer;




