const initialState = { 
      "timestamp":0,
      "batch":1,
      "totalRecordCount":0,
      "currentRecordCount":0,
      "searchResultList":[]
};

const searchResultsFilterTaxLiensReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'FILTER_TAX_LIEN_ID':
                  const document = action.document;      
                  let index = -1;
                  for (let i = 0; i < state["searchResultList"].length; i++) {
                        if (document.docId == state.searchResultList[i].docId) {
                              index = i;
                              break;
                        }
                  }

                  if (index > -1) { // if found, remove the document
                        state.searchResultList.splice(index, 1); 
                        state.totalRecordCount -= 1
                  } else if (state.totalRecordCount < 100) { // if not found, insert the document
                        state.searchResultList.push(document);
                        if (action.sortKey === 'docNumber') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.docNumber > document2.docNumber) ? 1 : ((document2.docNumber > document1.docNumber) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.docNumber > document1.docNumber) ? 1 : ((document1.docNumber > document2.docNumber) ? -1 : 0))
                              }
                        } else if (action.sortKey === 'recordedDate') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.recordedDateComparing > document2.recordedDateComparing) ? 1 : ((document2.recordedDateComparing > document1.recordedDateComparing) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.recordedDateComparing > document1.recordedDateComparing) ? 1 : ((document1.recordedDateComparing > document2.recordedDateComparing) ? -1 : 0))
                              }
                        } else if (action.sortKey === 'lienDate') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.lienDateComparing > document2.lienDateComparing) ? 1 : ((document2.lienDateComparing > document1.lienDateComparing) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.lienDateComparing > document1.lienDateComparing) ? 1 : ((document1.lienDateComparing > document2.lienDateComparing) ? -1 : 0))
                              }
                        } else if (action.sortKey === 'lienNumber') {
                              if (action.sortDirection === 'ASC') {
                                    state.searchResultList.sort((document1, document2) => (document1.lienNumber > document2.lienNumber) ? 1 : ((document2.lienNumber > document1.lienNumber) ? -1 : 0))
                              } else {
                                    state.searchResultList.sort((document1, document2) => (document2.lienNumber > document1.lienNumber) ? 1 : ((document1.lienNumber > document2.lienNumber) ? -1 : 0))
                              }
                        }
                        state.totalRecordCount += 1
                  }             
                  state.timestamp += 1;
                  return state;
            
            case 'SORT_FILTERED_TAX_LIENS':
                  if (action.sortKey === 'docNumber') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.docNumber > document2.docNumber) ? 1 : ((document2.docNumber > document1.docNumber) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.docNumber > document1.docNumber) ? 1 : ((document1.docNumber > document2.docNumber) ? -1 : 0))
                        }
                  } else if (action.sortKey === 'recordedDate') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.recordedDateComparing > document2.recordedDateComparing) ? 1 : ((document2.recordedDateComparing > document1.recordedDateComparing) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.recordedDateComparing > document1.recordedDateComparing) ? 1 : ((document1.recordedDateComparing > document2.recordedDateComparing) ? -1 : 0))
                        }
                  } else if (action.sortKey === 'lienDate') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.lienDateComparing > document2.lienDateComparing) ? 1 : ((document2.lienDateComparing > document1.lienDateComparing) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.lienDateComparing > document1.lienDateComparing) ? 1 : ((document1.lienDateComparing > document2.lienDateComparing) ? -1 : 0))
                        }
                  } else if (action.sortKey === 'lienNumber') {
                        if (action.sortDirection === 'ASC') {
                              state.searchResultList.sort((document1, document2) => (document1.lienNumber > document2.lienNumber) ? 1 : ((document2.lienNumber > document1.lienNumber) ? -1 : 0))
                        } else {
                              state.searchResultList.sort((document1, document2) => (document2.lienNumber > document1.lienNumber) ? 1 : ((document1.lienNumber > document2.lienNumber) ? -1 : 0))
                        }
                  }
                  return state;
            
            case 'UPDATE_SEARCH_RESULTS':    
            case 'LOGOUT_USER':
                  return {
                        "timestamp":0,
                        "totalRecordCount":0,
                        "currentRecordCount":0,
                        "searchResultList":[]   
                  }
            default:
                  break;
      }
      return state;
};
export default searchResultsFilterTaxLiensReducer;