import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import axios from "axios";
import { setSelectedControlPanelType, LoginPageLinksControlPanelKey } from "../../redux/actions/controlPanelActions";
import configData from "../../config/config.json";
import { handleError } from '../../redux/actions/commonStuff';
import PageBanner from "../common/page/PageBanner";
import PrimaryButton from "../common/form/PrimaryButton";
import { arrowDownIcon, arrowUpIcon, checkIconGreen, spinnerIconGray } from "../../img/font_awesome/font_awesome";
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = LoginPageLinksControlPanelKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName))
  }
}

const mapStateToProps = state => {

  return {
    sideNavigationOpen: state.sideNavigationReducer
  };
}

class LoginPageLinks extends Component {

  state = {
    paragraphs: [
      {
        heading: '',
        paragraphContent: ''
      }
    ],
    status: "",
    message: ""
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.status === "") {
      this.setState({
        status: LOADING_STATUES.LOADING
      })
    } else {
      return;
    }

    axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageLinks", this.state, { withCredentials: true })
      .then(
        (response) => {
          this.setState({
            status: LOADING_STATUES.SUCCESS
          });
        },
        (error) => {
          handleError(error, this.props.history);
        }
      );
  }

  handleChange = (event) => {
    const targetId = event.target.id;
    if (targetId.includes("heading")) {
      const index = targetId.replace("heading", "");
      let paragraphs = [...this.state.paragraphs];
      let rate = { ...paragraphs[index] };
      rate.heading = event.target.value;
      paragraphs[index] = rate;
      this.setState({
        status: "",
        paragraphs
      });
    } else if (targetId.includes("paragraphContent")) {
      const index = targetId.replace("paragraphContent", "");
      let paragraphs = [...this.state.paragraphs];
      let rate = { ...paragraphs[index] };
      rate.paragraphContent = event.target.value;
      paragraphs[index] = rate;
      this.setState({
        status: "",
        paragraphs
      });
    } else {
      this.setState({
        status: "",
        [event.target.id]: event.target.value ? event.target.value : 0
      })
    }
  }

  handleUpRow = (event) => {
    event.preventDefault();
    const clickedId = event.target.closest("button").id; // find the closest parent button
    let indexToMoveUp = clickedId.replace("up", "");
    indexToMoveUp = parseInt(indexToMoveUp);
    if (indexToMoveUp === 0) {
      alert("Already at first row" );
      return;
    }

    let paragraphs = this.state.paragraphs;
    [paragraphs[indexToMoveUp-1], paragraphs[indexToMoveUp]] = [paragraphs[indexToMoveUp], paragraphs[indexToMoveUp-1]];
    
    this.setState({
      paragraphs: paragraphs
    })
  }

  handleDownRow = (event) => {
    event.preventDefault();
    const clickedId = event.target.closest("button").id; // find the closest parent button
    let indexToMoveDown = clickedId.replace("down", "");
    indexToMoveDown = parseInt(indexToMoveDown);
    if (indexToMoveDown === 11) {
      alert("Already at last row" );
      return;
    }

    let paragraphs = this.state.paragraphs;
    [paragraphs[indexToMoveDown], paragraphs[indexToMoveDown+1]] = [paragraphs[indexToMoveDown+1], paragraphs[indexToMoveDown]];

    this.setState({
      paragraphs: paragraphs
    })
  }

  componentDidMount() {
    this.props.setSelectedControlPanelType();

    axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/countyConfig/loginPageLinks", { withCredentials: true })
      .then(
        (response) => {
          const content = response.data.content;
          let paragraphs = content.paragraphs;
          if (!paragraphs ) {
            paragraphs = []
          }

          while (paragraphs.length < 12) {
            paragraphs.push( { heading: '', paragraphContent: ''  })
          }

          this.setState({
            status: "",
            paragraphs
          });
        },
        (error) => {
          handleError(error, this.props.history);
        }
      );
  }

  render() {
    let formTemplateMarginLeft = 60
    let formTemplateMarginRight = 20
    if (this.props.formOnly) {
      formTemplateMarginLeft = 0
      formTemplateMarginRight = 0
    } else if (this.props.sideNavigationOpen) {
      formTemplateMarginLeft = 270
    }

    let paragraphTable =
      <>
        {
          this.state.paragraphs.map((rate, index) => (
         
            <Row>
              <Col xs={3} sm={3} md={3} xl={3} >
                <Form.Control type='text'  id={'heading' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.paragraphs[index].heading} style={{ maxWidth: 400}} />
              </Col>
              <Col xs={6} sm={6} md={7} xl={8} style={{ paddingLeft: 0}}>
                <Form.Control type='text'  id={'paragraphContent' + index} disabled={this.state.status === LOADING_STATUES.LOADING} onChange={this.handleChange} value={this.state.paragraphs[index].paragraphContent}  />
              </Col>
             
              <Col xs={3} sm={3} md={2} xl={1} style={{ paddingLeft: 0}}>
             
              <Button id={'up' + index} variant="secondary" size="sm" style={{ margin: 1 }} disabled={this.state.status === LOADING_STATUES.LOADING} onClick={this.handleUpRow}>{arrowUpIcon}</Button>
              <Button id={'down' + index} variant="secondary" size="sm" style={{ margin: 1 }} disabled={this.state.status === LOADING_STATUES.LOADING} onClick={this.handleDownRow}>{arrowDownIcon}</Button>
            
              </Col>
            </Row>
          ))
        }
      </>

    return (
      <div style={{ marginLeft: formTemplateMarginLeft, marginRight: formTemplateMarginRight,  marginTop:4, paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)"  }}>
        <PageBanner pageHeading="Login Page Links" />
        <Container fluid="true" style={{ paddingLeft: 50, paddingBottom: 50, marginTop: 10, marginLeft: 4, marginRight: 4, border: '3px solid darkGray', borderRadius: '5px' }}>
        <Row>
              <Col xs={3} sm={3} md={3} xl={3} >
                1 or 2-word description (ex. Trimin Systems)
              </Col>
              <Col xs={6} sm={6} md={7} xl={8} style={{ paddingLeft: 0}}>
                URL (ex. https://www.trimingov.com/)
              </Col>
             
              <Col xs={3} sm={3} md={2} xl={1} style={{ paddingLeft: 0}} /> 
          </Row>
          <Form onSubmit={this.handleSubmit}>
            {paragraphTable}
            <Row>&nbsp;</Row>
            <PrimaryButton buttonLabel="Submit" disabled={this.state.status === LOADING_STATUES.LOADING} />&nbsp;&nbsp;
            {
              this.state.status === LOADING_STATUES.SUCCESS &&
              <span style={{ width: 18, verticalAlign: "bottom", paddingBottom: 1, display: "inline-block" }}>{checkIconGreen}</span>
            }
            {
              this.state.status === LOADING_STATUES.LOADING &&
              <span style={{ width: 18, verticalAlign: "bottom", marginBottom: 1, display: "inline-block" }}>{spinnerIconGray}</span>
            }
          </Form>
        </Container>
      </div>
    )
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(LoginPageLinks);