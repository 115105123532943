import React, {Component} from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import FormTemplate from "../common/form/FormTemplate";
import { reportAccountType, setSelectedReportType, ReportAccountTypeKey } from "../../redux/actions/reportActions";
import { amPmOptions, hourOptions, minuteOptions, validateDateTimes, validateYearRangeLimit } from "../../utils/DateValidation";
import { LOADING_STATUES } from "../../components/constants/constants";

const componentName = ReportAccountTypeKey;

const matchDispatchToProps = (dispatch) => {
  return { 
    setSelectedReportType: () => dispatch(setSelectedReportType(componentName)), 
    reportAccountType: (searchCriteria, history, callback) => dispatch(reportAccountType(searchCriteria, history, callback))
  }
}

const mapStateToProps = (state) => {
  let reportByCriteria = state.reportCriteriaReducer[componentName];
  let transactionType = reportByCriteria['transactionType'] ? reportByCriteria['transactionType'] : 'all';
  let fromDate = reportByCriteria['fromDate'] ? reportByCriteria['fromDate'] : moment().format('L');
  let toDate = reportByCriteria['toDate'] ? reportByCriteria['toDate'] : moment().format('L');
  let fromHour = reportByCriteria['fromHour'] ? reportByCriteria['fromHour'] : '12';
  let fromMinute = reportByCriteria['fromMinute'] ? reportByCriteria['fromMinute'] : '0';
  let fromAmPm = reportByCriteria['fromAmPm'] ? reportByCriteria['fromAmPm'] : 'am';
  let toHour = reportByCriteria['toHour'] ? reportByCriteria['toHour'] : '11';
  let toMinute = reportByCriteria['toMinute'] ? reportByCriteria['toMinute'] : '59';
  let toAmPm = reportByCriteria['toAmPm'] ? reportByCriteria['toAmPm'] : 'pm';
  let dataSource = reportByCriteria['dataSource'] ? reportByCriteria['dataSource'] : "FIVE";

  return {
    transactionTypes: state.auditTransactionTypesReducer.codeDescriptionResultList,
    transactionTypesAll: state.auditTransactionTypesAllReducer.codeDescriptionResultList,
    dataSourceTypes: state.dataSourceTypesReducer.codeDescriptionResultList,
    transactionType,
    fromDate,
    fromHour,
    fromMinute,
    fromAmPm,
    toDate,
    toHour,
    toMinute,
    toAmPm,
    dataSource,
    role: state.currentUserReducer.role
  };
}

class AccountTypReport extends Component {

  state = {
    transactionType: this.props.transactionType,
    fromDate: this.props.fromDate,
    toDate: this.props.toDate,
    fromHour: this.props.fromHour,
    fromMinute: this.props.fromMinute,
    fromAmPm: this.props.fromAmPm,
    toHour: this.props.toHour,
    toMinute: this.props.toMinute,
    toAmPm: this.props.toAmPm,
    dataSource: this.props.dataSource,

    loadingStatus: "",
    errors: {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        const { loadingStatus, errors, ...request } = this.state; // remove UI only fields before submitting
        this.props.reportAccountType(request, this.props.history, this.hideLoading);
      }
    }
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    } 
    return false;
  }

  hideLoading = (message, status) => {
    if (status === 'ERROR') {
      alert(message);
    }
    this.setState({
      loadingStatus: ""
    })
  }


  handleValidation() {
    let errors = {};
    if (!this.state.fromDate) {
      errors['fromDate'] = "Required.";
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDateTimes(errors, this.state.fromDate, this.state.fromHour, this.state.fromMinute, this.state.fromAmPm, 'fromDate', this.state.toDate, this.state.toHour, this.state.toMinute, this.state.toAmPm, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }
    
    formIsValid = validateYearRangeLimit(errors, this.state.fromDate, 'fromDate', this.state.toDate, 'toDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleDatePickerChangeFromDate = (date) => {
    this.setState({
      fromDate : moment(date).format('L')
    })
  }

  handleDatePickerChangeToDate = (date) => {
    this.setState({
      toDate : moment(date).format('L')
    })
  }
  componentDidMount() {
    this.props.setSelectedReportType();
  }

  render() {

    let transactionTypes = this.props.transactionTypes;
    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      transactionTypes = this.props.transactionTypesAll;
    }

    let formGroupRows = [
      {
        formGroups: [
        { id: 'fromDate', label: 'From Date', fieldType: 'date', value: this.state.fromDate, handleDatePickerChange: this.handleDatePickerChangeFromDate, error: this.state.errors['fromDate'] },
        { id: 'fromHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.fromHour },
        { id: 'fromMinute', label: '',fieldType: 'minute', options: minuteOptions, value: this.state.fromMinute },
        { id: 'fromAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.fromAmPm }]
      },
      {
        formGroups: [
        { id: 'toDate', label: 'To Date', fieldType: 'date', value: this.state.toDate, handleDatePickerChange: this.handleDatePickerChangeToDate, error: this.state.errors['toDate'] },
        { id: 'toHour', label: '', fieldType: 'select', options: hourOptions, value: this.state.toHour },
        { id: 'toMinute', label: '', fieldType: 'minute', options: minuteOptions, value: this.state.toMinute },
        { id: 'toAmPm', label: '', fieldType: 'select', options: amPmOptions, value: this.state.toAmPm }]
      },
      {
        formGroups: [{ id: 'transactionType', label: 'Transaction Type', fieldType: 'select', options: transactionTypes, value: this.state.transactionType }]
      }
    ]

    if (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY') {
      formGroupRows.push({
        formGroups: [{ id: 'dataSource', label: 'Data Source', fieldType: 'select', options: this.props.dataSourceTypes, value: this.state.dataSource }]
      });
    }

    const formProps = {
      componentName: componentName,
      pageHeading: "Account Type Report",
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      formGroupRows: formGroupRows
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(AccountTypReport);