 const instrumentCodesWithBlankReducer = (state = [{code: "", description: ""}], action) => {
  switch (action.type) {
    case 'LANDLINK_INSTRUMENT_CODES_WITH_BLANKS':
      return action.results;
    default:
      break;
  }
  return state;
}

export default instrumentCodesWithBlankReducer;