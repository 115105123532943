
import countyConfigUserLockoutReducer from "./countyConfigUserLockoutReducer"
import countyConfigUserPurgeNowReducer from "./countyConfigUserPurgeNowReducer"

const initialState = {
  userPurgeNow: { "purgeDate": "01/05/2020" },
  userLockout: {"lockoutInPlace": false}
}

const countyConfigReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case 'SET_USER_LOCKOUT':
        return {...state, userAccess: countyConfigUserLockoutReducer(state.userLockout, action)}
    
    case 'SET_USER_PURGE_NOW':
      return {...state, userPurgeNow: countyConfigUserPurgeNowReducer(state.userPurgeNow, action)}

    default:
      return state
  }
}

export default countyConfigReducer;