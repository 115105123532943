const initialState = {
  "searchHistoryList": []
};

const searchHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_HISTORY':
      return action.historyResults;
    case 'LOGOUT_USER':
      return {
        "searchHistoryList": []
      };
    default:
      break;
  }
  return state;
}

export default searchHistoryReducer;