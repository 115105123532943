import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Alert from '../common/modal/Alert';
import Loading from '../common/modal/Loading';
import FormTemplate from "../common/form/FormTemplate";
import LoginTop from "../../components/top/LoginTop";
import axios from "axios";
import configData from "../../config/config.json";
import usStates from "../../config/us_states_no_blank_entry.json";
import { connect } from "react-redux";

import { formatPhone, formatInternationalPhone, formatZip } from "../common/function/formattingUtils.js";
import { validateUser, trimUserFields } from "../common/function/userValidation.js";
import Footer from '../bottom/Footer'
import { LOADING_STATUES } from "../../components/constants/constants";

const mapStateToProps = (state) => {
	return {
		countyStateCode: state.productPropertiesReducer.countyStateCode
	};
}

class Register extends React.Component {
	state = {
		username: "",
		firstName: "",
		lastName: "",
		company: "",
		address: "",
		city: "",
		state: this.props.countyStateCode,
		zip: "",
		email: "",
		phoneFormatType: 'STANDARD',
		phoneInternational: '',
		phoneStandard: '',
		password: "",
		passwordConfirm: "",
		showAlert: false,
		alertMessage: "",
		status: "",
		errors: {},
		redirectToLogin: false
	  }

	showLoading = () => {
		if (this.state.status === "") {
			this.setState({
				status: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	handleChange = (event) => {
		event.preventDefault();
	
		let value = event.target.value;
		if (event.target.id === 'phoneStandard') {
		   	value = formatPhone(value, this.state.phone);
		} else if (event.target.id === 'phoneInternational') {
			value = formatInternationalPhone(value);
		} else if (event.target.id === 'zip') {
			value = formatZip(value);
		}

		this.setState({
		   [event.target.id]: value,
		   errors: {}
		})
	}
	
	validateAndSubmit = () => {
		let errors = validateUser(this.state);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({errors});
			return;
		}

		if (this.showLoading() === true) {

			const { passwordConfirm, status, 		
				 showAlert, alertMessage, redirectToLogin, errors, 
				 phoneInternational, phoneStandard,
				 ...request } = this.state; // remove UI only fields before submitting

			if (this.state.phoneFormatType === 'INTERNATIONAL') {
				request.phone = this.state.phoneInternational;
			} else {
				request.phone = this.state.phoneStandard;
			}

			axios.post(configData.LANDSHARK_SERVER_URL + "auth/signUp", request, { withCredentials: true })
				.then((response) => {
					this.setState({status: "", redirectToLogin: true}, this.showAlert("We've emailed you a link.  Please follow the link to complete your registration.")); 
				}, (error) => {
					this.setState({status: "", redirectToLogin: false}, this.showAlert(error.response.data.message));
				});
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();

		const trimmedUserFields = trimUserFields(this.state);
		this.setState({ ...trimmedUserFields }, function () {
			this.validateAndSubmit();
		});
	}
	
	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/login');
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: ""
		})
		if (this.state.redirectToLogin) {
			this.props.history.push('/login');
		}
	}

	render() {
		let formGroupRows = [
			{
				formGroups: [{ id: 'username', label: 'Username', fieldType: 'text', value: this.state.username, required: true, primaryCriteria: true,  error: this.state.errors['username']  },  { id: 'email', label: 'Email', fieldType: 'email', value: this.state.email, required: true, primaryCriteria: true,  error: this.state.errors['email']  }]
		  	},
			{
				formGroups: [{ id: 'password', label: 'Password', fieldType: 'password', value: this.state.password, required: true,  primaryCriteria: true, error: this.state.errors['password']  }, { id: 'passwordConfirm', label: 'Confirm Password', fieldType: 'password', value: this.state.passwordConfirm, required: true, primaryCriteria: true,  error: this.state.errors['passwordConfirm']  }]
			},			
			{
				formGroups: [{ id: 'firstName', label: 'First Name', fieldType: 'text', value: this.state.firstName, required: true, primaryCriteria: true }, { id: 'lastName', label: 'Last Name', fieldType: 'text', value: this.state.lastName, required: true, primaryCriteria: true }]
		  	},
			{
			  	formGroups: [{ id: 'company', label: 'Company', fieldType: 'text', value: this.state.company, required: false, error: this.state.errors['company']  }]
			},
			{
			  	formGroups: [{ id: 'address', label: 'Address', fieldType: 'text', value: this.state.address, required: true, style: {minWidth: 400}, error: this.state.errors['address']  }]
			},
			{
			  	formGroups: [{ id: 'city', label: 'City', fieldType: 'text', value: this.state.city, required: true, error: this.state.errors['city']  }, { id: 'state', label: 'State', fieldType: 'select', required: true, value: this.state.state, options: usStates, error: this.state.errors['state']  }, { id: 'zip', label: 'Zip (ex. 55111)', fieldType: 'zip', value: this.state.zip, required: true, style: {maxWidth: 100, marginRight: 10}, error: this.state.errors['zip']  }]
			},
			{
				formGroups: [{ id: 'phoneFormatType', label: 'Phone Format', fieldType: 'select', options: [{ code: "STANDARD", description: "(XXX) XXX-XXXX" }, { code: "INTERNATIONAL", description: "International" }], value: this.state.phoneFormatType, required: true, error: this.state.errors['phoneFormatType'] },
				             { id: 'phoneStandard', label: 'Phone (ex. (612) 555-1234)', fieldType: 'tel', value: this.state.phoneStandard, required: true, hideField: this.state.phoneFormatType !== 'STANDARD', error: this.state.errors['phoneStandard'] },
							 { id: 'phoneInternational', label: 'Phone with country code (ex. 8613123456789)', fieldType: 'tel_international', value: this.state.phoneInternational, required: true, hideField: this.state.phoneFormatType !== 'INTERNATIONAL', error: this.state.errors['phoneInternational'] } ]
			}
		]

		const formProps = {
			componentName: "UserRegistration",
			pageHeading: "User Registration",
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows,
			formOnly: true
		}

		return (
			<>
                <LoginTop />
				<section className="cn container-fluid" style={{background: '#3674AB' }}>
				<div style={{background: 'white', border: 'solid 3px black', borderRadius: 5, paddingBottom: 10, paddingLeft: 10, paddingRight: 10}}>
					<Container fluid="true" >
						<Row>
						<Col>
							<FormTemplate {...formProps} />
						</Col>
						</Row>
					</Container>
				</div>
				</section>
				<Footer />
				<Loading loadingIndicator={this.state.status === LOADING_STATUES.LOADING} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} />
			</>
        )
    }
}

export default connect(mapStateToProps)(Register); 