import React, { Component } from "react";
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { connect } from "react-redux";
import translations from "../../config/translation/navigation_bar_translations.json"

const mapStateToProps = state => {
	return {
        search: state.currentUserReducer.search,
		role: state.currentUserReducer.role,
        countyConfigEfile: state.countyConfigEfileReducer,
        countyName: state.countyConfigCommonReducer.countyName,
		language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'  
	};
}

class Navigationbar extends Component {
    render() {
        return <Navbar bg="light" expand="lg" style={{ padding: 2 }}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" style={{ width: '100%' }}>
                    <NavLink to="/home" className="top-navlink">{translations[this.props.language].home}</NavLink>
                    {
						(this.props.countyConfigEfile.efile === true) &&
                        <NavLink to="/efile" className="top-navlink">{translations[this.props.language].e_file}</NavLink>
                    } 
                    {
                         <NavLink to="/search" className="top-navlink">{translations[this.props.language].search}</NavLink>
                    }
                    {
                        (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY' || this.props.role === 'ACCOUNT') &&
                        <>
                        <NavLink to="/reports" className="top-navlink">Reports</NavLink>
                        <NavLink to="/userManager" className="top-navlink">User Manager</NavLink> 
                        </>
                    }
                    { 
                        (this.props.role === 'TRIMIN' || this.props.role === 'COUNTY' ) &&
                        <NavLink to="/controlPanel" className="top-navlink top-navlink-last">Control Panel</NavLink>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    }

}

export default connect(mapStateToProps)(Navigationbar);
