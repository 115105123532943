import React, { Component } from 'react';
import { connect } from "react-redux";
import FormTemplate from "../../common/form/FormTemplate";
import { loadDocuments, setSelectedSearchType, SearchByDateKey, SearchByDateUrl } from "../../../redux/actions/searchActions";
import { validateDates, validateMonthRangeLimit } from "../../../utils/DateValidation";
import { LOADING_STATUES } from "../../constants/constants";
import translations from "../../../config/translation/search_by_date_translations.json";

import moment from 'moment';

import axios from "axios";
import configData from "../../../config/config.json";
import { handleError } from "../../../redux/actions/commonStuff";
import AjaxResult from "../../../utils/AjaxResult";
import NotableDates from '../../home/NotableDates';

const componentName = SearchByDateKey;

const matchDispatchToProps = (dispatch) => {
  return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByDate: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByDateUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName }),
    resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
    resetSearchSequenceDocumentNumber: () => dispatch({ type: 'SEARACH_SEARCH_SEQUENCE_DOCUMENT'}),
    resetSearchDetail: () => dispatch({ type: 'RESET_SEARCH_DETAILS'})
  }
}

const mapStateToProps = (state, ownProps) => {

  let previousSearchKeyValue = {};
  if (ownProps.match.params.searchHistoryNumber) {
    let searchHistoryIndex = ownProps.match.params.searchHistoryNumber - 1;
    if (searchHistoryIndex >= 0) {
      let searchHistory = state.searchHistoryReducer.searchHistoryList[searchHistoryIndex];
      if (searchHistory) {
        searchHistory.displayCriteria.forEach((criteria) => {
          previousSearchKeyValue[criteria.key] = criteria.value;
        })
      }
    }
  } else {
    let searchCriteria = state.searchCriteriaReducer[componentName];
    if (searchCriteria) {
      searchCriteria.forEach((criteria) => {
        previousSearchKeyValue[criteria.key] = criteria.value;
      })
    }
  }

  let toRecordedDate = previousSearchKeyValue.toRecordedDate;
  if (!toRecordedDate && state.countyConfigNotableDatesReducer.useVerificationDate === true) {
    toRecordedDate = state.landlinkConfigReducer.abstractVerifiedDate;
  }
  
  if (!toRecordedDate) {
    toRecordedDate = moment().format('L');
  }

  return {
    notableDates: state.notableDatesReducer,
    useVerificationDate: state.countyConfigNotableDatesReducer.useVerificationDate,
    abstractVerifiedDate: state.landlinkConfigReducer.abstractVerifiedDate,
    isPropertyCode: state.landlinkConfigReducer.propertyCode,
    allOnlySearch: state.countyConfigSearch2Reducer.allOnlySearch,
    documentTypes: state.documentTypesReducer.codeDescriptionResultList,
    documentType: previousSearchKeyValue.documentType ? previousSearchKeyValue.documentType : 'ALL',
    creditCardUser: state.currentUserReducer.paymentMethod === 'CREDIT_CARD' ? true : false,
    instrumentGroups: state.instrumentGroupsReducer.codeDescriptionResultList,
    fromRecordedDate: previousSearchKeyValue.fromRecordedDate ? previousSearchKeyValue.fromRecordedDate : '',
    toRecordedDate,
    instrumentGroup: previousSearchKeyValue.instrumentGroup ? previousSearchKeyValue.instrumentGroup : 'ALL',
    language: state.currentUserReducer.language ? state.currentUserReducer.language : 'ENGLISH'
  };
}

class SearchByDate extends Component {

  state = {
    documentType: this.props.documentType,
    fromRecordedDate: this.props.fromRecordedDate,
    toRecordedDate: this.props.toRecordedDate,
    instrumentGroup: this.props.instrumentGroup,
    loadingStatus: "",
    submitMessage: '',

    token: '',
    creditCardPriceListWindowShow: false,
    creditCardAuthorizeDotNetWindowShow: false,
    creditCardPriceListReAuthWindowShow: false,

    errors: {}
  }

  // Same code used in all searches - BEGIN

  componentDidMount() {
    this.props.setSelectedSearchType();
    if (this.props.creditCardUser) {
      axios.post(configData.LANDSHARK_SERVER_URL + "creditcard/authorize", {}, { withCredentials: true })
        .then((response) => {

          let result = new AjaxResult(response);

          if (!result.isOk()) {
            result.handleError();
          } else {
            this.setState({ token: result.content().token });
          }
        }, (error) => {
          handleError(error, this.props.history);
        })
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
      submitMessage: '',
      errors: {}
    })
  }

  showLoading = () => {
    if (this.state.loadingStatus === "") {
      this.setState({
        loadingStatus: LOADING_STATUES.LOADING
      })
      return true;
    }
    return false;
  }

  hideLoading = (message, status, content) => {
    if (content.paymentNeededAgain === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListReAuthWindowShow: true
      })
    } else if (content.paymentNeeded === true) {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: true
      })
    } else if (status === 'ERROR') {
      this.setState({
        loadingStatus: "",
        submitMessage: "",
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    } else {
      this.setState({
        loadingStatus: "",
        submitMessage: message,
        creditCardPriceListWindowShow: false,
        creditCardPriceListReAuthWindowShow: false,
        creditCardAuthorizeDotNetWindowShow: false
      })
    }
  }

  handleCreditCardAuthorizeDotNetWindowClose = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: false
      });
  }

  handleCreditCardPriceListClose = () => {
    this.setState(
      {
        creditCardPriceListWindowShow: false
      });
  }

  handleCreditCardPriceListContinue = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuth = () => {
    this.setState(
      {
        creditCardAuthorizeDotNetWindowShow: true
      });
  }

  handleCreditCardPriceListReAuthWindowClose = () => {
    this.setState(
      {
        creditCardPriceListReAuthWindowShow: false
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.showLoading() === true) {
        this.handleAuthorizedSubmit();
      }
    }
  }

  // Same code used in all searches - END

  handleAuthorizedSubmit = () => {
    this.props.resetSearchSequence();
    this.props.resetSearchSequenceDocumentNumber();
    this.props.resetSearchDetail();
    const { loadingStatus, submitMessage, errors, token, creditCardPriceListWindowShow, creditCardAuthorizeDotNetWindowShow, creditCardPriceListReAuthWindowShow, ...request } = this.state; // remove UI only fields before submitting
    this.props.searchByDate(request, this.props.history, this.hideLoading);
  }

  handleValidation() {
    let errors = {};
    if (!this.state.fromRecordedDate) {
      errors['fromRecordedDate'] = translations[this.props.language].required;
      this.setState({ errors: errors });
      return false;
    }

    let formIsValid = validateDates(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    formIsValid = validateMonthRangeLimit(errors, this.state.fromRecordedDate, 'fromRecordedDate', this.state.toRecordedDate, 'toRecordedDate');
    if (!formIsValid) {
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ errors: errors });
    return true;
  }

  handleClear = (event) => {
    event.preventDefault();
    this.props.resetSearchCriteria();

    let toRecordedDate = moment().format('L');
    if (this.props.useVerificationDate === true) {
      toRecordedDate = this.props.abstractVerifiedDate;
    }

    this.setState({
      documentType: 'ALL',
      fromRecordedDate: '',
      toRecordedDate,
      instrumentGroup: 'ALL',
      submitMessage: '',
      errors: {}
    })
  }

  handleDatePickerChangeFromRecordedDate = (date) => {
    this.setState({
      fromRecordedDate: moment(date).format('L')
    })
  }

  handleDatePickerChangeToRecordedDate = (date) => {
    this.setState({
      toRecordedDate: moment(date).format('L')
    })
  }

  render() {
    let formGroupRows = [
      {
        formGroups: [{ id: 'fromRecordedDate', label: translations[this.props.language].date, fieldType: 'date', primaryCriteria: true, value: this.state.fromRecordedDate, handleDatePickerChange: this.handleDatePickerChangeFromRecordedDate, error: this.state.errors['fromRecordedDate'] }]
      },
      {
        formGroups: [{ id: 'toRecordedDate', label: translations[this.props.language].to, fieldType: 'date', primaryCriteria: true, value: this.state.toRecordedDate, handleDatePickerChange: this.handleDatePickerChangeToRecordedDate, error: this.state.errors['toRecordedDate'] }]
      }
    ]

    if (this.props.isPropertyCode === true && this.props.allOnlySearch !== true && this.props.allOnlySearch !== 'true') {
          formGroupRows.push({
        formGroups: [{ id: 'documentType', label: translations[this.props.language].document_type, fieldType: 'select', value: this.state.documentType, options: this.props.documentTypes }]
      });
    }

    formGroupRows.push({
      formGroups: [{ id: 'instrumentGroup', label: translations[this.props.language].instrument_group, fieldType: 'select', options: this.props.instrumentGroups, value: this.state.instrumentGroup }]
    });

    const formProps = {
      componentName: componentName,
      pageHeading: translations[this.props.language].search_by_date,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit,
      onClear: this.handleClear,
      successIndicator: this.state.loadingStatus === LOADING_STATUES.SUCCESS,
      loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
      submitMessage: this.state.submitMessage,
      primaryButtonLabel: translations[this.props.language].submit,
      clearButtonLabel: translations[this.props.language].clear,
      formGroupRows: formGroupRows,
      formRightArea: <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />,
      handleAuthorizedSubmit: this.handleAuthorizedSubmit,

      // price list window
      creditCardPriceListWindowShow: this.state.creditCardPriceListWindowShow,
      handleCreditCardPriceListClose: this.handleCreditCardPriceListClose,
      handleCreditCardPriceListContinue: this.handleCreditCardPriceListContinue,

      // authoriz.net iframe window
      creditCardAuthorizeDotNetWindowShow: this.state.creditCardAuthorizeDotNetWindowShow,
      handleCreditCardAuthorizeDotNetWindowClose: this.handleCreditCardAuthorizeDotNetWindowClose,
      authorizeDotNetToken: this.state.token,

      // re-preauth price list window
      creditCardPriceListReAuthWindowShow: this.state.creditCardPriceListReAuthWindowShow,
      handleCreditCardPriceListReAuth: this.handleCreditCardPriceListReAuth,
      handleCreditCardPriceListReAuthWindowClose: this.handleCreditCardPriceListReAuthWindowClose
    }

    return <FormTemplate {...formProps} />
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchByDate);