import React, { Component } from 'react';
import { connect } from "react-redux";
import { loadDocuments, setSelectedSearchType, SearchByDocumentNumberKey, SearchByDocumentNumberUrl } from '../../../redux/actions/searchActions';
import { LOADING_STATUES } from "../../constants/constants";
import Alert from '../../common/modal/Alert';
import Loading from '../../common/modal/Loading';

const componentName = SearchByDocumentNumberKey;

const matchDispatchToProps = (dispatch) => {
	return {
    setSelectedSearchType: () => dispatch(setSelectedSearchType(componentName)),
    searchByDocumentNumber: (searchCriteria, history, callback) => dispatch(loadDocuments(searchCriteria, history, callback, SearchByDocumentNumberUrl)),
    resetSearchCriteria: () => dispatch({ type: 'RESET_SEARCH_CRITERIA', componentName })
	}
}

class SearchByDocumentNumberExternal extends Component {
	state = {
	  showAlert: false,
		alertMessage: 'Could not find requested Document Number',
		loadingStatus: LOADING_STATUES.LOADING
	}

	searchCallback = (message, okay, content) => {
		if (message === 'No Records Found.') {
			this.setState({
				showAlert: true,
				loadingStatus: LOADING_STATUES.SUCCESS
			})
		} else {
			this.setState({
				loadingStatus: LOADING_STATUES.SUCCESS
			})
		}
	}

	hideAlert = () => {
		this.props.history.push('/search');
	}

	componentDidMount() {
		this.props.setSelectedSearchType();
    let documentNumber = this.props.match.params.id;

		let request = {			
        documentType: 'ALL',
		    fromDocumentNumber: documentNumber
		}
		this.props.searchByDocumentNumber(request, this.props.history, this.searchCallback);
  }

  render() {
		return (
			<>
				<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING } />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
			</>
		)
	}
}

export default connect(null, matchDispatchToProps)(SearchByDocumentNumberExternal);